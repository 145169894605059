import React from "react";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {
    getRetailerName,
    loadRetailerMap
} from "../../utils/retailerMapCache";
import Page from "../Components/Page";
import { useServiceBase } from "../Components/ReactTable/useServiceBase";
import { LoadingWrapper } from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD,} from "../Components/ReactTable/common_custom_columns";
import {
    EditFormDropdownInputField,
    EditFormNumberInputField,
    EditFormTextInputField
} from "../Components/ReactTable/EditRecordForm";

function RetailerService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/retailers/`;
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl });
    const onRowEditWrapped = async (...args) => {
        await onRowEditConfirmed(...args);
        await loadRetailerMap();
    };

    const onRowDeleteWrapped = async (...args) => {
        await onRowDeleteConfirmed(...args);
        await loadRetailerMap();
    }
    const rollUpDayOfWeek = [
        { value: "SUNDAY", label: "SUNDAY" },
        { value: "MONDAY", label: "MONDAY" },
        { value: "TUESDAY", label: "TUESDAY" },
        { value: "WEDNESDAY", label: "WEDNESDAY" },
        { value: "THURSDAY", label: "THURSDAY" },
        { value: "FRIDAY", label: "FRIDAY" },
        { value: "SATURDAY", label: "SATURDAY" }
    ];
    const retailerType = [{ value: "CHAIN", label: "CHAIN" }, { value: "INDEPENDENT", label: "INDEPENDENT" }];

    const customColumns = [
        {
            header: "Retailer",
            id: "retailerIdName",
            filterable: true,
            accessorKey: "retailerId",
            size: 200,
            cell: ({ row }) => <RetailerIdAndName retailerId={row.original.retailerId} />,
            filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.retailerId} ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
        },
        {
            header: "Retail Tag",
            accessorKey: "retailTag"
        },
        {
            header: "Retailer Type",
            accessorKey: "retailerType"
        },
        {
            header: "Roll Up Day Of Week",
            accessorKey: "rollUpDayOfWeek"
        },
        {
            header: "Scv Growth Factor",
            accessorKey: "scvGrowthFactor"
        },
        {
            header: "Svc Acv Inflation Factor",
            accessorKey: "svcAcvInflationFactor"
        }
    ];
    return (
        <Page name={"All Retailers"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={customColumns}
                    initialState={{ pagination: { pageSize: 25 }, sorting: [{ id: "retailerIdName", desc: false }] }}
                    editTable={[
                        INDEX_FIELD,
                        {id: "retailChain", Component: args => <EditFormTextInputField  {...args} isRequired label={"Retail Chain"} />},
                        {id: "retailTag", Component: args => <EditFormNumberInputField  {...args} isRequired />},
                        {id: "retailerId", Component: args => <EditFormNumberInputField  {...args} disabled label={"Retailer Id"} />},
                        {id: "retailerType", Component: args => <EditFormDropdownInputField  {...args} isRequired  options={retailerType}/>},
                        {id: "rollUpDayOfWeek", Component: args => <EditFormDropdownInputField  {...args} isRequired  options={rollUpDayOfWeek}/>},
                        {id: "scvGrowthFactor", Component: args => <EditFormNumberInputField  {...args}  />},
                        {id: "svcAcvInflationFactor", Component: args => <EditFormNumberInputField  {...args}  />},

                    ]}
                    onRowEditConfirmed={onRowEditWrapped}
                    onRowDeleteConfirmed={onRowDeleteWrapped}
                />
            </LoadingWrapper>
        </Page>
    );
}

export default RetailerService;
