import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getPriorPeriodStartWeekId} from '../../../utils/periodUtils';
import {STORE_TOTALS_DEFAULT_SELECTIONS} from './TotalsSearchOptionsModal';
import Select from "react-select";
import {addLabelToOptionsIfNotExist} from "../../../utils/maybeAddLabelToOptions";

const AdditionalPeriodsDropdown = ({endPeriod, onChange}) => {
    const [additionalPeriods, updateAdditionalPeriods] = useState(STORE_TOTALS_DEFAULT_SELECTIONS.additionalPeriods);
    useEffect(() => {
        getPriorPeriodStartWeekId(STORE_TOTALS_DEFAULT_SELECTIONS.additionalPeriods.value, endPeriod.quadId)
            .then(startWeekId => onChange(startWeekId));
    }, [endPeriod, onChange]);
    return (

        <div className={'id-input'} data-testid={"additional-periods-wrapper"}>
            {
                <Select
                    className={"select-dropdown"}
                    classNamePrefix={"select-dropdown"}
                    id={'additional_periods_dropdown'}
                    value={additionalPeriods}
                    options={addLabelToOptionsIfNotExist([...Array(14).keys()])}
                    onChange={async (option) => {
                        updateAdditionalPeriods(option);
                        onChange(await getPriorPeriodStartWeekId(option.value, endPeriod.quadId))
                    }}
                />
            }
        </div>
    );
};


AdditionalPeriodsDropdown.propTypes = {
    endPeriod: PropTypes.shape({
        periodAbbreviation: PropTypes.string,
        weekId: PropTypes.number,
        quadId: PropTypes.number
    }),
    onChange: PropTypes.func
};

export default AdditionalPeriodsDropdown;
