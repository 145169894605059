import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useRedirectMessage} from "../../../utils/redirectMessage";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import Page from "../../Components/Page";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {toastError} from "../../../utils/toast";
import {SELECT_COLUMN} from "../../Components/ReactTable/CheckboxColumnComponent";
import IconImage, {Icons} from "../../Components/IconImage";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {toast} from "react-toastify";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import ReactTable from "../../Components/ReactTable";
import _ from "lodash";
import {getStringParamOrDefault} from "../../../utils/urlBuilder";
import NameValueLabels from "../../Components/NameValueLabels";


const MarketsToStores = () => {
    const params = useParams()
    const marketId = parseInt(params.marketId)
    const periodAbbreviation = getStringParamOrDefault('period')

    useRedirectMessage();

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/marketsToStores/?market_id=${marketId}&period_abbreviation=${periodAbbreviation}`;

    const [marketsToStores, setMarketToStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [unassignStoresModalVisible, setUnassignStoresModalVisible] = useState(false)
    const [marketDescription, setMarketDescription] = useState(undefined);


    const loadMarketToStores = () => {
        setLoading(true);
        makeApiCall(endpointUrl)
            .then(data => {
                setMarketToStores(data.data)
                const uniqMarketDescription = _.uniqBy(data.data.map(row => row.market_description).sort())
                setMarketDescription(uniqMarketDescription.length > 0 ? uniqMarketDescription[0] : undefined);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadMarketToStores()
    }, []);

    const saveUnassignStoresAndRefresh = (selectedRows) => {
        setLoading(true)
        setUnassignStoresModalVisible(false)
        return makeApiCallPost(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/marketsToStores/unassignStores`,
            {
                marketId: marketId,
                storeIds: selectedRows.map(row => row.original.storeId),
            }
        ).then((response) => {
            if (response?.apiError) {
                console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                toastError(toast, `Issue unassigning stores`)
                setLoading(false);
            }
        }).then(() => loadMarketToStores())
    }

    return (<Page beta name={"Market To Stores"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Services"},
                      {label: "Market", to: `/marketService?selectedMarketPeriod=${periodAbbreviation}`},
                      {label: `${marketId}`}]}
                  />}
    >
        <LoadingWrapper loading={loading}>
            <NameValueLabels labelValuePairs={[
                {label: "Market:", value: `${marketDescription} (${marketId})`},
                {label: "Period:", value: periodAbbreviation}
            ]}/>
            <ReactTable
                filterable
                data={marketsToStores}
                columns={[
                    SELECT_COLUMN,
                    {header: "Market Id", accessorKey: "marketId"},
                    {header: "Market Tag", accessorKey: "marketKey"},
                    {header: "Store Id", accessorKey: "storeId"},
                    {header: "Store Name", accessorKey: "store_name"},
                ]
                }
                initialState={{pagination: {pageSize: 25}}}
            >
                {
                    ({table}) => {
                        const selectedRows = table.getSelectedRowModel().rows;
                        return (
                            <span className={'designation-action-buttons-container'}
                                  data-testid={"stores-action-button-container"}>
                                 <a type={"button"} className={"button primary"}
                                    href={`/marketsToStores/assignStore/${marketId}?period=${periodAbbreviation}`}>Assign Store</a>
                                 <div className="designation-header-component-divider"/>
                                 <button type={'button'} aria-label={'un-assign'}
                                         onClick={() => setUnassignStoresModalVisible(true)}
                                         className={"designation-action-button"}
                                         disabled={!selectedRows?.length}>
                                     <IconImage iconType={Icons.UN_ASSIGN}/>
                                 </button>
                                {unassignStoresModalVisible && <ConfirmationModal
                                    confirmButtonClass={"danger-button"}
                                    confirmButtonText={"Unassign"}
                                    headerText={`Unassign ${selectedRows.length === 1 ? 'Store' : 'Stores'}`}
                                    cancellationCallback={() => setUnassignStoresModalVisible(false)}
                                    confirmationCallback={() => saveUnassignStoresAndRefresh(selectedRows)}>
                                    {`Unassign ${selectedRows.length} ${selectedRows.length === 1 ? 'store' : 'stores'} from`}&nbsp;
                                    <b>{`${marketId}`}</b>
                                </ConfirmationModal>}
                            </span>
                        )
                    }
                }
            </ReactTable>
        </LoadingWrapper>
    </Page>);
};

export default MarketsToStores;