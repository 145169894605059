import PropTypes from "prop-types";
import _ from "lodash";
import {ContextMenuTrigger} from "react-contextmenu";
import React from "react";
import {getDateAndDayHeader} from "./getDateAndDayHeader";

const StoreTotalsHeaderComponent = ({retailerId, date, contextMenuId, stores, setTimestamp, className, fillWaterMark, promoWatermark}) => {
    return <div className={className}>
        <ContextMenuTrigger id={contextMenuId} retailerId={retailerId} date={date}
                            storeIds={stores ? stores.map(data => data.storeId): []}
                            setTimestamp={setTimestamp}
                            header={true}
                            collect={_.identity}>{getDateAndDayHeader(date, fillWaterMark, promoWatermark)}</ContextMenuTrigger>
    </div>;
}

StoreTotalsHeaderComponent.propTypes = {
    retailerId: PropTypes.string,
    className: PropTypes.string,
    date: PropTypes.string,
    contextMenuId: PropTypes.string,
    stores: PropTypes.arrayOf(PropTypes.shape({
        storeId: PropTypes.number
    })),
    setTimestamp: PropTypes.func
}

export function getHeader(retailerId, date, contextMenuId, setTimestamp, className, fillWatermark, promoWatermark) {
    return ({ table }) => {
        const data = table.getCoreRowModel().rows.map(x => x.original);
        return <StoreTotalsHeaderComponent
            className={className}
            date={date}
            contextMenuId={contextMenuId}
            setTimestamp={setTimestamp}
            stores={data}
            retailerId={retailerId}
            fillWaterMark={fillWatermark}
            promoWatermark={promoWatermark}
        />;
    };
}