import React from "react";
import PropTypes from 'prop-types';
import OpsAlertsContent from "../../OpsAlerts/OpsAlertsContent";
import ApiGetContainer from "../../../Api/ApiGetContainer";
import Page from "../../Components/Page";

export const AlertTab = ({retailerId}) => {
    const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/opsAlertLogsByDate/?retailerId=${retailerId}`
    return (
        <Page name="Unacknowledged Alerts">
            <ApiGetContainer
                endpoint={url}
                componentToRender={OpsAlertsContent}
                componentToRenderProps={{url: url}}
            />
        </Page>
    )
}

AlertTab.propTypes = {
    retailerId: PropTypes.number
}