import React from 'react';
import PropTypes from "prop-types";
import CollapsableContent from "../../Components/CollapsableContent";


const MissingDatesCell = (props) => {

    const expandedContent = <ul style={{listStyleType: "none"}}>
        {props.dates.map((date, key) => {
            return <li key={key}>
                {date}
            </li>
        })}
    </ul>;

    return <CollapsableContent collapsedContent={props.dates.length} expandedContent={expandedContent} />
};

MissingDatesCell.propTypes = {
    dates: PropTypes.array
};

export default MissingDatesCell;