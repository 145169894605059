import React, {useState} from 'react';
import OptionalDropDown from './OptionalDropDown';
import PropTypes from 'prop-types';
import {getValueFromOptions} from "../../utils/maybeAddLabelToOptions";

const OptionalDropDownApi = ({
                                 checkboxText,
                                 labelText,
                                 helpText,
                                 selectedDropDownValue,
                                 checkBoxIsChecked,
                                 onCheckBoxChange,
                                 onDropDownChange,
                                 onParseApiResponse,
                                 apiRequest,
                                 optionalClassName,
                                 placeholder
                             }) => {

    const [dropDownValues, updateDropDownValues] = useState([])

    React.useEffect(() => {
        let canceled = false;
        apiRequest()
            .then(result => {
                if (!canceled) {
                    return onParseApiResponse(result);
                }
                return undefined;
            })
            .then(apiDropDownValues => {
                if (!canceled && apiDropDownValues) {
                    updateDropDownValues(apiDropDownValues)
                }
            })
        return () => {
            canceled = true
        }
    }, [apiRequest, onParseApiResponse])


    return (
        <OptionalDropDown checkboxText={checkboxText}
                          labelText={labelText}
                          helpText={helpText}
                          selectedDropDownValue={getValueFromOptions(selectedDropDownValue, dropDownValues)}
                          checkBoxIsChecked={checkBoxIsChecked}
                          onCheckBoxChange={onCheckBoxChange}
                          onDropDownChange={onDropDownChange}
                          dropDownValues={dropDownValues}
                          optionalClassName={optionalClassName}
                          placeholder={placeholder}
        />
    )
}

OptionalDropDownApi.defaultProps = {
    placeholder: "Select..."
}

OptionalDropDownApi.propTypes = {
    onCheckBoxChange: PropTypes.func.isRequired,
    onDropDownChange: PropTypes.func.isRequired,
    checkBoxIsChecked: PropTypes.bool.isRequired,
    selectedDropDownValue: PropTypes.string,
    checkboxText: PropTypes.string,
    labelText: PropTypes.string,
    helpText: PropTypes.string,
    onParseApiResponse: PropTypes.func.isRequired,
    apiRequest: PropTypes.func.isRequired,
    optionalClassName: PropTypes.string,
    placeholder: PropTypes.string
}

export default OptionalDropDownApi