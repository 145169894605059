import { sortAscendingWithNullsLast } from "../../../utils/sorting";
import { designationTypeLabels } from "./types";
import React from "react";
import { designationTypes } from "./Designations";
import ReactTableFilterDropdown from "../../ReactTable/ReactTableFilterDropdown";
import {wrapValueInOptionStructure} from "../../../utils/maybeAddLabelToOptions";

export const DESIGNATION_COLUMNS = [
    {
        header: "Designation",
        id: 'designation',
        accessorKey: 'designation',
        cell: ({row}) => <a href={`/designation/${row.original.index}`}
                            rel="noopener noreferrer">{`${row.original.index} - ${row.original.name}`}</a>,
        filterFn: (row, columnId, filterValue) => {
            return !filterValue || Boolean(`${row.original.index} - ${row.original.name?.toLowerCase()}`.includes(filterValue.toLowerCase()));
        },
        sortingFn: (rowA, rowB) => sortAscendingWithNullsLast(rowA.original.index, rowB.original.index)
    },
    {
        header: "Designation Type",
        id: "type",
        accessorFn: ({type}) => designationTypeLabels[type],
        filterFn: (row, columnId, filterValue) => {
            return !filterValue || row.getValue(columnId) === filterValue;
        },
        filter: (theseProps) => {
            const options = designationTypes.map(type => type.label).map(label => wrapValueInOptionStructure(label))
            return <ReactTableFilterDropdown
                id={"designation_type"}
                options={options}
                onChange={(selected) => theseProps.column.setFilterValue(selected)}
                selectedValue={theseProps.column.getFilterValue()}
                isClearable={true}
            />
        }
    }
];