import React from 'react';
import Page from "../Components/Page";
import ApiGetContainer from "../../Api/ApiGetContainer";
import {OpsAlertsSummaryContent} from "./OpsAlertsSummaryContent";

export const OpsAlertsSummary = () => {
    return <Page name="Alerts Summary">

        <ApiGetContainer
            endpoint={`${process.env.REACT_APP_EDP_API_BASE_URL}/opsAlertLogs/?days=7`}
            componentToRender={OpsAlertsSummaryContent}
            dataPreProcess={(data) => {
                return {
                    metadata: data.metadata.map(alert => {
                        return {
                            ...alert,
                            processedPayload: JSON.parse(alert.payload)
                        }
                    }),
                    taskTypes: data.taskTypes
                };
            }}
        />
    </Page>
};
