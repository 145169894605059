import React from "react";
import {EditFormDropdownInputField, EditFormTextInputField} from "../../Components/ReactTable/EditRecordForm";
import {validUSStates} from "../../../Api/googleMaps/address";

export const nonUsLabelText = 'State (Non-US)';
export const usLabelText = 'State (US)';
export const usStateOptions = validUSStates.map(state => ({value: state, label: state}));

export function AddressStateInputField({form}) {

    const id = 'state'
    const country = form.watch('country')
    
    return country === 'US' ?
        <EditFormDropdownInputField id={id} form={form} label={usLabelText} options={usStateOptions} isClearable={true}/> :
        <EditFormTextInputField id={id} form={form} label={nonUsLabelText}/>
}
