import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import {prettyFormatJsonString} from "../../utils/formatJson";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

const JsonPayloadModal = ({value, buttonText, onClick, className, tooltipTitle}) => {
    const [isModalOpen, updateIsModalOpen] = useState(false)
    const [modalValue, updateModalValue] = useState(value)
    return <>
        <div className={'open-json-payload-modal'}>
            <button
                className={className}
                onClick={async () => {
                    const results = onClick !== undefined ? await onClick() : value;
                    updateModalValue(results)
                    updateIsModalOpen(true);
                }}
                title={tooltipTitle}
                data-testid={'json-payload-modal-button'}
            >{buttonText}</button>
        </div>
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => updateIsModalOpen(false)}
            ariaHideApp={false}
            className="Modal alert-payload-modal"
        >
            <h2>Details</h2>
            <AceEditor
                mode="json"
                theme="github"
                name="json_payload_ace"
                editorProps={{$blockScrolling: true}}
                value={prettyFormatJsonString(modalValue)}
                readOnly={true}
            />
            <button className="hollow button secondary modal-button" type="button" name="close"
                    onClick={() => updateIsModalOpen(false)}>
                Close
            </button>
        </Modal>
    </>;
};

JsonPayloadModal.defaultProps = {
    className: 'button tertiary',
    buttonText: 'JSON',
    onClick: undefined,
    tooltipTitle: undefined
};

JsonPayloadModal.propTypes = {
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.any,
    onClick: PropTypes.func,
    tooltipTitle: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default JsonPayloadModal;