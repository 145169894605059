import React from 'react';
import {getBooleanParamOrDefault, getStringParamOrDefault} from '../../../utils/urlBuilder';
import Page from '../../Components/Page';
import NameValueLabels from '../../Components/NameValueLabels';
import ApiGetContainer from '../../../Api/ApiGetContainer';
import ExtractSpotlightDrillDownTables from './ExtractSpotlightDrillDownTables';
import _ from 'lodash';


const ExtractSpotlightDrillDown = () => {
    const retailerId = getStringParamOrDefault("retailerId", undefined);
    const retailerName = getStringParamOrDefault("retailerName", undefined);
    const fromSchemaSelection = getStringParamOrDefault("fromSchemaSelection", undefined);
    const toSchemaSelection = getStringParamOrDefault("toSchemaSelection", undefined);
    const ignorePlu = getBooleanParamOrDefault("ignorePlu", false);
    const ignoreIfps = getBooleanParamOrDefault("ignoreIfps", false);
    const thresholdDollars = getStringParamOrDefault("thresholdDollars", undefined);
    const thresholdUnits = getStringParamOrDefault("thresholdUnits", undefined);
    const weeks = getStringParamOrDefault("weeks", undefined);
    const problemWeeks = getStringParamOrDefault("problemWeeks", undefined);
    const percentGood = getStringParamOrDefault("percentGood", undefined);

    return (<Page name={`Cleanse Fill Extract: (${retailerId}) ${retailerName}`}>
        <div className={'spotlight-selection-group'}>
            <div className={'column'}>
                <h6 className={'spotlight-header'}>THRESHOLD %</h6>
                <div className={'threshold-selection'}>
                    <div className={'dollar-threshold-field'}>
                        <NameValueLabels containerClassName={"threshold-dollars-name-value-label"} labelValuePairs={[{label: "Dollars:", value: thresholdDollars}]}/>
                    </div>
                    <div className={'unit-threshold-field'}>
                        <NameValueLabels containerClassName={"threshold-units-name-value-label"} labelValuePairs={[{label: "Units:", value: thresholdUnits}]}/>
                    </div>
                </div>
            </div>
            <div className={'spotlight-divider'}/>
            <div className={'column'}>
                <h6 className={'spotlight-header'}>COMPARE</h6>
                <div className={'schema-selection'}>
                    <div className={'schema-field'}>
                        <NameValueLabels containerClassName={"from-schema-selection-name-value-label"}
                                         labelValuePairs={[{label: "From Table:", value: fromSchemaSelection}]}/>
                    </div>
                    <div className={'schema-field'}>
                        <NameValueLabels containerClassName={"to-schema-selection-name-value-label"}
                                         labelValuePairs={[{label: "To Table:", value: toSchemaSelection}]}/>
                    </div>
                    <div className={'schema-field'}>
                        <NameValueLabels containerClassName={"ignore-plu-selection-name-value-label"}
                                         labelValuePairs={[{label: "Ignore PLU:", value: ignorePlu.toString()}]}/>
                    </div>
                    <div className={'schema-field'}>
                        <NameValueLabels containerClassName={"ignore-ifps-selection-name-value-label"}
                                         labelValuePairs={[{label: "Ignore IFPS:", value: ignoreIfps.toString()}]}/>
                    </div>
                </div>
            </div>
            <div className={'column'}>
                <h6 className={'spotlight-header'}>Retailer Totals</h6>
                <div className={'threshold-selection'}>
                    <div className={'weeks-field'}>
                        <NameValueLabels containerClassName={"weeks-name-value-label"} labelValuePairs={[{label: "Weeks:", value: weeks}]}/>
                    </div>
                    <div className={'problem-weeks-field'}>
                        <NameValueLabels containerClassName={"problem-weeks-name-value-label"} labelValuePairs={[{label: "Problem Weeks:", value: problemWeeks}]}/>
                    </div>
                    <div className={'percent-good-field'}>
                        <NameValueLabels containerClassName={"percent-good-name-value-label"} labelValuePairs={[{label: "% Good:", value: _.round(percentGood*100, 2)}]}/>
                    </div>
                </div>
            </div>
        </div>

        <ApiGetContainer
            graphqlQuery={`query { getExtractSpotlightDrillDown(fromTable:"${fromSchemaSelection}", 
                                              toTable:"${toSchemaSelection}",
                                              ignorePlu:${ignorePlu},
                                              ignoreIfps:${ignoreIfps},
                                              dollarsPercentThreshold: ${thresholdDollars/100},
                                              unitsPercentThreshold: ${thresholdUnits/100},
                                              retailerId: ${retailerId}
                          ){
                            drillDownGroup {
                            weekId
                            dollarsFrom
                            dollarsTo
                            dollarsChangePercentage
                            unitsFrom
                            unitsTo
                            unitsChangePercentage
                            }
                          }
                        }`}
            dataAccessor={'getExtractSpotlightDrillDown'}
            componentToRender={ExtractSpotlightDrillDownTables}
            componentToRenderProps={{
                thresholdDollars,
                thresholdUnits
            }}
        />

    </Page>);
};

ExtractSpotlightDrillDown.defaultProps = {};

ExtractSpotlightDrillDown.propTypes = {};

export default ExtractSpotlightDrillDown;