

function _toConsumableArray(arr) { if (Array.isArray(arr)) { let arr2 = Array(arr.length);
    let i = 0;
    for (; i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

export function downloadBlobToFile(blob, filename) {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
        return;
    }
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default function CsvDownload(data, name, nullReplace) {
    const items = data;
    const filename = name || 'export.csv';

    const header = Array.from(new Set(items.reduce(function (r, e) {
        return [].concat(_toConsumableArray(r), _toConsumableArray(Object.keys(e)));
    }, [])));
    let csv = items.map(function (row) {
        return header.map(function (fieldName) {
            return JSON.stringify(row[fieldName] || nullReplace);
        }).join(',');
    });
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');

    const blob = new Blob([csv], {
        type: 'text/plain;charset=utf-8'
    });
    downloadBlobToFile(blob, filename);
};
