import React from 'react';
import DoNotCheckDigit from "../../Configurations/DoNotCheckDigit";
import BlockedItemCodes from "../../Configurations/BlockedItemCodes";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export const ItemsTab = ({retailerId}) => {
    return (
        <Tabs>
            <TabList>
                <Tab data-testid={"blockedItemCodes-tab-name"}>Blocked Item Codes</Tab>
                <Tab data-testid={"doNotCheckDigit-tab-name"}>Do Not Check Digit</Tab>
            </TabList>
            <TabPanel data-testid={"blockedItemCodes-tab-panel"}><BlockedItemCodes displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"doNotCheckDigit-tab-panel"}><DoNotCheckDigit displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
        </Tabs>
    )
};
