import {getStringParamOrDefault, updateQueryStringForState} from "./urlBuilder";
import React from "react";

export function queryParamFilter(filterId) {
    return (props) => {
        const queryParam = getStringParamOrDefault(filterId, '');
        return (
            <input
                onChange={(event) => {
                    props.onChange(event.target.value)
                    updateQueryStringForState({[filterId]: event.target.value});
                }}
                value={props.filter ? props.filter.value : queryParam}/>
        );
    };
}

export function queryParamFilterReactTable7(filterId) {
    return (props) => {
        const queryParam = getStringParamOrDefault(filterId, '');

        return (
            <input
                onChange={(event) => {
                    props.column.setFilterValue(event.target.value || undefined)
                    updateQueryStringForState({[filterId]: event.target.value});
                }}
                value={props.column.getFilterValue() ? props.column.getFilterValue() : queryParam}/>
        );
    };
}