import React from 'react';
import PropTypes from 'prop-types';
import FtpConfigs from "../../Configurations/FtpConfigs";
import {CONFIG_TYPES} from "../../Configurations/FileTypes";

const FtpConfig = (props) => {
    return (<div>
        {props.getValue() && <FtpConfigs
            ftp={props.getValue()}
            buttonTitle={props.getValue()}
            configType={CONFIG_TYPES.FILE_TYPE}
        />}
    </div>);
};


FtpConfig.propTypes = {
    value: PropTypes.string
};

export default FtpConfig;