import React from 'react'
import Page from "../Components/Page";
import ApiGetContainer from "../../Api/ApiGetContainer";
import {MissingStoreAcvContent} from "./MissingStoreAcvContent";
import {filterForStoresWithMissingAcv} from "./missingStoreAcvUtils";


export const storeAcvQuery = `
query {
  stores {
    retailerId
    storeId
    acvValues {
      acvCalculated
      acvOverride
    }
  }
}
`;

const MissingStoreAcvPage =() => {
    return  <Page name={'Missing Store Acv'}>
        <ApiGetContainer graphqlQuery={storeAcvQuery}
                         componentToRender={MissingStoreAcvContent}
                         dataPreProcess={filterForStoresWithMissingAcv}
        />
    </Page>;
}

export default MissingStoreAcvPage;