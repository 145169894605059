import {
    displayDiffFromAverageDollar,
    displayDiffFromAverageUnit,
    displayDollars,
    displayUnits,
    getAverageDollarFormat,
    getAverageUnitFormat,
    getDiffFromAverageDollar,
    getDiffFromAverageUnit
} from "./SummaryDisplayFunctions";

export const displayFunctions = {
    Dollars: {
        displayFunction: displayDollars,
        displayAverageFunction: getAverageDollarFormat,
        displayDiffFromAverageFunction: displayDiffFromAverageDollar,
        outlierFunction: getDiffFromAverageDollar
    },
    Units: {
        displayFunction: displayUnits,
        displayAverageFunction: getAverageUnitFormat,
        displayDiffFromAverageFunction: displayDiffFromAverageUnit,
        outlierFunction: getDiffFromAverageUnit
    }
}