const recommendedMarketDesignation = (row, companyType, salesforceMarketDesignation) => {
    const {
        vitaminsAndSupplementsDepartmentsSalesPercentage,
        naturalAndSpecialtySalesPercentage,
        naturalSalesPercentage
    } = row
    const normalizedMarketDesignation = salesforceMarketDesignation ? salesforceMarketDesignation.toUpperCase() : undefined;
    const normalizedCompanyType = companyType ? companyType.toUpperCase() : undefined;
    let recommendedDesignation = '';
    if (normalizedMarketDesignation === 'PET') {
        recommendedDesignation = 'PET'
    } else if (normalizedMarketDesignation === 'ECOMMERCE' && normalizedCompanyType === 'CONFIDENTIAL RETAILER') {
        recommendedDesignation = 'eCOMMERCE'
    } else if ((normalizedCompanyType === 'CONFIDENTIAL RETAILER' || normalizedCompanyType === 'KEY ACCOUNT RETAILER' ||
            normalizedCompanyType === 'KEY ACCOUNT PARTICIPANT') && vitaminsAndSupplementsDepartmentsSalesPercentage > 50) {
        recommendedDesignation = 'VITAMINS & SUPPLEMENTS'
    } else if (naturalAndSpecialtySalesPercentage >= 30 && naturalSalesPercentage > 15) {
        recommendedDesignation = 'NATURAL EXPANDED'
    } else if (naturalAndSpecialtySalesPercentage >= 30 && naturalSalesPercentage <= 15) {
        recommendedDesignation = salesforceMarketDesignation
    } else if (naturalAndSpecialtySalesPercentage < 30) {
        recommendedDesignation = 'REGIONAL & INDEPENDENT GROCERY'
    }
    return recommendedDesignation;
};

export default recommendedMarketDesignation;