import React, {Component, Fragment} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Login} from '../auth/components/Login';
import {LoginCallback} from '../auth/components/LoginCallback';
import {Logout} from '../auth/components/Logout';
import {RouteWithAuthorization} from './Components/RouteWithAuthorization';
import axios from 'axios';
import {Unauthorized} from '../auth/components/Unauthorized';
import {HealthCheck} from '../auth/components/HealthCheck';
import $ from 'jquery';
import '../scss/App.scss';
import 'foundation-sites';
import ROUTES from '../routes';
import {ToastContainer} from 'react-toastify';
import {loadRetailerMap} from "../utils/retailerMapCache";
import {setPendoVisitor} from "../utils/pendo";
import {
    DEFAULT_PATH,
    getPendoMetadata,
    getUserTypeFromLocalToken,
    userType
} from "../auth/accessTokenValidator";
import IngestionLogsPage from "./IngestionLogs/IngestionLogsPage";

function initFoundation() {
    $(document).foundation();
}

function initAxios() {
    axios.interceptors.request.use(
        (config) => {
            config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('access_token');
            return config
        },
        (error) => {
            return Promise.reject(error)
        },
    )
}

function initPendoUserInfo() {
    const accessToken = window.localStorage.getItem('access_token')
    if (accessToken) {
        setPendoVisitor(getPendoMetadata())
    }
}


class Dashboard extends Component {
    constructor(props) {
        super(props);
        initAxios();
        initPendoUserInfo();
    }

    async componentDidMount() {
        initFoundation();
        loadRetailerMap();
    }

    addTokenToAxiosInterceptor = () => {
        axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('access_token');
                return config
            },
            (error) => {
                return Promise.reject(error)
            },
        )
    };


    render() {
        return (
            <Fragment>
                <Routes>
                    <Route path="/" key={'default'} element={<RouteWithAuthorization path={DEFAULT_PATH} component={IngestionLogsPage} routes={ROUTES}/>} />
                    <Route path="/login" element={<Login />}/>
                    <Route path="/loginCallback" element={< LoginCallback />}/>
                    <Route path="/logout" element={<Logout />}/>
                    <Route path="/unauthorized" element={<Unauthorized />}/>
                    <Route path="/healthCheck" element={<HealthCheck />}/>
                    {
                        ROUTES
                            .filter(x => {
                                const userTypeFromLocalToken = getUserTypeFromLocalToken()
                                return userTypeFromLocalToken === userType.adminUser || x.userAccessList.includes(userTypeFromLocalToken);
                            })
                            .map(({path, component}) => {
                                    return   <Route
                                        key={path}
                                        path={`${path}/*`}
                                        element={
                                            <RouteWithAuthorization
                                                path={path}
                                                component={component}
                                                routes={ROUTES}
                                            />}

                                    />
                                }
                            )
                    }
                </Routes>
                <ToastContainer/>
            </Fragment>
        );
    }
}

export default Dashboard
