import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {addLabelToOptionsIfNotExist} from "../../../../utils/maybeAddLabelToOptions";
import iconCheck from "../../../../assets/check.svg";
import {convertLabelTextToDataTestId} from "../../../../utils/testHelpers/assertUtils";
import _ from "lodash";
import {DEFAULT_OPTION} from "../filters";

const FilterOptionsGroup = ({title, options, selectedValue, callback}) => {

    const formatOptionLabel = (data, formatOptionLabelMeta) => {
        const currentOptionValue = data?.value;
        const optionIsSelected = formatOptionLabelMeta.selectValue.filter((option) => option.value === currentOptionValue || option.value === 'All');
        const isSelected = optionIsSelected.length > 0;
        return formatOptionLabelMeta.context === "menu" ?
            <div className={`missing-data-multi-select-dropdown-option-label ${isSelected === true ? "is-selected" : ""}`}>
                <div className={"missing-data-label"}>{data.label}</div>
                {isSelected && <div className={"missing-data-active-icon"} data-testid={`${data.label}-selected`}>
                    <img alt={"active"} src={iconCheck}/>
                </div>}
            </div>
            :
            <div>
                <div>{data.label}</div>
            </div>

    };

    const id = convertLabelTextToDataTestId(title, '', "_dropdown");
    return <div className={'filter-group'} data-testid={`${id}-filter-group`}>
        <h6 data-testid={"filter-group-header"}>{title}</h6>
        <Select id={id}
                data-testid={id}
                className={"missing-data-multi-select-dropdown"}
                classNamePrefix={`missing-data-multi-select-dropdown`}
                options={addLabelToOptionsIfNotExist(options)}
                value={selectedValue}
                defaultValue={DEFAULT_OPTION}
                onChange={(options) => {
                    let finalSelectedOptions = []
                    if (options === null) {
                        finalSelectedOptions = DEFAULT_OPTION
                    } else if (_.last(options)?.value === 'All') {
                        finalSelectedOptions = options?.filter(option => option.value === 'All')
                    } else {
                        finalSelectedOptions = options?.filter(option => option.value !== 'All');
                    }
                    callback(finalSelectedOptions)
                }}
                isLoading={options === undefined}
                isClearable={false}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                formatOptionLabel={formatOptionLabel}
        />
    </div>
}

FilterOptionsGroup.defaultProps = {
    callback: () => {
    }
}

FilterOptionsGroup.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    selectedValue: PropTypes.array.isRequired,
    callback: PropTypes.func
}

export default FilterOptionsGroup
