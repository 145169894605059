import {toastSuccess} from "./toast";
import {toast} from "react-toastify";
import {useEffect} from "react";


let REDIRECT_MESSAGE_KEY = 'redirectMessage';

export function useRedirectMessage() {
    useEffect(() => {
        const redirectMessage = localStorage.getItem(REDIRECT_MESSAGE_KEY);
        if (redirectMessage) {
            localStorage.removeItem(REDIRECT_MESSAGE_KEY);
            toastSuccess(toast, redirectMessage);
        }
    }, []);
}

export function redirectWithMessage(message, nextUrl) {
    window.localStorage.setItem(REDIRECT_MESSAGE_KEY, message);
    window.location.replace(nextUrl);
}