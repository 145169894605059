import React, {useState} from 'react';

import 'moment-timezone';
import {getBooleanParamOrDefault, getStringParamOrDefault, updateQueryStringForState} from '../../utils/urlBuilder';
import Select from 'react-select';
import IngestionLogsContent from "./IngestionLogsContent";
import ApiGetContainer from "../../Api/ApiGetContainer";
import Page from "../Components/Page";
import {toastInfo} from "../../utils/toast";
import Checkbox from "../Components/Checkbox";
import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {toast} from "react-toastify";
import IngestionLogsPageKey from "./IngestionLogsPageKey";
import {getUserTypeFromLocalToken, userType} from "../../auth/accessTokenValidator";
import AirflowMetrics from "./AirflowMetrics";

export const IngestionLogsPage = ({retailerId, displayHelmetHeader, showAirflowMetrics}) => {
    const userTypeFromToken = getUserTypeFromLocalToken()
    const defaultState = {
        includeSuccess: getBooleanParamOrDefault('includeSuccess', false),
        includeShortCircuit: getBooleanParamOrDefault('includeShortCircuit', false),
        includeBulkOnly: getBooleanParamOrDefault('includeBulkOnly', false),
        includeCurrentlyRunning: getBooleanParamOrDefault('includeCurrentlyRunning', false),
        includeFailures: getBooleanParamOrDefault('includeFailures', true),
        hideOnboarding: getBooleanParamOrDefault('hideOnboarding', userTypeFromToken !== userType.locatorsUser),
        days: getStringParamOrDefault('days', '1'),
        retailerIds: retailerId
    }
    const [includeSuccess, updateIncludeSuccess] = useState(defaultState['includeSuccess']);
    const [includeShortCircuit, updateIncludeShortCircuit] = useState(defaultState['includeShortCircuit']);
    const [includeBulkOnly, updateIncludeBulkOnly] = useState(defaultState['includeBulkOnly']);
    const [includeCurrentlyRunning, updateIncludeCurrentlyRunning] = useState(defaultState['includeCurrentlyRunning']);
    const [includeFailures, updateIncludeFailures] = useState(defaultState['includeFailures']);
    const [hideOnboarding, updateHideOnboarding] = useState(defaultState['hideOnboarding']);
    const [days, updateDays] = useState(defaultState['days']);
    const [ingestionLogsParameters, updateIngestionLogsParameters] = useState(defaultState);
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ingestionLogs/`;
    const salesforceRetailers = useQuery(
        gql`
            query  {
                salesforceRetailers{
                    retailerId
                    onboardingStatus
                }
            }
        `
    );

    const parameters = {
        includeSuccess,
        includeShortCircuit,
        includeBulkOnly,
        includeCurrentlyRunning,
        includeFailures,
        hideOnboarding,
        days: days || undefined
    };

    updateQueryStringForState(parameters)

    const daysOptions = [
        {label: 'past 1 day', value: '1'},
        {label: 'past 2 days', value: '2'},
        {label: 'past 3 days', value: '3'},
        {label: 'past 1 week', value: '7'},
        {label: 'past 30 days', value: '30'}
    ];
    const selectedDaysOptions = daysOptions.filter(option => option.value === days);
    return (
        <Page name={'File Logs'} displayHelmetHeader={displayHelmetHeader}>
            {showAirflowMetrics && <AirflowMetrics />}
            <div id={'check-boxes'}>
                <div className={'checkbox-group'}>
                    <h6 className={'checkbox-header'}>Duration</h6>
                    <div className={'checkbox-checkboxes'}>
                        <div className={'dropdown-for-days-component-container'} data-testid={"dropdown-for-days-component-container"}>
                            <span>{'From'}</span>
                            <Select id={"dropdown-for-days_dropdown"}
                                    className={'dropdown-for-days select-dropdown'}
                                    classNamePrefix={'dropdown-for-days'}
                                    value={selectedDaysOptions ? selectedDaysOptions[0] : undefined}
                                    options={daysOptions}
                                    onChange={(selection) => updateDays(selection.value)}
                                    placeholder={daysOptions[daysOptions.length - 1].label}
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                            />
                        </div>
                    </div>
                </div>
                <div className={'ingestion-logs-divider'}/>
                <div className={'checkbox-group'}>
                    <h6 className={'checkbox-header'}>Status</h6>
                    <div className={'checkbox-checkboxes'}>
                        <Checkbox labelText={'Successful'}
                                  onChange={() => {
                                      updateIncludeSuccess(!includeSuccess);
                                  }}
                                  checked={includeSuccess}/>
                        <Checkbox labelText={'Failed'}
                                  onChange={() => {
                                      updateIncludeFailures(!includeFailures);
                                  }}
                                  checked={includeFailures}/>
                        <Checkbox labelText={'Running'}
                                  onChange={() => {
                                      updateIncludeCurrentlyRunning(!includeCurrentlyRunning);
                                  }}
                                  checked={includeCurrentlyRunning}/>
                    </div>
                </div>
                <div className={'ingestion-logs-divider'}/>
                {userTypeFromToken !== userType.locatorsUser &&
                    <>
                        <div className={'checkbox-group'}>
                            <h6 className={'checkbox-header'}>Advanced</h6>
                            <div className={'checkbox-checkboxes'}>
                                <Checkbox labelText={'Bulk Loads Only'}
                                          onChange={() => {
                                              updateIncludeBulkOnly(!includeBulkOnly);
                                          }}
                                          checked={includeBulkOnly}/>
                                <Checkbox labelText={'Short Circuits'}
                                          onChange={() => {
                                              updateIncludeShortCircuit(!includeShortCircuit);
                                          }}
                                          checked={includeShortCircuit}/>
                                <Checkbox labelText={'Hide Onboarding'}
                                          onChange={() => {
                                              updateHideOnboarding(!hideOnboarding);
                                          }}
                                          checked={hideOnboarding}/>
                            </div>
                        </div>
                        <div className={'ingestion-logs-divider'}/>
                    </>
                }
                <button className={'button primary left-margin'} onClick={() => {
                    if (!includeFailures && !includeSuccess && !includeCurrentlyRunning) {
                        toastInfo(toast, "A status must be selected")
                    }
                    updateIngestionLogsParameters({
                        includeSuccess,
                        includeShortCircuit,
                        includeBulkOnly,
                        includeCurrentlyRunning,
                        includeFailures,
                        days,
                        retailerIds: retailerId,
                        hideOnboarding
                    })

                }}>Submit
                </button>
            </div>
            <IngestionLogsPageKey/>
            {salesforceRetailers?.data && <ApiGetContainer endpoint={endpointUrl}
                                                           componentToRender={IngestionLogsContent}
                                                           parameters={ingestionLogsParameters}
                                                           componentToRenderProps={{
                                                               salesforceRetailers: salesforceRetailers.data.salesforceRetailers,
                                                               hideOnboarding
                                                           }}
            />}
        </Page>
    )
}


IngestionLogsPage.defaultProps = {
    displayHelmetHeader: true,
    showAirflowMetrics: true,
    retailerId: undefined
}

IngestionLogsPage.propTypes = {
    displayHelmetHeader: PropTypes.bool.isRequired,
    showAirflowMetrics: PropTypes.bool.isRequired,
    retailerId: PropTypes.number
}


export default IngestionLogsPage;