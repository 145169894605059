import LoadingSpinner from "./LoadingSpinner";
import PropTypes from "prop-types";


export const LoadingWrapper = ({loading, children, spinnerClassName}) => {
    const extraProps = spinnerClassName ? {spinnerClassName} : {};
    if (loading) {
        return <LoadingSpinner {...extraProps} />
    }

    return children;
}

LoadingWrapper.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
    spinnerClassName: PropTypes.string
}