import React from 'react';
import PropTypes from 'prop-types';
import RetailerIdAndName from "../../Components/RetailerIdAndName";

export function isOnboarding(salesforceRetailer) {
    return salesforceRetailer && salesforceRetailer.onboardingStatus !== 'Released';
}

const RetailerIdWithOnboarding = ({retailerId, salesforceRetailers}) => {
    const maybeSalesforceRetailer = salesforceRetailers.find((salesforceRetailer) => salesforceRetailer.retailerId === retailerId);
    return <>
        <span className='number'><RetailerIdAndName retailerId={retailerId}/></span>
        {isOnboarding(maybeSalesforceRetailer) && <span className='onboarding-label'>{'ONBOARDING'}</span>}
    </>
};


RetailerIdWithOnboarding.propTypes = {
    retailerId: PropTypes.number,
    salesforceRetailers: PropTypes.array,
};

export default RetailerIdWithOnboarding;