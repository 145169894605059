import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../Components/Checkbox";

function DataIssueFilterCheckboxes({
                                       updateShowDataIssueAlerts, showDataIssueAlerts,
                                       updateNotDataIssueAlerts, notDataIssueAlerts,
                                       updateFurtherReviewAlerts, furtherReviewAlerts
                                   }) {
    return (
        <div id={'check-boxes'}>
            <div className={'checkbox-group'}>
                <div className={'checkbox-checkboxes'}>
                    <Checkbox labelText={'Data Issue'}
                              onChange={() => {
                                  updateShowDataIssueAlerts(!showDataIssueAlerts)
                              }}
                              checked={showDataIssueAlerts}
                              extraLabelClassName={'is-data-issue-checkbox-label'}
                    />
                    <Checkbox labelText={'Not an issue'}
                              onChange={() => {
                                  updateNotDataIssueAlerts(!notDataIssueAlerts);
                              }}
                              checked={notDataIssueAlerts}
                              extraLabelClassName={'is-not-data-issue-checkbox-label'}
                    />
                    <Checkbox labelText={'For further review'}
                              onChange={() => {
                                  updateFurtherReviewAlerts(!furtherReviewAlerts);
                              }}
                              checked={furtherReviewAlerts}
                              extraLabelClassName={'is-further-review-checkbox-label'}
                    />
                </div>
            </div>
        </div>
    )

}

DataIssueFilterCheckboxes.propTypes = {
    updateShowDataIssueAlerts: PropTypes.func,
    showDataIssueAlerts: PropTypes.bool,
    updateNotDataIssueAlerts: PropTypes.func,
    notDataIssueAlerts: PropTypes.bool,
    updateFurtherReviewAlerts: PropTypes.func,
    furtherReviewAlerts: PropTypes.bool
}

export default DataIssueFilterCheckboxes;