import React from 'react';
import Page from "../../Components/Page";
import ContinuityTable from "./ContinuityTable";
import MissingDatesCell from "./MissingDatesCell";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";

export const missingDailyDatesForRetailer = `
    query{
       data:dailyRetailerMissingDays{
        storeId
        retailerId
        weekEndDate
        missingDates
  }
}
`
export const missingDatesForDailyRetailerDataColumn = [
    {
        header: 'Week',
        accessorKey: 'weekEndDate',
        size: 100
    },
    {
        header: 'Missing Days',
        accessorKey: 'missingDates',
        cell: ({getValue}) => <MissingDatesCell dates={getValue()}/>,
        size: 100,
        enableColumnFilter: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
            const datesA = rowA.original?.missingDates;
            const datesB = rowB.original?.missingDates;
            const numDatesA = datesA ? datesA.length : 0;
            const numDatesB = datesB ? datesB.length : 0;
            return sortAscendingWithNullsLast(numDatesA, numDatesB);
        }
    }
]
const MissingDaysPage = () => {

    return (
        <Page name="Missing Days">
            <ContinuityTable query={missingDailyDatesForRetailer} dataColumns={missingDatesForDailyRetailerDataColumn}/>
        </Page>
    );
};
export default MissingDaysPage;