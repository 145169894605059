import React, {useEffect, useState} from "react";
import {toastError} from "../../utils/toast";
import {toast} from "react-toastify";
import {makeApiCall, makeApiCallPut} from "../../Api/makeApiCall";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import _ from "lodash";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormNumberInputField, EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";

export default function CheckpointTypesService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/checkpointTypes`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    const [apiResponse, setApiResponse] = useState({});

    function mkEdpApiUrl(id) {
        const path = encodeURIComponent(`gs://spins-edp-${process.env.REACT_APP_ENV}-data-test/storagePrices/${id}.json`);
        return `${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath/?path=${path}`;
    }

    useEffect(() => {
        makeApiCall(mkEdpApiUrl("checkpoint"))
            .then((data) => {
                const responsesKeyedById = _.keyBy(data.subdirs, response => response.path.split("p_dataset=")[1]);
                setApiResponse(responsesKeyedById);
            });
        makeApiCallPut(`${process.env.REACT_APP_EDP_API_BASE_URL}/updateCheckpointTypes`);
    }, []);

    return (
        <Page name={"Checkpoint Types Service"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    key={_.isEmpty(apiResponse)}
                    validatePreSave={dataUpdateVerify}
                    data={data.data}
                    filterable
                    editTable={[
                        INDEX_FIELD,
                        {id: 'name', Component: args => <EditFormTextInputField {...args} isRequired/>},
                        {id: 'days_until_delete', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                    ]}
                    columns={[
                        {header: "Name", filterable: true, id: "name", accessorKey: "name"},
                        {header: "Days Until Delete", filterable: true, accessorKey: "days_until_delete"},
                        {
                            header: "Annual Cost", filterable: true, id: "cost",
                            sortingFn: (rowA, rowB, colId) => {
                                let valA = rowA.getValue(colId);
                                let valB = rowB.getValue(colId);
                                if (valA === "-" && valB === "-") {
                                    return 0;
                                } else if (valA === "-" || valB === "-") {
                                    return -1;
                                } else {
                                    valA = parseFloat(valA.substring(1));
                                    valB = parseFloat(valB.substring(1));
                                    return valA - valB;
                                }
                            },
                            accessorFn: ({name}) => {
                                return apiResponse[name]?.annualCost || "$0.00";
                            }
                        },
                        {
                            header: "Size", filterable: true, id: "size",
                            accessorFn: ({name}) => {
                                return apiResponse[name]?.size || "0GB";
                            }
                        }
                    ]}
                    initialState={{sorting: [{id: "cost", desc: true}], pagination: {pageSize: 25}}}
                    actionButtonsProps={{showDeleteButton: false, showAddButton: false}}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
}

export function dataUpdateVerify({currentData}) {
    const daysUntilDelete = currentData.days_until_delete;
    const minDaysUntilDelete = 0;
    const maxDaysUntilDelete = 121;
    const isValid = daysUntilDelete >= minDaysUntilDelete && daysUntilDelete <= maxDaysUntilDelete;
    if (!isValid) {
        toastError(toast, `Days until delete must be a number between ${minDaysUntilDelete} and ${maxDaysUntilDelete}`);
    }
    return isValid;
}
