import React, {useEffect, useState} from 'react';
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import ReactTable from "../Components/ReactTable";
import {convertDateTime} from "../../utils/formatUtil";
import ReactTableFilterDropdown from "../ReactTable/ReactTableFilterDropdown";
import _ from "lodash";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {makeApiCall, makeApiCallPost} from "../../Api/makeApiCall";
import "../../scss/_circanaDataLoad.scss"
import ButtonLoader from "../../utils/components/ButtonLoader";


const CircanaDataQC = () => {

    const dateDisplayFormat = 'YYYY/MM/DD kk:mm:ss';
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/conventional/unload/qctoupdate`;
    const updateEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/conventional/qcStatus/`;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    async function loadData() {
        setLoading(true);
        const result = await makeApiCall(endpointUrl);
        setData(result.data);
        setLoading(false);
    }

    useEffect(() => {
        loadData().then();
    }, []);


    const filteredData = data.filter(x => x.status === 'Completed' || x.status === 'Received');

    const customColumns = [
        {
            header: "Load End Time",
            accessorKey: "load_end_timestamp",
            cell: ({getValue}) => convertDateTime(getValue(), dateDisplayFormat)
        },
        {
            header: "Deliverable",
            accessorKey: "deliverable",
            filterFn: (row, columnId, filterValue) => !filterValue || row.getValue(columnId) === filterValue,
            filter: (theseProps) => {
                const uniqueOptions = _.uniqBy(
                    theseProps.table.getCoreRowModel().rows.map(row => row.original.deliverable).sort()
                ).map(option => ({label: option, value: option}));

                return <ReactTableFilterDropdown
                    id={"deliverable"}
                    options={uniqueOptions}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                    isClearable={true}
                />
            }
        },
        {
            header: "Schedule",
            accessorKey: "schedule",
            filterFn: (row, columnId, filterValue) => !filterValue || row.getValue(columnId) === filterValue,
            filter: (theseProps) => {
                const uniqueOptions = _.uniqBy(
                    theseProps.table.getCoreRowModel().rows.map(row => row.original.schedule).sort()
                ).map(option => ({label: option, value: option}));

                return <ReactTableFilterDropdown
                    id={"schedule"}
                    options={uniqueOptions}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                    isClearable={true}
                />
            }
        },
        {
            header: "QC Complete",
            accessorKey: "triggerUnload",
            enableColumnFilter: false,
            enableSorting: false,
            cell: ({row}) =>  <ButtonLoader
                buttonClassName={'button primary'}
                buttonTitle={`Mark QC completed`}
                buttonHasModal
                modalHeader={`Mark QC completed`}
                modalText={`Are you sure you want Mark QC completed for this dataset`}
                onSubmitCallback={loadData}
                axiosFunction={makeApiCallPost}
                url={updateEndpointUrl}
                postBody={{
                    schedule: row.original.schedule,
                    deliverable: row.original.deliverable
                }}
                toastTextFailure={`update of QC status has failed`}
                toastTextSuccess={`${row.original.schedule} ${row.original.deliverable} ${row.original.table_name}  has successfully been mark QC complete`}
            />
        }
    ];

    return (
        <div className={'conventional-data-qc'}>
            <Page name={"Conventional Data QC"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Circana"}, {label: "Conventional Data QC"}]}/>}
            >
                <LoadingWrapper loading={loading}>
                    <ReactTable
                        filterable
                        data={filteredData}
                        columns={customColumns}
                        initialState={{
                            pagination: {pageSize: 10},
                            sorting: [{id: "load_end_timestamp", desc: true}]
                        }}
                    />
                </LoadingWrapper>
            </Page>
        </div>
    );
};

export default CircanaDataQC;