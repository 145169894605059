import PropTypes from "prop-types";
import React from "react";

export const NewFileNameTextComponent = (props) => {
    return <div>
        <h5>{props.message}</h5>
        <label className={"change-file-name"}>Original File Name: {props.originalFileName}</label>
        <label className={"change-file-name"}>New File Name:</label>
        <input
            id={"new_file_name"}
            data-testid={"new_file_name"}
            className={"change-file-name"}
            type={"text"}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
        />
    </div>;
}

NewFileNameTextComponent.propTypes = {
    message: PropTypes.string,
    originalFileName: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func
};