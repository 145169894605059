import React from 'react';
import MarketServiceGenericService from "./MarketServiceGenericService";

function AccountService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/accounts/`
    return (<MarketServiceGenericService
        endpointUrl={endpointUrl}
        pageName={"Account Service"}
    />);
}

export default AccountService;
