import React, {useState} from 'react';

import ApiGetContainer from '../../Api/ApiGetContainer';
import Page from "../Components/Page";
import {
    convertToQueryParams,
    getBooleanParamOrDefault,
    getIntParamOrDefault,
    getStringArrayParamOrDefault,
    getStringParamOrDefault
} from "../../utils/urlBuilder";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import OpsAlertsContent from "./OpsAlertsContent";

const OpsAlerts = () => {
    const apiParams = []
    const [showAcknowledgedAlerts, updateShowAcknowledgedAlerts] = useState(getStringParamOrDefault("showAcknowledgedAlerts", false))


    const days = getIntParamOrDefault("days", undefined)
    if (days !== undefined) {
        apiParams.push(`days=${days}`)
    }
    const alertIdParams = getStringArrayParamOrDefault("alertIds", [])
    if (alertIdParams.length > 0) {
        apiParams.push(convertToQueryParams({alertIds: alertIdParams}))
    }
    const startDate = getStringParamOrDefault("startDate", undefined)
    if (startDate !== undefined) {
        apiParams.push(`startDate=${startDate}`)
    }
    const endDate = getStringParamOrDefault("endDate", undefined)
    if (endDate !== undefined) {
        apiParams.push(`endDate=${endDate}`)
    }
    const endpoint = (startDate && endDate) ? `opsAlertLogsByDate` : `opsAlertLogs`;
    const includeAcknowledged = getBooleanParamOrDefault('includeAcknowledged', showAcknowledgedAlerts)
    apiParams.push(`includeAcknowledged=${includeAcknowledged}`)


    const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/${endpoint}/?${apiParams.join('&')}`;
    const breadcrumbs =
        <Breadcrumbs items={[{label: 'Alerts'}, {label: 'Summary', to: '/alerts/summary'}, {label: 'Acknowledge'}]}/>;
    return <Page name="Alerts" breadCrumbs={breadcrumbs}>
        <ApiGetContainer
            endpoint={url}
            componentToRender={OpsAlertsContent}
            componentToRenderProps={{
                url: url,
                showAcknowledgedAlertsProps: {showAcknowledgedAlerts, updateShowAcknowledgedAlerts},
                showAcknowledgedAlertsButton: true,
                showJiraLoginButton: true
            }}
        />
    </Page>
};

export default OpsAlerts;
