import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";

function ProvidersService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/providerToFtp/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const columns = [
        {header: "Filename", accessorKey: "filename"},
        {header: "Destination Ftp", accessorKey: "destinationFtp"},
        {header: "Original Ftp", accessorKey: "originalFtp"}
    ];

    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Providers Service"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "Providers"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    {id: 'filename', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    {id: 'destinationFtp', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    {id: 'originalFtp', Component: args => <EditFormTextInputField {...args}/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default ProvidersService;