import React from 'react';
import {CONFIG_TYPES} from "../../Configurations/FileTypes";
import _ from "lodash";
import ReIngest from "./ReIngest";
import {ShowFilePartial} from "./ShowFilePartial";
import PropTypes from "prop-types";


const IngestionLogsActions = ({row, getValue}) => {
    const configType = Object.values(CONFIG_TYPES).filter(config => config.getName() === row.original.fileType);
    const configTypeClass = _.isEmpty(configType) ? CONFIG_TYPES.SALES : configType[0]

    return (
        <div className={'horizontal-control-block'}>
            <div className={'horizontal-control-block-element'}>
                <ReIngest className='original' original={row.original} value={getValue()}/>
            </div>
            {!row.original.isBulk &&
            <div className={'horizontal-control-block-element'}>
                <ShowFilePartial path={row.original.archiveLocation} ftp={row.original.ftp}
                                 fileType={configTypeClass}/>
            </div>
            }
        </div>
    )
}

IngestionLogsActions.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            fileType: PropTypes.string,
            uniqueArchiveFileName: PropTypes.string,
            isBulk: PropTypes.bool,
            retailerId: PropTypes.number
        })
    }),
    value: PropTypes.string
}


export default IngestionLogsActions;