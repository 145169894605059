import Checkbox from "../Components/Checkbox";
import React from "react";

function ShowAcknowledgedAlertsCheckbox({updateShowAcknowledgedAlerts, showAcknowledgedAlerts}) {
    return (
        <div id={'check-boxes'}>
            <div className={'checkbox-group'}>
                <div className={'checkbox-checkboxes'}>
                    <Checkbox labelText={'Show Acknowledged Alerts'}
                              onChange={() => updateShowAcknowledgedAlerts(!showAcknowledgedAlerts)}
                              checked={showAcknowledgedAlerts}/>
                </div>
            </div>
        </div>
    );
}

export default ShowAcknowledgedAlertsCheckbox;