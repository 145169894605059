import React, {Component} from 'react';
import ApiGetContainer from "../../Api/ApiGetContainer";
import Page from "../Components/Page";


class ProjectionService extends Component {
    projectionsColumns = [
        {
            header: 'Market Key',
            accessorKey: 'mkt_tag',
            filterable: true,
            cell: props => <div className='retail-cell-element ops-alert-element'>{props.getValue()}</div>
        },
        {
            header: 'Market Description',
            accessorKey: 'mkt_desc',
            filterable: true,
            cell: props => <div className='week-end-date-cell-element ops-alert-element'>{props.getValue()}</div>
        },
        {
            header: 'Store Id',
            accessorKey: 'store_id',
            filterable: true,
            cell: props => <div className='store-cell-element ops-alert-element'>{props.getValue()}</div>
        },
        {
            header: 'Store Description',
            accessorKey: 'str_desc',
            filterable: true,
            cell: props => <div className='week-end-date-cell-element ops-alert-element'>{props.getValue()}</div>
        },
        {
            header: 'Projection Factor',
            accessorKey: 't_p_factor',
            filterable: false,
            cell: props => <div className='record-count-cell-element ops-alert-element'>{props.getValue()}</div>
        },
        {
            header: 'Period',
            accessorKey: 'period',
            filterable: true,
            cell: props => <div className='record-count-cell-element ops-alert-element'>{props.getValue()}</div>
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            marketKey: undefined
        };
        this.handleMarketKeyChange = this.handleMarketKeyChange.bind(this);
    }

    handleMarketKeyChange(event) {
        const marketKey = event.target.value;
        const endpoint = `${process.env.REACT_APP_EDP_API_BASE_URL}/storesByMarket?marketId=${marketKey}`;
        this.setState({endpoint: endpoint});
    }

    render() {
        const submitButton = <div className='input-group-button fake-button-for-show'>
            <input type='submit' className='button' value='Submit'/>
        </div>;
        return (
            <Page name={'Projections'}>
                <div className={'id-input'}>
                    <span className='input-group-label'>Market Key:</span>
                    <input
                        data-testid={'market-key-input-field'}
                        className={'input-group-field'}
                        type='text'
                        defaultValue={this.state.marketKey}
                        onBlur={this.handleMarketKeyChange}
                    />
                    {submitButton}
                </div>
                {this.state.endpoint &&
                <ApiGetContainer
                    endpoint={this.state.endpoint}
                    columns={this.projectionsColumns}
                    dataAccessor={'metadata'}
                />
                }
            </Page>
        )
    }
}

export default ProjectionService;
