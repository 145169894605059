import React, {useEffect, useState} from 'react';
import ReactTable from "../../../Components/ReactTable";
import {makeApiCall, makeApiCallDownloadFile} from "../../../../Api/makeApiCall";
import LoadingSpinner from "../../../Components/LoadingSpinner";

const MissingDataExportTab = () => {
    const [exportData, setExportData] = useState([])
    const [loadingExportData, setLoadingExportData] = useState(false)

    useEffect(() => {
        setLoadingExportData(true)
        const exportListEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/missingData/export/list`
        makeApiCall(exportListEndpointUrl)
            .then(data => setExportData(data))
            .then(() => setLoadingExportData(false))
    }, [])

    const REQUEST_DATE_COLUMN = {
        header: 'Request date',
        accessorKey: 'requestTime',
        id: 'requestTime',
        cell: ({row}) => row.original.requestTime.slice(0, 10),
        enableSorting: true,
    }

    const FILE_NAME_LINK_COLUMN = {
        header: 'File',
        accessorKey: 'fileNames',
        cell: ({row}) => <a download
                            onClick={() => makeApiCallDownloadFile(`${process.env.REACT_APP_EDP_API_BASE_URL}/missingData/export/download?request_time=${row.original.requestTime}&file_name=${row.original.fileNames}`, row.original.fileNames)}>{row.original.fileNames}</a>
    }

    return (<>
        {(loadingExportData) ? <LoadingSpinner/> : <div>
            <div className={'missing-data-export-header-note'}>The generated files will be automatically
                deleted seven days (7) after request date.
            </div>
            <ReactTable
                data={exportData}
                columns={[
                    REQUEST_DATE_COLUMN,
                    FILE_NAME_LINK_COLUMN,
                    {
                        header: 'Filters',
                        id: 'filters',
                        accessorKey: 'filters',
                        className: 'rt-td-wrap-word'
                    },
                    {header: 'Run by', accessorKey: 'runBy'},
                ]}
                initialState={{sorting: [{id: 'requestTime', desc: true}]}}
            />
        </div>}
    </>);
};

export default MissingDataExportTab;