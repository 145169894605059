import React from 'react';
import PropTypes from 'prop-types';
import ButtonLoader from '../../utils/components/ButtonLoader';
import {makeApiCallPost} from '../../Api/makeApiCall';

export const IgnoreButton = ({onSubmitCallback, url, postBody, unmappedItem}) => {
    return <ButtonLoader
        buttonClassName={'button primary'}
        axiosFunction={makeApiCallPost}
        buttonTitle={'Ignore'}
        onSubmitCallback={onSubmitCallback}
        url={url}
        postBody={postBody}
        buttonHasModal
        modalHeader={"Ignore"}
        modalText={`Are you sure want to ignore ${unmappedItem}?`}
    />;
};

IgnoreButton.propTypes = {
    onSubmitCallback: PropTypes.func,
    url: PropTypes.string,
    postBody: PropTypes.object,
    unmappedItem: PropTypes.string
};