import gql from "graphql-tag";

export const insightsStartDateQuery = gql`
            query {
                records:periods(isInsightsStart: true) {
                 firstWeekOfPeriod {
                  endDate 
                  weekId
                  } 
                }
            }`;