import React from 'react';


export const RadioButton = ({
                                currentValue,
                                onChange,
                                value,
                                displayCheckIcon = false,
                                disabled = false,
                                dataTestId = 'radio'
                            }) => {

    const isChecked = value === currentValue;
    return <div className="radio" data-testid={dataTestId}>
        <label data-testid={"radio-button-label"} className={`radio-button-label-${isChecked}`}>
            {displayCheckIcon && <i data-testid={"check-icon"} className={`fas fa-check-circle check-icon-${isChecked}`}/>}
            <input
                className={'radio-button-input'}
                type="radio"
                value={value}
                disabled={disabled}
                checked={isChecked}
                onChange={() => onChange(value)}
            />
            <span className={'radio-button-label-text'}>{value}</span>
        </label>
    </div>;
};