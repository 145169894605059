import React from "react";
import PropTypes from 'prop-types';

const MarketServiceNavLinks = ({period}) => <ul className="vertical menu nested">
    <li className={"columns small-1"}><a href={`/accountService?period=${period}`}
                                         className={"dropdown-menu-link-large accountService"}>Account</a>
    </li>
    <li className={"columns small-1"}><a href={`/accountTypeService?period=${period}`}
                                         className={"dropdown-menu-link-large accountType"}>Account
        Type</a></li>
    <li className={"columns small-1"}><a href={`/channelSourceService?period=${period}`}
                                         className={"dropdown-menu-link-large channelSource"}>Channel
        Source</a></li>
    <li className={"columns small-1"}><a
        href={`/geographyCompositionService?period=${period}`}
        className={"dropdown-menu-link-large geographyComposition"}>Geography
        Composition</a></li>
    <li className={"columns small-1"}><a href={`/geographyLevelService?period=${period}`}
                                         className={"dropdown-menu-link-large geographyLevel"}>Geography
        Level</a></li>
    <li className={"columns small-1"}><a href={`/geographyTypeService?period=${period}`}
                                         className={"dropdown-menu-link-large geographyType"}>Geography
        Type</a></li>
    <li className={"columns small-1"}><a href={`/legacyAccountTypeService?period=${period}`}
                                         className={"dropdown-menu-link-large legacyAccountType"}>Legacy
        Account Type</a></li>
    <li className={"columns small-1"}><a
        href={`/measureAvailabilityService?period=${period}`}
        className={"dropdown-menu-link-large measureAvailability"}>Measure
        Availability</a></li>
    <li className={"columns small-1"}><a href={`/outletsService?period=${period}`}
                                         className={"dropdown-menu-link-large outletsService"}>Outlets</a>
    </li>
    <li className={"columns small-1"}><a href={`/releasabilityService?period=${period}`}
                                         className={"dropdown-menu-link-large releasability"}>Releasability</a>
    </li>
</ul>;

MarketServiceNavLinks.propTypes = {
    period: PropTypes.string
}

export default MarketServiceNavLinks