import Page from '../Components/Page';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import ExtractsTriggerConfirmationModal from './ExtractsTriggerConfirmationModal';
import {toastError, toastSuccess} from '../../utils/toast';
import ScaffoldingOptionalDatasetDropDown from './ScaffoldingOptionalDatasetDropDown';
import {invokeDAGviaApiPost} from '../../Api/invokeDag';

export const ScaffoldingExtractsTriggerDag = () => {
    const pageName = 'Scaffolding Extract Processes';
    const nucleusDag = 'cleanse_fill_extract';
    const checkpointPath = undefined;

    const [showConfirmationModal, updateShowConfirmationModal] = useState(false);
    const [runIdToScaffold, updateRunIdToScaffold] = useState(undefined);

    const submitButtonIsEnabled = runIdToScaffold
    const submitButtonDisabled = () => !submitButtonIsEnabled

    const datasetBucket = `spins-edp-${process.env.REACT_APP_ENV}-data-test`;
    const datasetPartition = `p_dataset=${nucleusDag}`;

    function modalConfirmed() {


        let data = {
            input_file_path: `gs://${datasetBucket}/${datasetPartition}/${runIdToScaffold}/`
        }

        const dagToTrigger = 'fill_t_week_f'
        invokeDAGviaApiPost(dagToTrigger, data)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `${dagToTrigger} DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `${dagToTrigger} DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `${dagToTrigger} DAG trigger failed with error ${JSON.stringify(e)}`)
            })
            .finally(() => updateShowConfirmationModal(false));

    }

    let scaffoldingDatasets;

    scaffoldingDatasets = ['t_week_f'];


    return (
        <Page name={pageName}>
            <div className={'trigger-dag-page-controls'}>
                <div className={'left-things'}>
                    {<ScaffoldingOptionalDatasetDropDown checkBoxIsChecked={true}
                                                         onCheckBoxChange={() => true}
                                                         endpoint={`${process.env.REACT_APP_EDP_API_BASE_URL}/listDirectories/?bucket=${datasetBucket}&subdirectory=${datasetPartition}`}
                                                         onDropDownChange={updateRunIdToScaffold}
                                                         selectedDropDownValue={runIdToScaffold}
                                                         checkboxText="Specify a Run Id To Scaffold for "
                                                         labelText="Run Id To Scaffold"
                    />}
                    <br/>
                    <div className={'dag-trigger-group'}>
                        <button type="button"
                                disabled={submitButtonDisabled()}
                                className="button primary submit"
                                onClick={() => updateShowConfirmationModal(true)}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <br/>
            {
                showConfirmationModal &&
                <ExtractsTriggerConfirmationModal confirmationCallback={modalConfirmed}
                                                  cancellationCallback={() => updateShowConfirmationModal(false)}
                                                  dagToTrigger={nucleusDag}
                                                  scaffoldingDatasets={scaffoldingDatasets}
                                                  runMissingPromo={undefined}
                                                  checkpointPath={checkpointPath}
                                                  processesToRun={['scaffolding']}
                                                  runIdToScaffold={runIdToScaffold}
                />
            }
        </Page>
    );
}

export default ScaffoldingExtractsTriggerDag;