import {getMoment} from "../../utils/time";
import PropTypes from "prop-types";
import React from "react";
import 'moment-timezone';

export function WhoEditedAndWhen({action, user, timestamp}) {
    return <div className={"config-change-record"} data-testid={'who_edited_and_when'}>
        {action} by <span className={"updated-by"}>{user}</span> on <span
        className={"updated-at"}>{getMoment(timestamp).tz('America/Chicago').format("dddd, MMMM D, YYYY hh:mm:ss z")}</span>
    </div>;
}

WhoEditedAndWhen.propTypes = {
    action: PropTypes.string,
    user: PropTypes.string,
    timestamp: PropTypes.string
};