import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from "../../Components/ReactTable";

const columns = [
    {
        header: 'Outlet',
        accessorKey: 'outlet'
    },
    {
        header: 'Run ID',
        accessorKey: 'runIdElement',
        cell: ({row}) => row.original.runIdElement
    }
]

const ScaffoldingPublishTable = ({marketInsightRunsForThisPeriod}) => {
    return <div>
        <br/>
        <ReactTable
            data={marketInsightRunsForThisPeriod}
            columns={columns}
        />
    </div>;
};

ScaffoldingPublishTable.defaultProps = {};

ScaffoldingPublishTable.propTypes = {
    marketInsightRunsForThisPeriod: PropTypes.arrayOf(
        PropTypes.shape({
            outlet: PropTypes.string.isRequired,
            runIdElement: PropTypes.element.isRequired,
            runIdentifier: PropTypes.string
        })
    ).isRequired
};

export default ScaffoldingPublishTable;