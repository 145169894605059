import React from 'react';
import MarketServiceGenericService from "./MarketServiceGenericService";

function MeasureAvailabilityService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/measureAvailability/`

    return (<MarketServiceGenericService
        endpointUrl={endpointUrl}
        pageName={"Measure Availability Service"}
    />);
}

export default MeasureAvailabilityService;