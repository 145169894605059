import React, {useState} from 'react';
import Modal from 'react-modal';
import PropTypes from "prop-types";

const LogoutModal = ({onClose, sessionType}) => {
    const [isOpen, setIsOpen] = useState(true)
    return (
        <Modal
            isOpen={isOpen}
            className="Modal"
            overlayClassName="Overlay"
            ariaHideApp={false}
            onRequestClose={onClose}
        >
            <div className={'logout-message'}>{`Your ${sessionType} Session Has Expired, Please Login In`}</div>
            <div>
                <button className="hollow button secondary modal-button" type="button"
                        onClick={() => {
                            setIsOpen(false)
                            onClose()
                        }}>
                    Login
                </button>
            </div>
        </Modal>
    );
};

LogoutModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    sessionType: PropTypes.string.isRequired
}

export default LogoutModal;