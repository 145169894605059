import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {makeApiCall} from '../../Api/makeApiCall';
import {diffString} from 'json-diff';
import Select from "react-select";

const CompareJson = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [periodToCompare, setPeriodToCompare] = useState(undefined);
    const [value, setValue] = useState(undefined);

    function closeModal() {
        setModalIsOpen(false);
    }

    function onPeriodToCompareChanged(periodSelection) {
        setPeriodToCompare(periodSelection.value);
    }

    function diffChecker() {
        const getSelectedPeriod = makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/markets?period=${periodToCompare}`);
        const getPassedInPeriod = makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/markets?period=${props.originalPeriodToCompare}`);
        Promise.all([getSelectedPeriod, getPassedInPeriod])
            .then(([selectedPeriodData, passedInPeriodData]) => {
                const diff = diffString(selectedPeriodData['data'], passedInPeriodData['data']);
                setValue(diff);
                setModalIsOpen(true);
            })
    }

    return (
        <div className={'market-service-icons'}>
            <span className={'select-period'}>  {'Compare To:'}</span>
            <div className={'dropdown-wrapper'}>
                <Select
                    placeholder={'Select...'}
                    options={props.marketPeriods || []}
                    onChange={onPeriodToCompareChanged}
                />
            </div>
            <button className="hollow button secondary json-compare"
                    onClick={diffChecker}
                    disabled={periodToCompare === undefined}
            >
                {'Compare'}
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal"
                overlayClassName="Overlay"
                ariaHideApp={false}
                contentLabel="Example Modal">
                <h2>Comparison {periodToCompare} with {props.originalPeriodToCompare}</h2>
                <textarea
                    className='modal-text-area'
                    defaultValue={value}
                />
                <button className="hollow button secondary modal-button" type="button" name="close"
                        onClick={() => closeModal()}>
                    Close
                </button>
            </Modal>
        </div>
    );
}

CompareJson.propTypes = {
    marketPeriods: PropTypes.arrayOf(PropTypes.string).isRequired,
    originalPeriodToCompare: PropTypes.string.isRequired
};

export default CompareJson