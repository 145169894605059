import {screen, waitFor, within} from "@testing-library/react";

export function assertRow(screen, idx, fields) {
    const row = {};

    for (const field in fields) {
        const cell = getCell(screen, idx, field);
        if (!cell) {
            throw new Error(`Did not find any cell for field ${field}, row ${idx}`)
        }
        row[field] = cell.textContent;
    }

    expect(row).toEqual(fields);
}

export function getCell(screen, idx, field) {
    return screen.queryAllByTestId(`data-cell-${field}`)[idx]
}

export function assertLink(screen, linkName, href){
    expect(screen.getByRole('link', {name: linkName})).toHaveAttribute('href', href)
    expect(screen.getByRole('link', {name: linkName})).toHaveAttribute('target', '_blank')
    expect(screen.getByRole('link', {name: linkName})).toHaveAttribute('rel', 'noopener noreferrer')
}

export function convertLabelTextToDataTestId(string, prefix, suffix) {
    const stringWithPrefix = prefix ? prefix.concat(" ", string) : string
    const stringWithPrefixAndSuffix = suffix ? stringWithPrefix.concat(" ", suffix) : stringWithPrefix

    return stringWithPrefixAndSuffix.replace(/\s/g, '-')
        .replace(/:/g, '')
        .replace(/--/g, '-')
        .replace(/-_/g, '_')
        .toLowerCase()
}

export function assertPageHeader(pageName){
    within(screen.queryByTestId('page-header-label')).getByText(pageName)
}

export async function assertPageHelmetTitle(env, pageName) {
    await waitFor(() => expect(document.title).toEqual(`${env} | ${pageName} | Nucleus Dashboard`))
}