import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../Components/ConfirmationModal';

const ScaffoldingPublishConfirmationModal = ({dagConf, cancellationCallback, confirmationCallback}) => {
    return <ConfirmationModal modalClassName={'Modal scaffolding-publish-modal'}
                              confirmationCallback={confirmationCallback}
                              cancellationCallback={cancellationCallback}>
        <div>
            <div>{'A DAG run with the following conf will be triggered:'}</div>
            <textarea className={'textarea'}
                      disabled={true}
                      value={JSON.stringify(dagConf, null, 2)}
            />
        </div>
    </ConfirmationModal>
};

ScaffoldingPublishConfirmationModal.propTypes = {
    dagConf: PropTypes.shape({
        period: PropTypes.string.isRequired,
        runs: PropTypes.arrayOf(
            PropTypes.shape({
                outlet: PropTypes.string.isRequired,
                runIdentifier: PropTypes.string.isRequired
            }))
    }).isRequired,
    cancellationCallback: PropTypes.func.isRequired,
    confirmationCallback: PropTypes.func.isRequired
};

export default ScaffoldingPublishConfirmationModal;