import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {Breadcrumbs} from "../Components/Breadcrumbs";

function CustomHierarchyRetailers() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/customHierarchyRetailers/`
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl });

    return (<Page name={"Custom Hierarchy Retailers"} breadCrumbs={<Breadcrumbs items={[{label: "Management"}, {label: "Custom Hierarchy"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[RETAILER_ID_NAME_COLUMN]}
                editTable={[INDEX_FIELD, RETAILER_ID_FIELD(serviceRetailerDropdown().data)]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default CustomHierarchyRetailers;