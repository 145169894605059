import RetailerIdAndName from "../RetailerIdAndName";
import {getRetailerName} from "../../../utils/retailerMapCache";
import React from "react";
import {EditFormDropdownInputField, EditFormNumberInputField, EditFormTextInputField} from "./EditRecordForm";

export const RETAILER_ID_NAME_COLUMN = {
    header: 'Retailer',
    id: 'retailerIdName',
    filterable: true,
    accessorKey: 'retailerId',
    size: 200,
    cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
    filterFn: (row, columnIds, filterValue) =>
        !filterValue || Boolean(`${row.original.retailerId} ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
}

export const NAME_COLUMN = {
    header: "Name",
    accessorKey: "name"
}

export const INDEX_FIELD = {
    id: 'index', Component: args => <EditFormNumberInputField {...args} disabled label={"Index"}/>
}

export const NAME_FIELD = {
    id: 'name', Component: args => <EditFormTextInputField {...args} isRequired/>
}

export const RETAILER_ID_FIELD = (options) => {
    return {
        id: 'retailerId',
        Component: args => <EditFormDropdownInputField {...args}
                                                       options={options}
                                                       isRequired
                                                       label={"Retailer Id"}/>
    }
}
