import JwtDecode from 'jwt-decode'
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {makeApiCallPost} from "../Api/makeApiCall";

export const DEFAULT_PATH = '/(ingestedLogs)?'

function isTokenExpired(jwtDecode) {
    return jwtDecode.exp < Math.floor(Date.now() / 1000)
}

const getJwtDecode = () => {
    const accessToken = window.localStorage.getItem('access_token')
    if (!accessToken) return undefined;
    return JwtDecode(accessToken);
}


function setRedirectLocalStorage(redirectLocation) {
    window.localStorage.setItem(
        'redirect',
        JSON.stringify({
            location: redirectLocation,
            state: uuidv4()
        })
    );
}

export const getOptionalAuthorizationRedirect = (accessToken, redirectLocation, path) => {
    if (accessToken === null) {
        setRedirectLocalStorage(redirectLocation);
        return 'login';
    }
    const jwtDecode = JwtDecode(accessToken);

    const appMetadata = jwtDecode[`https://aot/userSecurityFields`];

    if (!appMetadata || !appMetadata.dashboardAdmin) {
        return 'unauthorized';
    }

    if(isTokenExpired(jwtDecode)){
        setRedirectLocalStorage(redirectLocation);
        return 'logout';
    }

    return undefined;
};

export const getExpirationFromToken = (accessToken = null) => {
    if (accessToken === null) {
        return 0;
    }
    const jwtDecode = JwtDecode(accessToken);
    return jwtDecode.exp;
};

export const getUserEmail = () => {
    const accessToken = window.localStorage.getItem('access_token')
    if (!accessToken) {
        return undefined;
    }
    const jwtDecode = JwtDecode(accessToken);
    return jwtDecode['https://aot/email'];
};

export const getUserFullName = () => {
    const jwtDecode = getJwtDecode();
    if (!jwtDecode) return undefined;
    const securityFields = jwtDecode["https://aot/userSecurityFields"]["auth"]
    const firstName = securityFields?.user?.fname
    const LastName = securityFields?.user?.lname
    return firstName ? `${firstName} ${LastName}` : undefined;
};

export const getGreetingDisplay = () => {
    const userFullName = getUserFullName();
    const displayName = userFullName ? userFullName : getUserEmail();
    return `Hello ${displayName}!`
}

export function writeUsageLogCsv(path) {
    const user = getUserEmail()
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const currentUrl = window.location.href
    const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/logPageUsage/`;
    const callData = {
        user,
        page: path.replaceAll('/', '_'),
        currentUrl,
        dateTime
    }
    makeApiCallPost(url, callData)
}

export const getPendoMetadata = () => {
    const jwtDecode = getJwtDecode();
    if (!jwtDecode) return undefined;
    const securityFields = jwtDecode["https://aot/userSecurityFields"]["auth"]
    return {
        visitorId: securityFields.user.id,
        visitorFName: securityFields.user.fname,
        visitorLName: securityFields.user.lname,
        visitorEmail: securityFields.user.email,
        visitorInternal: securityFields.user.internal,
        companyId: securityFields.company.id,
        companyName: securityFields.company.name,
        companyType: securityFields.company.type,
        companyMarket: securityFields.company.compMarket,
        companyMarketDes: securityFields.company.marketDes
    }
}

export const copyAccessTokenToClipboard = () => {
    const token = window.localStorage.getItem('access_token')
    navigator.clipboard.writeText(token);
}

export const navigateToApolloStudioExplorer = () => {
    const graphName = "SPINS-SDN-API-h4iiyb"
    const accessToken = window.localStorage.getItem('access_token')
    const headers = {'Authorization': `Bearer ${accessToken}`}
    window.open(`https://studio.apollographql.com/graph/${graphName}/explorer?variant=${process.env.REACT_APP_ENV}&headers=${JSON.stringify(headers)}`)
}

export const userType = {
    locatorsUser: "locatorsUser",
    dataOperationsUser: "dataOperationsUser",
    dataGovernance: "dataGovernance",
    adminUser: "adminUser"
}

export const RETAILER_INGESTION_USERS = [userType.adminUser,userType.dataGovernance, userType.dataOperationsUser];
export const LOCATOR_INGESTION_USERS = [userType.adminUser, userType.dataGovernance, userType.locatorsUser];

export const getUserTypeFromLocalToken = () => {
    const accessToken = window.localStorage.getItem('access_token')
    if (accessToken === null) {
        return 0;
    }
    const jwtDecode = JwtDecode(accessToken);
    const appMetadata = jwtDecode[`https://aot/userSecurityFields`];
    const businessUnitDescription = appMetadata.auth?.user?.internal?.businessUnitDescription;

    if (businessUnitDescription === "Destini Technology") {
        return userType.locatorsUser
    }
    else if(businessUnitDescription === "Retail Onboarding") {
        return userType.dataOperationsUser
    }
    else if(businessUnitDescription === "Data Governance") {
        return userType.dataGovernance
    }
    else {
        return  userType.adminUser
    }
};