import React from 'react';
import Page from "../../Components/Page";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import RemoveMeasuresTabContent from "./RemoveMeasuresTabContent";
import {Breadcrumbs} from "../../Components/Breadcrumbs";

export default function RemoveMeasures() {
    const breadcrumbs =
        <Breadcrumbs items={[{label: 'Services'}, {label: 'Remove Measures'}]}/>;
    return (
        <Page name={'Remove Measures'} breadCrumbs={breadcrumbs}>
            <Tabs>
                <TabList>
                    <Tab>Start</Tab>
                    <Tab>Stop</Tab>
                </TabList>
                <TabPanel>
                    <RemoveMeasuresTabContent startOrStop={'Start'}/>
                </TabPanel>
                <TabPanel>
                    <RemoveMeasuresTabContent startOrStop={'Stop'}/>
                </TabPanel>
            </Tabs>
        </Page>
    )
}