import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from "../../ReactTable";
import RunIdLink from "../../../IngestionLogs/Columns/RunIdLink";
import RetailerIdAndName from "../../RetailerIdAndName";
import JsonPayloadModal from "../../JsonPayloadModal";
import {makeApiCall} from "../../../../Api/makeApiCall";
import { getRetailerName } from "../../../../utils/retailerMapCache";
import {
    sortAscendingWithNullsFirst
} from "../../../../utils/sorting";

const AirflowDagLogsContent = ({data, customColumns, includeDagName, includeRetailer, airflowUrl}) => {
    const columns = [
        {
            header: 'Run Id',
            accessorKey: 'runid',
            className: 'non-numeric-field',
            cell: ({row, getValue}) => <RunIdLink airflowUrl={airflowUrl} {...row} value={getValue()} />,
        },
        ...(includeRetailer ? [{
            header: 'Retailer',
            accessorKey: 'retailerid',
            className: 'numeric-field',
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.original.retailerid} ${getRetailerName(row.original.retailerid)?.toLowerCase()}`.includes(filterValue.toLowerCase()));
            },
            cell: ({row}) => {
                const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath?path=${encodeURIComponent(row.original?.retailer_id_url)}`;
                let retailerId
                if (row.original.retailerid === null || row.original.retailerid === "")
                    retailerId = "All"
                else if (row.original.retailerid)
                    retailerId = <RetailerIdAndName retailerId={row.original.retailerid}/>
                return (<div>
                    {
                        row.original?.retailer_id_url && row.original?.retailer_id_url.startsWith("gs://") ?
                            <JsonPayloadModal buttonText={"Retailers"}
                                              onClick={() => makeApiCall(endpointUrl)
                                                  .then((result) => result)}/>
                            : row.original?.retailer_id_url || retailerId}
                </div>)
            }
        }] : []),
        ...(includeDagName ? [{header: 'Dag Name', accessorKey: 'process', className: 'non-numeric-field'}] : []),
        ...customColumns,
        {
            header: 'Triggered By',
            accessorKey: 'triggeredby',
            className: 'non-numeric-field',
            sortingFn: (a, b, columnId) => {
                const valA = a.getValue(columnId);
                const valB = b.getValue(columnId);
                if (valA && valB) {
                    return valA.localeCompare(valB);
                }
                return sortAscendingWithNullsFirst(valA, valB);
            }
        },
        {
            header: 'Run Configuration',
            accessorKey: 'conf',
            enableColumnFilter: false,
            cell: ({getValue}) => <JsonPayloadModal value={getValue()}/>,
        }
    ]

    return (
        <>
            <ReactTable
                data={data.metadata}
                columns={columns}
                filterable
                getTrProps={(rowInfo) => {
                    const style = {};
                    if (rowInfo && rowInfo.original && rowInfo.original.status) {
                        if (rowInfo.original.status.toLowerCase() === 'failed') {
                            style['borderLeft'] = '4px solid #FA7978';
                        } else if (rowInfo.original.is_complete === true) {
                            style['borderLeft'] = '4px solid #95E969';
                        }
                    }
                    return {
                        style
                    };
                }}
            />
        </>
    )
}

AirflowDagLogsContent.defaultProps = {
    includeDagName: false,
    includeRetailer: false,
    customColumns: []
}

AirflowDagLogsContent.propTypes = {
    data: PropTypes.object,
    customColumns: PropTypes.arrayOf(PropTypes.object),
    includeDagName: PropTypes.bool,
    includeRetailer: PropTypes.bool,
    airflowUrl: PropTypes.string
}

export default AirflowDagLogsContent;