import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";

function IgnoredFTPsService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ignoredFTPs/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    return (<Page name={"Ignored FTPs Service"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Unmapped"}, {label: "Ignored FTPs"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[{header: "Ftp", accessorKey: "ftp"}]}
                editTable={[
                    INDEX_FIELD,
                    {id: 'ftp', Component: args => <EditFormTextInputField {...args} isRequired/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default IgnoredFTPsService;