import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral'
import ReactTable from "../../Components/ReactTable";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";

export const getColumns = (thresholdDollars, thresholdUnits) => ([
    {
        header: 'Week Id',
        accessorKey: 'weekId',
        className: 'numeric-field'
    },
    {
        header: 'Dollars From',
        accessorKey: 'dollarsFrom',
        className: 'numeric-field',
        cell: props => numeral(props.getValue()).format('$0,0.00'),
        sortingFn: (rowA, rowB) => {
            const dollarsFromA = rowA?.original?.dollarsFrom;
            const dollarsFromB = rowB?.original?.dollarsFrom;
            return sortAscendingWithNullsLast(dollarsFromA, dollarsFromB);
        }
    },
    {
        header: 'Dollars To',
        accessorKey: 'dollarsTo',
        className: 'numeric-field',
        cell: props => numeral(props.getValue()).format('$0,0.00'),
        sortingFn: (rowA, rowB) => {
            const dollarsToA = rowA?.original?.dollarsTo;
            const dollarsToB = rowB?.original?.dollarsTo;
            return sortAscendingWithNullsLast(dollarsToA, dollarsToB);
        }
    },
    {
        header: 'Dollars Change %',
        accessorKey: 'dollarsChangePercentage',
        className: 'numeric-field',
        cell: props => {
            const alertRow = Math.abs(props.getValue()) * 100 > thresholdDollars || (props.row.original.dollarsFrom === null && props.row.original.dollarsTo !== null);
            return (<span
                className={alertRow ? 'spotlight-percentage-red' : ''}>{numeral(props.getValue()).format('0.00%')}</span>)
        },
        sortingFn: (rowA, rowB) => {
            const dollarsChangePercentageA = rowA?.original?.dollarsChangePercentage;
            const dollarsChangePercentageB = rowB?.original?.dollarsChangePercentage;
            return sortAscendingWithNullsLast(dollarsChangePercentageA, dollarsChangePercentageB);
        }
    },
    {
        header: 'Units From',
        accessorKey: 'unitsFrom',
        className: 'numeric-field',
        cell: props => numeral(props.getValue()).format('0,0'),
        sortingFn: (rowA, rowB) => {
            const unitsFromA = rowA?.original?.unitsFrom;
            const unitsFromB = rowB?.original?.unitsFrom;
            return sortAscendingWithNullsLast(unitsFromA, unitsFromB);
        }
    },
    {
        header: 'Units To',
        accessorKey: 'unitsTo',
        className: 'numeric-field',
        cell: props => numeral(props.getValue()).format('0,0'),
        sortingFn: (rowA, rowB) => {
            const unitsToA = rowA?.original?.unitsTo;
            const unitsToB = rowB?.original?.unitsTo;
            return sortAscendingWithNullsLast(unitsToA, unitsToB);
        }
    },
    {
        header: 'Units Change %',
        accessorKey: 'unitsChangePercentage',
        className: 'numeric-field',
        cell: props => {
            const alertRow = Math.abs(props.getValue()) * 100 > thresholdUnits || (props.row.original.unitsFrom === null && props.row.original.unitsTo !== null);
            return (<span
                className={alertRow ? 'spotlight-percentage-red' : ''}>{numeral(props.getValue()).format('0.00%')}</span>)
        },
        sortingFn: (rowA, rowB) => {
            const unitsChangePercentageA = rowA?.original?.unitsChangePercentage;
            const unitsChangePercentageB = rowB?.original?.unitsChangePercentage;
            return sortAscendingWithNullsLast(unitsChangePercentageA, unitsChangePercentageB);
        }
    }
]);

const ExtractSpotlightDrillDownTables = ({data, thresholdDollars, thresholdUnits}) => {


    return <div>
        {data.map((drillDownGroup, index) => {
            return <div className={"drill-down-table"} key={index}>
                <ReactTable id={`drill-down-table-${index}`}
                            data={drillDownGroup.drillDownGroup}
                            columns={getColumns(thresholdDollars, thresholdUnits)}
                />
            </div>
        })}
    </div>;
};


ExtractSpotlightDrillDownTables.propTypes = {
    data: PropTypes.array,
    thresholdDollars: PropTypes.number.isRequired,
    thresholdUnits: PropTypes.number.isRequired,
};

export default ExtractSpotlightDrillDownTables;