import React from 'react';
import { CONFIG_TYPES } from "../Configurations/FileTypes";

export const KnownItemPercentage = (props) => {
    const {normalizedCount, cleansedInputCount, totalExistingUPCCount, fileType} = props.original;
    const denominator = fileType === CONFIG_TYPES.ITEMS.name && totalExistingUPCCount
      ? normalizedCount
      : cleansedInputCount;
    return (
        denominator ?
            <div className={'feedback'}>
                <div className={'width-80'}>
                    <div className={'known-item-percentage'}
                         data-tooltip={true}
                         title={'known item percentage'}
                    >
                        {'KI%:'}
                    </div>
                </div>
                {Math.trunc((totalExistingUPCCount / denominator) * 100)}%
            </div> : ''
    );
};

KnownItemPercentage.propTypes = {};