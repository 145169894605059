import React from 'react';
import _ from 'lodash';

export const SelectableList = ({items, selected, onItemSelected}) => {
    return (
        <ul className={'selectable-list'} data-testid="selectable_list">
            {items.map(item =>
                <li key={item.label}
                    className={_.isEqual(selected, item) ? 'selected' : ''}
                    onClick={() => onItemSelected(_.isEqual(item, selected) ? undefined : item)}
                    data-testid={item.label}
                >
                    <div className={'selectable-list-item-label'}>{item.label}</div>
                    <div className={'selectable-list-item-value'}>{item.value}</div>
                </li>
            )}
        </ul>
    )
}