import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import ButtonLoader from "../../../utils/components/ButtonLoader";
import {makeApiCallDelete, makeApiCallPost} from "../../../Api/makeApiCall";
import PropTypes from "prop-types";
import Select from "react-select";
import {faIcons} from "../../../utils/iconConstants";
import {wrapValueInOptionStructure} from "../../../utils/maybeAddLabelToOptions";
import {SELECT_DROPDOWN_CONTAINER_TEST_ID} from "../../Components/Dropdowns/SelectDropdown";

export const ManifestDropdown = ({ftp, ingestFileName, configName, manifestFileName, onSubmitCallback, data}) => {
    const [configSelection, setConfigSelection] = useState(configName);

    useEffect(() => {
        setConfigSelection(configName)
    }, [configName])

    const ftpConfigMappings = data.sales_mappings && data.sales_mappings.map(x => wrapValueInOptionStructure(x.filename));
    const replacedManifestFileName = manifestFileName && manifestFileName.replace(".json", "");
    const manifestFileNameForUse = replacedManifestFileName || ftp;
    const options = _.isEmpty(ftpConfigMappings) ? [wrapValueInOptionStructure(`${ftp}.json`)] : ftpConfigMappings;

    return <div className={'manifest-container'}>
        <div className={"manifest-table-dropdown-container"} data-testid={SELECT_DROPDOWN_CONTAINER_TEST_ID}>
            <Select
                id={"manifest_table_dropdown"}
                placeholder={"Manifest..."}
                menuPosition={"fixed"}
                menuPlacement={"bottom"}
                menuShouldBlockScroll={true}
                maxMenuHeight={200}
                classNamePrefix={"manifest-table-dropdown"}
                value={options.find(option => option.value === configSelection)}
                options={options}
                onChange={(selected) => setConfigSelection(selected.value)}
            />
        </div>
        <ButtonLoader
            buttonTitle={<i className={faIcons.save} data-testid={"manifest-save"}/>}
            buttonClassName={"header-button"}
            title={"Save"}
            url={`${process.env.REACT_APP_EDP_API_BASE_URL}/manifest`}
            axiosFunction={makeApiCallPost}
            toastTextSuccess={`Successfully added ${ingestFileName} to manifest with config ${configSelection}`}
            toastTextFailure={`Failed to add ${ingestFileName} to manifest`}
            onSubmitCallback={onSubmitCallback}
            postBody={({
                configFileName: configSelection,
                ingestFileName,
                manifestFileName: manifestFileNameForUse
            })}
        />
        <ButtonLoader
            buttonTitle={<i className={faIcons.delete} data-testid={"manifest-delete"}/>}
            buttonClassName={"header-button"}
            title={"Delete"}
            url={`${process.env.REACT_APP_EDP_API_BASE_URL}/manifest?manifestFileName=${manifestFileNameForUse}&ingestFileName=${ingestFileName}`}
            axiosFunction={makeApiCallDelete}
            toastTextSuccess={`Successfully deleted ${ingestFileName} from manifest`}
            toastTextFailure={`Failed to delete ${ingestFileName}`}
            onSubmitCallback={onSubmitCallback}
        />
    </div>;
};

ManifestDropdown.defaultProps = {
    onSubmitCallback: _.identity
};

ManifestDropdown.propTypes = {
    ftp: PropTypes.string,
    ingestFileName: PropTypes.string,
    configName: PropTypes.string,
    manifestFileName: PropTypes.string,
    onSubmitCallback: PropTypes.func,
    data: PropTypes.shape({
        sales_mappings: PropTypes.shape({
            filename: PropTypes.string
        })
    })
};
