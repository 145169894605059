import recommendedMarketDesignation from "./RecommendedMarketDesignation.js";
import partOfUniverse from "./PartOfUniverse.js";
import matchSorter from "match-sorter";

export const getUnitedState = original => original.country === 'US' ? original.state : '';

export const storesCompositionTabColumns = (companyType, marketDesignation, storeCount) => [
    {
        header: 'Id',
        id: 1,
        accessorKey: 'store.storeId'
    },
    {
        header: 'Name',
        id: 2,
        accessorKey: 'store.storeName'
    },
    {
        header: 'State',
        id: 3,
        accessorKey: 'store',
        cell: ({getValue}) => getUnitedState(getValue()),
        filterFn: (row, columnIds, filterValue) => matchSorter([row], filterValue, {keys: ['original.store.state']}).length > 0,
    },
    {
        header: 'Acv',
        id: 4,
        accessorKey: 'salesAcv',
        cell: ({getValue}) => `${getValue().toFixed(2)}`
    },
    {
        header: 'Departments With Sales',
        id: 5,
        accessorKey: 'departmentsWithSales',
    },
    {
        header: 'Natural And Specialty Percentage',
        id: 6,
        accessorKey: 'naturalAndSpecialtySalesPercentage',
        cell: ({getValue}) => `${getValue().toFixed(2)}%`
    },
    {
        header: 'Natural Percentage',
        id: 7,
        accessorKey: 'naturalSalesPercentage',
        cell: ({getValue}) => `${getValue().toFixed(2)}%`
    },
    {
        header: 'Herbs & Homeopathic + Vitamins & Supplements Sales Percentage',
        id: 8,
        accessorKey: 'vitaminsAndSupplementsDepartmentsSalesPercentage',
        cell: ({getValue}) => `${getValue().toFixed(2)}%`
    },
    {
        header: 'Recommended Market Designation',
        id: 9,
        accessorFn: (row) => recommendedMarketDesignation(row, companyType, marketDesignation)
    },
    {
        header: 'Part Of Universe',
        id: 10,
        accessorFn: (row) => partOfUniverse(row, storeCount, marketDesignation, 'store')
    },
]