import React from 'react';
import PropTypes from 'prop-types';
import {convertToQueryParams} from "../../../utils/urlBuilder";

const AlertLink = (props) => {
    const filteredAlertIds = props.value.filter(x => x !== "");
    const AlertLink = <a className={'numeric-field'} target={"_blank"} rel="noopener noreferrer" href={`/alerts/log?${convertToQueryParams({alertIds: filteredAlertIds})}`}>{props.value && filteredAlertIds.length}</a>;
    return filteredAlertIds.length ? <div  className={'feedback'}><div className={'width-80'}><b>Alerts:</b></div>{AlertLink}</div> : '';
};


AlertLink.propTypes = {
    value: PropTypes.array
};

export default AlertLink;