import React from 'react';
import PropTypes from 'prop-types';
import {convertLabelTextToDataTestId} from "./testHelpers/assertUtils";

const UnorderedListItems = ({listItems, title}) => {
    const values = listItems.map((item, index) => <li key={index}>{item}</li>)

    return <>
        <div>{title}</div>
        <ul data-testid={`${convertLabelTextToDataTestId(title, undefined,"unordered-list")}`}>
            {
                values
            }
        </ul>
    </>;
};

UnorderedListItems.propTypes = {
    listItems: PropTypes.array,
    title: PropTypes.string
};

export default UnorderedListItems;