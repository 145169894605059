import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, NAME_COLUMN, NAME_FIELD,} from "../Components/ReactTable/common_custom_columns";

const HighlightedEvents = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/highlightedEvents`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});


    return <Page name={"Highlighted Events"} displayHelmetHeader={false}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[NAME_COLUMN]}
                editTable={[INDEX_FIELD, NAME_FIELD]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>;
};

export default HighlightedEvents;