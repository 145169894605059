import * as PropTypes from 'prop-types';
import React from "react";

function RetailerTotalCell({data}) {
    const missingDataIssue = data.issues ? data.issues.filter(x => x.issueType === 'missingData') : [];
    const hasIssue = missingDataIssue.length > 0;
    const issueClassName = hasIssue ? 'issue-link-color-red' : ''
    const issueText = hasIssue ? `${missingDataIssue.length}` : '';


    return <div>
        {hasIssue ? <a target="_blank" rel="noopener noreferrer"
                       href={`/retailerReadiness/retailerTotals?retailerId=${data.retailer.retailerId}&directSubmit=true&periodAbbreviation=${data.periodAbbreviation}`}
                       className={`retailer-totals-link ${issueClassName}`}
                       title={`Retailer Totals`}
    >
        {issueText}
    </a> : ""}
    </div>

}

RetailerTotalCell.propTypes = {
    data: PropTypes.object
}

export default RetailerTotalCell;