import {CONFIG_TYPES} from "../Configurations/FileTypes";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import {AuditFtpConfigUpdateItem} from "./AuditFtpConfigUpdateItem";
import {makeApiCall} from "../../Api/makeApiCall";
import LoadingSpinner from "../Components/LoadingSpinner";

export const AuditFtpMetadataItem = ({ftp}) => {
    const [fileTypeMappingData, setFileTypeMappingData] = useState(undefined);
    React.useEffect(() => {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/?ftp=${ftp}&configType=file_type`;
        makeApiCall(endpointUrl)
            .then(data => {
                setFileTypeMappingData(data)
            });
    }, [ftp])

    return (
        <>
            {!fileTypeMappingData && <LoadingSpinner spinnerClassName={'spinner-container-small'}/>}
            {fileTypeMappingData &&
                <div id={`ftp_${ftp}`} className={'retail-management-audit-section ftp-metadata-item'}>
                    <h4>{ftp}</h4>
                    {Object.values(CONFIG_TYPES).map(configType =>
                        <AuditFtpConfigUpdateItem key={configType.getName()}
                                                  ftp={ftp}
                                                  configType={configType}
                                                  fileTypeMappingData={fileTypeMappingData}/>)}
                </div>}
        </>
    )
}

AuditFtpMetadataItem.propTypes = {
    ftp: PropTypes.string.isRequired
}
