import React, {useEffect, useState} from 'react';
import {
    dateOptions,
    DEFAULT_DATE_FILTER_OPTION,
    formatDate,
    getFilterDateRange,
    getFilterOptions,
    getLastWeekday,
    getMinFromDate,
    isCustomDateFilterValue,
    QUERYSTRING_PARAM_ACCOUNTMANAGER,
    QUERYSTRING_PARAM_COMPANYTYPE,
    QUERYSTRING_PARAM_FROM,
    QUERYSTRING_PARAM_POS_SUBMISSION_TYPE,
    QUERYSTRING_PARAM_POS_VENDOR,
    QUERYSTRING_PARAM_POSSYSTEM,
    QUERYSTRING_PARAM_RETAILER_ASSOCIATION,
    QUERYSTRING_PARAM_SELECTED_DATE_OPTION,
    QUERYSTRING_PARAM_TO,
    QUERYSTRING_PARAM_WHOLESALER,
    todayChicago,
    useLoadOptions
} from "../filters";
import moment from "moment";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import Select from "react-select";
import FilterOptionsGroup from "../components/FilterOptionsGroup";
import ReactTable from "../../../Components/ReactTable";
import {RETAILER_ID_NAME_COLUMN} from "../../../Components/ReactTable/common_custom_columns";
import {MissingStoresCell} from "../components/MissingStoresCell";
import {getRetailerLookup} from "../../../../utils/retailerMapCache";
import {
    convertToQueryParams,
    getStringParamOrDefault,
    getStringParamsOrDefault,
    setQueryStringForState,
} from "../../../../utils/urlBuilder";
import {makeApiCall, makeApiCallPost} from "../../../../Api/makeApiCall";
import {getUserEmail} from "../../../../auth/accessTokenValidator";
import {toastError, toastSuccess} from "../../../../utils/toast";
import {toast} from "react-toastify";
import CollapsableContent from "../../../Components/CollapsableContent";
import IconImage, {Icons} from "../../../Components/IconImage";

const MissingDataListTab = () => {
    const {
        loadingOptions,
        retailerAssociations,
        wholesalers,
        accountManagers,
        posSystems,
        companyTypes,
        posSubmissionType,
        posVendor
    } = useLoadOptions()

    const todaysDate = todayChicago().format().split("T")[0]

    function getInitialState(queryParamVariable) {
        const stringParamOrDefault = getStringParamsOrDefault(queryParamVariable, 'All');
        return stringParamOrDefault.map(x => ({value: x, label: x}))
    }

    function getInitialStateForDate() {
        const stringParamOrDefault = getStringParamOrDefault(QUERYSTRING_PARAM_SELECTED_DATE_OPTION, DEFAULT_DATE_FILTER_OPTION);
        return dateOptions.filter(x => x.value === stringParamOrDefault)[0]
    }

    function getInitialStateDates(queryVar, daysAgo, weekDayToGet) {
        let defaultDate = getLastWeekday(todayChicago().format(), daysAgo, weekDayToGet).split("T")[0];
        return getStringParamOrDefault(queryVar, defaultDate);
    }

    const [customFromDate, setCustomFromDate] = useState(getInitialStateDates(QUERYSTRING_PARAM_FROM, 7, 1));
    const [customToDate, setCustomToDate] = useState(getInitialStateDates(QUERYSTRING_PARAM_TO, 0, 0));
    const [selectedDateOption, setSelectedDateOption] = useState(getInitialStateForDate());
    const [selectedRetailerAssociation, setSelectedRetailerAssociation] = useState(getInitialState(QUERYSTRING_PARAM_RETAILER_ASSOCIATION))
    const [selectedWholesalers, setSelectedWholesalers] = useState(getInitialState(QUERYSTRING_PARAM_WHOLESALER))
    const [selectedPosSystems, setSelectedPosSystems] = useState(getInitialState(QUERYSTRING_PARAM_POSSYSTEM))
    const [selectedCompanyTypes, setSelectedCompanyTypes] = useState(getInitialState(QUERYSTRING_PARAM_COMPANYTYPE))
    const [selectedAccountManagers, setSelectedAccountManagers] = useState(getInitialState(QUERYSTRING_PARAM_ACCOUNTMANAGER))
    const [selectedPosSubmissionType, setSelectedPosSubmissionType] = useState(getInitialState(QUERYSTRING_PARAM_POS_SUBMISSION_TYPE))
    const [selectedPosVendor, setSelectedPosVendor] = useState(getInitialState(QUERYSTRING_PARAM_POS_VENDOR))

    const [fromDate, toDate] = getFilterDateRange(selectedDateOption.value, customFromDate, customToDate);

    const [loadingRetailerMissingData, setLoadingRetailerMissingData] = useState(false)
    const [retailerMissingData, setRetailerMissingData] = useState([]);

    const [exportData, setExportData] = useState([])
    const getSelectedValues = (selectedValues) => selectedValues.map(x => x.value)

    useEffect(() => {
        const paramsForQueryState = {
            [QUERYSTRING_PARAM_FROM]: fromDate,
            [QUERYSTRING_PARAM_TO]: toDate,
            [QUERYSTRING_PARAM_SELECTED_DATE_OPTION]: selectedDateOption.value,
            [QUERYSTRING_PARAM_RETAILER_ASSOCIATION.toString()]: getSelectedValues(selectedRetailerAssociation),
            [QUERYSTRING_PARAM_WHOLESALER]: getSelectedValues(selectedWholesalers),
            [QUERYSTRING_PARAM_ACCOUNTMANAGER]: getSelectedValues(selectedAccountManagers),
            [QUERYSTRING_PARAM_POSSYSTEM]: getSelectedValues(selectedPosSystems),
            [QUERYSTRING_PARAM_COMPANYTYPE]: getSelectedValues(selectedCompanyTypes),
            [QUERYSTRING_PARAM_POS_SUBMISSION_TYPE]: getSelectedValues(selectedPosSubmissionType),
            [QUERYSTRING_PARAM_POS_VENDOR]: getSelectedValues(selectedPosVendor),
        }
        setQueryStringForState(paramsForQueryState)
    }, [
        customFromDate, customToDate, selectedDateOption, selectedRetailerAssociation, selectedWholesalers,
        selectedPosSystems, selectedCompanyTypes, selectedAccountManagers, selectedPosSubmissionType, selectedPosVendor
    ])
    const getMissingData = () => {
        setLoadingRetailerMissingData(true)
        const params = convertToQueryParams({
            fromDate,
            toDate,
            retailerAssociation: getSelectedValues(selectedRetailerAssociation),
            wholesaler: getSelectedValues(selectedWholesalers),
            accountManager: getSelectedValues(selectedAccountManagers),
            posSystem: getSelectedValues(selectedPosSystems),
            companyType: getSelectedValues(selectedCompanyTypes),
            posSubmissionType: getSelectedValues(selectedPosSubmissionType),
            posVendor: getSelectedValues(selectedPosVendor)
        })
        const retailersEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/missingData/retailers?${params}`

        makeApiCall(retailersEndpointUrl)
            .then(data => setRetailerMissingData(data))
            .then(() => setLoadingRetailerMissingData(false))

    }

    useEffect(() => {
        const weeksDifference = moment(Date.parse(customToDate)).diff(Date.parse(customFromDate), 'weeks', true)
        if (weeksDifference > 52) {
            setCustomFromDate(moment(Date.parse(customToDate)).subtract(52, 'weeks').toISOString().split("T")[0])
        }
    }, [customToDate]);

    const validateFromDate = (fromDate, toDateToCheck) => {

        if (moment(Date.parse(toDateToCheck)).diff(Date.parse(fromDate), 'weeks') <= 52 && fromDate <= toDateToCheck) {
            setCustomFromDate(fromDate)
        } else {
            setCustomFromDate(moment(Date.parse(toDateToCheck)).subtract(52, 'weeks').toISOString().split("T")[0])
        }
    }
    const validateToDate = (toDate) => {
        let toDateToSet = toDate
        let minToDate = (moment.utc(Date.parse(customToDate)).subtract(52, 'weeks').toISOString().split("T")[0])
        let maxToDate = moment.utc(Date.parse(getLastWeekday(todayChicago().format()).split("T")[0]));
        if (toDateToSet < minToDate) {
            toDateToSet = minToDate
        }

        if (moment(Date.parse(toDateToSet)) > maxToDate) {
            toDateToSet = maxToDate.format('yyyy-MM-DD')
        }

        setCustomToDate(toDateToSet)
        validateFromDate(customFromDate, toDateToSet)
    }
    const generateNewExport = () => {
        setLoadingRetailerMissingData(true);

        makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/missingData/export/generate`,
            {
                runBy: getUserEmail(),
                fromDate,
                toDate,
                filtersDescription: JSON.stringify({
                    'date': selectedDateOption.value,
                    'fromDate': fromDate,
                    'toDate': toDate,
                    'retailerAssociation': getSelectedValues(selectedRetailerAssociation),
                    'wholesaler': getSelectedValues(selectedWholesalers),
                    'accountManager': getSelectedValues(selectedAccountManagers),
                    'posSystem': getSelectedValues(selectedPosSystems),
                    'companyType': getSelectedValues(selectedCompanyTypes),
                    'posSubmissionType': getSelectedValues(selectedPosSubmissionType),
                    'posVendor': getSelectedValues(selectedPosVendor)
                }),
                retailerAssociations: getSelectedValues(selectedRetailerAssociation),
                wholesaler: getSelectedValues(selectedWholesalers),
                accountManager: getSelectedValues(selectedAccountManagers),
                posSystem: getSelectedValues(selectedPosSystems),
                companyType: getSelectedValues(selectedCompanyTypes),
                posSubmissionType: getSelectedValues(selectedPosSubmissionType),
                posVendor: getSelectedValues(selectedPosVendor)
            }).then((result) => {
            if (result.apiError) {
                console.error(result.apiError)
                toastError(toast, 'Export was NOT generated. An unexpected error occurred.  Please try again later.')
            } else {
                exportData.push(result)
                setExportData(exportData)
                toastSuccess(toast, `Export was generated successfully! Switch to the "Exports" tab to download.`)
            }
            setLoadingRetailerMissingData(false)
        })
    }

    const missingDataFromId = 'missing-data-from';
    const missingDataStopId = 'missing-data-stop';

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {
            header: 'Missing Data',
            id: 'missingData',
            accessorKey: 'stores',
            cell: MissingStoresCell,
            className: 'display-linebreak'
        },
        {
            header: 'Consecutive Weeks',
            id: 'consecutiveWeeks',
            accessorKey: 'consecutiveWeeks'
        },
        {
            header: 'Roll-up Day',
            id: 'rollUpDay',
            accessorFn: (row) => getRetailerLookup()[row.retailerId]?.rollUpDayOfWeek
        }
    ];

    return (
        <div>
            {(loadingOptions) ? <LoadingSpinner/> : <div className={'action-buttons-and-filter-summary'}>
                <CollapsableContent
                    collapsedContent={<div>{`Select Filters`} <IconImage iconType={Icons.FILTER}/></div>}
                    expandedContent={<div className={'filters'}>
                        <div className={'missing-data-date-group'} data-testid={'date-group'}>
                            <h6>{'Date'}</h6>
                            <Select
                                id={"missing-data-date-filter_dropdown"}
                                className={"missing-data-date-select-dropdown"}
                                classNamePrefix={"missing-data-date-select-dropdown"}
                                value={selectedDateOption}
                                options={dateOptions}
                                onChange={(selectedDateOption) => setSelectedDateOption(selectedDateOption)}
                            />
                            {
                                isCustomDateFilterValue(selectedDateOption.value) && (
                                    <div className={'missing-data-date-pickers'}>
                                        <p className={"custom-date-notice"}>
                                            Select dates up to 52 weeks maximum prior to the latest complete
                                            SPINS week.
                                        </p>
                                        <div className={'missing-data-date-pickers-section'}>
                                            <div className={'missing-data-date-pickers-from-section'}>
                                                <label htmlFor={missingDataFromId}>
                                                    From
                                                </label>
                                                <input id={missingDataFromId} type="date"
                                                       min={getMinFromDate(customToDate)}
                                                       max={todaysDate}
                                                       value={customFromDate}
                                                       onChange={e => setCustomFromDate(e.target.value)}
                                                       onBlur={e => validateFromDate(e.target.value, customToDate)}
                                                />
                                            </div>
                                            <div className={'missing-data-date-pickers-to-section'}>
                                                <label htmlFor={missingDataStopId}>
                                                    To
                                                </label>
                                                <input id={missingDataStopId} type="date"
                                                       min={formatDate(moment(Date.parse(todaysDate)).subtract(52, 'weeks'))}
                                                       max={todaysDate}
                                                       value={customToDate}
                                                       onChange={e => validateToDate(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={'missing-data-select-filters'}>
                            <FilterOptionsGroup
                                title={'Retailer Associations'}
                                options={getFilterOptions(retailerAssociations)}
                                selectedValue={selectedRetailerAssociation}
                                callback={(retailerAssociation) => setSelectedRetailerAssociation(retailerAssociation)}
                            />
                            <FilterOptionsGroup
                                title={'Wholesaler'}
                                options={getFilterOptions(wholesalers)}
                                selectedValue={selectedWholesalers}
                                callback={(wholesalers) => setSelectedWholesalers(wholesalers)}
                            />
                            <FilterOptionsGroup
                                title={'Company Type'}
                                options={getFilterOptions(companyTypes)}
                                selectedValue={selectedCompanyTypes}
                                callback={(companyTypes) => setSelectedCompanyTypes(companyTypes)}
                            />
                            <FilterOptionsGroup
                                title={'Account Manager'}
                                options={getFilterOptions(accountManagers)}
                                selectedValue={selectedAccountManagers}
                                callback={(accountManagers) => setSelectedAccountManagers(accountManagers)}
                            />
                        </div>
                        <div className={'missing-data-select-filters'}>
                            <FilterOptionsGroup
                                title={'POS Vendor'}
                                options={getFilterOptions(posVendor)}
                                selectedValue={selectedPosVendor}
                                callback={(posVendor) => setSelectedPosVendor(posVendor)}
                            />
                            <FilterOptionsGroup
                                title={'POS System'}
                                options={getFilterOptions(posSystems)}
                                selectedValue={selectedPosSystems}
                                callback={(posSystems) => setSelectedPosSystems(posSystems)}
                            />
                            <FilterOptionsGroup
                                title={'POS Submission Type'}
                                options={getFilterOptions(posSubmissionType)}
                                selectedValue={selectedPosSubmissionType}
                                callback={(posSubmissionType) => setSelectedPosSubmissionType(posSubmissionType)}
                            />
                        </div>
                    </div>}/>
            </div>}
            {(loadingOptions || loadingRetailerMissingData) ? <LoadingSpinner/> :
                <ReactTable filterable={true} data={retailerMissingData} columns={columns}>
                    {({}) => {
                        return (<div className={'action-buttons'}>
                            <button className={'button primary'} aria-label={'generate-download'}
                                    onClick={generateNewExport}>
                                Generate Download
                            </button>
                            <button className={'button primary'} aria-label={'apply-filters'}
                                    onClick={getMissingData}>
                                Apply Filters
                            </button>
                        </div>)
                    }}
                </ReactTable>}
        </div>)
};

export default MissingDataListTab;