import React from 'react';

export const SELECT_COLUMN = {
    id: 'selected',
    size: 60,
    headerStyle: {flexGrow: 0},
    cellStyle: {flexGrow: 0},
    header: ({table}) => (
        <IndeterminateCheckbox
            {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
            }}
        />
    ),
    cell: ({row}) => (
        <div className="px-1">
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    onChange: row.getToggleSelectedHandler(),
                }}
            />
        </div>
    ),
};
function IndeterminateCheckbox({ indeterminate, className = '', ...rest }) {
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean' && ref.current) {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={'react-table-checkbox'}
            {...rest}
        />
    )
}