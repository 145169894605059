import React, {useEffect, useMemo, useState} from "react";
import {
    filterFns,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import classnames from "classnames";
import _ from "lodash";
import {Pagination} from "../ReactTable/Pagination";
import {ReactTableHeader} from "../ReactTable/ReactTableHeader";
import {ReactTableBody} from "../ReactTable/ReactTableBody";
import {DefaultColumnFilter} from "./ReactTable/helpers";
import {wrapValueLabelInOptionStructure} from "../../utils/maybeAddLabelToOptions";
import PropTypes from "prop-types";
import {adjustMinimumOffset, handleScrollChange} from "./ReactTable/scrollLogic";
import {getJsonParamOrDefault, getStringParamOrDefault, updateQueryStringForState} from "../../utils/urlBuilder";


const ReactTable = (props) => {
    const {
        data,
        columns,
        initialState,
        children,
        className = "",
        loading = false,
        showPagination = true,
        trClassName = "",
        getTrProps = () => ({}),
        filterable = false,
        hasStickyHeader = false,
        getTdProps = () => {
        },
        CustomPaginationComponent = undefined,
        customPaginationProps = {},
        reactTableXCoordinateRef = undefined,
        updatePageIndex,
        updatePageSize,
        saveSortStateToUrl
    } = props;

    const memoColumns = useMemo(() => {
        return columns.map(x => ({...x, filterFn: x.filterFn || filterFns.includesString}))
            .filter(col => _.has(col, "show") ? col.show : true);
    }, [columns]);

    const defaultColumn = React.useMemo(
        () => ({
            filter: DefaultColumnFilter,
            minWidth: 30,
            maxWidth: 400
        }),
        []
    );

    const [sorting, setSorting] = useState(saveSortStateToUrl ? getJsonParamOrDefault('sorting', []) : undefined)
    const sortingProps = saveSortStateToUrl ? {state: {sorting}, onSortingChange: setSorting} : {}
    useEffect(() => {
        if (!saveSortStateToUrl) return;
        updateQueryStringForState({sorting: JSON.stringify(sorting)})
    }, [saveSortStateToUrl, sorting])

    const table = useReactTable(
        {
            columns: memoColumns, data, defaultColumn,
            initialState: {
                pageIndex: 0,
                ...initialState
            },
            ...sortingProps,
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            enableColumnResizing: true,
            columnResizeMode: "onChange"
        }
    );


    const updateTableXCoordinate = event => {
        if (reactTableXCoordinateRef) {
            reactTableXCoordinateRef.current = event.currentTarget.scrollLeft;
        }
    };

    const state = table.getState();
    const {pagination: {pageIndex, pageSize}} = state;
    const headerMinimumOffset = hasStickyHeader ? adjustMinimumOffset() : 0;
    const stickyClassName = hasStickyHeader ? "sticky" : "";
    const stickyHeaderClassName = hasStickyHeader ? "headerForStickyHeader" : "";
    const stickyBodyClassName = hasStickyHeader ? "bodyForStickyHeader" : "";

    React.useLayoutEffect(() => {
        if (!_.isEmpty(reactTableXCoordinateRef)) {
            const rtTable = document.getElementsByClassName("rt-table")[0];
            if (rtTable)
                rtTable.scrollLeft = reactTableXCoordinateRef.current;
        }
    }, []);


    useEffect(() => {
        updatePageIndex(pageIndex);
        updatePageSize(pageSize);
        handleScrollChange(headerMinimumOffset);

    }, [pageIndex, pageSize, headerMinimumOffset]);

    useEffect(() => {
        table.resetRowSelection();
    }, [state.columnFilters])

    return <>
        <div className={"react-table-wrapper"} data-testid={"react-table-wrapper"}>
            {children && children({rows: table.getFilteredRowModel().rows, table})}
            <div className={classnames(className, stickyClassName, "ReactTable7")} data-testid={"ReactTable7"}>
                <div className={"rt-table"} data-testid={'rt-table'}
                     data-testref={reactTableXCoordinateRef}
                     onScroll={updateTableXCoordinate}>
                    <div className={classnames(stickyHeaderClassName, "rt-thead -header")}
                         data-testid={"ReactTable7-rt-thead"}>
                        {
                            table.getHeaderGroups().map((headerGroup, i) => <ReactTableHeader
                                key={`headerGroup_${i}`}
                                headerGroup={headerGroup}
                                table={table}
                                filterable={filterable}/>)
                        }
                    </div>
                    <div className={classnames(stickyBodyClassName, "rt-tbody")}
                         data-testid={"ReactTable7-rt-tbody"}>
                        <div role={"rowgroup"} className="rt-tr-group" data-testid={"react-table-body"}>
                            {
                                table.getRowModel().rows.map((row, ind) => <ReactTableBody
                                    key={`headerGroup_${ind}`}
                                    row={row} index={ind}
                                    table={table}
                                    trClassName={trClassName}
                                    getTrProps={getTrProps}
                                    getTdProps={getTdProps}/>)
                            }
                        </div>
                    </div>
                </div>
                {showPagination && (CustomPaginationComponent ?
                    <CustomPaginationComponent {...customPaginationProps} /> :
                    <Pagination
                        showPageSizeDropdown={props.showPageSizeDropdown}
                        firstPageOnClick={() => table.setPageIndex(0)}
                        canPreviousPage={table.getCanPreviousPage()}
                        previousPageOnClick={() => table.previousPage()}
                        pageIndex={pageIndex}
                        pageOptions={table.getPageOptions()}
                        value={pageSize}
                        onChange={(option) => table.setPageSize(Number(option.value))}
                        dropdownCallBack={(pageSize) => wrapValueLabelInOptionStructure(pageSize, `Show ${pageSize}`)}
                        nextPageOnClick={() => table.nextPage()}
                        canNextPage={table.getCanNextPage()}
                        lastPageOnClick={() => table.setPageIndex(table.getPageCount() - 1)
                        }/>)
                }


                <div className={classnames("-loading", loading ? "-active" : "")}>
                    {
                        loading && <div className="-loading-inner" data-testid={"loading-spinner"}>
                            <i className="fas fa-carrot fa-spin spinner"/> Loading...
                        </div>
                    }
                </div>
            </div>
        </div>
    </>;
};

export default ReactTable;

const paginationShape = {
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number
};

const initialState = {
    columnFilters: PropTypes.arrayOf(PropTypes.object),
    sorting: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.shape(paginationShape)
};

ReactTable.defaultProps = {
    saveSortStateToUrl: false,
    showPageSizeDropdown: true,
    updatePageSize: () => undefined,
    updatePageIndex: () => undefined,
}

ReactTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    initialState: PropTypes.shape(initialState),
    children: PropTypes.any,
    className: PropTypes.string,
    loading: PropTypes.bool,
    showPagination: PropTypes.bool,
    trClassName: PropTypes.string,
    getTrProps: PropTypes.func,
    filterable: PropTypes.bool,
    hasStickyHeader: PropTypes.bool,
    getTdProps: PropTypes.func,
    showPageSizeDropdown: PropTypes.bool,
    CustomPaginationComponent: PropTypes.any,
    customPaginationProps: PropTypes.object,
    reactTableXCoordinateRef: PropTypes.object,
    saveSortStateToUrl: PropTypes.bool
}
