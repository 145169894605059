import React from 'react';
import ButtonLoader from "../../../utils/components/ButtonLoader";
import ReIngest from "./ReIngest";
import {ShowFilePartial} from "./ShowFilePartial";
import {CONFIG_TYPES} from "../../Configurations/FileTypes";

export const SourceFilesReportActions = ({archiveLocation, sourceFiles, displayFileName, usedFile, ftp}) => {
    const UNDEFINED_BECAUSE_THIS_CAN_NEVER_BE_BULK = undefined;

    return sourceFiles.map(fileNameAdditionalInfoKeyValue => {
        const fileName = Object.keys(fileNameAdditionalInfoKeyValue)[0];
        const moreInfo = Object.values(fileNameAdditionalInfoKeyValue)[0];
        const archiveLocationAlwaysWithFilename = !archiveLocation.includes(fileName) ? `${archiveLocation}/${fileName}` : archiveLocation;
        let usedFileClassName = usedFile === fileName ? 'used-file' : 'unused-file';

        return <div key={fileName} className={'source-files-column'}>
            {displayFileName && <div className={`${usedFileClassName} file-name`} data-testid={"displayFileName"}>{fileName} ({moreInfo})</div>}
            <div className={'source-file-action'}>
                <ButtonLoader buttonTitle={'Ignore'}
                              url={`${process.env.REACT_APP_EDP_API_BASE_URL}/ignoreFile/?archiveLocation=${encodeURIComponent(archiveLocationAlwaysWithFilename)}`}
                              toastTextSuccess={`${fileName} has successfully been ignored`}
                              toastTextFailure={`${fileName} has failed to be ignored.`}
                />
            </div>
            <div className={'source-file-action'}>
                <ReIngest original={{
                    uniqueArchiveFileName: fileName,
                    isBulk: false,
                    retailerId: UNDEFINED_BECAUSE_THIS_CAN_NEVER_BE_BULK
                }} value={archiveLocationAlwaysWithFilename}
                />
            </div>
            <ShowFilePartial path={archiveLocationAlwaysWithFilename} fileType={CONFIG_TYPES.SALES} ftp={ftp}/>
        </div>
    });
};
