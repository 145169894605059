import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CollapsableContent from '../Components/CollapsableContent';
import {makeApiCallWithoutCatch} from '../../Api/makeApiCall';
import _ from 'lodash';
import {WhoEditedAndWhen} from './WhoEditedAndWhen';

const changedDiv = (data, filterMethod, formattingMethod) => {
    if (!data)
        return []

    return Object.entries(data).filter(([key, value]) => filterMethod(key, value, false)).map(([key, value]) => {
            return <div key={`${key}-${value}`}>{formattingMethod(key, value)}</div>
        })
}

function getFormattingMethod(parentField, insertOrDelete) {
    return (key, value) => {
        if (_.isArray(value) && _.isFinite(parseInt(key))) {
            return <div data-testId={`${insertOrDelete}_array_field_changes`}>The field <b>{parentField.replace('.', '')}</b> at index {value[0]} with
                value <b>{JSON.stringify(value[1])}</b> was {insertOrDelete}.</div>;
        }
        return <div data-testId={`${insertOrDelete}_field_changes`}>The field <b>{parentField}{key}</b> was {insertOrDelete} with value <b>{JSON.stringify(value)}</b>.</div>;
    };
}

function pushDiffMessages(messages, data, parentField) {
    const filterDeleteInsertAndPlainObjects =
        (key, value, includePlainObject) => !['$delete', '$insert'].includes(key) && _.isPlainObject(value) === includePlainObject;

    Object.entries(data)
        .filter(([key, value]) => filterDeleteInsertAndPlainObjects(key, value, true))
        .map(([key, value]) => pushDiffMessages(messages, value, `${parentField}${key}.`))

    messages.push(
        ...changedDiv(
            data,
            filterDeleteInsertAndPlainObjects,
            (key, value) => <div data-testId={`field_changes`}>The field <b>{parentField}{key}</b> was updated
                from <b>{JSON.stringify(value[0])}</b> to <b>{JSON.stringify(value[1])}</b>.</div>
        )
    );

    messages.push(
        ...changedDiv(data.$insert,
            () => true,
            getFormattingMethod(parentField, 'inserted')
        )
    )

    messages.push(
        ...changedDiv(
            data.$delete,
            () => true,
            getFormattingMethod(parentField, 'deleted')
        )
    )
}

const AuditConfigItemDiff = ({configName, fileName, update, previousItem}) => {
    const [differences, updateDifferences] = useState([])
    React.useEffect(() => {
        previousItem && makeApiCallWithoutCatch(`${process.env.REACT_APP_EDP_API_BASE_URL}/getConfigVersions/?ftp=${fileName}&configType=${configName}&updatedDate=${update.timestamp}&previousDate=${previousItem.timestamp}`)
            .then(data => {
                if (data) {
                    const messages = []

                    pushDiffMessages(messages, data, '');

                    updateDifferences(messages)
                }
            })
            .catch(() => {
            })
    }, [update, configName, fileName, previousItem])


    const whoEditedAndWhen = <WhoEditedAndWhen action={'Updated'} user={update.user} timestamp={update.timestamp}/>

    return previousItem && !_.isEmpty(differences) ?
        <CollapsableContent collapsedContent={whoEditedAndWhen} expandedContent={differences}/>
        : whoEditedAndWhen
};


AuditConfigItemDiff.propTypes = {
    configName: PropTypes.string,
    fileName: PropTypes.string,
    previousItem: PropTypes.object,
    update: PropTypes.object
};

export default AuditConfigItemDiff;