import React from 'react';
import PropTypes from "prop-types";
import {ConfigType} from "../../Configurations/FileTypes";
import {getNicelyFormattedNumber} from "../../../utils/formatUtil";

const ReportLink = ({runId, fileType, displayValue, gcsSuffix = ''}) => {
    const url = fileType ? `https://console.cloud.google.com/storage/browser/${process.env.REACT_APP_GCS_TMP_BASE_URL}${runId}/report/p_data${encodeURIComponent('=' + fileType.getPartition())}${gcsSuffix}` : `https://console.cloud.google.com/storage/browser/${process.env.REACT_APP_GCS_TMP_BASE_URL}${runId}/report`;
    return (
        <span className='number'>
            <a target='_blank'
               rel="noopener noreferrer"
               href={url}
            >
                {getNicelyFormattedNumber(displayValue)}
            </a>
        </span>
    );
};

ReportLink.propTypes = {
    fileType: PropTypes.instanceOf(ConfigType)
};

export default ReportLink;
