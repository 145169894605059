import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_NAME_COLUMN} from "../../Components/ReactTable/common_custom_columns";
import Page from "../../Components/Page";
import {
    EditFormDateInputField,
    EditFormDropdownInputField, EditFormMultiDropdownInputField,
    EditFormNumberInputField
} from "../../Components/ReactTable/EditRecordForm";
import {makeApiCall} from "../../../Api/makeApiCall";
import _ from "lodash";

const RetailerHighlightedEvents = ({retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/retailerHighlightedEvents/?retailerId=${retailerId}`
    const endpointUrlForPost = `${process.env.REACT_APP_EDP_API_BASE_URL}/retailerHighlightedEvents/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl, endpointUrlForPost});

    const [decodeTablesLoading, setDecodeTablesLoading] = useState(true);
    const [highlightEventsDropdown, setHighlightEventsDropdown] = useState([]);
    const [storesDropdown, setStoresDropdown] = useState([]);

    useEffect(() => {
        setDecodeTablesLoading(true)
        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/highlightedEvents`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/stores/?retailerId=${retailerId}`),
        ])
            .then(([highlightedEventsService, storesService,]) => {
                const dropdownValues = highlightedEventsService.data.map(value => (
                    {label: value.name, value: value.index}
                ));
                setHighlightEventsDropdown(dropdownValues);

                const storesDropdownValues = storesService.data.map(value => (
                    {label: value.storeName, value: value.storeId}
                ));
                setStoresDropdown(storesDropdownValues);

            })
            .finally(() => setDecodeTablesLoading(false));
    }, []);

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "Store Id", accessorKey: "storeIds"},
        {
            header: "Event",
            accessorKey: "highlightedEventId",
            filterable: true,
            size: 250,
            cell: ({getValue}) => {
                const displayValue = _.find(highlightEventsDropdown, {value: getValue()})
                return <div>{displayValue?.label || getValue()} </div>
            },
            filterFn: (row, columnIds, filterValue) => {
                const recordValue = row.original[columnIds];
                const label = _.find(highlightEventsDropdown, {value: recordValue})?.label
                return !filterValue || Boolean(`${recordValue} ${label?.toLowerCase()}`.includes(filterValue.toLowerCase()));
            },
        },
        {header: "Start Date", accessorKey: "startDate"},
        {header: "End Date", accessorKey: "endDate"}
    ];

    const cleanUpAndSubmitData = currentData => {
        Object.keys(currentData).forEach(attribute => {
                if (!currentData[attribute]) {
                    currentData[attribute] = null
                }
            }
        )
        onRowEditConfirmed(currentData).then()
    };

    return <Page name={"Highlighted Events"} displayHelmetHeader={false}>
        <LoadingWrapper loading={loading || decodeTablesLoading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    {id: 'retailerId', defaultValue: retailerId, Component: args => <EditFormNumberInputField {...args} label={"Retailer Id"} isRequired disabled/>},
                    {id: 'storeIds', Component: args => <EditFormMultiDropdownInputField {...args} options={storesDropdown}/>},
                    {id: 'highlightedEventId', Component: args => <EditFormDropdownInputField {...args} options={highlightEventsDropdown} isRequired/>},
                    {id: 'startDate', Component: args => <EditFormDateInputField {...args} isRequired/>},
                    {id: 'endDate', Component: args => <EditFormDateInputField {...args} />}
                ]}
                editModalAdditionalClass={"retailer-highlighted-events-edit-modal"}
                onRowEditConfirmed={cleanUpAndSubmitData}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>;
};

RetailerHighlightedEvents.propTypes = {
    retailerId: PropTypes.number.isRequired
};

export default RetailerHighlightedEvents;