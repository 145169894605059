import React from 'react';
import PropTypes from 'prop-types';
import CollapsableContent from "../../Components/CollapsableContent";
import RunIdLink from "./RunIdLink";
import {PreviousRunSummaryInformation} from "./PreviousRunSummaryInformation";

export const PreviousRuns = (props) => {
    const expandedContent = <ul style={{listStyleType: "none"}} data-testid="previous-runs-list">
        {props.getValue().map((item, key) => {
            return <li key={key} data-testid={`previous-runs-list-${key}`}>
                <div className={'bottom-border'}>
                    <RunIdLink value={item.runId} original={item}/>
                    <PreviousRunSummaryInformation previousRun={item}/>
                </div>
            </li>

        })}
    </ul>;
    return props.getValue().length > 0 ?
        <CollapsableContent collapsedContent={props.getValue().length + 1} expandedContent={expandedContent} /> :
        <div>1</div>
};

PreviousRuns.propTypes = {
    getValue: PropTypes.func
};