import $ from "jquery";

export const headerOffsetAdjustment = 47;

export function handleScrollChange(headerMinimumOffset) {
    const setOffset = () => {
        let header = $('.headerForStickyHeader');
        if (window.scrollY >= headerMinimumOffset) {
            header.css("transform", `translateY(${window.scrollY - headerMinimumOffset}px)`);
        } else {
            header.css("transform", `translateY(0px)`);
            header.css('top', 0);
        }
    }

    setOffset();
    const handleScrollChange = () => {
        setOffset();
    };
    window.addEventListener('scroll', handleScrollChange);

    return () => {
        window.removeEventListener('scroll', handleScrollChange);
    };
}

export const adjustMinimumOffset = () => {
    const header = $('.headerForStickyHeader');
    if (header.length) {
        return header.parent().offset().top - headerOffsetAdjustment;
    }
}

export const setStickyHeaderClasses = (
    setStickyClassName,
    setStickyHeaderClassName,
    setStickyBodyClassName
) => {
    setStickyClassName('sticky');
    setStickyHeaderClassName('headerForStickyHeader');
    setStickyBodyClassName('bodyForStickyHeader');
}