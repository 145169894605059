import React from 'react'
import Page from "../../Components/Page";
import ApiGetContainer from "../../../Api/ApiGetContainer";
import CompletenessContent from "./CompletenessContent";

export const completenessQuery = `{
    completeness {
            name
            cleansedWeeks
            fillWeeks
            retailerId
            storeCount
            fillRecordCount
            fillSalesAmount
            cleansedRecordCount
            cleansedSalesAmount
            fillStoresWithSales
            cleansedStoresWithSales
        }
    }`

const CompletenessPage = () => {
    return <Page name={'Ingestion Completeness'}>
        <ApiGetContainer graphqlQuery={completenessQuery}
                         componentToRender={CompletenessContent}
        />
    </Page>;
}

export default CompletenessPage;