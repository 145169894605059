import { useServiceBase } from "../../../Components/ReactTable/useServiceBase";
import { LoadingWrapper } from "../../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../../Components/ReactTable/EditableReactTableV2";
import { STORE_ID_AND_NAME_COLUMN_GETTER } from "../../../Components/StoreIdAndName";
import Page from "../../../Components/Page";
import { useState } from "react";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import { toastError, toastSuccess } from "../../../../utils/toast";
import { toast } from "react-toastify";
import { makeApiCallPost } from "../../../../Api/makeApiCall";

function validateStoreIds(storeIdsToAddStr) {
    const commaSeparatedNumsRegexp = /\d+(,\s*\d+)*$/;
    return commaSeparatedNumsRegexp.test(storeIdsToAddStr);
}

function convertStoreIdStrToList(storeIdsToAddStr) {
    const whitespaceRegexp = /[ ,]+/;
    return storeIdsToAddStr
        .split(whitespaceRegexp)
        .map(s => ({ storeId: parseInt(s, 10) }));
}

function createPostBody(excludedStores, storesToPost) {
    const mergedStores = excludedStores.concat(storesToPost);
    const newStores = mergedStores.map((store, index) => ({...store, index}));
    return {
        data: newStores
    }
}

export const MissingDataStoreExclusions = () => {
    const apiUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/missingDataStoreExclusions`;
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [storeIdsToAddStr, setStoreIdsToAddStr] = useState("");
    const { data, loading, onRowDeleteConfirmed, refreshData } = useServiceBase({
        endpointUrl: apiUrl
    });

    const excludedStores = data?.serviceData?.data;

    function onConfirmClicked() {
        if (validateStoreIds(storeIdsToAddStr)) {
            const storesToPost = convertStoreIdStrToList(storeIdsToAddStr);
            makeApiCallPost(apiUrl, createPostBody(excludedStores, storesToPost))
                .then(() => {
                    toastSuccess(toast, `Successfully added ${storesToPost.length} excluded store(s)`);
                    refreshData();
                    setIsAddModalOpen(false);
                });
        } else {
            toastError(toast, `Please add a comma separated list of numbers and no other characters`);
        }
    }

    return (
        <Page name={"Missing Data Store Exclusions"}>
            <LoadingWrapper loading={loading}>
                <button onClick={() => setIsAddModalOpen(true)} className={"button primary"}>
                    Add Stores
                </button>
                <EditableReactTableV2
                    filterable
                    actionButtonsProps={{
                        showDeleteButton: true,
                        showAddButton: false,
                        showEditButton: false
                    }}
                    data={excludedStores}
                    columns={[
                        STORE_ID_AND_NAME_COLUMN_GETTER(data.stores)
                    ]}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
            {
                isAddModalOpen && (
                    <ConfirmationModal
                        cancellationCallback={() => setIsAddModalOpen(false)}
                        confirmationCallback={onConfirmClicked}
                        headerText={"Add Stores to Exclude"}
                    >
                        <div>Enter Store IDs to exclude. Each Store ID must be comma seperated.</div>
                        <textarea
                            style={{ height: 200 }}
                            placeholder={"12345,67890"}
                            title={'Store Ids To Add'}
                            value={storeIdsToAddStr}
                            onChange={(e) => setStoreIdsToAddStr(e.target.value)}
                        />
                    </ConfirmationModal>
                )
            }
        </Page>
    );
};