import React, {useState} from "react";
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import ReactTable from "../../Components/ReactTable";
import LoadingSpinner from "../../Components/LoadingSpinner";
import Modal from "react-modal";
import {Controller, useForm} from "react-hook-form";
import {makeApiCallPost} from "../../../Api/makeApiCall";
import {designationTypeLabels} from "./types";
import {toastError} from "../../../utils/toast";
import {toast} from "react-toastify";
import IconImage, {Icons} from "../../Components/IconImage";
import {redirectWithMessage, useRedirectMessage} from "../../../utils/redirectMessage";
import {DESIGNATION_COLUMNS} from "./designationColumns";
import Select from "react-select";
import {getValueFromOptions} from "../../../utils/maybeAddLabelToOptions";

export const handleChange = (event, designation, setStoreInfo) => {
    setStoreInfo(event.target.value);
};


export const designationTypes = Object.keys(designationTypeLabels).sort().map(value => ({
    value: Number(value),
    label: designationTypeLabels[value]
}))

const Designations = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/designations/`;
    const postUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/createDesignation/`;
    const {loading, data, refreshData} = useServiceBase({endpointUrl});
    const [saving, setSaving] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const {
        register: registerField,
        handleSubmit,
        reset: resetForm,
        formState: {errors},
        control
    } = useForm({
        defaultValues: {
            type: designationTypes[2].value,
        }
    });


    useRedirectMessage();

    const saveAndRefresh = (designation) => {
        setSaving(true)
        return makeApiCallPost(
            `${postUrl}`,
            {...designation, index: null}
        )
            .then((response) => {
                if (response?.apiError) {
                    console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                    toastError(toast, `Issue adding designation`)
                } else {
                    const newId = response.index;
                    const message = `Successfully added designation: ${newId} - ${designation.name}`
                    redirectWithMessage(message, `/designation/${newId}`)
                }
            })
            .then(() => setModalVisible(false))
            .then(() => setSaving(false))
            .then(() => refreshData())
    }


    return (
        <Page
            name={"Designations"}
            breadCrumbs={
                <Breadcrumbs items={[{label: "Services"}, {label: "Designations"}]}/>
            }
        >
            {loading ? <LoadingSpinner/> : <>
                <div className={'page-top-buttons'}>
                    <button type={"button"}
                            className={"button primary"}
                            onClick={() => {
                                resetForm()
                                setSaving(false)
                                setModalVisible(true)
                            }}
                    >Add designation
                    </button>
                </div>
                <ReactTable
                    filterable={true}
                    data={data.data}
                    columns={DESIGNATION_COLUMNS}
                />
                <Modal
                    clickable
                    isOpen={modalVisible}
                    className={"add-edit-modal"}
                    overlayClassName="Overlay"
                    ariaHideApp={false}
                    onRequestClose={() => {
                        setModalVisible(false)
                    }}
                >
                    <div data-testid={'modal'} className={'designation-settings'}>
                        <div className={"modal-header"}>
                            <h1>Add Designation</h1>
                            <button type={'button'} aria-label={'close'} onClick={() => setModalVisible(false)}>
                                <IconImage iconType={Icons.CLOSE}/>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit(saveAndRefresh)}>
                            <label htmlFor={'name'}>Designation Name*</label>
                            <input type={'text'} id={'name'}
                                   disabled={saving}  {...registerField('name', {required: true})}/>
                            {errors.name?.type === 'required' &&
                                <div className={'error'}>Designation name is required.</div>}
                            <label htmlFor={'type'}>Designation Type*</label>
                            <Controller
                                control={control}
                                name="type"
                                id="type"
                                {...registerField('type', {required: true})}
                                render={({field: {onChange, value, name, ref},}) => (
                                    <div data-testid={"designation-type-form-dropdown-component-container"}>
                                        <Select
                                            id={"designation_type_dropdown"}
                                            options={designationTypes}
                                            onChange={(option) => onChange(option?.value)}
                                            value={getValueFromOptions(value, designationTypes)}
                                            name={name}
                                            ref={ref}
                                        />
                                    </div>
                                )}
                            />
                            <div className={'settings-header'}>Settings</div>
                            <div className={'checkbox-container'}>
                                <label htmlFor={'is-versioned-checkbox'} className={'checkbox-label'}>Is
                                    Versioned</label>
                                <input className={'checkbox'}
                                       type="checkbox"
                                       id={'is-versioned-checkbox'}
                                       {...registerField('versioned', {required: false})}
                                />
                            </div>

                            <div className={'modal-buttons'}>
                                <button type={"button"} className={"button cancel"} disabled={saving}
                                        onClick={() => setModalVisible(false)}>Cancel
                                </button>
                                <button type={"submit"} className={"button primary"} disabled={saving}>Add</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </>}
        </Page>
    );
};

export default Designations;