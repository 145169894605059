import React, {Component} from 'react';
import OutletsDropdown from '../Components/Dropdowns/OutletsDropdown';
import ReadinessContent from '../Components/ReadinessContent';
import Page from '../Components/Page';
import PeriodsDropdown from '../Components/Dropdowns/PeriodsDropdown';
import {makeApolloClientCall} from '../../Api/makeApolloApiCall';
import {ReadinessApiError} from '../Components/ReadinessApiError';
import LoadingSpinner from "../Components/LoadingSpinner";
import {gql} from "@apollo/client";
import {makeApiCall} from "../../Api/makeApiCall";

export const ALL_OTHER_RETAILERS = 'All Other Retailers (No outlet assigned)';

class Readiness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            outletSelection: "SPINS PET",
            periodSelection: undefined,
            sampleMarketsOnly: false,
            activeRetailersOnly: true,
            data: undefined,
            loading: false,
            error: undefined,
            alertData: [],
            alertStartDate: undefined,
            alertEndDate: undefined
        }
    }

    updatePeriodCallback = (value) => {
        this.setState({periodSelection: value});
    }

    getDefaultPeriod = () => {
        const graphqlQuery = `query {
                getNextPeriodIfExists {
                    periodAbbreviation
                 }
            }`

        makeApolloClientCall(graphqlQuery)
            .then((results) => {
                this.setState({periodSelection: results.getNextPeriodIfExists.periodAbbreviation})
            });
    }

    componentDidMount() {
        this.getDefaultPeriod()
    }

    handleSubmit() {
        this.setState({loading: true, data: undefined})
        this.getAlerts()
        makeApolloClientCall(this.getQuery())
            .then((result) => {
                this.setState({loading: false, data: result})
            })
            .catch((e) => {
                this.setState({loading: false, error: e})
            })
    }

    getAlerts() {
        const {periodSelection} = this.state;
        const query = gql`query periods($periodAbbreviation: String!) {
            periods(periodAbbreviation: $periodAbbreviation, periodEndWeeksOnly:true){
                endDate
                getPriorPeriod(periodsPrior:1, periodType:QUAD) {
                  endDate
                }
            }
        }`;

        makeApolloClientCall(query, false, {periodAbbreviation: periodSelection})
            .then(periodData => {
                const thisPeriod = periodData.periods[0];
                const startDate = thisPeriod.getPriorPeriod.endDate;
                const endDate = thisPeriod.endDate;
                this.setState({alertStartDate: startDate, alertEndDate: endDate, alertIsLoading: true});
                return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/opsAlertLogsByDate/`)
            })
            .then(alertData => {
                this.setState({alertData: alertData.metadata, alertIsLoading: false})
            })
    }

    getQuery() {
        const {outletSelection, periodSelection, sampleMarketsOnly} = this.state;
        const outlets = outletSelection !== ALL_OTHER_RETAILERS ? `["${outletSelection}"]` : '[null]';
        const period = periodSelection ? `, periodAbbreviation:"${periodSelection}"` : '';
        const geographyComposition = sampleMarketsOnly ? ', geographyComposition: "sample"' : ''
        return `
query {
  periodReleaseReadiness(outlets:${outlets}${period}${geographyComposition}) {
    periodAbbreviation
    periodWeeks {endDate}
    accounts {
      retailer {retailerId retailChain}
      isKeyAccount
      isNonMarketRetailer
      isRetailerActive
      issues {issueType week {endDate} count message days}
      weeksWithSales {weeksWithData totalExpectedWeeks}
    }
  }
}
`;
    }

    render() {
        const {
            outletSelection,
            periodSelection,
            sampleMarketsOnly,
            activeRetailersOnly,
            data,
            loading,
            error,
            alertData,
            alertStartDate,
            alertEndDate,
            alertIsLoading
        } = this.state;

        return (

            <Page name={'Period Release Readiness'}>
                <div className={'readiness-options'}>
                    <PeriodsDropdown
                        id={"periods"}
                        updatePeriod={this.updatePeriodCallback}
                        selectedPeriod={periodSelection}
                        graphqlQuery={'{ marketPeriodList }'}
                    />
                    <OutletsDropdown
                        onChange={(option) => this.setState({outletSelection: (option && option.outletName)})}
                        value={outletSelection}
                        additionalOptions={[{value: -100, label: ALL_OTHER_RETAILERS, outletName: ALL_OTHER_RETAILERS}]}
                    />
                    <div id={'check-boxes'}>
                        <div className="checkbox-container">
                            <input className={'checkbox'} type="checkbox" id={'sample-markets-only'} data-testid={'sample-markets-only'}
                                   checked={sampleMarketsOnly}
                                   onChange={() => this.setState({sampleMarketsOnly: !sampleMarketsOnly})}
                            />
                            <label className={'checkbox-label'} htmlFor={'sample-markets-only'}>Sample Markets
                                Only</label>
                        </div>
                        <div className="checkbox-container">
                            <input className={'checkbox'} type="checkbox" id={'active-retailers-only'} data-testid={'active-retailers-only'}
                                   checked={activeRetailersOnly}
                                   onChange={() => this.setState({activeRetailersOnly: !activeRetailersOnly})}
                            />
                            <label className={'checkbox-label'} htmlFor={'active-retailers-only'}>Active Retailers
                                Only</label>
                        </div>
                    </div>
                    <div>
                        <button className={'button primary left-margin'} onClick={() => this.handleSubmit()}>Submit
                        </button>
                    </div>
                </div>
                {loading && <LoadingSpinner/>}
                {data &&
                <ReadinessContent data={data} activeRetailersOnly={activeRetailersOnly}
                                  alertData={alertData}
                                  alertIsLoading={alertIsLoading}
                                  alertStartDate={alertStartDate} alertEndDate={alertEndDate}/>}
                {error && <ReadinessApiError errorMessage={JSON.stringify(error)}/>}
            </Page>);
    }
}

export default Readiness;