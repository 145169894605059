import React from 'react';
import MarketServiceGenericService from "./MarketServiceGenericService";

function LegacyAccountTypeService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/legacyAccountType/`;
    return (<MarketServiceGenericService
        endpointUrl={endpointUrl}
        pageName={"Legacy Account Type Service"}
    />);
}

export default LegacyAccountTypeService;