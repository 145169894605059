import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCallPost} from '../../Api/makeApiCall';
import {isValidPeriodFormat} from "../../utils/util";
import ButtonLoader from "../../utils/components/ButtonLoader";

export const CopyMarketServicePeriod = (props) => {
    const [marketPeriodToCreate, setMarketPeriodToCreate] = useState('');

    function handleTextChange(event) {
        const value = event.target.value;
        setMarketPeriodToCreate(value);
    }

    return (
        <div className={'market-service-icons'}>
            <span className={'select-period'}>{'Copy To:'}</span>
            <input className="input-group-field input-new-period"
                   type="text"
                   value={marketPeriodToCreate}
                   placeholder="YYYY-P##"
                   onChange={handleTextChange}/>
            <ButtonLoader
                buttonClassName={`hollow button secondary create-period-button`}
                axiosFunction={makeApiCallPost}
                disabled={!isValidPeriodFormat(marketPeriodToCreate)}
                url={`${process.env.REACT_APP_EDP_API_BASE_URL}/copyMarket/?from_period=${props.selectedMarketPeriod}&to_period=${marketPeriodToCreate}`}
                postBody={{}}
                buttonTitle={"Copy"}
                toastTextSuccess={`Period ${props.selectedMarketPeriod} copied to  ${marketPeriodToCreate}`}
                toastTextFailure={"Failed to Copy"}
                onSubmitCallback={() => {
                    (setMarketPeriodToCreate(''));
                    props.onSubmitCallback()
                }}
            />
        </div>
    );
}

CopyMarketServicePeriod.propTypes = {
    data: PropTypes.object,
    selectedMarketPeriod: PropTypes.string.isRequired,
    onSubmitCallback: PropTypes.func
};