import React, {useState} from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../Components/LoadingSpinner";
import {makeApiCall} from "../../Api/makeApiCall";
import ReactTable from "../Components/ReactTable";


export function getAuditColumns(defaultAction, customColumns) {
    return [
        {
            header: 'Action',
            id: '1',
            accessorKey: 'action',
            cell: ({getValue, row}) => {
                const actionType = row.original.index === null && getValue() === 'update' ? "added" :getValue() || defaultAction ;
                return <div className='audit-action-cell-element'>{actionType}</div>;
            }
        },
        ...customColumns,
        {
            header: 'Timestamp', id: '5', accessorKey: 'timestamp', size: 200,
            cell: props => {
                const moment = require('moment-timezone');
                return <div className='audit-timestamp-cell-element'>
                    {
                        moment(props.getValue())
                            .tz('America/Chicago')
                            .format('dddd, MMMM D, YYYY hh:mm:ss z')
                    }
                </div>;
            }
        },
        {header: 'User', id: '6', accessorKey: 'user'},
    ];
}

export const AuditHistory = ({configName, configAuditEndpoint, skipUseEffect, action, customColumns}) => {
    const [auditEvents, setAuditEvents] = useState(undefined);
    const configAuditUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/${configAuditEndpoint}`
    React.useEffect(() => {
        if (skipUseEffect) {
            return
        }
        makeApiCall(configAuditUrl).then(result => setAuditEvents(result))
    }, [configName, configAuditUrl, skipUseEffect])

    return <div className={'retail-management-audit-section'}>
        {configName && <h4>{configName}</h4>}
        {(!auditEvents) && <LoadingSpinner spinnerClassName={'spinner-container-small'}/>}
        {auditEvents && (auditEvents.length === 0) && <div className={'no-changes'}>No Changes</div>}
        {auditEvents && (auditEvents.length !== 0) &&
            <ReactTable
                filterable={true}
                data={auditEvents}
                columns={getAuditColumns(action, customColumns)}
                initialState={{sorting: [{id: '5', desc: true}]}}
            />
        }
    </div>
}


AuditHistory.defaultProps = {
    skipUseEffect: false,
    customColumns: []
}

AuditHistory.propTypes = {
    configName: PropTypes.string,
    skipUseEffect: PropTypes.bool,
    configAuditEndpoint: PropTypes.string.isRequired,
    action: PropTypes.string,
    customColumns: PropTypes.array
}
