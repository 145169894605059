import React from 'react';
import PropTypes from 'prop-types';
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";

const DoNotCheckDigit = ({displayHelmetHeader, retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/doNotCheckDigitItemCodes/${retailerId}`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    const columns = [
        {header: "Item Code", accessorKey: "spinsItemCode"}
    ];

    function validatePreSave({form}) {
        const spinsItemCodeValue = form.getValues('spinsItemCode');
        const errors = [
            {regex: new RegExp(/^[0-9]{2}-[0-9]{5}-[0-9]{5}$/), message: 'Please enter a SPINS Formatted item code, ex: ##-#####-#####'}
        ].filter(e => !e.regex.test(spinsItemCodeValue))
            .map(e => e.message)

        if (errors.length > 0) {
            form.setError('spinsItemCode', {type: 'custom', message: errors});
        }

        return errors.length === 0;
    }

    return (<Page name={"Do Not Process as Check Digit"} displayHelmetHeader={displayHelmetHeader}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    {id: 'spinsItemCode', Component: args => <EditFormTextInputField {...args} isRequired/>}
                ]}
                validatePreSave={validatePreSave}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
};

DoNotCheckDigit.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
};


DoNotCheckDigit.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
};


export default DoNotCheckDigit;