import React from 'react';
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";

const AlertIdMapping = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/alertIdMapping`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    return (
        <Page name={"Alert ID Mappings"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={[
                        {header: "Alert Id", filterable: true, id: "alertId", accessorKey: "alertId"},
                        {header: "Display Name", filterable: true, id: "displayName", accessorKey: "displayName"}]
                    }
                    editTable={[
                        INDEX_FIELD,
                        {id: 'alertId', Component: args => <EditFormTextInputField {...args} isRequired/>},
                        {id: 'displayName', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    ]}
                    initialState={{pagination: {pageSize: 25}}}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
};

export default AlertIdMapping;