import React, {useState} from 'react';
import Modal from "react-modal";

const JiraLoginButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return <>
        <button className={'button jira-alert-button'} onClick={() => setIsModalOpen(true)}>Login to JIRA</button>
        <Modal
            clickable
            isOpen={isModalOpen}
            className={"Modal"}
            ariaHideApp={false}
            onRequestClose={() => setIsModalOpen(false)}
        >
            <div>JIRA integration has been removed.</div>
            <div>Please raise a JIRA ticket <a target={'_blank'} rel="noreferrer" href={'https://spins.atlassian.net/jira/software/c/projects/NUC/boards/504/backlog'}>here</a> for any question.</div>
            <div>&nbsp;</div>
            <button className={'button'} onClick={() => setIsModalOpen(false)}>Close</button>
        </Modal>
    </>;
}

export default JiraLoginButton;
