import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Popover} from "react-tiny-popover";

const ReactTablePopoverMenu = ({title, id, children}) => {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={["bottom", "top", "left", "right"]}
            align="start"
            containerClassName={"react-table-popover-wrapper"}
            content={() => (<div className="popover-content">{children}</div>)}
        >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)} className="btn-container">
                <button
                    type={"button"}
                    data-toggle={`popover-row-dropdown-${id}`}
                    className={"button secondary hollow dropdown hover popover-menu-button"}
                >
                    {title}
                </button>
            </div>
        </Popover>
    );
};

ReactTablePopoverMenu.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
    title: PropTypes.string
};

export default ReactTablePopoverMenu;