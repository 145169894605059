import React from "react";
import PropTypes from "prop-types";
import PastDueContent from "../OpsAlerts/PastDueContent";
import ReactTable from "../Components/ReactTable";
import {getDefaultPageSize} from "../ReactTable/initialStateHelpers";


export const MISSING_STORE_ACV_COLUMNS = [
    {
        header: 'Retailer Id',
        accessorKey: 'retailerId',
        className: 'numeric-field'
    },
    {
        header: 'Store Id',
        accessorKey: 'storeId',
        className: 'numeric-field'
    }
];

export const MissingStoreAcvContent = ({data}) => {
    return <ReactTable
        data={data}
        columns={MISSING_STORE_ACV_COLUMNS}
        filterable
        initialState={getDefaultPageSize(100)}
    />;
}

PastDueContent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        retailerId: PropTypes.number,
        storeId: PropTypes.number
    }))
};