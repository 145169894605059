import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from '../Components/ConfirmationModal';
import {makeApiCall} from '../../Api/makeApiCall';

const ExtractsTriggerConfirmationModal = (
    {
        cancellationCallback,
        confirmationCallback,
        dagToTrigger,
        endWeekId,
        endpointUrl,
        extractsRunPrefix,
        checkpointPath,
        processesToRun,
        runIdToScaffold,
        scaffoldingDatasets,
        startWeekId,
        runMissingPromoAndPromoCfeMismatch,
        runPromoExtract
    }
) => {
    const [retailerIds, setRetailerIds] = useState([]);
    React.useEffect(() => {
        if (endpointUrl) {
            makeApiCall(endpointUrl)
                .then(response => {
                    setRetailerIds(response.data.map(x => x.retailerId));
                });
        }
    }, [endpointUrl]);

    const retailerIdsCommaDelimited = retailerIds.join(',');

    return (
        <ConfirmationModal confirmationCallback={() => confirmationCallback(retailerIdsCommaDelimited)}
                           cancellationCallback={cancellationCallback}
                           headerText={'You have requested to create extracts with the following criteria:'}
        >
            <div id={'extracts-trigger-confirmation-modal'}>
                <div className={'table'}>
                    <div id={'processes'} className={'row'}>
                        <div className={'left'}>{'PROCESSES TO RUN: '}</div>
                        <div className={'right'} data-testid={'process_to_run_list'}>
                            <ul>{processesToRun.map(x => <li key={x}>{x}</li>)}</ul>
                        </div>
                    </div>
                    <div id={'dag'} className={'row'}>
                        <div className={'left'}>{'EXTRACT: '}</div>
                        <div className={'right'} data-testid={"dagToTrigger"}>{dagToTrigger}</div>
                    </div>
                    {scaffoldingDatasets.length > 0 && <div id={'scaffolding-datasets'} className={'row'}>
                        <div className={'left'}>{'Scaffolding Dataset(s): '}</div>
                        <div className={'right'} data-testid={'scaffolding_dataset_list'}>
                            <ul>{scaffoldingDatasets.map(x => <li key={x}>{x}</li>)}</ul>
                        </div>
                    </div>}
                    {startWeekId && <div id={'startWeekId'} className={'row'}>
                        <div className={'left'}>{'Start Week ID: '}</div>
                        <div className={'right'} data-testid={'startWeekId'}>{startWeekId}</div>
                    </div>
                    }
                    {endWeekId && <div id={'endWeekId'} className={'row'}>
                        <div className={'left'}>{'End Week ID: '}</div>
                        <div className={'right'} data-testid={'endWeekId'}>{endWeekId}</div>
                    </div>
                    }
                    {extractsRunPrefix && <div id={'extractsRunPrefix'} className={'row'}>
                        <div className={'left'}>{'Extracts Run Prefix: '}</div>
                        <div className={'right'} data-testid={'extractsRunPrefix'}>{extractsRunPrefix}</div>
                    </div>
                    }
                    {checkpointPath && <div id={'checkpointPath'} className={'row'}>
                        <div className={'left'}>{'Checkpoint Path: '}</div>
                        <div className={'right'} data-testid={'checkpointPath'}>{checkpointPath}</div>
                    </div>
                    }
                    {runIdToScaffold && <div id={'runIdToScaffold'} className={'row'}>
                        <div className={'left'}>{'Run ID to scaffold from: '}</div>
                        <div className={'right'} data-testid={'runIdToScaffold'}>{runIdToScaffold}</div>
                    </div>
                    }
                    {runMissingPromoAndPromoCfeMismatch && <div id={'runMissingPromoAndPromoCfeMismatch'} className={'row'}>
                        <div className={'left'}>{'Run Promo & Cleanse Filled Data Compare Check: '}</div>
                        <div className={'right'} data-testid={'runMissingPromoAndPromoCfeMismatch'}>{String(runMissingPromoAndPromoCfeMismatch)}</div>
                    </div>}
                    {runPromoExtract && <div id={'runPromoExtract'} className={'row'}>
                        <div className={'left'}>{'Run Promo Extract: '}</div>
                        <div className={'right'} data-testid={'runPromoExtract'}>{String(runPromoExtract)}</div>
                    </div>
                    }
                    {
                        retailerIds.length > 0 && <div id={'has-retailer-ids'}>
                            <div data-testid={'retailer_ids_message'}>
                                {'The below list of retailers has been included. Only those retailers will be ingested. Please confirm your list prior to continuing.'}
                            </div>
                        </div>
                    }
                </div>
                {retailerIds.length > 0 && <div id={'extracts-confirmation-modal-retailer-ids'}
                                                data-testid={'retailer_ids'}>{retailerIdsCommaDelimited}</div>}
                <br/>
                <div>{'This cannot be undone. Do you wish to continue?'}</div>
            </div>
        </ConfirmationModal>
    );
};

ExtractsTriggerConfirmationModal.defaultProps = {
    scaffoldingDatasets: []
};

ExtractsTriggerConfirmationModal.propTypes = {
    cancellationCallback: PropTypes.func.isRequired,
    confirmationCallback: PropTypes.func.isRequired,
    dagToTrigger: PropTypes.string.isRequired,
    endWeekId: PropTypes.number,
    endpointUrl: PropTypes.string,
    extractsRunPrefix: PropTypes.string,
    checkpointPath: PropTypes.string,
    processesToRun: PropTypes.arrayOf(PropTypes.string).isRequired,
    runIdToScaffold: PropTypes.string,
    runMissingPromoAndPromoCfeMismatch: PropTypes.bool,
    runPromoExtract: PropTypes.bool,
    scaffoldingDatasets: PropTypes.arrayOf(PropTypes.string).isRequired,
    startWeekId: PropTypes.number,
};

export default ExtractsTriggerConfirmationModal;