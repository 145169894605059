import {PreviousRuns} from "./PreviousRuns";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";
import RunId from "./RunId";
import IssueCount from "./IssueCount";
import {ShowReportPartial} from "./ShowReportPartial";
import {CONFIG_TYPES} from "../../Configurations/FileTypes";
import {REPORT_TYPES} from "../../Configurations/ReportTypes";
import AlertLink from "./AlertLink";
import {KnownItemPercentage} from "../KnownItemPercentage";
import {retailerDisplay} from "../../../utils/retailerDisplayFormatter";
import {queryParamFilterReactTable7} from "../../../utils/QueryParamFilter";
import FileTypeConfig from "./FileTypeConfig";
import matchSorter from "match-sorter";
import FtpConfig from "./FtpConfig";
import IngestionLogsFileLink from "./IngestionLogsFileLink";
import IngestionLogsActions from "./IngestionLogsActions";
import React from "react";
import RetailerIdWithOnboarding from "./RetailerIdWithOnboarding";
import {getRetailerName} from '../../../utils/retailerMapCache';
import {getUserTypeFromLocalToken, userType} from "../../../auth/accessTokenValidator";

export const retailerQueryStringKey = 'retailer';
export const retailerColumnId = 'Retailer';
export const fileTypeColumnId = 'fileType';
export const fileTypeQueryStringKey = 'fileType';
export const fileNameColumnId = 'fileName';
export const fileNameQueryStringKey = 'fileName';

export function getIngestionLogsColumns(salesforceRetailers) {
    const userTypeFromLocalToken = getUserTypeFromLocalToken();

    return [
        {
            header: 'Attempts',
            accessorKey: 'previousRuns',
            className: 'non-numeric-field',
            enableColumnFilter: false,
            cell: PreviousRuns,
            size: 150,
            sortingFn: (rowA, rowB) => {
                const previousRunsA = rowA?.original?.previousRuns.length;
                const previousRunsB = rowB?.original?.previousRuns.length;
                return sortAscendingWithNullsLast(previousRunsA, previousRunsB);
            }
        },
        {
            header: 'Run Id',
            id: 'runId',
            accessorKey: 'runId',
            enableColumnFilter: false,
            cell: RunId,
            className: 'non-numeric-field line-wrap-field',
            minSize: 150,
            maxSize: 200
        },
        {
            header: 'Feedback',
            id: 'feedback',
            className: 'non-numeric-field',
            enableColumnFilter: false,
            size: 160,
            accessorFn: (row) => row,
            sortingFn: (rowA, rowB) => {
                const knownItemPercentageA = rowA?.original?.cleansedInputCount && (rowA.original.totalExistingUPCCount / rowA.original.cleansedInputCount);
                const knownItemPercentageB = rowB?.original?.cleansedInputCount && (rowB.original.totalExistingUPCCount / rowB.original.cleansedInputCount);
                return sortAscendingWithNullsLast(knownItemPercentageA, knownItemPercentageB);
            },
            cell: ({row}) => {
                return <div><IssueCount
                    issueType={
                        (row.original.normalizedRejectCount || row.original.normalizedRejectCount === 0) &&
                        <ShowReportPartial path={row.original.archiveLocation}
                                           runId={row.original.normalizationRunId}
                                           rowCount={row.original.normalizedRejectCount}
                                           fileType={Object.values(CONFIG_TYPES).find(config => config.getName() === row.original.fileType)}/>
                    }
                    original={row.original}
                    reportType={REPORT_TYPES.NORMALIZED_FILE}
                    fileCount={row.original.normalizedRejectCount}
                    retailerId={row.original.retailerId}
                />
                    <AlertLink value={row.original.alertIds}/>
                    <IssueCount
                        issueType={'MS:'}
                        original={row.original}
                        reportType={REPORT_TYPES.NORMALIZED_MULTIPLE_SOURCE}
                        fileCount={row.original.multiSourceFileCount}
                        gcsSuffix={`/${REPORT_TYPES.NORMALIZED_MULTIPLE_SOURCE}`}
                        retailerId={row.original.retailerId}
                    />


                    <KnownItemPercentage original={row.original}/>
                </div>
            }
        },
        {
            header: 'Retailer',
            id: retailerColumnId,
            accessorKey: 'retailerId',
            cell: ({getValue}) => {
                return <RetailerIdWithOnboarding salesforceRetailers={salesforceRetailers}
                                                 retailerId={getValue()}
                />
            },
            filter: queryParamFilterReactTable7(retailerQueryStringKey),
            filterFn: (row, columnIds, filterValue) => {
                if (!filterValue) {
                    return true;
                }
                const retailerId = row.original.retailerId
                if (!retailerId) {
                    return false
                }
                const retailerIdString = retailerId.toString();
                const retailChain = getRetailerName(retailerId);

                const searchableValue = retailChain ? retailerDisplay(retailerId, retailChain) : retailerIdString
                return searchableValue.toUpperCase().includes(filterValue.toUpperCase())
            } ,
            className: 'non-numeric-field line-wrap-field',
            minSize: 150,
            show: userTypeFromLocalToken !== userType.locatorsUser
        },
        {
            header: "Client Id",
            id: "clientId",
            accessorKey: "retailerId",
            className: 'non-numeric-field line-wrap-field',
            minSize: 150,
            show: userTypeFromLocalToken !== userType.dataOperationsUser
        },
        {
            header: 'File Type',
            accessorKey: fileTypeColumnId,
            cell: FileTypeConfig,
            filter: queryParamFilterReactTable7(fileTypeQueryStringKey),
            filterFn: (row, columnIds, filterValue) => {
                return matchSorter([row], filterValue, { keys: [`original.${fileTypeColumnId}`] }).length > 0;
            },
            className: 'non-numeric-field',
            size: 100
        },
        {
            header: 'File Mapping',
            accessorKey: 'ftp',
            enableColumnFilter: false,
            cell: FtpConfig,
            className: 'non-numeric-field',
            minSize: 125,
            maxSize: 175
        },
        {
            header: 'File',
            accessorKey: fileNameColumnId,
            cell: IngestionLogsFileLink,
            filter: queryParamFilterReactTable7(fileNameQueryStringKey),
            filterFn: (row, columnIds, filterValue) => matchSorter([row], filterValue, { keys: [`original.${fileNameColumnId}`] }).length > 0,
            className: 'non-numeric-field',
            minSize: 250
        },
        {
            header: 'Triggered By',
            accessorKey: 'triggeredBy',
            className: 'non-numeric-field',
            cell: (props) => {
                return <div title={props.getValue()}>{props.getValue()}</div>
            },
            minSize: 175,
            maxSize: 300
        },
        {
            header: 'Actions',
            accessorKey: 'archiveLocation',
            enableColumnFilter: false,
            cell: IngestionLogsActions,
            className: 'non-numeric-field',
            size: 200
        }
    ];
}