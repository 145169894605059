import React, {useEffect, useLayoutEffect, useMemo} from "react";

import {
    filterFns,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import classnames from "classnames";
import _ from "lodash";
import {Pagination} from "../../ReactTable/Pagination";
import {ReactTableHeader} from "../../ReactTable/ReactTableHeader";
import {ReactTableBody} from "../../ReactTable/ReactTableBody";
import {useSticky} from "react-table-sticky";
import {DefaultColumnFilter, getActionsColumn, toTitleCase} from "./helpers";
import PropTypes from "prop-types";
import {adjustMinimumOffset, handleScrollChange} from "./scrollLogic";
import {wrapValueLabelInOptionStructure} from "../../../utils/maybeAddLabelToOptions";
import {useForm} from "react-hook-form";
import Modal from "react-modal";
import {EditRecordForm} from './EditRecordForm';


EditRecordForm.propTypes = {onSubmit: PropTypes.func};
const EditableReactTableV2 = (props) => {
    const {
        updatePageSize,
        updatePageIndex,
        initialState,
        children,
        className,
        showPagination,
        trClassName,
        getTrProps,
        filterable,
        hasStickyHeader,
        getTdProps,
        reactTableXCoordinateRef,
        editable,
        editModalProps,
        actionButtonsProps,
        validatePreSave,
        editModalAdditionalClass
    } = props;

    const [mode, setMode] = React.useState(undefined);

    const stickyClassName = hasStickyHeader ? "sticky" : "";
    const stickyHeaderClassName = hasStickyHeader ? "headerForStickyHeader" : "";
    const stickyBodyClassName = hasStickyHeader ? "bodyForStickyHeader" : "";
    const headerMinimumOffset = hasStickyHeader ? adjustMinimumOffset() : 0;
    const initializeEditMode = (newMode, initialFormData) => {
        recordEditForm.reset(initialFormData, {keepDefaultValues: true})
        setMode(newMode);
    }

    const defaultColumn = useMemo(() => ({filter: DefaultColumnFilter, minWidth: 30, maxWidth: 400}), []);

    const memoColumns = useMemo(() => {
        const mappedColumns = props.columns.map(x => ({...x, filterFn: x.filterFn || filterFns.includesString}));
        const containsColumnHeaders = props.columns.filter(x => x.columns);

        if(containsColumnHeaders.length > 0){
            mappedColumns[0].columns = [
                ...getActionsColumn(editable, initializeEditMode, props.onRowDeleteConfirmed, actionButtonsProps, submitDeleteAllData),
                ...mappedColumns[0].columns
            ]

            return mappedColumns
        }
        else {
            return [
                ...getActionsColumn(editable, initializeEditMode, props.onRowDeleteConfirmed, actionButtonsProps, submitDeleteAllData),
                ...mappedColumns
            ];
        }

    }, [props.columns]);

    let table = useReactTable(
        {
            columns: memoColumns,
            data: props.data || [],
            defaultColumn,
            initialState: initialState,
            enableColumnResizing: true,
            columnResizeMode: "onChange",
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            useSticky
        }
    );

    const state = table.getState();
    const {pagination: {pageIndex, pageSize}} = state;
    const updateTableXCoordinate = event => {
        if (reactTableXCoordinateRef) {
            reactTableXCoordinateRef.current = event.currentTarget.scrollLeft;
        }
    };

    useLayoutEffect(() => {
        if (!_.isEmpty(reactTableXCoordinateRef)) {
            const rtTable = document.getElementsByClassName("rt-table")[0];
            rtTable.scrollLeft = reactTableXCoordinateRef.current;
        }
    }, []);

    useEffect(() => {
        updatePageIndex(pageIndex);
        updatePageSize(pageSize);
        handleScrollChange(headerMinimumOffset);

    }, [pageIndex, pageSize, headerMinimumOffset]);

    function submitUpdatedData(newData) {
        if (validatePreSave({
            newData,
            rows: table.getCoreRowModel().rows,
            previousData: props.data,
            form: recordEditForm
        })) {
            props.onRowEditConfirmed(newData, table.getCoreRowModel().rows)
        }
    }

    function cancellationCallback() {
        setMode(undefined)
    }

    function submitDeleteAllData(dataToDelete) {
        props.onDeleteAllConfirmed(dataToDelete);
    }

    const derivedDefaultValues = props.editTable
        .filter(({defaultValue}) => defaultValue !== undefined)
        .map(({id, defaultValue}) => ({[id]: defaultValue}))
        .reduce((acc, curr) => Object.assign(acc, curr), {})

    const recordEditForm = useForm({
        defaultValues: {...derivedDefaultValues}
    });


    useEffect(() => {
        table.resetRowSelection();
    }, [state.columnFilters])

    return <>
        <div className={"react-table-wrapper"} data-testid={"react-table-wrapper"}>
            {children && children({rows: table.getFilteredRowModel().rows, table})}
            <div className={classnames(className, stickyClassName, "ReactTable7")}>
                <div className={"rt-table"}
                     data-testref={reactTableXCoordinateRef}
                     onScroll={updateTableXCoordinate}>
                    <div className={classnames(stickyHeaderClassName, "rt-thead -header")}>
                        {
                            table.getHeaderGroups().map((headerGroup, i) => <ReactTableHeader key={`headerGroup_${i}`}
                                                                                              headerGroup={headerGroup}
                                                                                              filterable={filterable}
                                                                                              table={table}/>)
                        }
                    </div>
                    <div className={classnames(stickyBodyClassName, "rt-tbody")}>
                        <div className="rt-tr-group" data-testid={"react-table-body"}>
                            {
                                table.getRowModel().rows.map((row, ind) => <ReactTableBody key={`headerGroup_${ind}`}
                                                                                           row={row}
                                                                                           index={ind}
                                                                                           trClassName={trClassName}
                                                                                           getTrProps={getTrProps}
                                                                                           getTdProps={getTdProps}
                                                                                           table={table}/>)
                            }
                        </div>
                    </div>
                </div>
                {showPagination && (
                    <Pagination
                        firstPageOnClick={() => table.setPageIndex(0)}
                        canPreviousPage={table.getCanPreviousPage()}
                        previousPageOnClick={() => table.previousPage()}
                        pageIndex={pageIndex}
                        pageOptions={table.getPageOptions()}
                        value={pageSize}
                        onChange={(option) => table.setPageSize(Number(option.value))}
                        dropdownCallBack={(pageSize) => wrapValueLabelInOptionStructure(pageSize, `Show ${pageSize}`)}
                        nextPageOnClick={() => table.nextPage()}
                        canNextPage={table.getCanNextPage()}
                        lastPageOnClick={() => table.setPageIndex(table.getPageCount() - 1)
                        }/>)}
            </div>
        </div>
        <Modal
            clickable
            isOpen={!!mode}
            className={`react-table-edit-modal ${editModalAdditionalClass}`}
            overlayClassName="Overlay"
            ariaHideApp={false}
            onRequestClose={() => cancellationCallback()}
        >
            <div><h5 className={"modal-header-text"}>{`${toTitleCase(mode)} Record`}</h5></div>
            <EditRecordForm
                form={recordEditForm}
                submitUpdatedData={submitUpdatedData}
                cancellationCallback={cancellationCallback}
                dropdownConfig={editModalProps}
                columns={props.columns}
                editTable={props.editTable}
            />
        </Modal>
    </>
}

EditableReactTableV2.defaultProps = {
    customColumns: [],
    editModalAdditionalClass: undefined,
    updatePageSize: () => undefined,
    updatePageIndex: () => undefined,
    className: "",
    showPagination: true,
    trClassName: "",
    getTrProps: () => ({}),
    validatePreSave: _.constant(true),
    filterable: false,
    editable: true,
    hasStickyHeader: true,
    getTdProps: () => {
    },
    reactTableXCoordinateRef: undefined,
    endpointUrlForPost: undefined,
    actionButtonsProps: {
        showDeleteAllButton: false,
        showDeleteButton: true,
        showAddButton: true,
        showEditButton: true,
        sticky: false
    },
    editTable: []
};

const actionButtonsPropsShape = {
    showDeleteAllButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    showAddButton: PropTypes.bool,
    showEditButton: PropTypes.bool,
    sticky: PropTypes.bool
};

const paginationShape = {
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number
};

const initialState = {
    columnFilters: PropTypes.arrayOf(PropTypes.object),
    sorting: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.shape(paginationShape)
};

EditableReactTableV2.propTypes = {
    editable: PropTypes.bool,
    customColumns: PropTypes.array,
    editModalAdditionalClass: PropTypes.string,
    updatePageSize: PropTypes.func,
    updatePageIndex: PropTypes.func,
    initialState: PropTypes.shape(initialState),
    children: PropTypes.any,
    className: PropTypes.string,
    showPagination: PropTypes.bool,
    trClassName: PropTypes.string,
    getTrProps: PropTypes.func,
    filterable: PropTypes.bool,
    hasStickyHeader: PropTypes.bool,
    getTdProps: PropTypes.func,
    reactTableXCoordinateRef: PropTypes.object,
    actionButtonsProps: PropTypes.shape(actionButtonsPropsShape),
    onRowEditConfirmed: PropTypes.func,
    onRowDeleteConfirmed: PropTypes.func,
    onDeleteAllConfirmed: PropTypes.func
};
export default EditableReactTableV2;