import React, {useState} from 'react';
import CollapsableContent from '../../../Components/CollapsableContent';
import PropTypes from "prop-types";

function StoreItem({store}) {
    const [height, setHeight] = useState(null);
    const dates = store.storeMissingFillData.join(', ');

    // This ref with height exists because if this cell is rendered without an explicit height,
    // the table row displays with a crazy unnecessary large height (around ~4x the individual cell height)
    //
    // I (Chris) can't figure out the CSS to stop that from happening (solving this with css = preferable to
    // a ref + height property), this is the workaround I found
    // const ref = useRef(null);
    const computedStyle = height === null ? {} : {height};
    return (
        <div ref={el => {
            if (el) {
                setHeight(el.offsetHeight);
            }
        }} style={computedStyle}>
            {`${store.storeName} - ${dates}`}
        </div>
    )
}

StoreItem.propTypes = {
    store: PropTypes.object
}

export function MissingStoresCell({getValue}) {
    const retailerMissingStores = getValue();
    const storesList = retailerMissingStores.map(store => {
        return <StoreItem store={store} key={store.storeId}/>;
    });
    return (
        <CollapsableContent collapsedContent={`${retailerMissingStores.length} Stores Missing Data`} expandedContent={storesList} />
    );
}

MissingStoresCell.propTypes = {
    getValue: PropTypes.func
}