import React from 'react';
import PropTypes from 'prop-types';
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";

const AsinRetailers = ({displayHelmetHeader, retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/asinRetailers/${retailerId ? `?retailerId=${retailerId}` : ''}`
    const endpointUrlForPost =`${process.env.REACT_APP_EDP_API_BASE_URL}/asinRetailers/`
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl, endpointUrlForPost });

    return (<Page name={"ASIN Retailers"} displayHelmetHeader={displayHelmetHeader}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[RETAILER_ID_NAME_COLUMN]}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data)
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
};

AsinRetailers.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
};

AsinRetailers.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
};

export default AsinRetailers;