import React from "react";
import PropTypes from 'prop-types'

export const ReadinessApiError = (props) => {
    let errorMessage;
    let className;
    if(props.errorMessage.includes('Partition location does not exist')){
        errorMessage = 'Partition files currently being written. Please try again later.'
        className = 'readiness-info-message'
    }
    else {
        errorMessage = props.errorMessage
        className = 'readiness-error-message'
    }
    return <div className={'readiness-error'}>
        <div className={`readiness-message ${className}`}>
            {errorMessage}
        </div>
    </div>;
}


ReadinessApiError.propTypes = {
    errorMessage: PropTypes.string
}