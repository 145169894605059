import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import RunIdLink from "./RunIdLink";
import {getUserTypeFromLocalToken, userType} from "../../../auth/accessTokenValidator";

const RunId = ({row}) => {
    const listItems = _.uniq(
        row.original.details
            .filter(task => task.status === "Failure")
            .map(task => task.taskId)
    ).map(taskId => taskId)
    const userTypeFromLocalToken = getUserTypeFromLocalToken()
    return <>
        <RunIdLink {...row}/>
        {!_.isEmpty(listItems) && <>
            <br/>
            <a target={'_blank'}
               id={'confluence-link'}
               href={userTypeFromLocalToken === userType.locatorsUser ?
                   'https://spins.atlassian.net/wiki/spaces/EDP/pages/83648970754/Nucleus+Ingestion+DAG+Issues+and+Resolution' :
                   'https://spins.atlassian.net/wiki/spaces/PO/pages/6224379947/Sales+Ingestion+DAG+failure+resolutions'
               }
               rel={'noopener noreferrer'}>
                {listItems}
            </a>
        </>}
    </>
}

RunId.propTypes = {
    row: PropTypes.object
};

export default RunId;