import React from 'react';
import PropTypes from 'prop-types';
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {EditFormBooleanDropdownInputField} from "../Components/ReactTable/EditRecordForm";
import {Breadcrumbs} from "../Components/Breadcrumbs";

function StoreInsightsIgnoredRetailers({displayHelmetHeader}) {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeInsightsIgnoredRetailers/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    const columns = [
        RETAILER_ID_NAME_COLUMN,
        { header: "Store Insights Period", accessorKey: "ignorePeriod"},
        { header: "Store Insights Weekly", accessorKey: "ignoreWeekly"},
    ];

    return (<Page name={"Store Insights Ignored Retailers Configuration"} displayHelmetHeader={displayHelmetHeader}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "Retailer"}, {label: "Store Insights"}]}/>}
    >
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'ignorePeriod', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'ignoreWeekly', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

StoreInsightsIgnoredRetailers.defaultProps = {
    displayHelmetHeader: true
}

StoreInsightsIgnoredRetailers.propTypes = {
    displayHelmetHeader: PropTypes.bool
}

export default StoreInsightsIgnoredRetailers;