import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Page from "../../Components/Page";
import _ from 'lodash';
import {sortAscendingWithNullsFirst} from "../../../utils/sorting";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import DynamicColumns from "../../Alerts/Continuity/DynamicColumns";
import {columns} from "../../Alerts/Continuity/ContinuityTable";
import LoadingSpinner from "../../Components/LoadingSpinner";
import Checkbox from "../../Components/Checkbox";
import {getDayOfWeek} from "../../../utils/time";
import {getUserEmail} from "../../../auth/accessTokenValidator";
import {makeApiCall, makeApiCallDeleteWithData} from "../../../Api/makeApiCall";
import ReactTable from "../../Components/ReactTable";
import {toastError} from "../../../utils/toast";
import {toast} from "react-toastify";


export function getRetailerWhatWillFillQuery(retailerId, fillEndDate, fillAllDates, fillIncludePlu) {
    const fillEndDateParam = fillEndDate && fillEndDate !== '' ? `, fillEndDate: "${fillEndDate}"` : ''
    return `
    query{
        data:storeSalesDateValidation(ignoreWatermarks: ${fillAllDates}, retailerId: ${retailerId}${fillEndDateParam}, includePlu: ${fillIncludePlu}){
            retailerId
            dates:datesToFill{
                weekEndDate
            }
            storeId
        }
    }
`

}

export function getStores(retailerId) {
    return `
    query {
      data: stores (retailerId: ${retailerId}) {
        retailerId
        storeId
      }
    }
`
}

export function getRetailerFillWeeksColumns({data}) {
    const allDates = [];
    data.forEach(store => {
        store.dates.forEach(date => {
            allDates.push(date);
        });
    });
    const uniqueSortedDates = _.uniq(allDates).sort().reverse();

    return uniqueSortedDates.map(date => {
        return {
            id: date,
            header: date,
            enableColumnFilter: false,
            sortingFn: sortAscendingWithNullsFirst,
            accessorFn: (retailer) => {
                const possiblyFoundDate = retailer.dates.find(rowDate => rowDate === date);
                return possiblyFoundDate ? 'X' : '';
            }
        }
    });
}

export function retailerReduceByStoreId({data}) {
    const getDatesFromCurrentStore = currentValue => currentValue.dates.map(date => date.weekEndDate);
    return {
        data: data.reduce((accumulator, currentValue) => {
            let possiblyFoundRetailerAndStore = accumulator.find(retailerAndStore =>
                retailerAndStore.retailerId === currentValue.retailerId &&
                retailerAndStore.storeId === currentValue.storeId);

            if (possiblyFoundRetailerAndStore) {
                possiblyFoundRetailerAndStore.dates = possiblyFoundRetailerAndStore.dates
                    .concat(getDatesFromCurrentStore(currentValue));
            } else {
                const datesFromCurrentStore = getDatesFromCurrentStore(currentValue);
                !_.isEmpty(datesFromCurrentStore) && accumulator.push({
                    retailerId: currentValue.retailerId,
                    storeId: currentValue.storeId,
                    dates: datesFromCurrentStore
                })
            }
            return accumulator;
        }, [])
    }
}

export function getDataPreProcess(data, retailerId, retailerStores) {
    const filteredAlwaysFill = data.dates
        .filter(x => retailerStores.data.find(store => store.storeId === x.storeId))
        .map(x => {
            return {
                "retailerId": retailerId,
                ...x,
                "day": getDayOfWeek(x.salesEndDate)
            }
        });
    return {
        "data": filteredAlwaysFill
    };
}

const RetailerFill = ({retailerId, rollUpDayOfWeek}) => {
    const [fillEndDate, setFillEndDate] = useState('');
    const [fillAllDates, setFillAllDates] = useState(false);
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined);
    const [alwaysFill, setAlwaysFill] = useState(undefined);
    const [alwaysFillLoading, setAlwaysFillLoading] = useState(false)
    const [retailerStore, setRetailerStore] = useState(undefined)
    const [fillIncludePlu, setFillIncludePlu] = useState(false);

    const handleSubmit = useMemo(() => (retailerId, fillEndDate, fillAllDates, fillIncludePlu) => {
        setLoading(true)
        setData(undefined)
        setError(undefined)
        makeApolloClientCall(getRetailerWhatWillFillQuery(retailerId, fillEndDate, fillAllDates, fillIncludePlu))
            .then((result) => {
                return retailerReduceByStoreId(result);
            })
            .then((processedData) => {
                setData(processedData)
                setLoading(false)
            })
            .catch(() => {
                setError('storeSalesDateValidation Query Failed, Please Try Again')
                setLoading(false)
            })

    }, [retailerId, fillEndDate, fillAllDates, fillIncludePlu])

    function getAlwaysFillData(retailerStores) {
        setAlwaysFillLoading(true)
        setAlwaysFill(undefined)

        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/alwaysFill`)
            .then((result) => {
                const dataPreProcess = getDataPreProcess(result, retailerId, retailerStores);
                setAlwaysFill(dataPreProcess.data);
                setAlwaysFillLoading(false)
            })
            .catch((error) => {
                console.log("=============> error: ", error);
                toastError(toast, 'There was an error trying to load the Always Fill Data')
            }).finally(() => setAlwaysFillLoading(false))
    }

    React.useEffect(() => {
        setAlwaysFillLoading(true)
        makeApolloClientCall(getStores(retailerId))
            .then((result) => {
                setRetailerStore(result)
                return result
            }).then((result) => getAlwaysFillData(result));

    }, [])

    return (
        <>
            <Page name="What Will Fill">
                <div className={'what-will-fill-selection-group'}>
                    <div className={'column'}>
                        <div className={'horizontal-control-block-element-left'}>
                            <div className={'retailer-fill-all-dates'} data-testid={'retailer-fill-all-dates-checkbox-wrapper'}>
                                <Checkbox checked={fillAllDates}
                                          labelText='Fill All Dates'
                                          onChange={() => setFillAllDates(!fillAllDates)}
                                />
                            </div>
                            <div className={'retailer-fill-all-dates-checkbox-wrapper'}>
                                <Checkbox checked={fillIncludePlu}
                                          labelText='Include Plu'
                                          onChange={() => setFillIncludePlu(!fillIncludePlu)}
                                />
                            </div>
                            <div className={'id-input'}>
                                <span className='input-group-label fill-end-date-label'>Fill End Date:</span>
                                <input className="fill-end-date input-group-field" type="date"
                                       data-testid={'fill-end-date'}
                                       onChange={(event => setFillEndDate(event.target.value))}/>
                            </div>
                        </div>
                    </div>
                    <div className={'column'}>
                        <button id={"submitButton"} className={"button submit left-margin"}
                                onClick={() => handleSubmit(retailerId, fillEndDate, fillAllDates, fillIncludePlu)}>
                            Submit
                        </button>
                    </div>
                </div>
                {data &&
                    <DynamicColumns columns={columns} data={data} dynamicColumnsFunc={getRetailerFillWeeksColumns}/>}
                {loading &&
                    <LoadingSpinner spinnerClassName={'spinner-container-normal what-will-fill-loading-spinner'}/>}
                {error && <div className={'error-div'}>{error}</div>}
            </Page>
            <Page name="Always Fill">
                {alwaysFillLoading &&
                    <LoadingSpinner spinnerClassName={"spinner-container-normal always-fill-loading-spinner"}/>}
                {!alwaysFillLoading && alwaysFill && <ReactTable
                    filterable={true}
                    data={alwaysFill}
                    columns={[
                        {
                            header: 'Delete', id: 'delete',
                            cell: ({row}) => {

                                return <button type="button"
                                               className="fas fa-trash-alt"
                                               onClick={() => {
                                                   setAlwaysFillLoading(true);
                                                   const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeSalesEndDateConfig/alwaysFill`
                                                   const postBody = [{
                                                       retailerId: row.original.retailerId,
                                                       storeIds: [row.original.storeId],
                                                       salesEndDate: row.original.salesEndDate,
                                                       user: getUserEmail()
                                                   }]
                                                   makeApiCallDeleteWithData(url, postBody)
                                                       .then((response) => {
                                                           if (response?.apiError) {
                                                               toastError(toast, `There was an error trying to delete ${JSON.stringify(postBody)}`)
                                                               console.log("=============> Delete error: ", response.apiError);
                                                           } else {
                                                               getAlwaysFillData(retailerStore);
                                                           }
                                                           setAlwaysFillLoading(false)
                                                       })
                                               }}
                                />
                            }
                        },
                        {header: 'Retailer', accessorKey: 'retailerId'},
                        {header: 'Store Id', accessorKey: 'storeId'},
                        {header: 'Sales End Date', accessorKey: 'salesEndDate'},
                        {
                            header: 'Day', accessorKey: 'day',
                            cell: ({row}) => {
                                const dayOfWeek = row.original.day;
                                const className = dayOfWeek.toLowerCase() !== rollUpDayOfWeek.toLowerCase() ? 'invalid-day-of-week' : ''
                                return <div className={className}>{dayOfWeek}</div>
                            }
                        }
                    ]}
                />
                }
            </Page>
        </>
    );
}


RetailerFill.propTypes = {
    retailerId: PropTypes.number.isRequired,
    rollUpDayOfWeek: PropTypes.string.isRequired
};

export default RetailerFill;