import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { flexRender } from "@tanstack/react-table";
import { getStickyProps } from "../Components/ReactTable/sticky-columns";

export const ReactTableBody = ({ row, index, trClassName, getTrProps, table, getTdProps }) => {
    return (
        <React.Fragment key={row.id}>
            <div
                data-testid={`react-table-row-${index}`}
                className={classnames(
                    "rt-tr",
                    index % 2 === 0 ? "-odd" : "-even",
                    trClassName
                )}
                role={"row"}
                style={{ display: "flex", flex: "1 0 auto", minWidth: "90px" }}
                {...getTrProps(row, table)}
            >
                {
                    row.getAllCells().map((cell, i) => {
                        const column = cell.column;
                        let stickyData = {};
                        let style = {
                            position: "relative",
                            width: column.getSize(),
                            minWidth: '30px',
                            flex: `1 0 auto`,
                            boxSizing: "border-box",
                            ...(column.columnDef.cellStyle || {})
                        };

                        if (column.columnDef.sticky) {
                            const stickyProps = getStickyProps(column, table);
                            const stickyStyle = stickyProps.style;
                            stickyData = stickyProps.data;
                            style = {
                                ...style,
                                ...stickyStyle
                            };
                        }

                        return (
                            <div key={`cell_${i}`}
                                 data-testid={`react-table-cell-${cell.column.id}`}
                                 className={classnames("rt-td", cell.column.columnDef.className, `table-cell-${cell.column.id}`, getTdProps(row, table, cell.column.id))}
                                 role={"cell"}
                                 style={style}
                                 {...stickyData}
                            >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </div>
                        );
                    })}
            </div>
        </React.Fragment>
    );
};

ReactTableBody.props = {
    index: PropTypes.number,
    getTdProps: PropTypes.func,
    getTrProps: PropTypes.func,
    prepareRow: PropTypes.func,
    row: PropTypes.object,
    trClassName: PropTypes.string
};