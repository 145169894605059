import React, {Component} from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../Components/LoadingSpinner";
import _ from "lodash";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import Page from "../Components/Page";
import gql from "graphql-tag";
import {getStringParamOrDefault, updateQueryStringForState} from "../../utils/urlBuilder";
import ReactTable from "../Components/ReactTable";
import BaseOutlierInputBlur from "./BaseOutlierInputBlur";
import BaseOutlierInputOnChange from "./BaseOutlierInputOnChange";
import RetailerIdAndName from "../Components/RetailerIdAndName";

class BaseOutlierCountAlert extends Component {

    countAlertColumns = [
        {
            header: "Retailer",
            accessorKey: "retailerId",
            cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
            className: "retail-cell-element ops-alert-element",
            size: 60,
            enableColumnFilter: false
        },
        {
            header: "Store Id",
            accessorKey: "storeId",
            className: "store-cell-element ops-alert-element",
            size: 60
        },
        {
            header: "Sales End Date",
            accessorKey: "weekEndDate",
            className: "week-end-date-cell-element ops-alert-element",
            size: 60
        },
        {
            header: `${this.props.alertType} Count`,
            accessorKey: `${this.props.accessor}Count`,
            cell: props => <div
                className="record-count-cell-element ops-alert-element">{_.round(props.getValue(), 2)}</div>
        },
        {
            header: `Average ${this.props.alertType} Count`,
            accessorKey: `average${this.props.alertType}Count`,
            enableColumnFilter: false,
            cell: props => <div
                className="record-count-cell-element ops-alert-element">{_.round(props.getValue(), 2)}</div>
        },
        {
            header: `Percent Difference From Average ${this.props.alertType} Count`,
            accessorKey: `diffFromAverage${this.props.alertType}`,
            enableColumnFilter: false,
            cell: props => <div
                className="record-count-cell-element ops-alert-element">{_.round(props.getValue(), 2)}</div>
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            alertData: undefined,
            loading: false,
            percentThreshold: this.props.threshold
        };
        this.handleRetailerChange = this.handleRetailerChange.bind(this);
        this.handlePercentThresholdChange = this.handlePercentThresholdChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.retrieveAlertData = this.retrieveAlertData.bind(this);
    }

    getRetailerId() {
        return getStringParamOrDefault("retailerId", undefined);
    }

    getPercentThreshold() {
        return getStringParamOrDefault("percentThreshold", "25");
    }

    getStartDate() {
        return getStringParamOrDefault("startDate", undefined);
    }

    getEndDate() {
        return getStringParamOrDefault("endDate", undefined);
    }

    async retrieveAlertData() {
        if (!this.getRetailerId()) {
            return;
        }
        this.setState({
            alertData: undefined,
            loading: true
        });

        await makeApolloClientCall(
            gql`query storeSalesDataOutliers($retailerId: [Int!],
                $percentDifferenceThreshold: Int!,
                $percentDifferenceMeasure: differenceMeasureType!,
                $startDate: String, $endDate: String)
            {
                storeSalesDataOutliers(
                    retailerId: $retailerId,
                    percentDifferenceThreshold: $percentDifferenceThreshold,
                    percentDifferenceMeasure: $percentDifferenceMeasure,
                    startDate: $startDate,
                    endDate: $endDate
                )
                {
                    data {
                        retailerId,
                        storeId,
                        weekEndDate,
                        recordCount,
                        averageRecordCount,
                        diffFromAverageRecord,
                        unitCount,
                        averageUnitCount,
                        diffFromAverageUnit,
                        dollarCount,
                        averageDollarCount,
                        diffFromAverageDollar
                    }
                }
            }`, false, {
                retailerId: parseInt(this.getRetailerId()),
                percentDifferenceThreshold: parseInt(this.getPercentThreshold()),
                percentDifferenceMeasure: this.props.accessor,
                startDate: this.getStartDate(),
                endDate: this.getEndDate()
            })
        .then((alertData) => {
            this.setState({
                loading: false,
                alertData: alertData
            });
        })
        .catch((error) => {
            this.setState({
                loading: false
            });
            console.error(error);
        });
    }

    handleRetailerChange(event) {
        updateQueryStringForState({"retailerId": event.target.value});
    }

    handlePercentThresholdChange(event) {
        updateQueryStringForState({"percentThreshold": event.target.value});
    }

    handleStartDateChange(event) {
        updateQueryStringForState({"startDate": event.target.value});
    }

    handleEndDateChange(event) {
        updateQueryStringForState({"endDate": event.target.value});
    }

    componentDidMount() {
        if (this.getRetailerId()) {
            this.retrieveAlertData();
        }
    }

    render() {
        return (
            <Page name={`${this.props.alertType} Counts By Store`}>
                <BaseOutlierInputBlur labelText={"Retailer Id:"}
                                      defaultValue={this.getRetailerId()}
                                      onBlur={this.handleRetailerChange}/>
                <BaseOutlierInputBlur labelText={"Threshold percentage to display:"}
                                      defaultValue={this.getPercentThreshold()}
                                      onBlur={this.handlePercentThresholdChange}/>
                <BaseOutlierInputOnChange id={"start-date"}
                                          defaultValue={this.getStartDate()}
                                          labelText={"Start Date:"}
                                          onChange={this.handleStartDateChange}/>
                <BaseOutlierInputOnChange id={"end-date"}
                                          defaultValue={this.getEndDate()}
                                          labelText={"End Date:"}
                                          onChange={this.handleEndDateChange}/>

                <div className={"input-group-button"}>
                    <input type="submit" className="button" value="Submit"
                           onClick={this.retrieveAlertData}/>
                </div>

                {this.state.loading && <LoadingSpinner/>}
                {this.state.alertData && <ReactTable
                    filterable
                    data={this.state.alertData.storeSalesDataOutliers.data}
                    columns={this.countAlertColumns}
                    initialState={
                        {sorting: [{id: `diffFromAverage${this.props.alertType}`, desc: true}]}
                    }
                />
                }
            </Page>
        );
    }
}

BaseOutlierCountAlert.propTypes = {
    accessor: PropTypes.string,
    alertType: PropTypes.string,
    threshold: PropTypes.number
};

export default BaseOutlierCountAlert;