import React from "react";
import PropTypes from "prop-types";
import ReactTable from "../../Components/ReactTable";
import {getNicelyFormattedNumber} from "../../../utils/formatUtil";
import RetailerIdAndName from "../../Components/RetailerIdAndName";
import {getRetailerName} from "../../../utils/retailerMapCache";

export const COMPLETENESS_COLUMNS = [
    {
        header: 'Retailer',
        accessorKey: 'retailerId',
        cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
        className: 'non-numeric-field',
        filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.retailerId} ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))

    },
    {
        header: 'Cleansed Stores With Sales',
        accessorKey: 'cleansedStoresWithSales',
        enableColumnFilter: false,
        className: 'numeric-field'
    },
    {
        header: 'Filled Stores With Sales',
        accessorKey: 'fillStoresWithSales',
        enableColumnFilter: false,
        className: 'numeric-field'
    },
    {
        header: 'Total Stores',
        accessorKey: 'storeCount',
        enableColumnFilter: false,
        className: 'numeric-field'
    },
    {
        header: 'Cleansed Weeks',
        accessorKey: 'cleansedWeeks',
        enableColumnFilter: false,
        className: 'numeric-field'
    },
    {
        header: 'Filled Weeks',
        accessorKey: 'fillWeeks',
        enableColumnFilter: false,
        className: 'numeric-field'
    },
    {
        header: 'Cleansed Total Record Count',
        accessorKey: 'cleansedRecordCount',
        enableColumnFilter: false,
        className: 'numeric-field',
        cell: ({getValue}) => getNicelyFormattedNumber(getValue())
    },
    {
        header: 'Filled Total Record Count',
        accessorKey: 'fillRecordCount',
        enableColumnFilter: false,
        className: 'numeric-field',
        cell: ({getValue}) => getNicelyFormattedNumber(getValue())
    },
    {
        header: 'Cleansed Dollars',
        accessorKey: 'cleansedSalesAmount',
        enableColumnFilter: false,
        className: 'numeric-field',
        cell: ({getValue}) => getNicelyFormattedNumber(getValue())
    },
    {
        header: 'Filled Dollars',
        accessorKey: 'fillSalesAmount',
        enableColumnFilter: false,
        className: 'numeric-field',
        cell: ({getValue}) => getNicelyFormattedNumber(getValue())
    }
]

const CompletenessContent = ({data}) => {
    return <ReactTable
        filterable
        data={data.completeness}
        columns={COMPLETENESS_COLUMNS}
    />
}
CompletenessContent.propTypes = {
    data: PropTypes.shape({
        completeness: PropTypes.arrayOf(PropTypes.object)
    }).isRequired
};

export default CompletenessContent;