import React, {Fragment, useState} from 'react';
import PropTypes from "prop-types";
import {toastError, toastSuccess} from "../../utils/toast";
import {toast} from "react-toastify";
import {makeApiCallPost} from "../../Api/makeApiCall";
import IconImage, {Icons} from "../Components/IconImage";

const AssignedUserButton = ({
                              insertId,
                              initialNotes,
                              onSubmitCallback,
                              acknowledged,
                              falsePositive,
                              assignButtonEnabled,
                              user
                          }) => {
    const isDataIssue = falsePositive === null ? null : !falsePositive
    const [loading, setLoading] = useState(false);
    const isAcknowledged = acknowledged ? acknowledged : false;

    const getOnClick = async () => {
        return fetchData()
    }

    const fetchData = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/updateAlerts/`
        const postBody = {
            alertIds: [insertId],
            notes: initialNotes,
            isDataIssue: isDataIssue,
            acknowledged: isAcknowledged,
            assignedUser: user
        }


        makeApiCallPost(url, postBody)
            .then((results) => {
                if (results?.apiError) {
                    toastError(toast, `Failed to save alert with id ${insertId}`);
                } else {
                    toastSuccess(toast, `Successfully saved alert with id ${insertId}`)
                    onSubmitCallback(results);
                }
            }).finally(() => setLoading(false));
    };

    return (<Fragment>
            <button className={"header-button assign-user-button"} onClick={getOnClick}
                    disabled={!assignButtonEnabled || loading}>
                {loading && (<i className="fa fa-spinner fa-spin" data-testid={"spinner"}/>)}
                {!assignButtonEnabled && (<IconImage iconType={Icons.WARNING}/>)}
                {!loading && assignButtonEnabled && (<IconImage iconType={Icons.USER_ADD}/>)}
            </button>
        </Fragment>

    )
}

AssignedUserButton.propTypes = {
    insertIds: PropTypes.arrayOf(PropTypes.string),
    onSubmitCallback: PropTypes.func,
    falsePositive: PropTypes.any,
    initialNotes: PropTypes.string,
    acknowledged: PropTypes.bool,
};

export default AssignedUserButton