import React from 'react';
import Modal from "react-modal";
import {makeApiCall} from "../../../Api/makeApiCall";
import PropTypes from "prop-types";

export class ShowReportPartial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.getFilePartial = this.getFilePartial.bind(this);
    }

    async getFilePartial(url) {

        const results = await makeApiCall(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFilePartial/?is_json=true&path=${encodeURIComponent(url)}&start=0&end=50000`
        )
        if (results.apiError)
            this.setState({fileData: "File is too large to display"})
        else {
            this.setState({fileData: JSON.stringify(results, null, 2)});
        }
    }

    render() {
        const lastSlash = this.props.path.lastIndexOf('/');
        const fileName = this.props.path.substring(lastSlash + 1);
        const url = this.props.fileType ? `gs://${process.env.REACT_APP_GCS_TMP_BASE_URL}${this.props.runId}/report/p_data${'=' + this.props.fileType.getPartition()}/normalized_record_report/${fileName + '.json'}` : '';

        return <>
            {this.props.rowCount > 0 ? <a className={'issues-link'} onClick={async () => {
                if (this.props.rowCount > 0) {
                    this.setState({isModalOpen: true});
                    await this.getFilePartial(url)
                }
            }}>Issues:</a> : <div>Issues:</div>}
            <Modal
                isOpen={this.state.isModalOpen}
                className="Modal show-file-partial-modal"
                overlayClassName="Overlay"
                ariaHideApp={false}

            >
                <div className={'modal-x-close'}>
                    <button className={"modal-x-close-button"} type="button" name="close"
                            onClick={() => this.setState({isModalOpen: false})}>
                        X
                    </button>
                </div>
                <h2>First 50000 characters of {url}</h2>
                <div className={'file-partial-content'}>{this.state.fileData && this.state.fileData}</div>
            </Modal>
        </>
    }
}

ShowReportPartial.defaultProps = {
    maxCharacters: 2000,
}

ShowReportPartial.propTypes = {
    path: PropTypes.string,
    runId: PropTypes.string,
    fileType: PropTypes.string,
    rowCount: PropTypes.number
}
