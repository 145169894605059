import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCall} from '../../../../Api/makeApiCall';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import {makeApolloClientCall} from '../../../../Api/makeApolloApiCall';
import ArchiveFilenamesList from './ArchiveFilenamesList';
import ItemCodeOutliers from './ItemCodeOutliers';

export const storeWeekItemDateOutliersQuery = `
query storeWeekItemDataOutliers($retailerId: Int!, $storeIds: [Int!] $weekEndDate: String!){
records:storeWeekItemDataOutliers(retailerId:$retailerId, storeIds: $storeIds, weekEndDate: $weekEndDate, percent: 25)
    {
        salesAmount
        spinsItemCode
        itemCode
        weekEndDate
        spinsStoreId
        spinsRetailerId
        percentTotalDollar
        itemDescription
    }
}`

const ArchiveFilenameModalContent = ({archiveFilenames, retailerId, storeId, weekEndDate, skipUseEffect}) => {
    const [archiveFileList, updateArchiveFileList] = useState(undefined);
    const [itemCodeOutliers, updateItemCodeOutliers] = useState(undefined);
    const [parentRetailerId, updateRetailerId] = useState(retailerId);

    React.useEffect(() => {
        if (skipUseEffect) return;
        makeApolloClientCall(storeWeekItemDateOutliersQuery, false, {
            retailerId,
            storeIds: [storeId],
            weekEndDate
        }).then(results => updateItemCodeOutliers(results.records))

        const supplementalStores = encodeURIComponent(`gs://spins-edp-${process.env.REACT_APP_ENV}-services/stores/supplementalStores.json`);
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath?path=${supplementalStores}`)
            .then((result) => {
                result.data.filter(x =>
                    x.retailerIdDisplayed === retailerId && x.storeId === storeId)
                    .map(supplementalRetailer => updateRetailerId(supplementalRetailer.retailerId))
            })

        const retailerArchiveLocation = `gs://spins-edp-${process.env.REACT_APP_ENV}-archive/p_data=sales/p_retailer_id=${parentRetailerId}`;
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/listGcsFiles?url=${encodeURIComponent(retailerArchiveLocation)}`)
            .then(files => files.reduce((accum, file) => {
                    accum[file.fileName] = file
                    return accum
                }, {}))
            .then(allArchiveFiles => archiveFilenames
                .map(filename => allArchiveFiles[filename])
                .filter(file => file)
                .map(({fileName, blobName}) => ({filename: fileName, blobName}))
            ).then(fileList => updateArchiveFileList(fileList))

    }, [archiveFilenames, parentRetailerId, storeId, weekEndDate, retailerId, skipUseEffect]);

    return archiveFileList && itemCodeOutliers ?
        <>
            <ArchiveFilenamesList archiveFileList={archiveFileList} retailerId={parentRetailerId}/>
            <ItemCodeOutliers outliers={itemCodeOutliers}/>
        </> :
        <LoadingSpinner/>;
};

ArchiveFilenameModalContent.propTypes = {
    archiveFilenames: PropTypes.arrayOf(PropTypes.string),
    retailerId: PropTypes.number,
    storeId: PropTypes.number,
    weekEndDate: PropTypes.string,
    skipUseEffect: PropTypes.bool
};

export default ArchiveFilenameModalContent