import React from "react";
import PropTypes from "prop-types";

const StoreIdAndName = ({ storeId, storeMap }) => {
    return <>{getDisplayText(storeId, storeMap)}</>;
};

const getDisplayText = (storeId, storeMap) => {
    const store = storeMap[`${storeId}`];
    return store ? `${storeId} - ${store["storeName"]}` : `${storeId}`;
};

export const STORE_ID_AND_NAME_COLUMN_GETTER = (storeMap) => {
    return {
        header: "Store",
        accessorKey: "storeId",
        cell: ({ row }) => <StoreIdAndName storeId={row.original["storeId"]} storeMap={storeMap} />,
        id: "storeId",
        filterFn: (row, columnIds, filterValue) => {
            return !filterValue || getDisplayText(row.original["storeId"], storeMap).toLowerCase().includes(filterValue.toLowerCase());
        }
    };
};

StoreIdAndName.defaultProps = {};

StoreIdAndName.propTypes = {
    storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    storeMap: PropTypes.object.isRequired
};

export default StoreIdAndName;