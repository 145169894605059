import {findBestAddressAndLocationForAddress} from "../../../Api/googleMaps/search";
import {getRetailerLookup} from "../../../utils/retailerMapCache";


const parseBoolean = (fieldName, value) => {
    if (value?.trim()?.length > 0) {
        if (['TRUE', '1', 'Y'].includes(value.trim().toUpperCase())) return {data: 1}
    }
    return {data: 0}
}

const parseNumeric = (fieldName, value) => {
    if (!value?.trim()?.length > 0) return {}
    const result = Number(value.trim())
    return isNaN(result) ? {error: `${fieldName} is not numeric`} : {data: result}
}

const parseString = (fieldName, value) => {
    if (!value?.trim()?.length > 0) return {}
    return {data: value.trim()};
}

const parseDate = (fieldName, value) => {
    if (!value?.trim()?.length > 0) return {}
    if (!value.trim().match(/^\d{4}-\d{2}-\d{2}$/)) return {error: `date value ${value} is not in the format YYYY-MM-DD`}
    return {data: value.trim()};
}

const validateRequired = (fieldName, result) => {
    if (result.error) return result
    if (!result.data) return {error: `${fieldName} is required`}
    return result
}


const getRetailerLookupByName = (retailerLookupById) => {
    return Object.values(retailerLookupById).filter(retailer => !!retailer.retailChain).reduce((accumulator, next) => (
        {
            ...accumulator,
            [next.retailChain.toLowerCase()]: next
        }), {});
}


function getRetailerFromRetailerLookUpByName(retailchain, retailerLookupByName, valueType) {
    const retailer = retailerLookupByName[retailchain.toLowerCase()]
    return retailer ? retailer[valueType] : undefined
}

export const fieldProcessors = {
    retailerId: (parsedRecord, retailerId, retailerLookupByName) => {
        if (retailerId) return {data: retailerId}
        if (parsedRecord.retailerid) return validateRequired('retailerId', parseNumeric('retailerId', parsedRecord.retailerid))
        if (parsedRecord.retailchain) {
            const decodedRetailerId = getRetailerFromRetailerLookUpByName(parsedRecord.retailchain, retailerLookupByName, 'retailerId')
            return validateRequired('retailerId',  {data: decodedRetailerId})
        }
        return {error: 'retailerId is required'}
    },
    storeName: (parsedRecord) => validateRequired('storeName', parseString('storeName', parsedRecord.storename)),
    storeId: (parsedRecord) =>  parseNumeric('storeId', parsedRecord.storeid),
    storeTag: (parsedRecord) => parseNumeric('storeTag', parsedRecord.storetag),
    addr1: (parsedRecord) => parseString('addr1', parsedRecord.addr1 || parsedRecord.street1),
    addr2: (parsedRecord) => parseString('addr2', parsedRecord.addr2 || parsedRecord.street2),
    city: (parsedRecord) => parseString('city', parsedRecord.city),
    state: (parsedRecord) => parseString('state', parsedRecord.state),
    county: (parsedRecord) => parseString('county', parsedRecord.county),
    country: (parsedRecord) => parseString('country', parsedRecord.country),
    zip1: (parsedRecord) =>  parseString('zip1', parsedRecord.zip1),
    zip2: (parsedRecord) =>  parseString('zip2', parsedRecord.zip2),
    latitude: (parsedRecord) => parseNumeric('latitude', parsedRecord.latitude),
    longitude: (parsedRecord) => parseNumeric('longitude', parsedRecord.longitude),
    storeCode: (parsedRecord) => validateRequired('storeCode', parseString('storeCode', parsedRecord.storecode)),
    externalStoreId: (parsedRecord) =>  parseString('externalStoreId', parsedRecord.externalstoreid || parsedRecord.externalid),
    account: (parsedRecord) =>  parseString('account', parsedRecord.account),
    banner: (parsedRecord) =>  parseString('banner', parsedRecord.banner),
    consumerName: (parsedRecord) => validateRequired('consumerName', parseString('consumerName', parsedRecord.consumername)),
    closed: (parsedRecord) => parseBoolean('closed', parsedRecord.closed),
    closedDate: (parsedRecord) => parseDate('closedDate', parsedRecord.closeddate || parsedRecord.closedat),
    acv: (parsedRecord) => parseNumeric('acv', parsedRecord.acv),
    areaCode: (parsedRecord) => parseString('areaCode', parsedRecord.areacode),
    dairyDoorNum: (parsedRecord) => parseNumeric('dairyDoorNum', parsedRecord.dairydoornum || parsedRecord.dairydoornumber),
    dbTag: (parsedRecord) => validateRequired('dbTag', parseString('dbTag', parsedRecord.dbtag)),
    notes: (parsedRecord) => parseString('notes', parsedRecord.notes),
    phone: (parsedRecord) => parseString('phone', parsedRecord.phone),
    primarySvc: (parsedRecord) => validateRequired('primarySvc', parseString('primarySvc', parsedRecord.primarysvc)),
    refDoorNum: (parsedRecord) => parseNumeric('refDoorNum', parsedRecord.refdoornum ||parsedRecord.refdoornumber),
    storeLevelInd: (parsedRecord) => parseBoolean('storeLevelInd', parsedRecord.storelevelind),
    version: () => ({data: 1}),
    xsMkt: (parsedRecord) => parseNumeric('xsMkt', parsedRecord.xsmkt || parsedRecord.market),
    index: () => ({data: null})
}

export const processParsedResults = async (parsedResults, retailerId) => {
    const data = []
    const errors = parsedResults.errors ? [...parsedResults.errors] : []
    const retailerLookupById = getRetailerLookup()
    const retailerLookupByName = getRetailerLookupByName(retailerLookupById)
    parsedResults.data?.forEach((parsedRecord) => {
        const fieldErrors = []
        const processedRecord = {}
        const parsedDataAdjustedKeys = Object.keys(parsedRecord).reduce((acc, key) => {
            const adjustedKey = key.trim().replace(/ /g, '').replace(/_/g, '').toLowerCase()
            acc[adjustedKey] = parsedRecord[key]
            return acc
        }, {})
        Object.keys(fieldProcessors).forEach((field) => {
            const result = fieldProcessors[field](parsedDataAdjustedKeys, retailerId, retailerLookupByName)
            if (result.error) fieldErrors.push(result.error)
            else processedRecord[field] = result.data
        })
        if (fieldErrors.length > 0) {
            errors.push({fieldErrors, record: parsedRecord})
        } else {
            processedRecord.retailTag = retailerLookupById[processedRecord.retailerId].retailTag
            data.push(processedRecord)
        }
    })
    return {data, errors}
}

export const maybeVerifyAddresses = async (processedResult, verifyAddresses, geocodingLib, placesLib, placesMap) => {
    if (!verifyAddresses) return processedResult
    const verifyResults = await Promise.all(processedResult.data.map(record =>
        findBestAddressAndLocationForAddress(record, record.retailerId, geocodingLib, placesLib, placesMap)
            .then(verifiedAddress => (verifiedAddress ?
                {data: {
                    ...record,
                    addr1: verifiedAddress.addr1,
                    addr2: verifiedAddress.addr2,
                    city: verifiedAddress.city,
                    state: verifiedAddress.state,
                    county: verifiedAddress.county,
                    country: verifiedAddress.country,
                    zip1: verifiedAddress.zip1,
                    zip2: verifiedAddress.zip2,
                    latitude: verifiedAddress.location.lat,
                    longitude: verifiedAddress.location.lng,
                }} :
                {error: {message: 'Unable to validate address.  Please enter a valid address and retry the upload.', record}}
            )).catch((error) => ({error: {message: `Unable to validate address (google mapping api unexpected error "${error.message}").  Please try again later.`, record}}))
    ))
    return {
        data: verifyResults.filter(result => result.data).map(result => result.data),
        errors: [...(processedResult.errors || []), ...verifyResults.filter(result => result.error).map(result => result.error)]
    }
}