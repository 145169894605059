import gql from 'graphql-tag'
import {createApolloClient} from "./createApolloClient";

export const apolloClient = createApolloClient()

export const makeApolloClientCall = (graphqlQuery, useCache, variables) => {
    const cachePolicy = useCache ? 'cache-first' : 'no-cache';
    return apolloClient
    .query({
        query: gql` ${graphqlQuery} `,
        fetchPolicy: cachePolicy,
        variables: variables
    }).then((response) => {
        if (response.error) {
            console.error(`=============> Apollo query ${graphqlQuery} failed with error `, response.error);
            throw new Error(`Apollo query ${graphqlQuery} failed!`)
        }
        return response.data
    }).catch((error) => {
        console.error("=============> error: ", error.message);
        throw new Error(error)
    });
};