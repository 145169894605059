import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CsvDownload from "../../utils/CsvDownloadWithNulls";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import _ from "lodash";
import {toast} from "react-toastify";
import {toastInfo, toastSuccess} from "../../utils/toast";

export const DownloadMarketServiceButton = (props) => {

    async function downloadableData(period) {
        const filename = period + '.csv';
        toastInfo(toast, `Download for ${filename} in progress`)
        await getDownloadableData(period)
            .then((result) => {
                    CsvDownload(result, filename, '')
                    toastSuccess(toast, `${filename} has been successfully downloaded`)
                }
            );
    }

    async function getDownloadableData(period) {
        return makeApolloClientCall(` { markets(periodAbbreviation: "${period}", includeInternalOnly: true){
                index,
                market_id,
                market_key,
                description,
                order_no,
                ewb,
                account,
                account_id,
                outlet {
                    channel_source_id,
                    channel_source,
                    outlets_id: index,
                    outlets: name
                },
                secondary_outlets {
                    channel_source_id,
                    channel_source,
                    outlets_id: index,
                    outlets: name
                },
                dataset,
                geography_level,
                geography_level_id,
                geography_composition,
                geography_composition_id,
                geography_type,
                geography_type_id,
                legacy_account_type,
                legacy_account_type_id,
                account_type,
                account_type_id,
                releasability,
                releasability_id,
                measure_availability,
                measure_availability_id,
                control_brand,
                control_brand_id,
                iri_hicone_market,
                iri_hicone_market_id,
                manufacturer_geo_family_id,
                retailer_geo_family_id,
                geo_family_description,
                market_group_id:marketGroupId,
                retailer_corporate_totals,
                activate_for_distribution_tracker,
            }
            } `)
            .then((result) => {
                return result.markets
                    .map((market) => _.omit(market, ['__typename']))
                    .map((market) => {
                        const flattenedMarket = Object.entries(market)
                            .reduce((accum, [key, value]) => {
                                function flattenArray(marketValue, marketKey) {
                                    const result = {};
                                    marketValue.forEach(item => {
                                            if (_.isObject(item)) {
                                                Object.keys(item).forEach(key => {
                                                    if (key !== '__typename')
                                                        if (!result[key]) {
                                                            result[key] = item[key];
                                                        } else
                                                            result[key] = result[key] + "," + item[key];
                                                })
                                            } else {
                                                if (result[marketKey] === undefined)
                                                    result[marketKey] = ""
                                                result[marketKey] = result[marketKey] + " " + item
                                            }
                                        }
                                    );

                                    return result;
                                }

                                const flattenedValue = _.isArray(value) ? flattenArray(value, key) : value
                                const valueAsObject = _.isObject(flattenedValue) ? flattenedValue : {[key]: flattenedValue};
                                return {...accum, ...valueAsObject}
                            }, {});
                        Object.keys(flattenedMarket).forEach((key) => flattenedMarket[key] = (flattenedMarket[key] === null ? null : flattenedMarket[key].toString()));
                        return flattenedMarket;
                    });

            });
    }

    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <div title={'Download CSV'}>
            {
                isDownloading ? <i className="fa fa-spinner fa-spin"/> :
                    <i className={'fas fa-download'} data-testid={"download-markets-button-name"} onClick={() => {
                        setIsDownloading(true);
                        downloadableData(props.period).then(() => setIsDownloading(false))
                    }}>
                    </i>
            }
        </div>
    )
};

DownloadMarketServiceButton.propTypes = {
    period: PropTypes.string.isRequired
};


export default DownloadMarketServiceButton;