import React from 'react';
import {IgnoreButton} from "./IgnoreButton";

export const IgnoreUnmappedStores = ({row}) => <IgnoreButton
    url={`${process.env.REACT_APP_EDP_API_BASE_URL}/updateUnmappedStoresIgnoreList`}
    postBody={{
        retailerId: row.original.retailerId,
        fileName: row.original.fileName
    }}
    unmappedItem={row.original.fileName}
/>;