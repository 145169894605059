import React from 'react';
import {serviceRetailerDropdown} from "../../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {
    INDEX_FIELD,
    RETAILER_ID_FIELD,
    RETAILER_ID_NAME_COLUMN
} from "../../Components/ReactTable/common_custom_columns";
import {EditFormNumberInputField} from "../../Components/ReactTable/EditRecordForm";
import {checkForDuplicateRetailersForm} from "../serviceUtils";

function ReceiptLagService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/receiptLag/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "Receipt Lag", accessorKey: "receiptLag"}
    ];

    function validatePreSave({rows, form}) {
        const retailerIdValue = form.getValues('retailerId');
        const indexValue = form.getValues('index');
        const hasDuplicate =  checkForDuplicateRetailersForm(retailerIdValue, indexValue, rows)

        if (hasDuplicate) {
            form.setError('retailerId', {
                type: 'custom',
                message: [`Retailer Id ${retailerIdValue} already exists in this config`]
            });
        }

        return !hasDuplicate;
    }

    return (<Page name={"Receipt Lag"} breadCrumbs={<Breadcrumbs
        items={[{label: "Configurations"}, {label: "Retailer"}, {label: "Receipt Lag"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                initialState={{ pagination: { pageSize: 25 }, sorting: [{ id: "retailerIdName", desc: false }] }}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'receiptLag', Component: args => <EditFormNumberInputField {...args} isRequired/>}
                ]}
                validatePreSave={validatePreSave}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default ReceiptLagService;
