import React, {Component} from "react";
import * as PropTypes from "prop-types";
import PeriodsDropdown from "../../Components/Dropdowns/PeriodsDropdown";
import SelectDropdown from "../../Components/Dropdowns/SelectDropdown";

export const totalDollarsDateFormat = "yyyy-MM-dd";

export class TotalsSearchOptionsAdvanced extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAdvancedOptions: false
        };
        this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this)
    }

    toggleAdvancedOptions() {
        this.setState({
            showAdvancedOptions: !this.state.showAdvancedOptions
        });
    }

    render() {
        const periodOptionsMapFunction = (period) => (period.periodWeek.toString());
        return <>
            <div className={'totals-modal-toggle-wrapper'}>
                <hr className={"draw-a-line"}/>
                <label id={'advanced_options_label'}>Advanced Options</label>
            </div>
            <div className={'totals-modal-row-wrapper'}>
                <div className={'totals-modal-column-wrapper'} data-testid={'start-period-dropdown-container'}>
                    <label id={'period_label'}>Start Week ID</label>
                    <PeriodsDropdown
                        id={"start_period"}
                        className={"data-row-dropdown start-week-dropdown"}
                        updatePeriod={this.props.startWeekIdCallBack}
                        showLabel={false}
                        periodOptionsMapFunction={periodOptionsMapFunction}
                        selectedPeriod={this.props.startWeekId && this.props.startWeekId.toString()}
                        isDisabled={this.props.isDisabled}
                    />
                </div>
                <div className={'totals-modal-column-wrapper'}  data-testid={'end-period-dropdown-container'}>
                    <label id={'period_label'}>End Week ID</label>
                    <PeriodsDropdown
                        id={"end_period"}
                        className={"data-row-dropdown end-week-dropdown"}
                        updatePeriod={this.props.endWeekIdCallback}
                        showLabel={false}
                        periodOptionsMapFunction={periodOptionsMapFunction}
                        selectedPeriod={this.props.endWeekId && this.props.endWeekId.toString()}
                        isDisabled={this.props.isDisabled}
                    />
                </div>
            </div>
            <div className={'totals-modal-row-wrapper-state'}>
                <div className={'totals-modal-column-wrapper-state'} data-testid={'state-dropdown-container'}>
                    <label id={'period_label'}>State</label>
                    <SelectDropdown
                        id={"state_dropdown"}
                        onChange={this.props.stateFilterCallback}
                        options={this.props.stateFilterOptions.map((state) => {return {value: state, label: state}})}
                        selectValue={this.props.stateFilter}
                        isClearable
                        placeholder={"Select State..."}
                    />
                </div>
            </div>
        </>;
    }
}

TotalsSearchOptionsAdvanced.defaultProps = {
    isDisabled: false
}

TotalsSearchOptionsAdvanced.propTypes = {
    startWeekIdCallBack: PropTypes.func,
    startWeekId: PropTypes.number,
    endWeekIdCallback: PropTypes.func,
    endWeekId: PropTypes.string,
    isDisabled: PropTypes.bool,
    stateFilterCallback: PropTypes.func,
    stateFilterOptions: PropTypes.array,
    stateFilter: PropTypes.object
};