import React, {useState} from 'react';
import PropTypes from 'prop-types';
import OptionalDropDownApi from "../Scaffolding/OptionalDropDownApi";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";

const CheckpointDropdown = ({updateCheckpointPath, datasets, tableData, id}) => {
    const [checkpointCheckBox, updateCheckpointCheckBox] = useState(false)
    const [checkpoint, updateCheckpoint] = useState(undefined)

    return <>
        <OptionalDropDownApi
            onDropDownChange={(value) => {
                updateCheckpoint(value?.checkpointName);
                if (value === undefined) {
                    updateCheckpointPath({});
                } else {
                    updateCheckpointPath(value)
                }
            }}
            selectedDropDownValue={checkpoint}
            checkBoxIsChecked={checkpointCheckBox}
            onCheckBoxChange={() => {
                updateCheckpointCheckBox(!checkpointCheckBox);
                if (checkpointCheckBox) {
                    updateCheckpointPath({})
                }
            }}
            apiRequest={() => {
                const datasetParam = datasets.map(dataset => `"p_dataset=${dataset}"`).join(',');
                const tableDataParam = tableData.map(dataset => `"p_data=${dataset}"`).join(',');
                const query = `query {
                                schemasForCheckpoints(
                                    dataset: [${datasetParam}]
                                    tableData: [${tableDataParam}]
                                    ){
                                    checkpointName,
                                    checkpointPath,
                                    checkpointSchema,
                                dataset
                              }
                            }`;
                return makeApolloClientCall(query);
            }}
            onParseApiResponse={apiReturn => apiReturn.schemasForCheckpoints.map((value) => (
                {label: value.checkpointName, value}
            ))}
            checkboxText='Use an optional set of checkpoint data'
            labelText={'Checkpoint'}
            optionalClassName={`large-optional-dropdown ${id}`}
            placeholder={"Select Checkpoint..."}
        />
    </>;
};

CheckpointDropdown.defaultProps = {};

CheckpointDropdown.propTypes = {
    id: PropTypes.string,
    updateCheckpointPath: PropTypes.func.isRequired,
    datasets: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired
};

export default CheckpointDropdown;