import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {makeApolloClientCall} from '../../Api/makeApolloApiCall';


const NextPeriod = () => {
    const [nextPeriod, updateNextPeriod] = useState(undefined);
    const [periodAbbreviation, updatePeriodAbbreviation] = useState('Period');
    const currentDate = Date.now();

    const getNextPeriod = () => {
        makeApolloClientCall('{ periods(periodEndWeeksOnly:true excludeFuture:false){ endDate, isCurrentPeriod periodAbbreviation} }')
            .then((results) => {
                const periods = results.periods.sort((o1, o2) => o1.endDate.localeCompare(o2.endDate));
                const currentPeriodIndex = periods.findIndex(x => x.isCurrentPeriod);
                const nextPeriod = periods[currentPeriodIndex+1];
                const date = new Date(nextPeriod.endDate);
                updatePeriodAbbreviation(nextPeriod.periodAbbreviation)
                date.setDate(date.getDate() + 14)
                return updateNextPeriod(date);
            });
    };

    useEffect(() => {
        getNextPeriod();
    }, []);

    // noinspection JSValidateTypes
    const daysUntilNextPeriod = moment(nextPeriod).diff(currentDate, 'days');
    return <>
        <div className={"next-period-display"}>
            {`${periodAbbreviation} Release In ${daysUntilNextPeriod} Days.`}
        </div>
    </>;
};

NextPeriod.propTypes = {};

export default NextPeriod;