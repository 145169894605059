import * as PropTypes from 'prop-types';
import React from "react";


function OutliersCell({data}) {
    function getOutliersBadge(issuesType) {
        const issue = data.issues?.find((x) => x.issueType === issuesType);
        return <span className={"issue-link-color-red"}>{issue ? issue.count : ""}</span>;
    }

    function getOutliersLink(outliersType, iconClass, outliersLinkUrlQueryParams, outlierTypeLink) {
        const hasIssue = data.issues?.find((x) => x.issueType === `${outliersType}Outliers`);
        return hasIssue ? <a target="_blank" rel="noopener noreferrer"
                          href={`/alerts/outliers/${outlierTypeLink}/?${outliersLinkUrlQueryParams}`}
                          className={`${outliersType}s-count-alert-link`}>
            <i className={`fas ${iconClass}`} title={`${outliersType} outliers`}/>
        </a> : "";
    }


    const outliersLinkUrlQueryParams = `retailerId=${data.retailer.retailerId}&startDate=${data.periodStartDate}&endDate=${data.periodEndDate}`;
    return (
        <div className={'outliers-readiness-column'}>
            {getOutliersLink('dollar','fa-money-bill-wave', `${outliersLinkUrlQueryParams}`, 'salesAmount')}
            {getOutliersBadge('dollarOutliers')}

            {getOutliersLink('unit','fa-balance-scale',`${outliersLinkUrlQueryParams}`, 'salesUnits')}
            {getOutliersBadge('unitOutliers')}

            {getOutliersLink('record', 'fa-record-vinyl',`${outliersLinkUrlQueryParams}`, 'records')}
            {getOutliersBadge('recordOutliers')}

        </div>
    )
}

OutliersCell.propTypes = {
    data: PropTypes.object
}

export default OutliersCell;