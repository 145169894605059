import {toastError} from "../../utils/toast";
import {toast} from "react-toastify";

export const checkForDuplicateRetailers = (updatedData, allData, retailerIdKey) => {
    const retailerIdCount = allData.map((data) => data[retailerIdKey]).filter(x => x === updatedData[retailerIdKey]).length;
    if (retailerIdCount > 1) {
        const errorMessage = `Retailer Id ${updatedData[retailerIdKey]} already exists in this config`
        toastError(toast, errorMessage)
    } else {
        return updatedData;
    }
};

export const checkForDuplicateRetailersForm = (retailerIdValue, indexValue, rows) => {
    const rawRecordsFiltered = rows.map(row => row.original).filter(row => row.retailerId === retailerIdValue);
    return rawRecordsFiltered.length > 0 && indexValue !== rawRecordsFiltered[0].index;
}
export const checkForDuplicateStoresForm = (storeIdValue, indexValue, rows) => {
    const rawRecordsFiltered = rows.map(row => row.original).filter(row => row.storeId === storeIdValue);
    return rawRecordsFiltered.length > 0 && indexValue !== rawRecordsFiltered[0].index;
}