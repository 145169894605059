import React from 'react';
import carrot from '../../assets/carrot-large.png';
import PropTypes from 'prop-types';

const LoadingSpinner = ({spinnerClassName}) => {
    const random = Math.floor(1000 * Math.random());
    return (
        <div className={`spinner-container ${spinnerClassName}`}>
            {
                random === 0 ? <img alt={'carrot'} className={'spinner-carrot'} src={carrot}/> :
                    <i className="fas fa-carrot fa-spin fa-500px spinner"/>
            }
            Loading...
        </div>
    );
};

LoadingSpinner.defaultProps = {
    spinnerClassName: 'spinner-container-normal'
}

LoadingSpinner.propTypes = {
    spinnerClassName: PropTypes.string
}

export default LoadingSpinner;