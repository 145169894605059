import React, {useState} from "react";
import PropTypes from "prop-types";
import NameValueLabels from "../../Components/NameValueLabels";
import {KnownItemPercentage} from "../KnownItemPercentage";
import AlertLink from "./AlertLink";


export const PreviousRunSummaryInformation = ({previousRun}) => {
    const [inHover, setHover] = useState(false);

    return <div className={'hover-tool-tip-div'}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>

        <i className={`fas fa-info-circle previous-run-summary-icon-${previousRun.hasFailures ? "failure":"success"}`} />
        {inHover && <div className={'tooltip-contents'}>
            <NameValueLabels
                containerClassName={'hover-tool-tip-div-hover'}
                labelValuePairs={[
                    {label: "Triggered By:", value: previousRun.triggeredBy},
                    {label: "Issue Count:", value: previousRun.normalizedRejectCount},
                    {label: "Alerts:", value: <AlertLink value={previousRun.alertIds}/>},
                    {label: "Known Item %:", value: <KnownItemPercentage original={previousRun}/>}
                ]}/>
        </div>}
    </div>;


}
PreviousRunSummaryInformation.propTypes = {previousRun: PropTypes.any};