import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWater} from "@fortawesome/free-solid-svg-icons";
import {faProductHunt} from "@fortawesome/free-brands-svg-icons";

export function getDateAndDayHeader(date, fillWatermark, promoWatermark) {
    const dateParts = date.split('-');
    const fillWatermarkDisplay = fillWatermark ? <FontAwesomeIcon icon={faWater} shake /> : undefined;
    const promoWatermarkDisplay = promoWatermark ? <FontAwesomeIcon icon={faProductHunt} shake /> : undefined;
    return (<div>{dateParts[0] + '/' + dateParts[1] + '/' + dateParts[2] + ' ' + new Date(date).toLocaleDateString('en-US', {weekday: 'long', timeZone: 'UTC'}) + "\n"}{fillWatermarkDisplay}{promoWatermarkDisplay}</div>);
}