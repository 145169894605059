import ReactTable from '../Components/ReactTable';
import React from 'react';
import _ from 'lodash';

export const GenericCostDisplay = ({header, fileContents, filePath}) => {
    const columnsByDate = _.groupBy(fileContents, 'header');
    const pivotedRows = _.map(columnsByDate, (columnsForDate) => {
        return columnsForDate.reduce((tableRow, cost) => {
            return {
                ...tableRow,
                header: cost.header,
                [cost.date]: cost.cost
            }
        }, {})
    })

    const headers = _.chain(fileContents)
        .map(x => x.date)
        .uniq()
        .sort()
        .reverse()
        .map(x => ({header: x, accessorKey: x}));

    return (
        <>
            <h2 id={filePath}>{header}</h2>
            <ReactTable
                data={pivotedRows}
                filterable
                columns={[
                    {
                        header: 'ID',
                        accessorKey: 'header'
                    },
                    ...headers
                ]}
            />
        </>
    );
}