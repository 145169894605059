import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import React, {useEffect, useState} from 'react'
import Page from "../../Components/Page";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {makeApiCall} from "../../../Api/makeApiCall";
import RetailerListConfiguration from "./RetailerListConfiguration";

function AlertsConfigurations () {

    const [alertDescriptions, setAlertDescriptions] = useState(undefined)

    useEffect(() => {
        const alertIdMappingEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/alertIdMapping`;
        makeApiCall(alertIdMappingEndpointUrl).then(data => setAlertDescriptions(data.data?.reduce((accum, record) => {
                accum[record.alertId] = record.displayName
                return accum
            }, {}) || {})
        )
    }, [])

    const getAlertDescription = (alertId) => (alertDescriptions ? (alertDescriptions[alertId] || alertId) : alertId)

    return (
        <Page name={"Alerts Configuration"}>
            <LoadingWrapper loading={!alertDescriptions}>
                <Tabs>
                    <TabList>
                        <Tab>{getAlertDescription('qa_sales_subscriber')}</Tab>
                        <Tab>{getAlertDescription('stores_missing_daily_sales')}</Tab>
                    </TabList>
                    <TabPanel>
                        <RetailerListConfiguration
                            endpoint={'alerts_configurations'}
                            configDescription={'Retailers in the table below may have the following alert generated at most once per day (overnight).'}
                            alertDescriptions={[getAlertDescription('qa_sales_subscriber')]}
                            configFootnote={'Other retailers may have this alert generated immediately after any sales file ingestion.'}
                        />
                    </TabPanel>
                    <TabPanel>
                        <RetailerListConfiguration
                            endpoint={'alertStoresMissingDailySalesRetailers'}
                            configDescription={'Retailers in the table below may have the following alert generated at most once per day (overnight).'}
                            alertDescriptions={[getAlertDescription('stores_missing_daily_sales')]}
                            configFootnote={'This alert will never be generated for other retailers.'}
                        />
                    </TabPanel>
                </Tabs>
            </LoadingWrapper>
        </Page>)
}
export default AlertsConfigurations;
