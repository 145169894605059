import {round} from "lodash";
import {getNicelyFormattedNumber} from "../../../../utils/formatUtil";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

export const displayDiffFromAverageDollar = (storeData, outlierData) => {
    return outlierData ?
        getPlusOrMinus(outlierData.diffFromAverageDollar, outlierData.dollarCount, outlierData.averageDollarCount)
        : undefined
};


export const displayDiffFromAverageUnit = (storeData, outlierData) => {
    return outlierData ?
        getPlusOrMinus(outlierData.diffFromAverageUnit, outlierData.unitCount, outlierData.averageUnitCount)
        : undefined
}

function getPlusOrMinus( diffFromAverage, count, averageCount) {
    if (!diffFromAverage || !count)
        return undefined;
    const plusOrMinus = count < averageCount ? "-" : "+";
    return plusOrMinus + round(diffFromAverage, 1) + '%';
}

export function getDollarsFormat(dollars) {
    return dollars && dollars < 1 && dollars > 0 ? formatter.format(dollars) : formatter.format(round(dollars));
}

export function getAverageDollarFormat(data) {
    return getDollarsFormat(data.averageDollarCount);
}

export function getAverageUnitFormat(data) {
    return getNicelyFormattedNumber(round(data.averageUnitCount));
}

export function getDiffFromAverageDollar(data) {
    return data.diffFromAverageDollar;
}

export function getDiffFromAverageUnit(data) {
    return data.diffFromAverageUnit;
}

export const displayDollars = (storeData) => {
    return storeData?.dollars && getDollarsFormat(storeData.dollars);
};

export const displayUnits = (storeData) => {
    return storeData?.units &&  getNicelyFormattedNumber(round(storeData.units));
};