import React from "react";
import PropTypes from "prop-types";
import {AuditHistory} from "./AuditHistory";

export const AuditStoreSalesEndDateConfig = ({retailerId, configName, configEndpoint}) => {
    return <AuditHistory
        configAuditEndpoint={`storeSalesEndDateConfig/${configEndpoint}/audit/${retailerId}`}
        configName={configName}
        customColumns={[
            {header: 'Sales End Date', id: 3, accessorKey: 'salesEndDate'},
            {header: 'Store Id', id: 4, accessorKey: 'storeId'}
        ]}
    />
}

AuditStoreSalesEndDateConfig.defaultProps = {}

AuditStoreSalesEndDateConfig.propTypes = {
    retailerId: PropTypes.number.isRequired,
    configName: PropTypes.string.isRequired,
    configEndpoint: PropTypes.string.isRequired
}
