import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Page from "../Components/Page";
import AsinRetailers from "../Configurations/AsinRetailers";
import AsinToUpc from "../Configurations/AsinToUpc";

function AsinService() {
    return (
        <Page name={'ASIN'}>
            <Tabs>
                <TabList>
                    <Tab data-testid={"asinToUpc-tab-name"}>ASIN to SPINS UPC</Tab>
                    <Tab data-testid={"asinRetailers-tab-name"}>ASIN Retailers</Tab>
                </TabList>
                <TabPanel data-testid={"asinToUpc-tab-panel"}><AsinToUpc/></TabPanel>
                <TabPanel data-testid={"asinRetailers-tab-panel"}><AsinRetailers/></TabPanel>
            </Tabs>
        </Page>
    )
}

export default AsinService;