export const getDrillDownLink = ({
                                     retailerId,
                                     retailerName,
                                     fromSchemaSelection,
                                     toSchemaSelection,
                                     ignorePlu,
                                     ignoreIfps,
                                     thresholdDollars,
                                     thresholdUnits,
                                     weeks,
                                     problemWeeks,
                                     percentGood
                                 }) =>
    `extractSpotlightDrillDown?retailerId=${retailerId}&retailerName=${encodeURIComponent(retailerName)}&fromSchemaSelection=${encodeURIComponent(fromSchemaSelection)}&toSchemaSelection=${encodeURIComponent(toSchemaSelection)}&ignorePlu=${ignorePlu}&ignoreIfps=${ignoreIfps}&thresholdDollars=${thresholdDollars}&thresholdUnits=${thresholdUnits}&weeks=${weeks}&problemWeeks=${problemWeeks}&percentGood=${percentGood}`

export const getMissingWeeksDrillDownLink = ({
        retailerId,
        retailerName,
        fromSchemaSelection,
        toSchemaSelection,
        ignorePlu,
        runIdentifier
}) =>
    `missingWeeksSpotlightDrillDown?retailerId=${retailerId}&retailerName=${encodeURIComponent(retailerName)}&fromSchemaSelection=${encodeURIComponent(fromSchemaSelection)}&toSchemaSelection=${encodeURIComponent(toSchemaSelection)}&ignorePlu=${ignorePlu}&runIdentifier=${runIdentifier}`