import React from 'react';
import PropTypes from 'prop-types';
import csvDownloadWithNulls from "../../utils/CsvDownloadWithNulls";
import {compositionTabColumns} from "./Tabs/CompositionTab";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {
    compositionReportCodeAbleItemsQuery,
    compositionReportNoDepartmentQuery
} from "../../queries/compositionReportNoDepartmentQuery";
import {storesCompositionTabColumns} from "./StoresCompositionTabColumns";
import _ from 'lodash'
import numeral from 'numeral'
import {retailerCompositionTabColumns} from "./RetailerCompositionTabColumns";
import ReactTable from "../Components/ReactTable";

const NOT_IN_PRODUCT_SNAPSHOT_DEPARTMENT = 'NOT IN PRODUCT SNAPSHOT'

const CompositionContent = ({data, retailerId, weekEndDate, companyType, marketDesignation}) => {
    const retailerTotalsFlatMap = data.flatMap(retailers =>
        retailers.retailerTotals.map(compositionReportNoDepartment => {
            delete compositionReportNoDepartment.__typename;
            return compositionReportNoDepartment;
        }));

    const notInProductSnapshot = _.find(retailerTotalsFlatMap, {department: NOT_IN_PRODUCT_SNAPSHOT_DEPARTMENT})
    const initialRetailerTotals = _.filter(retailerTotalsFlatMap, (r) => r.department !== NOT_IN_PRODUCT_SNAPSHOT_DEPARTMENT)
    const productSnapshotSum = _.reduce(initialRetailerTotals, (t, n) => n.salesAmountTotal + t, 0)
    const retailerTotals = initialRetailerTotals.map((rt) => ({
        ...rt,
        salesAmountPercentage: (rt.salesAmountTotal / productSnapshotSum) * 100
    }))

    const stores = data.flatMap(retailers => retailers.stores);

    const retailer = data.map(retailers => retailers.retailer);

    function getCsvDownload(variables, graphqlQuery) {
        return makeApolloClientCall(graphqlQuery,
            false,
            variables)
            .then(result => {
                    return result
                        .records.map(compositionReportNoDepartment => {
                            delete compositionReportNoDepartment.__typename;
                            return compositionReportNoDepartment;
                        })

                }
            )
    }

    async function downloadCompositionReportAsCsv() {
        const fileName = 'composition_report.csv';
        csvDownloadWithNulls(retailerTotals, fileName, 0)
    }

    async function downloadDataAsCsv(graphqlQuery, downloadFileName) {
        const variables = {retailerId: retailerId, salesEndDate: weekEndDate}
        const compositionReportNoDepartmentResults = await getCsvDownload(variables, graphqlQuery);
        csvDownloadWithNulls(compositionReportNoDepartmentResults, downloadFileName, null)
    }

    return <div className={'composition-table'}>
        <div className="composition-row-container">
            <div className="codeable-items-container">
                <span style={{fontWeight: 'bold'}}>{_.round(retailer[0].percentDollarsCodeableItems, 2)}%</span>&nbsp;
                (<span
                style={{fontStyle: 'italic'}}>{numeral(retailer[0].totalDollarsCodeableItems).format('$0,0.00')}</span>)&nbsp;
                <span>Code-able Items Not In Product Snapshot</span>
            </div>
            <button className={'download-composition-code-able-items-csv hollow button'}
                    onClick={() => downloadDataAsCsv(compositionReportCodeAbleItemsQuery, 'composition_code-able-items.csv')}>{'Download As CSV'}
            </button>
        </div>
        <div className="composition-row-container">
            <div className="not-in-snapshot-container">
                <span
                    style={{fontWeight: 'bold'}}>{_.round(notInProductSnapshot?.salesAmountPercentage, 2)}%</span>&nbsp;
                (<span
                style={{fontStyle: 'italic'}}>{numeral(notInProductSnapshot?.salesAmountTotal).format('$0,0.00')}</span>)&nbsp;
                <span>All Items Not In Product Snapshot</span>
            </div>
            <button className={'download-composition-item-codes-not-in-product-snapshot-csv hollow button'}
                    onClick={() => downloadDataAsCsv(compositionReportNoDepartmentQuery, 'composition_item_codes_not_in_product_snapshot.csv')}>{'Download As CSV'}
            </button>
        </div>

        <h1>Product Mix</h1>
        <div className={"download-csv-container"}>
            <button className={'download-composition-report-csv hollow button'}
                    onClick={downloadCompositionReportAsCsv}>{'Download As CSV'}
            </button>
        </div>
        <ReactTable
            filterable
            data={retailerTotals}
            columns={compositionTabColumns}
            initialState={{sorting: [{id: '2', desc: true}], pagination: { pageSize: 20 }}}
            showPagination={retailerTotals.length > 20}
        />
        <h1>Retailer Mix</h1>
        <ReactTable
            filterable
            data={retailer}
            columns={retailerCompositionTabColumns(companyType, marketDesignation, stores.length)}
            showPagination={false}
        />
        <h1>Store Mix</h1>
        <ReactTable
            filterable
            data={stores}
            columns={storesCompositionTabColumns(companyType, marketDesignation, stores.length)}
        />
    </div>;
};

CompositionContent.propTypes = {
    data: PropTypes.array,
    retailerId: PropTypes.number.isRequired,
    weekEndDate: PropTypes.string,
    companyType: PropTypes.string,
    marketDesignation: PropTypes.string
};

export default CompositionContent;