import React, {useState} from "react";
import PropTypes from "prop-types";

const IconDropdown = ({iconName, children, title}) => {
    const [isOpen, setIsOpen] = useState(false);
    return <div onMouseLeave={() => setTimeout(() => setIsOpen(false), 3000)} title={title}>
        <i onClick={() => setIsOpen(!isOpen)}
           className={`fas ${iconName} ${isOpen ? "selected-icon" : ""}`}
           data-testid={`icon-${title}`}
        />
        <ul className={"icon-dropdown-ul"} data-testid={`icon-dropdown-${title}`}>
            {
                isOpen && React.Children.map(children || null, (child, i) => {
                    return <li className={"icon-dropdown-li"}>
                        <child.type {...child.props} key={i}/>
                    </li>;
                })
            }
        </ul>
    </div>;
};

IconDropdown.propTypes = {
    iconName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    title: PropTypes.string
};

export default IconDropdown