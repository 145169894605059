import ButtonLoader from "../../utils/components/ButtonLoader";
import {getUserEmail} from "../../auth/accessTokenValidator";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {makeApiCall} from "../../Api/makeApiCall";
import {toastError} from "../../utils/toast";
import {toast} from "react-toastify";
import {toTitleCase} from "../Components/ReactTable/helpers";

export const bulkLoadOptionsEnum = {
    sales: 'sales',
    items: 'items',
}
export const BulkLoadArchiveButton = ({retailerId, fileType}) => {

    const fileTypeTitleCase = toTitleCase(fileType);
    const [modalMessage, setModalMessage] = useState(`You are about to reload the entire ${fileTypeTitleCase} archive for this retailer`);
    const triggerIngestIfRetailerNotRunning = async () => {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ingestionLogs/`;
        const parameters = {
            includeSuccess: false,
            includeShortCircuit: true,
            includeBulkOnly: true,
            includeCurrentlyRunning: true,
            includeFailures: false,
            days: 2,
            retailerIds: retailerId
        };
        const ingestLogs = await makeApiCall(endpointUrl, parameters)
        if (ingestLogs?.apiError) {
            toastError(toast, `${fileTypeTitleCase} Archive Load for Retailer [${retailerId}] has failed to be kicked off.`);
        } else {
            ingestLogs.metadata.find(dagRun => !dagRun.fileType || dagRun.fileType === fileType) ?
                setModalMessage(`A bulk load for retailer ${retailerId} is already in progress. Would you still like to start another bulk load?`)
                : setModalMessage(`A bulk load for retailer ${retailerId} will be kicked off`)
        }
    }

    return (<ButtonLoader buttonTitle={`${fileTypeTitleCase}: Archive`}
                  url={`${process.env.REACT_APP_EDP_API_BASE_URL}/ingestFromArchive/`}
                  postBody={{
                      retailerId,
                      fileType: fileType,
                      triggeredBy: getUserEmail()
                  }}
                  buttonClassName={"button tertiary"}
                  toastTextFailure={`${fileTypeTitleCase}: Archive has failed to kicked off.`}
                  toastTextSuccess={`${fileTypeTitleCase}: Archive has successfully kicked off.`}
                  buttonHasModal
                  modalHeader={`${fileTypeTitleCase} Archive Reload: ${retailerId}`}
                  modalText={modalMessage}
                  buttonOnClickAction={triggerIngestIfRetailerNotRunning}
                  title={`${fileTypeTitleCase}: Archive`}
    />);
}

BulkLoadArchiveButton.propTypes = {retailerId: PropTypes.number.isRequired, fileType: PropTypes.string.isRequired};