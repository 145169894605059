import {getRetailerLookup} from "../../../utils/retailerMapCache";

export const getRetailersFromCache = () => {
    const retailerLookup = getRetailerLookup();
    return {
        retailerLookup,
        retailerOptions: Object.keys(retailerLookup).map(retailerId => ({
            value: Number(retailerId),
            label: `${retailerId} - ${retailerLookup[retailerId]?.retailChain || ""}`
        }))
    }
}