import React from "react";
import PropTypes from "prop-types";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormBooleanDropdownInputField, EditFormDropdownInputField} from "../Components/ReactTable/EditRecordForm";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {getRetailerName} from "../../utils/retailerMapCache";

const SkipCleanseRules = ({displayHelmetHeader}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/skipCleanseRules/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const columns = [
        {
            header: 'Retailer',
            id: 'retailerIdName',
            filterable: true,
            accessorKey: 'spinsRetailerId',
            size: 200,
            cell: ({row}) => <RetailerIdAndName retailerId={row.original.spinsRetailerId}/>,
            filterFn: (row, columnIds, filterValue) =>
                !filterValue || Boolean(`${row.original.spinsRetailerId} ${getRetailerName(row.original.spinsRetailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
        },
        {header: "Skip Hi Cone", accessorKey: "skipHiCone"},
        {header: "Skip Adjusted Units", accessorKey: "skipAdjustedUnits"}
    ];

    function validatePreSave({rows, form}) {
        const retailerIdValue = form.getValues('spinsRetailerId');
        const indexValue = form.getValues('index');
        const rawRecordsFiltered = rows.map(row => row.original).filter(row => row.spinsRetailerId === retailerIdValue);
        const hasDuplicate = rawRecordsFiltered.length > 0 && indexValue !== rawRecordsFiltered[0].index;

        if (hasDuplicate) {
            form.setError('spinsRetailerId', {
                type: 'custom',
                message: [`Retailer Id ${retailerIdValue} already exists in this config`]
            });
        }

        return !hasDuplicate;
    }

    return (<Page name={"Skip Cleanse Rules"} displayHelmetHeader={displayHelmetHeader}
                  breadCrumbs={<Breadcrumbs
                      items={[{label: "Configurations"}, {label: "Retailer"}, {label: "Skip Cleanse Rules"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data}
                columns={columns}
                initialState={{pagination: {pageSize: 25}, sorting: [{id: "retailerIdName", desc: false}]}}
                editTable={[
                    INDEX_FIELD,
                    {id: 'spinsRetailerId', Component: args => <EditFormDropdownInputField {...args} label={"Retailer"} options={serviceRetailerDropdown().data} isRequired/>},
                    {id: 'skipHiCone', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'skipAdjustedUnits', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />}
                ]}
                validatePreSave={validatePreSave}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

SkipCleanseRules.defaultProps = {
    displayHelmetHeader: true
}

SkipCleanseRules.propTypes = {
    displayHelmetHeader: PropTypes.bool
}

export default SkipCleanseRules