import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import PropTypes from 'prop-types';
import {DAG_NAMES} from "../../Configurations/DagNames";

const RunIdLink = (props) => {
    const INGEST_PROCESS = 'Ingest';

    function getDagName() {
        const process = props.original.process;
        if (process === INGEST_PROCESS) {
            return props.original.isBulk ? DAG_NAMES.INGEST_FILE_TO_GCS_BULK : DAG_NAMES.INGEST_FILE_TO_GCS;
        } else {
            return process;
        }
    }

    let runId = props.value || props?.original?.runId;
    let executionDate = props?.original?.executionDate;
    if (runId) {
        let aMoment, formattedTimeString;
        const runDate = runId.match('(__|backfill_)(.*)');
        if (runDate && runDate.length > 2) {
            if (runId.includes("ext")) {
                aMoment = executionDate ? moment.utc(executionDate, 'YYYY-MM-DD HH:mm:ssZZ').tz('America/Chicago') : moment.utc(parseFloat(runDate[2]) * 1000).tz('America/Chicago');
                formattedTimeString = executionDate || aMoment.format('YYYY-MM-DD HH:mm:ssZZ')
            } else {
                formattedTimeString = executionDate || runDate[2];
                aMoment = moment.utc(formattedTimeString, 'YYYY-MM-DD HH:mm:ssZZ').tz('America/Chicago')
            }
        }

        const dagName = getDagName();
        if (aMoment) {
            const airFlowUrl = `${props.airflowUrl || props.original.airflowUrl}/graph?dag_id=${dagName}&root=&execution_date=${encodeURIComponent(formattedTimeString)}&base_date=${encodeURIComponent(formattedTimeString)}`;

            return (<span className='number display-linebreak'>
                <a target='_blank' id='the-a-tag' rel="noopener noreferrer"
                   href={airFlowUrl}
                   title={runId}
                >
                         {props.getDisplayText(props.original, aMoment)}
                        </a>
                <br/>
                    </span>);
        }
    }
    return <span className='number'/>;
};

RunIdLink.defaultProps = {
    getDisplayText: (row, runMoment) => `${runMoment.format('YYYY-MM-DD')} \n ${runMoment.format('h:mm:ss a')}`
}

RunIdLink.propTypes = {
    original: PropTypes.shape({
        isBulk: PropTypes.bool,
        status: PropTypes.string,
        airflowUrl: PropTypes.string
    }),
    value: PropTypes.string,
    airflowUrl: PropTypes.string,
    getDisplayText: PropTypes.func
};

export default RunIdLink;
