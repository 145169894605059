import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";

export const SELECT_DROPDOWN_CONTAINER_TEST_ID = "select-dropdown-component-container";

const SelectDropdown = ({
                            id,
                            inputLabel,
                            className,
                            onChange,
                            selectValue,
                            selectLabel,
                            options,
                            isClearable,
                            isDisabled,
                            placeholder,
                            dataTestId
                        }) => {

    const value = {label: selectLabel || selectValue, value: selectValue};
    return (<div className={"id-input"} data-testid={dataTestId}>
        {inputLabel && <span className='input-group-label'>{inputLabel}:</span>}
        <Select id={id}
                className={className}
                classNamePrefix={"select-dropdown"}
                onChange={onChange}
                options={options}
                value={selectValue ? value : undefined}
                isClearable={isClearable}
                placeholder={placeholder}
                isLoading={options === undefined}
                isDisabled={isDisabled}
        />
    </div>);
};

SelectDropdown.defaultProps = {
    className: "select-dropdown",
    id: 'select_dropdown',
    isClearable: false,
    placeholder: 'Select...',
    isDisabled: false,
    selectLabel: undefined,
    dataTestId: SELECT_DROPDOWN_CONTAINER_TEST_ID
};

SelectDropdown.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    isClearable: PropTypes.bool,
    inputLabel: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    selectLabel: PropTypes.string,
    value: PropTypes.string,
    dataTestId: PropTypes.string
};

export default SelectDropdown;