import React, {Component} from 'react';

import Page from "../Components/Page";
import {getStringParamOrDefault} from "../../utils/urlBuilder";
import {UnmappedStoresTable} from "./UnmappedStoresTable";


class UnmappedStores extends Component {

    constructor(props) {
        super(props);

        this.state = {
            retailerId: getStringParamOrDefault('retailerId', null)
        };
    }

    render ()
    {
        return (
            <Page name="Unmapped Stores">
                <UnmappedStoresTable retailerId={this.state.retailerId}/>
            </Page>
        );
    }
}

export default UnmappedStores;
