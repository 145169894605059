import React, {useState} from 'react';
import Page from "../Components/Page";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import ReactTable from "../Components/ReactTable";
import {makeApiCall, makeApiCallPost} from "../../Api/makeApiCall";
import LoadingSpinner from "../Components/LoadingSpinner";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import NameValueLabels from "../Components/NameValueLabels";
import ButtonLoader from "../../utils/components/ButtonLoader";
import {createDagRunConf} from "../../Api/dagRunConf";
import {getNicelyFormattedNumber} from "../../utils/formatUtil";

export const ITEMS_SUMMARY_QUERY = `{ itemsSummary { retailerId, total_count, spins_item_code_count, in_snapshot_count, effective_date, process_date, retailer { retailChain } } } `

export const ITEMS_SUMMARY_COLUMNS = [
    {
        header: 'Retailer',
        className: 'non-numeric-field',
        cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>
    },
    {
        header: 'Effective Date',
        accessorKey: 'effective_date',
        className: 'non-numeric-field'
    },
    {
        header: 'Unformatted Item Count',
        id: 'unformatted-item',
        accessorFn: (x) => getNicelyFormattedNumber(x.total_count)

    },
    {
        header: 'Spins Formatted Item Count',
        id: 'spins-item',
        accessorFn: (x) => getNicelyFormattedNumber(x.spins_item_code_count)

    },
    {
        header: 'Product Library Item Count',
        id: 'item-count',
        accessorFn: (x) => getNicelyFormattedNumber(x.in_snapshot_count)

    },
    {
        header: 'Failed Last 30 Days',
        accessorKey: 'failedCount',
        className: 'non-numeric-field'
    }
];

const ItemsSummaryPage = () => {
    const [itemsSummaryData, updateItemsSummaryData] = useState(undefined);
    const [lastUpdated, updateLastUpdated] = useState(undefined);

    React.useEffect(() => {
        Promise.all([makeApolloClientCall(ITEMS_SUMMARY_QUERY),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/itemsIngestSummary`)])
            .then(([{itemsSummary}, itemsIngestSummary]) => {
                const combinedSummary = itemsSummary.map(row => {
                    const {failedCount} = itemsIngestSummary.metadata.find(x => x.retailerId === row.retailerId) || {failedCount: 0};
                    return {...row, failedCount}
                });
                updateLastUpdated(combinedSummary?.[0].process_date)
                updateItemsSummaryData(combinedSummary)
            })
    }, [])



    return <Page name={"Items Summary"}>
        {itemsSummaryData ? <>
            <NameValueLabels containerClassName={'last-updated'} labelValuePairs={[
                {label: 'Last Updated:', value: lastUpdated},
                {label: '', value: <ButtonLoader
                        buttonClassName={'reload-items-summary'}
                        buttonHasModal
                        buttonTitle={<i className="fas fa-sync"/>}
                        toastTextFailure={`Items Summary DAG has failed to kick off.`}
                        toastTextSuccess={`Items Summary DAG has successfully kicked off.`}
                        modalHeader={'Items Summary Reload'}
                        modalText={'Are you sure you want to trigger an Items Summary update'}
                        postBody={{
                            dagName: "extract_item_summary_table",
                            data: createDagRunConf()
                        }}
                        title={'Update Items Summary'}
                        url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                        axiosFunction={makeApiCallPost}
                    />}
            ]}/>

            <ReactTable
                data={itemsSummaryData}
                columns={ITEMS_SUMMARY_COLUMNS}
            />
        </> : <LoadingSpinner/>}
    </Page>;
};


export default ItemsSummaryPage;