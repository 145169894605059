import {userType} from "../auth/accessTokenValidator";
import ROUTES from '../routes';
import _ from "lodash";

export function filterShouldShowNavigationLink(item, userTypeFromToken) {

    if (userTypeFromToken !== userType.adminUser) {
        const route = ROUTES.filter(route =>
            item.url === route.path ||
            item.url === route.pathForSearchResults ||
            item.url === "#"
        ).filter(route => {
            if (item?.nestedContent?.menuItems) {
                const nestedUrls = item.nestedContent.menuItems.map(x => x.url);
                return nestedUrls.includes(route.path)
            }
            return true
        });

        if (_.isEmpty(route)) {
            return false
        }

        const routesByUserAccess = route
            .map(x => x.userAccessList.includes(userTypeFromToken))
            .filter(x => x === true);

        return routesByUserAccess.length > 0
    }

    return true
}