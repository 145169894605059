import React, {useEffect, useState} from "react";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormDateInputField, EditFormNumberInputField} from "../Components/ReactTable/EditRecordForm";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";


export const StoreClosuresForRetailer = ({retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeClosures/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const [storeLoading, setStoreLoading] = useState(true)
    const [filteredStoreData, setFilteredStoreData] = useState([]);

    useEffect(() => {
        const storesQuery = ` { stores (retailerId: ${retailerId}) { storeId } } `
        makeApolloClientCall(storesQuery)
            .then((result) => {
                    const storeIds = result.stores.map(store => store.storeId)
                    const filteredByStore = data.data?.filter(store => storeIds.includes(store.storeId))
                    setFilteredStoreData(filteredByStore)
                    setStoreLoading(false)
                }
            )
    }, [data, loading]);

    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Retailer Store Closures"} displayHelmetHeader={false}>
        <LoadingWrapper loading={loading || storeLoading}>
            <EditableReactTableV2
                filterable
                data={filteredStoreData}
                columns={[
                    {header: "Store ID", accessorKey: "storeId"},
                    {header: "Start Date", accessorKey: "startDate"},
                    {header: "End Date", accessorKey: "endDate"}
                ]}
                editTable={[
                    INDEX_FIELD,
                    {id: 'storeId', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                    {id: 'startDate', Component: args => <EditFormDateInputField {...args} isRequired/>},
                    {id: 'endDate', Component: args => <EditFormDateInputField {...args} />},
                ]}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}