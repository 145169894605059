import React from 'react';
import PropTypes from "prop-types";
import {RetailerFilesTabs} from "../RetailerFilesTabs";

export const FilesTab = ({retailerId}) => {
    return (
        <>
            <RetailerFilesTabs retailerId={retailerId}/>
        </>
    )
};

FilesTab.propTypes = {
    retailerId: PropTypes.number.isRequired,
}
