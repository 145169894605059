import React, {useState} from 'react';
import PropTypes from 'prop-types';
import csvDownloadWithNulls from '../../utils/CsvDownloadWithNulls';
import {filterCaseInsensitiveReactTable} from "../../utils/filter";
import ReactTable from "../Components/ReactTable";
import {PAST_DUE_COLUMNS} from "./opsAlertsContentConstants";
import {makeApiCall} from "../../Api/makeApiCall";
import Checkbox from "../Components/Checkbox";


const PastDueContent = ({data}) => {
    const [displayData, updateDisplayData] = useState(undefined);
    const [checkbox, updateCheckbox] = useState(false);

    function formatDataForDownload(displayData) {
        const objectToUse = displayData.map(row => {
            return {
                retailerId: row.retailerId,
                retailerName: row.retailChain,
                rollUpDayOfWeek: row.rollUpDayOfWeek,
                lastCleansedDate: row.maxSalesEndDate,
                daysPastDue: row.daysPastDue,
                active: row.salesforceRetailer?.active?.toString() || 'false'
            }
        })
        csvDownloadWithNulls(objectToUse);
    }

    React.useEffect(() => {
        if (checkbox) {
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/receiptLag/`)
                .then(receiptData => {
                    let filteredData = [];
                    data.pastDue.forEach(element => {
                        const existsInBoth = !!receiptData.data.find(e => e.retailerId === element.retailerId);
                        if (existsInBoth) {
                            receiptData.data.forEach(r => {
                                if ((element.retailerId === r.retailerId && element.daysPastDue > r.receiptLag)) {
                                    filteredData.push(element)
                                }
                            })
                        } else if (!existsInBoth && element.daysPastDue > 0) {
                            filteredData.push(element)
                        }
                    })
                    updateDisplayData(filteredData)
                });
        }
        updateDisplayData(data.pastDue)


    }, [data, checkbox])

    return (
        <>
            <div id={'check-boxes'}>
                <div className={'checkbox-group'}>
                    <div className={'checkbox-checkboxes'}>
                        <Checkbox labelText={'Include Receipt Lag'}
                                  onChange={() => updateCheckbox(!checkbox)}
                                  checked={checkbox}/>
                    </div>
                </div>
            </div>
            <br/>
            <div className={'past-due-downloads-div'}>
                <button className="hollow button secondary past-due-downloads"
                        onClick={() => formatDataForDownload(displayData)}>
                    {'Download CSV'}
                </button>
            </div>
            {displayData && <ReactTable
                filterable={true}
                data={displayData}
                columns={PAST_DUE_COLUMNS}
                initialState={{
                    columnFilters: [{id: 'Active', value: 'true'}],
                    sorting: [{id: 'daysPastDue', desc: true}]
                }}
                getTrProps={(rowInfo) => {
                    let className = '';
                    if (rowInfo?.original.daysPastDue >= 28) {
                        className = 'left-gutter-alert'
                    } else if (rowInfo?.original.daysPastDue > 7) {
                        className = 'left-gutter-warning'
                    }
                    return {className};
                }}
                defaultFilterMethod={filterCaseInsensitiveReactTable}
            />
            }
        </>
    )
}
PastDueContent.propTypes = {
    data: PropTypes.shape({
        pastDue: PropTypes.arrayOf(PropTypes.object)
    })
};


export default PastDueContent;