import React from 'react'
import Page from "../Components/Page";
import ApiGetContainer from "../../Api/ApiGetContainer";
import PastDueContent from "./PastDueContent";

export const pastDueQuery = `{
    pastDue{
       retailerId
       retailChain
       rollUpDayOfWeek
       maxSalesEndDate
       daysPastDue
       salesforceRetailer { active accountId companyType accountManager retailerAssociation }
    }
 }`

const PastDuePage =() => {
    return  <Page name={'Past Due'}>
        <ApiGetContainer graphqlQuery={pastDueQuery}
                         componentToRender={PastDueContent}
        />
        </Page>;
}

export default PastDuePage;