import React from 'react';
import PropTypes from 'prop-types';
import FtpConfigs from '../../Configurations/FtpConfigs';
import {CONFIG_TYPES, ConfigType} from '../../Configurations/FileTypes';
import _ from "lodash";

const FileTypeConfig = (props) => {
    const fileType = props.row.original.fileType;
    const configType = Object.values(CONFIG_TYPES)
        .filter(config => config.getName() === fileType);

    const configTypeClass = _.isEmpty(configType) ? (fileType === 'ignore' ? new ConfigType('ignore') : CONFIG_TYPES.SALES) : configType[0]

    return (<div>
        {
            props.row.original.ftp
            && <FtpConfigs
                ftp={props.row.original.ftp}
                buttonTitle={configTypeClass.getName()}
                configType={configTypeClass}
            />
        }
    </div>);
};

FileTypeConfig.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            fileType: PropTypes.string,
            ftp: PropTypes.string
        })
    })
};

export default FileTypeConfig;