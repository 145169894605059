import gql from "graphql-tag";

export const periodAbbreviationQuery = gql`
            query periods($periodAbbreviation: String){
                records:periods(periodAbbreviation: $periodAbbreviation){
                    weekId
                    endDate
                    quadId
                    periodAbbreviation
                }
            }`;