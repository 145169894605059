import React from 'react'
import TransactionalRetailers from "../../Configurations/TransactionalRetailers";
import ImpliedDecimalRetailers from "../../Configurations/ImpliedDecimalRetailers";
import CalculatedSalesAmountRetailers from "../../Configurations/CalculatedSalesAmountRetailers";
import TransactionalMultiFile from "../../Configurations/TransactionalMultiFile";
import SkipFillRetailers from "../../Configurations/SkipFillRetailers";
import CheckDigitRetailers from "../../Configurations/CheckDigitRetailers";
import SkipStores from "../../Configurations/SkipStores";
import AsinRetailers from "../../Configurations/AsinRetailers";
import ItemsExtract from "../../Configurations/ItemsExtract";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export const ConfigurationsTab = ({retailerId}) => {
    return (
        <Tabs>
            <TabList>
                <Tab data-testid={"transactionalRetailers-tab-name"}>Transactional</Tab>
                <Tab data-testid={"impliedDecimalRetailers-tab-name"}>Implied Decimal</Tab>
                <Tab data-testid={"calculatedSalesAmountRetailers-tab-name"}>Calculated Sales Amount</Tab>
                <Tab data-testid={"transactionalMultiFile-tab-name"}>Transactional Multi File</Tab>
                <Tab data-testid={"skipFillRetailers-tab-name"}>Skip Fill</Tab>
                <Tab data-testid={"skipStores-tab-name"}>Skip Stores</Tab>
                <Tab data-testid={"checkDigitRetailers-tab-name"}>Check Digit</Tab>
                <Tab data-testid={"asinRetailers-tab-name"}>ASIN</Tab>
                <Tab data-testid={"itemsExtract-tab-name"}>Items Extract</Tab>
            </TabList>
            <TabPanel data-testid={"transactionalRetailers-tab-panel"}><TransactionalRetailers displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"impliedDecimalRetailers-tab-panel"}><ImpliedDecimalRetailers displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"calculatedSalesAmountRetailers-tab-panel"}><CalculatedSalesAmountRetailers displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"transactionalMultiFile-tab-panel"}><TransactionalMultiFile displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"skipFillRetailers-tab-panel"}><SkipFillRetailers displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"skipStores-tab-panel"}><SkipStores displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"checkDigitRetailers-tab-panel"}><CheckDigitRetailers displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"asinRetailers-tab-panel"}><AsinRetailers displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
            <TabPanel data-testid={"itemsExtract-tab-panel"}><ItemsExtract displayHelmetHeader={false} retailerId={retailerId}/></TabPanel>
        </Tabs>
    )
}