import Page from '../Components/Page';
import React from 'react';
import {makeApiCallPost} from '../../Api/makeApiCall';
import {toast} from 'react-toastify';
import {toastError, toastSuccess} from '../../utils/toast';
import {Label} from 'react-foundation';
import Files from 'react-files';
import {getUserEmail} from '../../auth/accessTokenValidator';
import {invokeDAGviaApiPost} from '../../Api/invokeDag';
import ButtonLoader from "../../utils/components/ButtonLoader";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import _ from "lodash";

export const PromoTriggerDag = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/promoByRetailerConfig/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed, onDeleteAllConfirmed, refreshData} = useServiceBase({endpointUrl});

    function onSubmitRetailerButtonClicked() {
        const dagData = {
            retailers: data.data.map(retailer => ({retailer_id: retailer.retailerId})),
            triggered_by: getUserEmail()
        }
        invokeDAGviaApiPost('promo_by_retailer_json', dagData)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `promo_by_retailer_json DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `promo_by_retailer_json DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `promo_by_retailer_json DAG trigger failed with error ${JSON.stringify(e)}`)
            })
    }

    function onSubmitMissingButtonClicked() {
        const dagData = {
            triggered_by: getUserEmail(),
            ignore_paused_retailers: "true"
        }
        invokeDAGviaApiPost('promo_missing_promo', dagData)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `promo_missing DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `promo_missing DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `promo_missing DAG trigger failed with error ${JSON.stringify(e)}`)
            })
    }

    function uploadCsvPostCall(fileContents) {
        const data = {data: fileContents.replace(/\s/g, '')};
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/extractsUpdate/promoByRetailerConfig/`;
        return makeApiCallPost(url, JSON.stringify(data));
    }

    function onFilesChange(files) {
        const fileReader = new FileReader();
        const file = files[0];
        fileReader.onload = (loadedFiles) => {
            return uploadCsvPostCall(loadedFiles.target.result)
                .then(() => refreshData());
        };
        fileReader.readAsText(file);
    }

    function isSubmitButtonDisabled() {
        return !data || _.isEmpty(data)|| data.data.length === 0;
    }

    return (
        <Page name={'Promo'}>
            <div className={'trigger-dag-page-controls-left'}>
                <div className={'left-things'}>
                    <br/>
                    <div className={'button-row'}>
                        <div className={'dag-trigger-group-row'}>
                            <div className={'promo-trigger-dag-text-group'}>
                                <h4>Submit Listed Retailers</h4>
                                <h6>Only retailers in "Listed Retailers" below will be submitted. Data for each retailer will be generated back to 2018.</h6>
                            </div>
                            <ButtonLoader type="button"
                                          buttonHasModal
                                          modalHeader={`Are you sure?`}
                                          modalText={`Submit Listed Retailers`}
                                          disabled={isSubmitButtonDisabled()}
                                          buttonClassName={"button promo-trigger-button submit-retailers primary"}
                                          onSubmitCallback={onSubmitRetailerButtonClicked}
                                          className={"submit-retailer"}
                                          buttonTitle={"Submit Listed Retailers"}
                            />
                        </div>

                        <div className={'dag-trigger-group-row'}>
                            <div className={'promo-trigger-dag-text-group'}>
                                <h4>Submit Updated and Missing Promo Retailers</h4>
                                <h6>Only retailers and dates which are missing data or have been updated will be submitted. <b>PREFERRED</b></h6>
                            </div>
                            <ButtonLoader type="button"
                                          buttonHasModal
                                          modalHeader={`Are you sure?`}
                                          modalText={`Submit Updated and Missing Promo Retailers`}
                                          buttonClassName="promo-trigger-button button primary submit-subscription"
                                          onSubmitCallback={onSubmitMissingButtonClicked}
                                          className={"submit-missing"}
                                          buttonTitle={"Submit Updated and Missing Promo Retailers"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'trigger-dag-page-controls-right'}>
                <div className={'right-things'}>
                    <Label className={"csv-label"} title={"Only Retailer Ids"}>
                        <Files
                            className='hollow button secondary files-dropzone'
                            onChange={onFilesChange}
                            onError={(error) => {
                                console.error('error code ' + error.code + ': ' + error.message)
                            }}
                            accepts={['.csv']}
                            multiple
                            maxFiles={1}
                            maxFileSize={10000000}
                            clickable
                        >
                            Upload CSV for Listed Retailers
                        </Files>
                    </Label>
                </div>
            </div>
            <Page name={"Listed Retailers"} >
                <LoadingWrapper loading={loading}>
                    <EditableReactTableV2
                        filterable
                        data={data.data}
                        columns={[RETAILER_ID_NAME_COLUMN]}
                        actionButtonsProps={{showDeleteAllButton: true}}
                        initialState={{ pagination: { pageSize: 25 }, sorting: [{ id: "retailerIdName", desc: false }] }}
                        editTable={[INDEX_FIELD, RETAILER_ID_FIELD(serviceRetailerDropdown().data)]}
                        onRowEditConfirmed={onRowEditConfirmed}
                        onRowDeleteConfirmed={onRowDeleteConfirmed}
                        onDeleteAllConfirmed={onDeleteAllConfirmed}
                    />
                </LoadingWrapper>
            </Page>
        </Page>
    );
}

export default PromoTriggerDag;