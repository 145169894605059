import React from 'react';
import * as PropTypes from "prop-types";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {Breadcrumbs} from "../Components/Breadcrumbs";

const IgnoredStoreService = (props) => {

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ignoreUnmappedStores/${props.retailerId ? `?retailerId=${props.retailerId}` : ''}`
    const endpointUrlForPost = `${process.env.REACT_APP_EDP_API_BASE_URL}/ignoreUnmappedStores/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl, endpointUrlForPost});

    const customColumns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "External Store Id", accessorKey: "externalStoreId"}
    ];

    return (<Page name={"Ignored Stores"} displayHelmetHeader={props.displayHelmetHeader}
                  breadCrumbs={<Breadcrumbs items={[{label: "Unmapped"}, {label: "Ignored Stores"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={customColumns}
                actionButtonsProps={{showAddButton: false}}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'externalStoreId', Component: args => <EditFormTextInputField {...args} isRequired/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
};


IgnoredStoreService.propTypes = {
    retailerId: PropTypes.number,
    displayHelmetHeader: PropTypes.bool.isRequired
};

export default IgnoredStoreService;