import React, {useState} from "react";
import Page from "../../Components/Page";
import PropTypes from 'prop-types';
import ApiGetContainer from "../../../Api/ApiGetContainer";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import _ from "lodash"
import CompositionContent from "../CompositionContent";
import SelectDropdown from "../../Components/Dropdowns/SelectDropdown";
import { sortAscendingWithNullsLast } from "../../../utils/sorting";

export const compositionTabColumns = [
    {
        id: '1',
        header: 'Department',
        accessorKey: 'department'
    },
    {
        id: '2',
        header: 'Percentage',
        accessorKey: 'salesAmountPercentage',
        cell: props => <div className='record-count-cell-element ops-alert-element'>{_.round(props.getValue(), 2)}</div>,
        sortingFn: (rowA, rowB) => {
            const salesAmountPercentageA = rowA?.original?.salesAmountPercentage;
            const salesAmountPercentageB = rowB?.original?.salesAmountPercentage;
            return sortAscendingWithNullsLast(salesAmountPercentageA, salesAmountPercentageB);
        }
    }
]


export const CompositionTab = ({retailerId, companyType, marketDesignation}) => {
    const [weekEndDate, updateWeekEndDate] = useState(undefined);
    const [allWeekEndDates, updateAllWeekEndDates] = useState(undefined);

    React.useEffect(() => {
        const weekEndDatesQuery = `query {
             salesIngestionSummary(retailerId: ${retailerId} includePlu: false productSnapshotOnly: false){
                    weekEndDates
                    }
            }`
        makeApolloClientCall(weekEndDatesQuery, false).then(
            result => {
                const weekEndDatesOptions = result.salesIngestionSummary.weekEndDates.map(weekEndDate => {
                    return {
                        value: weekEndDate,
                        label: weekEndDate
                    }
                });
                updateAllWeekEndDates(weekEndDatesOptions)
            }
        )
    }, [retailerId]);

    const query = `
        query {
            data: compositionReport(retailerId: ${retailerId} salesEndDate: "${weekEndDate}") {
                retailerTotals {
                    salesAmountTotal
                    salesAmountPercentage
                    department
                }
                stores {
                  store {
                      storeId
                      storeName
                      state
                      country
                  }
                  totalSales
                  salesAcv
                  departmentsWithSales
                  naturalAndSpecialtySalesPercentage
                  naturalSalesPercentage
                  vitaminsAndSupplementsDepartmentsSalesPercentage
                }
                retailer {
                  retailerId
                  totalSales
                  averageSalesAcv
                  averageDepartmentsWithSales
                  naturalAndSpecialtySalesPercentage
                  naturalSalesPercentage
                  vitaminsAndSupplementsDepartmentsSalesPercentage
                  totalDollarsCodeableItems
                  percentDollarsCodeableItems
                }
          }
    }
    `

    return <Page displayHelmetHeader={false} name={'Composition'}>
        <div className={"week-end-date-dropdown"}>
            <SelectDropdown
                id={"week-end-date-dropdown"}
                inputLabel={"Week End Date"}
                selectValue={weekEndDate}
                placeholder={'Select Week End Date...'}
                options={allWeekEndDates}
                onChange={(option) => updateWeekEndDate(option ? option.value : undefined)}
            />
        </div>
        {weekEndDate && <div><ApiGetContainer
            graphqlQuery={query}
            columns={compositionTabColumns}
            dataAccessor={'data'}
            componentToRender={CompositionContent}
            componentToRenderProps={{retailerId, weekEndDate, companyType, marketDesignation}}
        /></div>}
    </Page>
}

CompositionTab.propTypes = {
    retailerId: PropTypes.number.isRequired,
    companyType: PropTypes.string,
    marketDesignation: PropTypes.string
}