import React from "react";
import {getIntParamOrDefault} from "../../../utils/urlBuilder";
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import Page from "../../Components/Page";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD,} from "../../Components/ReactTable/common_custom_columns";
import RetailerIdAndName from "../../Components/RetailerIdAndName";
import {getRetailerName} from "../../../utils/retailerMapCache";
import {EditFormDateInputField, EditFormNumberInputField} from "../../Components/ReactTable/EditRecordForm";
import {setValuesToNullIfEmptyOrUndefined} from "../../Components/ReactTable/helpers";
import {sortAscendingWithNullsFirst} from "../../../utils/sorting";

function StoreClosures() {
    const storeId = getIntParamOrDefault('storeId', undefined);

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeClosures/?add_retailer_column=true`
    const endpointUrlForPost = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeClosures/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl, endpointUrlForPost});
    const columns = [
        {
            header: 'Retailer',
            id: 'retailerIdName',
            filterable: true,
            accessorKey: 'retailerId',
            size: 200,
            cell: ({row}) => <RetailerIdAndName retailerId={row.original?.Retailer?.retailerId}/>,
            filterFn: (row, columnIds, filterValue) => {
                const retailerId = row.original?.Retailer?.retailerId;
                return !filterValue || Boolean(`${retailerId} ${getRetailerName(retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()));
            },
            sortingFn: (a, b) => {
                const valA = a.original?.Retailer?.retailerId;
                const valB = b.original?.Retailer?.retailerId;
                return sortAscendingWithNullsFirst(valA, valB);
            }
        },
        {header: "Store ID", accessorKey: "storeId"},
        {header: "Start Date", accessorKey: "startDate"},
        {header: "End Date", accessorKey: "endDate"}
    ];

    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        delete sanitizedData.Retailer
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Store Closures"}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data?.filter(record => storeId ? record.storeId === storeId : true)}
                columns={columns}
                actionButtonsProps={{showAddButton: storeId === undefined}}
                editTable={[
                    INDEX_FIELD,
                    {id: 'storeId', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                    {id: 'startDate', Component: args => <EditFormDateInputField {...args} isRequired/>},
                    {id: 'endDate', Component: args => <EditFormDateInputField {...args} />},
                ]}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default StoreClosures;