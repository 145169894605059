import ButtonLoader from "../../utils/components/ButtonLoader";
import {makeApiCallPost} from "../../Api/makeApiCall";
import {getUserEmail} from "../../auth/accessTokenValidator";
import React, {useState} from "react";
import PropTypes from "prop-types";

export const FillAllForRetailer = ({retailerId, active, skipFillRetailerIds, fillAllDates, fillIncludePlu}) => {
    const [fillEndDate, setFillEndDate] = useState('');

    const shouldNotShow = skipFillRetailerIds.includes(retailerId);

    return shouldNotShow ? <></> : <>
        {
            active && <input
                data-testid={'fill-end-date-input'}
                className="fill-end-date"
                type="date"
                onChange={(event => setFillEndDate(event.target.value))}/>
        }
        <ButtonLoader
            buttonTitle={"Fill All"}
            url={`${process.env.REACT_APP_EDP_API_BASE_URL}/runFillAll`}
            buttonHasModal
            modalHeader={`Fill All: Retailer ${retailerId}`}
            modalText={`Are you sure you want to fill all for retailer ${retailerId}?`}
            axiosFunction={makeApiCallPost}
            postBody={{
                retailer_id: retailerId,
                fill_end_date: fillEndDate,
                triggered_by: getUserEmail(),
                fill_all_dates: fillAllDates,
                include_plu: fillIncludePlu
            }}
            toastTextFailure={`Fill All for Retailer [${retailerId}], Fill End Date [${fillEndDate}] and Fill All Dates [${fillAllDates}] has failed to kicked off.`}
            toastTextSuccess={`Fill All for Retailer [${retailerId}], Fill End Date [${fillEndDate}] and Fill All Dates [${fillAllDates}] has successfully kicked off.`}
        />
    </>;
};

FillAllForRetailer.propTypes = {
    retailerId: PropTypes.number.isRequired,
    active: PropTypes.bool,
    skipFillRetailerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fillAllDates: PropTypes.bool,
    fillIncludePlu: PropTypes.bool,
};