export const ingestionLogsTrProps = (salesforceRetailers) => (rowInfo) => {
    let className = '';
    if (rowInfo?.original) {
        if (rowInfo.original.isComplete) {
            if (rowInfo.original.hasFailures) {
                className = 'left-dotted-border'
            } else {
                className = 'left-gutter-success'
            }
        } else if (rowInfo.original.hasFailures) {
            className = 'left-gutter-alert'
        }
    }
    return {className};
};
