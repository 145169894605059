import _ from "lodash";

export const checkErrors = (columns) => {
    const hasGroups = !!columns.find((column) => column.parent);
    const stickyColumnsWithoutGroup = columns.filter((column) => column.sticky && !column.parent).map(({ header }) => `'${header}'`);

    if (hasGroups && stickyColumnsWithoutGroup.length) {
        throw new Error(`WARNING react-table-sticky:
      \nYour ReactTable has group and sticky columns outside groups, and that will break UI.
      \nYou must place ${stickyColumnsWithoutGroup.join(' and ')} columns into a group (even a group with an empty Header label)\n`);
    }

    const bugWithUnderColumnsSticky = columns.find((parentCol) => !parentCol.sticky && parentCol.columns && parentCol.columns.find((col) => col.sticky));

    if (!bugWithUnderColumnsSticky) return;

    // @ts-ignore
    const childBugs = bugWithUnderColumnsSticky.columns.find(({ sticky }) => sticky);

    if (!childBugs) return;

    throw new Error(`WARNING react-table-sticky:
    \nYour ReactTable contain columns group with at least one child columns sticky.
    \nWhen ReactTable has columns groups, only columns groups can be sticky
    \nYou must set sticky: 'left' | 'right' for the '${bugWithUnderColumnsSticky.Header}'
    column, or remove the sticky property of '${childBugs.Header}' column.`);
};

export function getStickyValue(column) {
    if (column.sticky === 'left' || column.sticky === 'right') {
        return column.sticky;
    }

    if (column.parent) {
        return getStickyValue(column.parent);
    }

    return null;
}


const cellStylesSticky = {
    // hard coded inline style will be removed in the next major release
    position: 'sticky',
    zIndex: 3,
    left: '0px',
    background: '#f4f6f8'
};

function findHeadersSameLevel(header, headers) {
    if (_.isEmpty(header.columns)) {
        const headerFromRt = headers.find(x => x.id === header.id);
        return headers.filter((flatHeaderItem) => {
            return flatHeaderItem.depth === headerFromRt.depth && headerFromRt.index > flatHeaderItem.index;
        });
    }
    return [];
}

export function getStickyProps(header, instance) {
    let style = {};
    const dataAttrs = {};

    checkErrors(instance.getAllColumns());

    const sticky = getStickyValue(header.columnDef);

    if (sticky) {
        style = {
            ...cellStylesSticky,
        };

        // @ts-ignore
        dataAttrs['data-sticky-td'] = true;

        const headers = findHeadersSameLevel(header, instance.getFlatHeaders());

        const margin = headers.map(x => x.getSize()).reduce(_.add, 0);
        style = {
            ...style,
            [sticky]: `${margin || 0}px`,
        };
    }

    return {
        style,
        data: {...dataAttrs}
    };
}