const cssObject = [
    {factorFrom: 4, cssClass: "outlier-severity-4"},
    {factorFrom: 3, factorTo: 4, cssClass: "outlier-severity-3"},
    {factorFrom: 2, factorTo: 3, cssClass: "outlier-severity-2"},
    {factorFrom: 1, factorTo: 2, cssClass: "outlier-severity-1"}
]

export function getStyleForOutlierFunction(dataSelection, percentThreshold) {
    return (possiblyFoundOutlier) => {
        if (possiblyFoundOutlier) {
            const outlierToCheck = dataSelection.outlierFunction(possiblyFoundOutlier);
            for (let i = 0; i < cssObject.length; i++) {
                if (outlierToCheck >= cssObject[i].factorFrom * percentThreshold
                    && (outlierToCheck < cssObject[i].factorTo * percentThreshold
                        || cssObject[i].factorTo === undefined)
                ) {
                    return cssObject[i].cssClass
                }
            }
            return 'not-outlier';
        }
        return undefined;
    };
}