import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import ReactTable from "../../Components/ReactTable";
import {getAuditColumns} from "../../Retailer/AuditHistory";
import FileLink from "../../IngestionLogs/Columns/FileLink";
import {sortAscendingWithNullsFirst} from "../../../utils/sorting";

const PeriodReleaseAuditChangesTable = ({customColumns, data, gcsFolderPath, headerValue}) => {
    const getConfigFileColumn = gcsFolderPath => ({
        header: 'Audit File',
        accessorKey: 'configName',
        cell: ({row}) => <FileLink displayText={row.original.configName}
                                   gcsFileLocation={`${process.env.REACT_APP_GCS_SERVICES_BASE_URL}${gcsFolderPath}/audit/${row.original.configName}`}/>,
        filterFn: (row, columnId, filterValue) => !filterValue || Boolean(`${row.original.configName?.toLowerCase()}`.includes(filterValue.toLowerCase())),
        sortingFn: (a, b, columnId) => {
            const valA = a.getValue(columnId);
            const valB = b.getValue(columnId);
            if (valA && valB) {
                return valA.localeCompare(valB);
            }
            return sortAscendingWithNullsFirst(valA, valB);
        }
    });

    return (<div>
        <h4>{headerValue}</h4>
        {!_.isEmpty(data) ? <ReactTable
            filterable={true}
            data={data}
            columns={getAuditColumns(undefined, [getConfigFileColumn(gcsFolderPath), ...customColumns])}
            initialState={{
                pagination: {pageSize: 10},
                sorting: [{id: 'configName', desc: true}, {id: '5', desc: true}]
            }}
        /> : <span>{'No Data Found'}</span>}
    </div>);
};


PeriodReleaseAuditChangesTable.propTypes = {
    customColumns: PropTypes.any.isRequired,
    data: PropTypes.object.isRequired,
    gcsFolderPath: PropTypes.string.isRequired,
    headerValue: PropTypes.string.isRequired,
};

export default PeriodReleaseAuditChangesTable;