import React, {Component} from 'react';
import Page from '../Components/Page';
import PeriodsDropdown from '../Components/Dropdowns/PeriodsDropdown';
import {makeApolloClientCall} from '../../Api/makeApolloApiCall';
import LoadingSpinner from "../Components/LoadingSpinner";
import {gql} from "@apollo/client";
import {makeApiCall} from "../../Api/makeApiCall";
import {ReadinessApiError} from "../Components/ReadinessApiError";
import ReadinessContent from "../Components/ReadinessContent";

class SldReadiness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            periodSelection: undefined,
            priorityRetailers: undefined,
            data: undefined,
            loading: false,
            error: undefined,
            alertData: [],
            alertStartDate: undefined,
            alertEndDate: undefined
        }
    }

    updatePeriodCallback = (value) => {
        this.setState({periodSelection: value});
    }

    getDefaultPeriod = () => {
        const graphqlQuery = `query {
                getNextPeriodIfExists {
                    periodAbbreviation
                 }
            }`

        makeApolloClientCall(graphqlQuery)
            .then((results) => {
                this.setState({periodSelection: results.getNextPeriodIfExists.periodAbbreviation})
            });
    }

    getPriorityRetailers = () => {
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/priority_retailers`;
        makeApiCall(url)
            .then((r) => this.setState({priorityRetailers: r.data.map(i => i.retailerId)}))
    }

    componentDidMount() {
        this.getDefaultPeriod()
        this.getPriorityRetailers()
    }

    handleSubmit() {
        this.setState({loading: true, data: undefined})
        this.getAlerts()
        makeApolloClientCall(this.getQuery())
            .then((result) => {
                this.setState({loading: false, data: result})
            })
            .catch((e) => {
                this.setState({loading: false, error: e})
            })
    }

    getAlerts() {
        const {periodSelection} = this.state;
        const query = gql`query periods($periodAbbreviation: String!) {
            periods(periodAbbreviation: $periodAbbreviation, periodEndWeeksOnly:true){
                endDate
                getPriorPeriod(periodsPrior:1, periodType:QUAD) {
                  endDate
                }
            }
        }`;

        makeApolloClientCall(query, false, {periodAbbreviation: periodSelection})
            .then(periodData => {
                const thisPeriod = periodData.periods[0];
                const startDate = thisPeriod.getPriorPeriod.endDate;
                const endDate = thisPeriod.endDate;
                this.setState({alertStartDate: startDate, alertEndDate: endDate, alertIsLoading: true});
                return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/opsAlertLogsByDate/`)
            })
            .then(alertData => {
                this.setState({alertData: alertData.metadata, alertIsLoading: false})
            })
    }

    getQuery() {
        const {periodSelection, priorityRetailers} = this.state;
        const period = periodSelection ? `periodAbbreviation:"${periodSelection}"` : '';
        return `
query {
  periodReleaseReadiness(${period}, retailerIds: [${priorityRetailers.join(',')}]) {
    periodAbbreviation
    periodWeeks {endDate}
    accounts {
      retailer {retailerId retailChain}
      isKeyAccount
      isNonMarketRetailer
      isRetailerActive
      issues {issueType week {endDate} count message}
      weeksWithSales {weeksWithData totalExpectedWeeks}
    }
  }
}
`;
    }

    render() {
        const {
            periodSelection,
            activeRetailersOnly,
            priorityRetailers,
            data,
            loading,
            error,
            alertData,
            alertStartDate,
            alertEndDate,
            alertIsLoading
        } = this.state;

        return (

            <Page name={'Store Insights Readiness'}>
                <div className={'readiness-options'}>
                    <PeriodsDropdown
                        id={"periods"}
                        updatePeriod={this.updatePeriodCallback}
                        selectedPeriod={periodSelection}
                        graphqlQuery={'{ marketPeriodList }'}
                    />
                    <div>
                        <button disabled={priorityRetailers === undefined} className={'button primary left-margin'} onClick={() => this.handleSubmit()}>Submit
                        </button>
                    </div>
                </div>
                {loading && <LoadingSpinner/>}
                {data &&
                <ReadinessContent data={data} activeRetailersOnly={activeRetailersOnly}
                                  alertData={alertData}
                                  alertIsLoading={alertIsLoading}
                                  alertStartDate={alertStartDate} alertEndDate={alertEndDate}/>}
                {error && <ReadinessApiError errorMessage={JSON.stringify(error)}/>}
            </Page>);
    }
}

export default SldReadiness;