import {ManifestDropdown} from "../IngestionLogs/Columns/manifestDropdown";
import React from "react";


export const getManifestTableColumns = (ftp, onSubmitCallback, data) => ([
    {
        header: 'File Name',
        accessorKey: 'ingestFileName',
        className: 'non-numeric-field'
    },
    {
        header: 'Manifest',
        cell: ({row}) => <ManifestDropdown
            ftp={ftp}
            configName={row.original.configFileName}
            manifestFileName={ftp}
            ingestFileName={row.original.ingestFileName}
            onSubmitCallback={onSubmitCallback}
            data={data}
        />,
        className: 'non-numeric-field table-dropdown',
        maxSize: 500
    }
]);