import RunIdLink from "../../IngestionLogs/Columns/RunIdLink";
import {DAG_NAMES} from "../../Configurations/DagNames";
import React from "react";
import _ from "lodash";

export function formatRunIdentifierForThisPeriod(runIdOfRunForThisPeriod, airflowUrl, isCurrentAirflowEnvironment) {
    if (!_.isEmpty(runIdOfRunForThisPeriod)) {
        if (runIdOfRunForThisPeriod.length > 1) {
            return <div>
                You have reached a scenario that should never happen: multiple runs are assigned to a single outlet.
                <a href={"/marketInsights/release"}>Consider fixing this here.</a>
            </div>
        }
        if (isCurrentAirflowEnvironment) {
            return <RunIdLink value={runIdOfRunForThisPeriod[0].runId}
                              getDisplayText={() => runIdOfRunForThisPeriod[0].runIdentifier}
                              original={{process: DAG_NAMES.GENERATE_MEASURES}}
                              airflowUrl={airflowUrl}
            />;
        }
        return <div>{runIdOfRunForThisPeriod[0].runIdentifier} (Link no longer valid)</div>
    } else {
        return <a href={"/marketInsights/release"}>Please associate a run with this outlet and period.</a>;
    }
}
