import React from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import IconImage, {Icons} from "../../Components/IconImage";

const DesignationBulkAssignStoresModal = ({isOpen, onClose, children}) => {
    return <Modal
        clickable
        isOpen={isOpen}
        className={"designation-bulk-assign-stores-modal"}
        overlayClassName="Overlay"
        ariaHideApp={false}
        onRequestClose={onClose}
    >
        <div data-testid={'designation-bulk-assign-stores-modal'}>
            <div className={'modal-header'}>
                <h1>Bulk Assign Stores by File Import</h1>
                <button type={'button'} aria-label={'close'} onClick={onClose}>
                    <IconImage iconType={Icons.CLOSE}/>
                </button>
            </div>
            <div className={'modal-body'}>{children}</div>
        </div>




    </Modal>

};

DesignationBulkAssignStoresModal.defaultProps = {
    onClose: () => {}
};

DesignationBulkAssignStoresModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func
};

export default DesignationBulkAssignStoresModal;