import {toast} from "react-toastify";
import _ from "lodash";
import {ContextMenu, MenuItem} from "react-contextmenu";
import React from "react";
import {toastError, toastSuccess} from "../../../../utils/toast";
import {makeApiCallDeleteWithData, makeApiCallPost} from "../../../../Api/makeApiCall";
import {getUserEmail} from "../../../../auth/accessTokenValidator";


export const ConfigUpdateContextMenu = ({id, trigger}) => {

    const updateConfig = async (configEndpoint, trigger, isRemove) => {
        const salesEndDate = trigger.date
        const storeIds = trigger.storeIds
        const retailerId = Number(trigger.retailerId)
        const user = getUserEmail()

        const apiUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeSalesEndDateConfig/${configEndpoint}`
        const apiData = {retailerId, storeIds, salesEndDate, user}
        const apiReturn = isRemove ?
            (await makeApiCallDeleteWithData(apiUrl, [apiData])) :
            (await makeApiCallPost(apiUrl, apiData))

        const addOrRemoveText = isRemove ? "removed" : "added"
        if (!apiReturn?.apiError) {
            toastSuccess(toast, `Successfully ${addOrRemoveText} salesEndDate ${salesEndDate} for ${storeIds.length} storeId(s) to ${configEndpoint}`)
            trigger.setTimestamp(Date.now())
        } else {
            toastError(toast, `ERROR: salesEndDate ${salesEndDate} for ${storeIds.length} storeId(s) to ${configEndpoint} was not ${addOrRemoveText}`)
        }
    }
    const updateStoreClosureConfig = async (configEndpoint, trigger, isRemove) => {
        const storeId = trigger.storeIds[0]
        if (isRemove) {
            window.open(`/storeClosures?storeId=${storeId}`, '_blank')
            return
        }
        const retailerId = Number(trigger.retailerId)
        const apiUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeClosures?retailerId=${retailerId}&single_row_action=update`
        const cellDate = trigger.date
        const apiData = {startDate: cellDate, storeId: storeId, endDate: null, index: null}
        const postReturn = await makeApiCallPost(apiUrl, apiData)
        if (!postReturn?.apiError) {
            toastSuccess(toast, `Successfully added store closure information for ${storeId} to ${configEndpoint}`);
            trigger.setTimestamp(Date.now())
        } else {
            toastError(toast, `Error with adding store closure information for ${storeId} to ${configEndpoint}`);
        }
    }

    const menuItemsTableBody = [
        {config: 'storeStart', displayName: 'Measures Start', endpointFunction: updateConfig},
        {config: 'storeStop', displayName: 'Measures Stop', endpointFunction: updateConfig},
        {config: 'alwaysFill', displayName: 'Always Fill', endpointFunction: updateConfig},
        {config: 'doNotFill', displayName: 'Do Not Fill', endpointFunction: updateConfig},
    ];

    const menuHeaderItems = [
        {
            config: 'storeStart',
            displayName: <span>Measures Start <b>ON</b></span>,
            isRemove: false,
            endpointFunction: updateConfig
        },
        {
            config: 'storeStart',
            displayName: <span>Measures Start <b>OFF</b></span>,
            isRemove: true,
            endpointFunction: updateConfig
        },
        {
            config: 'storeStop',
            displayName: <span>Measures Stop <b>ON</b></span>,
            isRemove: false,
            endpointFunction: updateConfig
        },
        {
            config: 'storeStop',
            displayName: <span>Measures Stop <b>OFF</b></span>,
            isRemove: true,
            endpointFunction: updateConfig
        },
        {
            config: 'alwaysFill',
            displayName: <span>Always Fill <b>ON</b></span>,
            isRemove: false,
            endpointFunction: updateConfig
        },
        {
            config: 'alwaysFill',
            displayName: <span>Always Fill <b>OFF</b></span>,
            isRemove: true,
            endpointFunction: updateConfig
        },
        {
            config: 'doNotFill',
            displayName: <span>Do Not Fill <b>ON</b></span>,
            isRemove: false,
            endpointFunction: updateConfig
        },
        {
            config: 'doNotFill',
            displayName: <span>Do Not Fill <b>OFF</b></span>,
            isRemove: true,
            endpointFunction: updateConfig
        },
    ];

    function getMenuItems(menuItems) {
        if (!trigger) {
            return [];
        }

        if (!trigger.header) {
            const startDate = trigger.storeClosures?.some(x => !_.isNil(x.startDate)) || false;

            if (!startDate) {
                menuItems.push({
                    config: 'storeClosure',
                    displayName: 'Closure Start',
                    isRemove: false,
                    endpointFunction: updateStoreClosureConfig
                })
            } else if (startDate) {
                menuItems.push({
                    config: 'storeClosure',
                    displayName: 'Closure Stop',
                    isRemove: true,
                    endpointFunction: updateStoreClosureConfig
                })
            }
        }

        return menuItems.map(config => {
            const triggerHasDataForConfig = trigger[config.config];
            const isRemove = config.isRemove || triggerHasDataForConfig || false;
            const className = `${triggerHasDataForConfig ? '' : 'hollow '}${config.config}${trigger.header ? '-header' : ''}${isRemove ? '-remove' : '-add'}-menu-item button secondary`
            return <MenuItem
                key={config.displayName}
                className={className}
                onClick={() => config.endpointFunction(config.config, trigger, config.isRemove || isRemove)}>
                {config.displayName}
            </MenuItem>
        });
    }

    const isHeader = trigger && trigger.header === true;

    return (
        <ContextMenu className={'store-totals-context-menu'}
                     id={id}>{getMenuItems(isHeader ? menuHeaderItems : menuItemsTableBody)}</ContextMenu>
    );
};
