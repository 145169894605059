import React, {Component} from 'react';
import {getStringParamOrDefault} from "../../utils/urlBuilder";

import {SupplementalStoresTable} from "./SupplementalStoresTable";


class SupplementalStoreConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            retailerId: getStringParamOrDefault('retailerId', null)
        };

    }

    render() {
        return <SupplementalStoresTable
            retailerId={this.state.retailerId}
            displayHelmetHeader={true}
        />;
    }
}


export default SupplementalStoreConfig;
