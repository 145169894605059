import React from 'react';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import ReactTable from "./ReactTable";
import {getReadinessColumns} from "../RetailerReadiness/readinessConstants";

function getFilteredAccounts(data, activeRetailersOnly, alertData, alertStartDate, alertEndDate, isKeyAccount, isNonMarketRetailer) {
    const periodWeekEndDates = data.periodReleaseReadiness.periodWeeks.map(periodWeek => periodWeek.endDate);
    return _
        .filter(data.periodReleaseReadiness.accounts,
            account =>
                (!activeRetailersOnly || account.isRetailerActive) &&
                account.isKeyAccount === isKeyAccount &&
                account.isNonMarketRetailer === isNonMarketRetailer
        )
        .map(retailer => {
            return {
                ...retailer,
                periodAbbreviation: data.periodReleaseReadiness.periodAbbreviation,
                periodStartDate: _.min(periodWeekEndDates),
                periodEndDate: _.max(periodWeekEndDates),
                alertData: alertData.filter((ad) => ad.retailer_id === String(retailer.retailer.retailerId)),
                alertStartDate,
                alertEndDate
            }
        })
}

function getTable(accounts, maxPageSize, alertIsLoading) {


    return accounts.length > 0 ? <ReactTable
            filterable
            data={accounts}
            columns={getReadinessColumns(alertIsLoading)}
            initialState={{sorting: [{id: 'issues', desc: true}]}}
            getTrProps={(rowInfo) => {
                let className = ''
                if (rowInfo && rowInfo.original) {
                    if (!rowInfo.original.isRetailerActive) {
                        className = 'inactive-retailer-row'
                    }
                }
                return {
                    className
                };
            }}
        /> :
        <div className={'key-account-no-data-found'}>{'No Data Found'}</div>;
}

const ReadinessContent = ({data, maxPageSize, activeRetailersOnly, alertData, alertStartDate, alertEndDate, alertIsLoading}) => {
    const keyAccounts = getFilteredAccounts(data, activeRetailersOnly, alertData, alertStartDate, alertEndDate, true, false);
    const nonKeyAccounts = getFilteredAccounts(data, activeRetailersOnly, alertData, alertStartDate, alertEndDate, false, false);
    const nonMarketAccounts = getFilteredAccounts(data, activeRetailersOnly, alertData, alertStartDate, alertEndDate, false, true);

    return (<div className={'readiness-content'}>
        <div className={'ingest-cards'}>
            <div className={'card'}>
                <span className={'card-title'}>Retailer Data</span>
                <div className={'sub-cards'}>
                    <div className={'card-content'}>
                        <div className={'retailer-data key-account-retailer'}>
                            {keyAccounts.length}
                        </div>
                        <div className={'content-label'}>
                            Key Accounts
                        </div>
                    </div>
                    <div className={'card-content'}>
                        <div className={'retailer-data additional-retailer'}>
                            {nonKeyAccounts.length}
                        </div>
                        <div className={'content-label'}>
                            Non Key Account
                        </div>
                    </div>
                    <div className={'card-content'}>
                        <div className={'retailer-data other-retailer'}>
                            {nonMarketAccounts.length}
                        </div>
                        <div className={'content-label'}>
                            Other
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={'key-account-container'}>
            <div className={'key-account-retailer-contents'}>
                <div className={'key-account-header'}>{'Key Account Retailers'}</div>
                {getTable(keyAccounts, maxPageSize, alertIsLoading)}
            </div>
            <div className={'non-key-account-retailer-contents'}>
                <div className={'key-account-header'}>{'Non Key Account Retailers'}</div>
                {getTable(nonKeyAccounts, maxPageSize, alertIsLoading)}
            </div>
            <div className={'non-market-account-retailer-contents'}>
                <div className={'key-account-header'}>{'Other Retailers'}</div>
                {getTable(nonMarketAccounts, maxPageSize, alertIsLoading)}
            </div>
        </div>
    </div>);
};

ReadinessContent.defaultProps = {
    maxPageSize: 25
}

ReadinessContent.propTypes = {
    maxPageSize: PropTypes.number,
    activeRetailersOnly: PropTypes.bool,
    data: PropTypes.shape({
        periodReleaseReadiness: PropTypes.object
    }),
    alertData: PropTypes.array,
    alertStartDate: PropTypes.string,
    alertEndDate: PropTypes.string,
    alertIsLoading: PropTypes.bool
}

export default ReadinessContent;