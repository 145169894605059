import React, {Fragment} from 'react';
import {makeApiCall} from '../../../Api/makeApiCall';
import logo from '../../../assets/spins-logo.svg';
import TokenExpirationTimer from '../../../auth/components/TokenExpirationTimer';
import HeaderDropdown, {SEPARATOR} from './HeaderDropdown';
import TitleDropdown from '../TitleDropdown';
import NextPeriod from '../../../auth/components/NextPeriod';
import {getGreetingDisplay} from '../../../auth/accessTokenValidator';
import SearchBar from '../SearchBar';
import {forceLogout} from "../../../auth/url";
import {
    nestedMarketContent,
    nestedOutliersContent,
    nestedRetailerConfigurationsContent,
    nestedRetailerManagementContent,
    nestedStoreContent
} from "../../Management/nestedHeaderSubmenus";

export class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unmappedStores: undefined,
            unmappedRetailers: undefined
        };
    }

    static async getUnmappedCount(unmapped) {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/${unmapped}/`;
        const unmappedResult = await makeApiCall(endpointUrl);
        return unmappedResult?.metadata?.length || 0;
    }

    async componentDidMount() {
        this.setState({unmappedRetailers: await Header.getUnmappedCount('unmappedRetailers')});
    }

    render() {
        // noinspection SqlNoDataSourceInspection
        return (
            <Fragment>
                <div className={`App App-${process.env.REACT_APP_ENV}`}>
                    <div className={'title-bar-container'}>
                        <div className={'header-section'}>
                            <a href='/public'><img src={logo} alt={'SPINS logo'} className={"columns small-1 spins-logo"}
                                                   data-testid={'spins-logo'}/></a>
                            <a href='/public'>
                                <div className={'dashboard-title'}>Dashboard</div>
                            </a>
                        </div>
                        <div className={'header-section'}>
                            <SearchBar routes={this.props.routes}/>
                            <div className={'header-next-period'}>
                                <NextPeriod/>
                            </div>
                            <div className={'header-session-timeout'}>
                                <TokenExpirationTimer onTimerExpiration={forceLogout}
                                                      labelValue={'Session Expires In:'}
                                                      tokenAccessor={'access_token'}
                                                      sessionType={'Dashboard'}
                                />
                            </div>
                            <div className={'header-session-user'}>
                                {getGreetingDisplay()}
                            </div>
                            <TitleDropdown/>
                        </div>
                    </div>
                </div>
                <div className={'nav-bar'}>
                    <div className={"nav-title-bar"}>
                        <nav className={"nav z-to-the-top"} data-animate="hinge-in-from-top spin-out">
                            <ul className="dropdown menu columns small-12 header-dropdowns" data-dropdown-menu={true}>
                                <HeaderDropdown
                                    title={"Logs"}
                                    menuItems={[
                                        {name: "File Logs", url: "/ingestedLogs"},
                                        {name: "Processing Logs", url: "/processingLogs"},
                                        {name: "Items Summary", url: "/itemsSummary"}
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Alerts"}
                                    menuItems={[
                                        {name: "Summary", url: "/alerts/summary"},
                                        {name: "Outliers", url: "#", nestedContent: nestedOutliersContent},
                                        {name: "Past Due", url: "/alerts/pastDue"},
                                        {name: "Missing Weeks", url: "/alerts/missingWeeks"},
                                        {name: "Missing Days", url: "/alerts/missingDays"},
                                        {name: "Missing Data", url: "/alerts/missingData"}
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Retailer Readiness"}
                                    menuItems={[
                                        {name: "Retailer Totals", url: "/retailerReadiness/retailerTotals"},
                                        {name: "Store Totals", url: "/retailerReadiness/storeTotals"},
                                        {name: "Completeness", url: "/retailerReadiness/completeness"},
                                        {name: "What Will Fill", url: "/continuity/whatWillFill"},
                                        {name: "Generate: Promo", url: "/retailerReadiness/promo"},
                                        {
                                            name: "Fill All: Multi Retailer",
                                            url: "/retailerReadiness/fillAllMultipleRetailers"
                                        }
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Store Insights"}
                                    menuItems={[
                                        {
                                            name: "Readiness",
                                            url: "/storeInsights/readiness"
                                        },
                                        {
                                            name: "Generate",
                                            url: "/storeInsights/generate"
                                        }
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Market Insights"}
                                    menuItems={[
                                        {name: "Checklist", url: "/marketInsights/checklist"},
                                        {name: "Readiness", url: "/marketInsights/readiness"},
                                        SEPARATOR,
                                        {name: "Generate", url: "/marketInsights/generate"},
                                        {name: "Release", url: "/marketInsights/release"},
                                        SEPARATOR,
                                        {name: "Spotlight: Cleanse Fill", url: "/extractSpotlight"},
                                        {name: "Publish", url: "/scaffoldingPublish"},
                                        SEPARATOR,
                                        {name: "Extracts", url: "/nucleusExtractsTriggerDag"},
                                    ]}
                                />

                                <div className={'divider'}/>
                                <HeaderDropdown
                                    title={"Management"}
                                    menuItems={[
                                        {name: "Retailers", url: "#", nestedContent: nestedRetailerManagementContent},
                                        {name: "System", url: "/system"},
                                        {name: "Checkpoints", url: "/checkpoints"},
                                        {name: "Providers", url: "/providersService"},
                                        {name: "Custom Hierarchies", url: "/customHierarchyRetailers"}
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Configurations"}
                                    menuItems={[
                                        {name: "FTP", url: "/ftpConfigurations"},
                                        {name: "File Exclusion", url: "/fileExclusion"},
                                        {name: "Providers", url: "/providersService"},
                                        {name: "Retailer", url: "#", nestedContent: nestedRetailerConfigurationsContent},
                                        {name: "Store", url: "#", nestedContent: nestedStoreContent},
                                        {name: "Private Label Shown", url: "/privateLabelShown"},
                                        {name: "Alerts", url: '/alertsConfigurations'},
                                        {name: "Checkpoint Types", url: "/checkPointTypesService"},
                                    ]}
                                />
                                <div className={'divider'}/>
                                <HeaderDropdown
                                    title={"Unmapped"}
                                    menuItems={[
                                        {
                                            name: "Retailers",
                                            url: "/unmappedRetailers",
                                            additionalContent: this.state.unmappedRetailers > 0 ?
                                                <span data-testid={'unmapped-retailers'}
                                                      className="badge alert badge-retailers">{this.state.unmappedRetailers}</span> : <></>
                                        },
                                        {
                                            name: "Stores",
                                            url: "/unmappedStores",
                                        },
                                        {name: "Ignored FTPs", url: "/ignoredFTPsService"},
                                        {
                                            name: "Ignored Stores",
                                            url: "/ignoreUnmappedStoreService",
                                        }

                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Services"}
                                    menuItems={[
                                        {name: "Market", url: "#", nestedContent: nestedMarketContent},
                                        {name: "Products", url: "/productService"},
                                        {name: "Projections", url: "/projectionService"},
                                        {name: "Store", url: "/storeService"},
                                        {name: "Time", url: "/timeService"},
                                        {name: "ASIN", url: "/asinService"},
                                        {name: "Remove Measures", url: "/removeMeasures"},
                                        {name: "Designations", url: "/designations"}
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Scaffolding"}
                                    menuItems={[
                                        {name: "Extracts", url: "/scaffoldingExtractsTriggerDag"},
                                        {name: "Cleansed Data Csv Extracts", url: "/extractTriggerCleanseDataCsv"},
                                    ]}
                                />
                                <HeaderDropdown
                                    title={"Circana"}
                                    menuItems={[
                                        {name: "Conventional Data QC", url: "/conventionalDataQC"},
                                        {name: "Conventional Data Unload", url: "/conventionalDataUnload"},
                                    ]}
                                />
                            </ul>
                        </nav>
                    </div>
                </div>
            </Fragment>
        )
    }
}