import React, {useState} from 'react';
import PropTypes from 'prop-types';

const InputAndButton = ({inputType, sendInputValueToAncestor}) => {
    const [value, setValue] = useState(null);

    return (
        <div className={'input-button-container'}>
            <input name={'input'}
                   className={'input'}
                   onChange={e => setValue(e.target.value)}
                   type={inputType}
                   value={value}
            />
            <input className={'button secondary'}
                   name={'button'}
                   onClick={() => sendInputValueToAncestor(value)}
                   value={'Add'}
                   type={'submit'}
            />
        </div>
    );
}

InputAndButton.propTypes = {
    inputType: PropTypes.oneOf(['number', 'text']).isRequired,
    sendInputValueToAncestor: PropTypes.func.isRequired
};

export default InputAndButton;