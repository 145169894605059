import _ from "lodash";
import {getDollarsFormat} from "./SummaryDisplayFunctions";
import {getDateAndDayHeader} from "../../Components/getDateAndDayHeader";

const mapDataToSeries = (storeData, dataAccessor) => {
    const dataByWeek = storeData.stores.reduce(
        (accum, storeRecord) => {
            storeRecord.weekEndData.forEach((weekEndData) => {
                const date = weekEndData.date;
                const accumDollars = accum[date] || 0;
                accum[date] = accumDollars + weekEndData[dataAccessor];
            });
            return accum;
        },
        {}
    );
    const dataByWeekKeys = Object.keys(dataByWeek).sort();
    return dataByWeekKeys.map((dateKey) => {
        return {
            x: dateKey,
            y: _.round(dataByWeek[dateKey])
        }
    });
}

const mapDataToSeriesStores = (storeData, dataAccessor) => {
    storeData.sort((a, b) => (a.date > b.date) ? 1 : -1);
    return storeData.map((dateKey) => {
        return {
            x: dateKey.date,
            y: _.round(dateKey[dataAccessor])
        }
    });
}

export function getSortedDates(data) {
    const expectedDates = _.union(Object.values(data).flatMap(retailerData => retailerData.rollUpDates));
    const columnDates = _.union(expectedDates, Object.values(data).flatMap(retailerData => retailerData.weekEndDates));
    return columnDates.sort().reverse().map(date => {
        return {date, flagged: !expectedDates.includes(date)};
    });
}

export function getSeriesRetailers(data, dataAccessor) {
    function getThisSeries(storeData, seriesKey, dataAccessor) {
        return {
            name: seriesKey,
            data: mapDataToSeries(storeData, dataAccessor)
        };
    }
    const seriesKey = Object.keys(data);
    return seriesKey.map(key => getThisSeries(data[key], key, dataAccessor));
}

export function getSeriesStores(data, dataAccessor, displayType) {
    function getThisSeries(storeData, dataAccessor) {
        return {
            name: storeData.storeId + '_' + storeData.storeName,
            data: mapDataToSeriesStores(storeData.weekEndData, dataAccessor)
        };
    }
    if (displayType === 'Chart')
        return data.stores.map(store =>
            getThisSeries(store, dataAccessor)
        );
    return data.stores;
}

export function getDynamicColumnsRetailers(dates, dataAccessor) {
    const retailerColumn = [{
        id: 'Retailer',
        header: 'Retailer',
        className: 'retailer-totals-retailer-column',
        sticky: 'left',
        accessorFn: (retailer) => _.replace(retailer.name, "retailerId_", '')
    }];

    const sortedDates = getSortedDates(dates)
    return retailerColumn.concat(sortedDates.map((sortedDate) => {
        const date = sortedDate.date;
        const flagged = sortedDate.flagged;
        const combinedDate = getDateAndDayHeader(date);
        return {
            id: date,
            header: combinedDate,
            headerClassName: flagged ? 'totals-header-emphasis' :  'totals-header',
            maxSize: 100,
            enableColumnFilter: false,
            accessorFn: (retailer) => {
                const possiblyFoundDate = retailer.data.find(data => data.x === date);
                return possiblyFoundDate ?
                    'dollars' === dataAccessor ? getDollarsFormat(possiblyFoundDate.y) : possiblyFoundDate.y.toLocaleString()
                     : ''
            }
        }
    }));
}