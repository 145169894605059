import React, {useEffect, useState} from 'react';
import {round} from "lodash";
import NameValueLabels from "../Components/NameValueLabels";
import moment from "moment";
import {makeApiCall} from "../../Api/makeApiCall";

const AirflowMetrics = () => {
    const [inHover, setHover] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const path = encodeURIComponent(`gs://spins-edp-${process.env.REACT_APP_ENV}-qualitycontrol/airflow-metrics/result.json`);
        setLoading(true);
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath/?path=${path}`)
            .then((result) => {
                setData(result);
                setLoading(false);
            });
    }, []);

    return (
        <div className={'hover-tool-tip-div'} onMouseEnter={() => setHover(true)}
             onMouseLeave={() => setHover(false)}>
        <span id={'airflow-success-rate'} data-testid={"airflow-success-rate"}>
            {loading ?
            <><i className="fas fa-carrot fa-spin fa-500px spinner" aria-hidden="true"></i> Loading...</> :
            `Airflow Task Success Rate (Last 24 Hours): ${(round((data.successcount / data.count) * 100, 2))}%`}
            </span>
            {inHover && <div className={'tooltip-contents'}>
                <NameValueLabels
                    containerClassName={'hover-tool-tip-div-hover'}
                    labelValuePairs={[
                        {label: "Successful:", value: data.successcount},
                        {label: "Failed:", value: data.failedcount},
                        {label: "Total:", value: data.count},
                        {label: "Last Updated:", value: moment.unix(data.updatedtimestamp).format("YYYY-MM-DD hh:mm:ssa")}
                    ]}/>
            </div>}
        </div>);
};

export default AirflowMetrics;