import {useServiceBase} from "../../../Components/ReactTable/useServiceBase";
import Page from "../../../Components/Page";
import {
    INDEX_FIELD,
    RETAILER_ID_FIELD,
    RETAILER_ID_NAME_COLUMN
} from "../../../Components/ReactTable/common_custom_columns";
import {retailerDisplay, serviceRetailerDropdown} from "../../../../utils/retailerDisplayFormatter";
import _ from "lodash";
import {useCallback, useMemo} from "react";
import {getRetailerName, isRetailerMapLoaded} from "../../../../utils/retailerMapCache";
import EditableReactTableV2 from "../../../Components/ReactTable/EditableReactTableV2";
import {LoadingWrapper} from "../../../Components/LoadingWrapper";
import {toastSuccess} from "../../../../utils/toast";
import {toast} from "react-toastify";

export const MissingDataRetailerExclusions = () => {
    const {
        loading,
        data,
        onRowEditConfirmed,
        onRowDeleteConfirmed
    } = useServiceBase({endpointUrl: `${process.env.REACT_APP_EDP_API_BASE_URL}/missingDataRetailerExclusions`});

    const retailersNotInConfig = useMemo(() => {
        if (isRetailerMapLoaded()) {
            const missingStoreLookup = _.keyBy(data.data, "retailerId");
            return serviceRetailerDropdown().data.filter(({value}) => missingStoreLookup[value] === undefined)
        }
    }, [data]);

    const onRowEditConfirmedWithToast = useCallback((newOrUpdatedRow) => {
        return onRowEditConfirmed(newOrUpdatedRow).then(() => {
            const retailerId = newOrUpdatedRow.retailerId;
            toastSuccess(
                toast,
                `Successfully added ${retailerDisplay(retailerId, getRetailerName(retailerId))}`
            );
        });

    }, [onRowEditConfirmed]);

    const onRowDeleteConfirmedWithToast = useCallback((deletedRow) => {
        return onRowDeleteConfirmed(deletedRow).then(() => {
                const retailerId = deletedRow.retailerId;
                toastSuccess(
                    toast,
                    `Successfully removed ${retailerDisplay(retailerId, getRetailerName(retailerId))}`
                );
            }
        );

    }, [onRowDeleteConfirmed]);

    return (
        <Page name={"Missing Data Exclusion List"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    data={data.data}
                    filterable
                    columns={[RETAILER_ID_NAME_COLUMN]}
                    actionButtonsProps={{
                        showDeleteButton: true,
                        showAddButton: true,
                        showEditButton: false
                    }}
                    editTable={[INDEX_FIELD, RETAILER_ID_FIELD(retailersNotInConfig)]}
                    onRowEditConfirmed={onRowEditConfirmedWithToast}
                    onRowDeleteConfirmed={onRowDeleteConfirmedWithToast}
                >
                </EditableReactTableV2>
            </LoadingWrapper>
        </Page>
    );
};