import {checkApiResults} from "./errors";
import {geocodeSearch} from "./geocode";
import {findBestAddress} from "./address";

const placesSearch = (request, placesLib, map) => {
    return new Promise((resolve, reject) => {
        if (!map) {
            console.log('=============> placesSearch: cannot do a "places" search without a map')
            reject(new Error('cannot do a "places" search without a map'))
            return
        }
        const placesService = new placesLib.PlacesService(map)
        placesService.textSearch(request, (results, status) => {
            resolve(checkApiResults(results, status))
        });
    })
}

export const placesBestAddressSearch = async (request, geocodingLib, placesLib, map) => {
    const placesResults = await placesSearch(request, placesLib, map)
    if (!placesResults) return undefined
    return Promise.all(
        placesResults.map(placesResult => geocodeSearch({location: placesResult.geometry.location}, geocodingLib).then(findBestAddress))
    ).then(addresses => addresses.filter(address => address))
}