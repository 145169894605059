import React from 'react';
import PropTypes from 'prop-types';
import {CONFIG_TYPES, ConfigType} from "../../Configurations/FileTypes";
import {DAG_NAMES} from "../../Configurations/DagNames";

const NormalizedFileReportLink = ({shouldDisplayReportIcon, reportType, original, retailerId, fileType}) => {
    const runId = original.normalizationRunId;
    const runDate = runId ? runId.match('__(.*)') : "";
    const dagName = original.isBulk ? DAG_NAMES.INGEST_FILE_TO_GCS_BULK : DAG_NAMES.INGEST_FILE_TO_GCS

    function getPath() {
        const normalizedReportUrl = `gs://${process.env.REACT_APP_GCS_TMP_BASE_URL}${runId}/report/p_data=normalized/${reportType}/result.json`
        return encodeURIComponent(normalizedReportUrl);
    }

    const href = runDate && runDate.length > 1 ? `/normalizedFileReport/?path=${(getPath())}`
        + `&runId=${encodeURIComponent(runId)}`
        + `&runDate=${encodeURIComponent(runDate[1])}`
        + `&dagName=${dagName}`
        + `&reportType=${reportType}`
        + `&archiveLocation=${encodeURIComponent(original.archiveLocation)}`
        + `&ftp=${encodeURIComponent(original.ftp)}`
        + `&retailerId=${retailerId}`
        : '';

    const isASalesIngestion = fileType === CONFIG_TYPES.SALES;
    return (
        shouldDisplayReportIcon && isASalesIngestion &&
        <a target='_blank' rel="noopener noreferrer" href={href} title={`${reportType} File Report`}>{
            <i className="fas report-icon fa-file-alt"/>}
        </a>
    );
};

NormalizedFileReportLink.defaultProps = {};

NormalizedFileReportLink.propTypes = {
    original: PropTypes.object.isRequired,
    reportType: PropTypes.string.isRequired,
    retailerId: PropTypes.number.isRequired,
    shouldDisplayReportIcon: PropTypes.bool.isRequired,
    fileType: PropTypes.instanceOf(ConfigType).isRequired
};

export default NormalizedFileReportLink;