import React from "react";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import Page from "../Components/Page";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormDropdownInputField} from "../Components/ReactTable/EditRecordForm";


function MinimumDaysOfSalesService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/minimumDaysOfSales/`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    const minimumDaysOfSalesOptions = [
        {value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3},
        {value: 4, label: 4}, {value: 5, label: 5}, {value: 6, label: 6}
    ];

    return (
        <Page name={"Minimum Days of Sales"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={[
                        RETAILER_ID_NAME_COLUMN,
                        {
                            header: "Minimum Days Of Sales",
                            accessorKey: "MinimumDaysOfSales"
                        }
                    ]}
                    initialState={{pagination: {pageSize: 25}, sorting: [{id: "retailerIdName", desc: false}]}}
                    editTable={[
                        INDEX_FIELD,
                        RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                        {id: "MinimumDaysOfSales", Component: args => <EditFormDropdownInputField  {...args} isRequired  options={minimumDaysOfSalesOptions}/>}
                    ]}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
}

export default MinimumDaysOfSalesService;