import ApiGetContainer from "../../../../Api/ApiGetContainer";
import React, {useCallback, useState} from "react";
import {getStoreTotalsDataColumns} from "../../Components/StoreTotalsDataColumns";
import TotalsSearchResults from "../../Components/TotalsSearchResults";
import {getSeriesStores} from "./MapSalesIngestionSummary";
import PropTypes from "prop-types";
import {connectMenu} from "react-contextmenu";
import {dataPreProcess, dataPreProcessFilledDatesOnly} from "./StoreTotalsMerge";
import {getStoreDataAsCsv} from "./StoreTotalsTotalDollarsCsv";
import {ConfigUpdateContextMenu} from "./ConfigUpdateContextMenu";
import {displayFunctions} from "./StoreTotalsDataSelections";
import StoreTotalsDollarsKey from "./StoreTotalsDollarsKey";
import NameValueLabels from "../../../Components/NameValueLabels";
import ArchiveFilenameModal from "./ArchiveFilenamesModal";
import YagoDollarsModal from "./YagoDollarsModal";

const SHOW_YAGO_HOT_KEY = "y";
const CONTEXT_MENU_ID = 'total-dollars-context-menu';

export const StoreTotalsTotalDollars = (
    {
        selectedData,
        retailerId,
        percentThreshold,
        dataFormat,
        onDataFormatChange,
        graphqlQuery,
        shouldRenderChart,
        initialCellValueClicked,
        includeFillOnly,
        fillWatermark,
        promoWatermark,
        includeYago
    }
) => {
    const totalsDataSelection = displayFunctions[selectedData];

    const displayTabs = [
        {displayKey: 'value', label: 'value', displayFunction: totalsDataSelection.displayFunction},
        {
            displayKey: 'percentDifference',
            label: '% Difference',
            displayFunction: totalsDataSelection.displayDiffFromAverageFunction
        },
    ]
    const [displayDataColumnFunction, setDisplayDataColumnFunction] = useState(() => totalsDataSelection.displayFunction);
    const [realData, setRealData] = useState([]);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [cellValueClicked, setCellValueClicked] = useState(initialCellValueClicked);
    const [pageIndex, updatePageIndex] = useState(0);
    const [pageSize, updatePageSize] = useState(10);
    const [showModal, setShowModal] = useState(false);

    const reactTableXCoordinateRef = React.useRef(0);
    React.useEffect(() => {
        reactTableXCoordinateRef.current = 0;
    }, [retailerId])

    const cellValueClickedCallback = (store, weekEndData) => setCellValueClicked({store, weekEndData})



    const ConnectedMenu = connectMenu(CONTEXT_MENU_ID)(ConfigUpdateContextMenu);

    //ToDo: dunno is this actually does anything important
    React.useEffect(() => {
        setDisplayDataColumnFunction(() => totalsDataSelection.displayFunction);
    }, [totalsDataSelection.displayFunction]);



    return <div>
        <ConnectedMenu/>
        <div className="tab-radio-buttons">
            {displayTabs.map((displayObject => {
                    return <div key={displayObject.displayKey} className="tab-radio-button-container">
                        <input id={`radio-${displayObject.displayKey}`}
                               type="radio"
                               className="tab-radio-button"
                               name={"file-type-radio"}
                               checked={displayObject.displayKey === dataFormat}
                               onChange={() => {
                                   onDataFormatChange(displayObject.displayKey);
                                   setDisplayDataColumnFunction(() => displayObject.displayFunction);
                               }}
                        />
                        <label htmlFor={`radio-${displayObject.displayKey}`}
                               className="radio-inline">{displayObject.label}</label>
                    </div>
                })
            )}
        </div>
        <div className={"total-dollars-key"}>
            <NameValueLabels labelValuePairs={[{label: "Store Totals Table", value: <StoreTotalsDollarsKey/>}]}/>
        </div>
        <ApiGetContainer
            graphqlQuery={graphqlQuery + " #" + timestamp}
            useApolloCache={false}
            allowUpdating={true}
            componentToRender={TotalsSearchResults}
            parameters={{percentThreshold}}
            dataPreProcess={includeFillOnly ? dataPreProcessFilledDatesOnly : dataPreProcess}
            componentCallback={(preProcessedData, rawData) => setRealData(rawData)}
            componentToRenderProps={{
                key: displayDataColumnFunction,
                dataSelection: selectedData,
                pageSize: pageSize,
                updatePageSize: (pageSize) => updatePageSize(pageSize),
                pageIndex: pageIndex,
                updatePageIndex: (pageIndex) => updatePageIndex(pageIndex),
                getSeriesFunc: getSeriesStores,
                dynamicColumnsFunc: () => getStoreTotalsDataColumns(
                    {
                        retailerId,
                        data: realData,
                        percentThreshold,
                        displayDataFunction: displayDataColumnFunction,
                        contextMenuId: CONTEXT_MENU_ID,
                        selectedData,
                        setTimestamp,
                        cellValueClicked: cellValueClickedCallback,
                        fillWatermark: fillWatermark,
                        promoWatermark: promoWatermark
                    }),
                downloadAsCSVFunc: (retailerId) => getStoreDataAsCsv(retailerId, graphqlQuery),
                retailerIds: retailerId,
                defaultSortedId: "storeId",
                reactTableXCoordinateRef: reactTableXCoordinateRef,
                shouldRenderChart
            }}
        />
        {cellValueClicked && <ArchiveFilenameModal
            archiveFilenames={cellValueClicked.weekEndData.archiveFilenames}
            closeModal={() => setCellValueClicked(undefined)}
            date={cellValueClicked.weekEndData.date}
            retailerId={retailerId}
            source={cellValueClicked.weekEndData.source}
            storeId={cellValueClicked.store.storeId}
            storeName={cellValueClicked.store.storeName}
        />}
    </div>;
};

StoreTotalsTotalDollars.propTypes = {
    graphqlQuery: PropTypes.string,
    dataFormat: PropTypes.string,
    initialCellValueClicked: PropTypes.object,
    onDataFormatChange: PropTypes.func,
    percentThreshold: PropTypes.number.isRequired,
    retailerId: PropTypes.string.isRequired,
    selectedData: PropTypes.string.isRequired,
    shouldRenderChart: PropTypes.bool,
    includeFillOnly: PropTypes.bool,
    fillWatermark: PropTypes.array,
    promoWatermark: PropTypes.array,
    includeYago: PropTypes.bool
}


export default StoreTotalsTotalDollars;
