import React from 'react';
import * as PropTypes from 'prop-types';
import SelectDropdown from "./SelectDropdown";

export const NumberSelectionDropdown = ({minNumber, maxNumber, labelText, onChange, selectedValue}) => {
    const length = (maxNumber - minNumber) + 1
    const options = Array.from({length}, (v, k) => k + minNumber).map(v => ({label: v, value: v}))
    const _selected = selectedValue ? options.find((o) => o.value === selectedValue) : undefined

    return (
        <SelectDropdown
            inputLabel={labelText}
            onChange={onChange}
            selectValue={_selected?.value}
            options={options}
        />
    )
}

NumberSelectionDropdown.propTypes = {
    minNumber: PropTypes.number.isRequired,
    maxNumber: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    labelText: PropTypes.string.isRequired,
    selectedValue: PropTypes.number
}
