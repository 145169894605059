import React, {Component, Fragment} from 'react';
import Files from 'react-files';
import {makeApiCall, makeApiCallDelete, makeApiCallPost} from '../../Api/makeApiCall';
import {getBooleanParamOrDefault, getStringParamOrDefault, setQueryStringForState} from '../../utils/urlBuilder';
import CompareJson from './CompareJson';
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import Page from "../Components/Page";
import {Callout} from "react-foundation";
import {CalloutColors, CalloutSizes} from "react-foundation/lib/enums";
import DownloadMarketServiceButton from "./DownloadMarketServiceButton";
import {DeleteMarketPeriod} from "./DeleteMarketPeriod";
import {CopyMarketServicePeriod} from "./CopyMarketServicePeriod";
import IconDropdown from "../Components/IconDropdown";
import {toastError, toastSuccess} from "../../utils/toast";
import {toast} from "react-toastify";
import SelectDropdown from '../Components/Dropdowns/SelectDropdown';
import Modal from "react-modal";
import {RadioButtonGroup} from "../../utils/components/RadioButtonGroup";
import {getUserTypeFromLocalToken, userType} from "../../auth/accessTokenValidator";
import MarketServiceContent from "./MarketService/MarketServiceContent";
import {Breadcrumbs} from '../Components/Breadcrumbs';

class MarketServicePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined,
            currentPeriodAbbreviation: undefined,
            marketPeriods: undefined,
            selectedMarketPeriod: undefined,
            showCompare: false,
            showCopy: false,
            showDownload: false,
            showUpload: false,
            deleteModalOpen: false,
            downloadToggle: false,
            isPubSubOpen: false,
            pubsubStatus: 'Publish'
        };

        this.fileReader = new FileReader();

        this.onFilesChange = this.onFilesChange.bind(this);
        this.changeMarketPeriod = this.changeMarketPeriod.bind(this);
        this.uploadCsvPostCall = this.uploadCsvPostCall.bind(this);
        this.deleteMarketPeriod = this.deleteMarketPeriod.bind(this);
        this.setCompareTo = this.setCompareTo.bind(this);
        this.setCopyTo = this.setCopyTo.bind(this);
        this.setDownloadTo = this.setDownloadTo.bind(this);
        this.setUploadTo = this.setUploadTo.bind(this);
        this.dataChanged = this.dataChanged.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.getMarketPeriodList = this.getMarketPeriodList.bind(this);
    }

    componentDidMount() {
        this.getMarketPeriodList();
        this.getCurrentPeriod();
        this.uploadToast();
    }

    closeDeleteModal() {
        this.setState({deleteModalOpen: false});
    }

    openDeleteModal() {
        this.setState({deleteModalOpen: true});
    }

    closePubSubModal() {
        this.setState({isPubSubOpen: false})
    }

    updatePubSubStatus(selected){
        this.setState({pubsubStatus: selected})
    }

    async submitPubSub() {
        const data = {status: this.state.pubsubStatus, period: this.state.selectedMarketPeriod}
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/publishMarketUpdate`;
        this.closePubSubModal()
        makeApiCallPost(url, JSON.stringify(data))
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `${this.state.pubsubStatus} notification period ${this.state.selectedMarketPeriod} failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `${this.state.pubsubStatus} notification period ${this.state.selectedMarketPeriod} successfully sent`)
            })
            .catch(e => {
                toastError(toast, `${this.state.pubsubStatus} notification period ${this.state.selectedMarketPeriod} failed with error ${JSON.stringify(e)}`)
            })

    }

    getCurrentPeriod() {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/timeService`, {currentPeriodQuad: true})
            .then(result => this.setState({
                currentPeriodAbbreviation: result?.periodAbbreviation,
                selectedMarketPeriod: getStringParamOrDefault('selectedMarketPeriod', undefined) || result?.periodAbbreviation
            }));
    }
    uploadToast() {
        if (getBooleanParamOrDefault('uploadComplete', false)) {
            toastSuccess(toast, `${getStringParamOrDefault('selectedMarketPeriod', 'Market Service') } has been successfully upload`)
        }
    }

    getMarketPeriodList() {
        makeApolloClientCall('{ marketPeriodList }')
            .then((marketPeriods) => {
                this.setState({
                    marketPeriods: marketPeriods
                        .marketPeriodList
                        .sort(function (a, b) {
                            return b.toLowerCase().localeCompare(a.toLowerCase());
                        })
                });
            })
            .catch((error) => {
                console.error(error)
            });
    }

    onFilesChange(files) {
        this.fileReader.onload = (loadedFiles) => {
            return this.uploadCsvPostCall(loadedFiles.target.result)
                .then(() => {
                    const queryString = `${window.location.search}&uploadComplete=true`;
                    const url = window.location.origin + window.location.pathname + queryString;
                    window.history.pushState('', '', url);
                    window.location.reload();
                    return Promise.resolve();
                });
        };

        const file = files[0];
        this.fileReader.readAsText(file);
    }

    changeMarketPeriod(periodSelection, refreshMarketPeriods = false) {
        if (refreshMarketPeriods) {
            this.getMarketPeriodList();
        }

        this.setState({selectedMarketPeriod: periodSelection});
    }

    uploadCsvPostCall(fileContents) {
        const data = {data: fileContents};
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/marketsUpdate/?period=${this.state.selectedMarketPeriod}`;
        return makeApiCallPost(url, JSON.stringify(data));
    }

    deleteMarketPeriod() {
        return makeApiCallDelete(`${process.env.REACT_APP_EDP_API_BASE_URL}/markets?period=${this.state.selectedMarketPeriod}`)
            .then(() => {
                this.getMarketPeriodList();
                this.setState({selectedMarketPeriod: this.state.currentPeriodAbbreviation, deleteModalOpen: false})
            });
    }

    setCompareTo() {
        this.setState({showCompare: !this.state.showCompare});
    }

    setCopyTo() {
        this.setState({showCopy: !this.state.showCopy});
    }

    setDownloadTo() {
        this.setState({showDownload: !this.state.showDownload});
    }

    setUploadTo() {
        this.setState({showUpload: !this.state.showUpload});
    }

    dataChanged(data) {
        this.setState({data: data});
    }

    render() {
        const {selectedMarketPeriod, currentPeriodAbbreviation} = this.state;
        const theCurrentPeriodIsSelected = selectedMarketPeriod === currentPeriodAbbreviation;
        const displayWarningCurrentPeriodNotSelected = !(theCurrentPeriodIsSelected || !selectedMarketPeriod)
        const parameters = {
            selectedMarketPeriod: this.state.selectedMarketPeriod,
        };
        if (this.state.selectedMarketPeriod)
            setQueryStringForState(parameters);

        const options = this.state.marketPeriods?.map(mp => ({value: mp, label: mp}));
        return (
            <Page name="Market Service" breadCrumbs={<Breadcrumbs items={[{label: 'Services'}, {
                label: 'Market'
            }]}/>}>
                {displayWarningCurrentPeriodNotSelected && <Callout
                    size={CalloutSizes.SMALL}
                    color={CalloutColors.WARNING}>
                    <h5><i className="fa fa-exclamation-triangle"/> Current Period is not selected</h5>
                </Callout>}
                <div id={'market-service-page'}>
                    <div className={'market-service-page-controls'}>
                        <div className={'left-things'}>
                            <div className={'period-selection'}>
                                <span className={'select-period'}>{'Select Period:'}</span>
                                <div className={'dropdown-wrapper'}>
                                    <SelectDropdown
                                        selectValue={selectedMarketPeriod}
                                        options={options}
                                        onChange={(option) => this.changeMarketPeriod(option.value)}
                                        />
                                </div>
                                {selectedMarketPeriod && theCurrentPeriodIsSelected
                                    && <div className={'current-period-label'}>{'(Current Period)'}</div>
                                }
                            </div>
                            {
                                selectedMarketPeriod &&
                                <Fragment>
                                    <IconDropdown iconName={'fa-compress-alt'} title={"Compare To"}>
                                        <CompareJson
                                            marketPeriods={this.state.marketPeriods || []}
                                            originalPeriodToCompare={selectedMarketPeriod}
                                        />
                                    </IconDropdown>
                                    <IconDropdown iconName={'fa-copy'} title={"Copy To"}>
                                        <CopyMarketServicePeriod
                                            selectedMarketPeriod={selectedMarketPeriod}
                                            onSubmitCallback={this.getMarketPeriodList}
                                        />
                                    </IconDropdown>
                                    <DownloadMarketServiceButton period={selectedMarketPeriod}/>
                                    <div
                                        title={"Upload"}>
                                        <Files
                                            className={`fas fa-upload upload files-dropzone ${this.state.showUpload ? "selected-icon" : ""}`}
                                            onChange={this.onFilesChange}
                                            accepts={['.csv']}
                                            multiple
                                            maxFiles={10}
                                            maxFileSize={10000000}
                                            clickable
                                        >
                                            <i onClick={() => this.setDownloadTo()}/>
                                        </Files>
                                    </div>
                                    <div>
                                        {!theCurrentPeriodIsSelected &&
                                            <DeleteMarketPeriod onClick={this.openDeleteModal}
                                                                deleteModalOpen={this.state.deleteModalOpen}
                                                                confirmationCallback={this.deleteMarketPeriod}
                                                                cancellationCallback={this.closeDeleteModal}
                                                                selectedMarketPeriod={selectedMarketPeriod}/>
                                        }
                                    </div>
                                    {  getUserTypeFromLocalToken() === userType.dataGovernance ? <button type={"button"}
                                            className={"button primary notification-button"}
                                            onClick={() => this.setState({isPubSubOpen: true})}>
                                        Send Notification
                                    </button> : <div/>}
                                </Fragment>
                            }
                        </div>

                    </div>
                </div>
                <Modal
                    isOpen={this.state.isPubSubOpen}
                    onRequestClose={()=>this.closePubSubModal()}
                    ariaHideApp={false}
                    className="Modal">
                    <>
                        <div className={'modal-header'}>
                            <div><h5 className={"modal-header-text"}>Send Notification</h5></div>
                            <div>
                                <button className={"right-exit-button"} onClick={()=>this.closePubSubModal()}>
                                    <i className="fas fa-times" /></button>
                            </div>
                        </div>
                        <span>SND Market Metadata for {this.state.selectedMarketPeriod} has been:</span>
                        <RadioButtonGroup
                            selectedValue={this.state.pubsubStatus}
                            callback={(value) => this.updatePubSubStatus(value)}
                            radioButtonOptions={['Publish', 'Republish']}
                            className={'modal-radio-button-group-container'}
                            displayCheckIcon={true}
                        />

                    </>
                    <div className={"right-things"}>
                        <button className="button cancel modal-button" type="button" name="close"
                                onClick={() => this.closePubSubModal()}>
                            Cancel
                        </button>
                        <button className="button submit modal-button" type="button" name="submit"
                                onClick={() => this.submitPubSub()}>
                            Send
                        </button>
                    </div>
                </Modal>
                <div className={'table-data'}>
                    {selectedMarketPeriod && <MarketServiceContent
                        period={selectedMarketPeriod}
                    />}
                </div>
            </Page>
        )

    }
}

export default MarketServicePage;
