import ConfirmationModal from "../Components/ConfirmationModal";
import React from "react";
import * as PropTypes from "prop-types";

export const DeleteMarketPeriod = (props) => {
    return <>
        <i
            title={"Delete"}
            className={"fas fa-trash-alt"}
            onClick={props.onClick}
        />
        {
            props.deleteModalOpen &&
            <ConfirmationModal confirmationCallback={props.confirmationCallback}
                               cancellationCallback={props.cancellationCallback}
                               modalClassName={"DeleteModal"}
                               headerText={`Delete - Period ${props.selectedMarketPeriod}`}

            >
                <div>{`Are you sure you want to delete Period ${props.selectedMarketPeriod}?`}</div>
            </ConfirmationModal>
        }
    </>;
}

DeleteMarketPeriod.propTypes = {
    onClick: PropTypes.func,
    deleteModalOpen: PropTypes.bool,
    confirmationCallback: PropTypes.func,
    cancellationCallback: PropTypes.func,
    selectedMarketPeriod: PropTypes.any
};