export const prettyFormatJsonString = payload => {
    try {
        let value;
        if(!payload) {
            value = {};
        }
        else if (typeof payload === 'object') {
            value = payload
        } else {
            const parsed = JSON.parse(payload)
            if (parsed["archived_file_url"])
                parsed["archived_file_url"] = parsed["archived_file_url"].split(',');
            value = parsed
        }

        return JSON.stringify(value, null, 2);
    } catch (e) {
        console.error(e)
        return payload
    }
};
