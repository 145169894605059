import RunIdLink from "../IngestionLogs/Columns/RunIdLink";
import React from "react";
import {encodeURI} from "@google-cloud/storage/build/src/util";


export function getGcsLink(checkpointUsed) {
    const link = `https://console.cloud.google.com/storage/browser/${encodeURI(checkpointUsed.replace("gs://", ""), false)}`;
    let text = checkpointUsed.substring(checkpointUsed.indexOf('p_run_id=') + 9)
    text = text.includes('/') ? text.substring(0, text.indexOf('/')) : text

    return <a target='_blank'
              rel="noopener noreferrer"
              href={link}>
        {text}
    </a>;
}


export const getColumnsWithoutRunConfiguration = (airflowUrl) => ([
    {
        header: 'Run Id',
        accessorKey: 'runid',
        enableColumnFilter: false,
        className: 'non-numeric-field',
        cell: ({row, getValue}) => <RunIdLink airflowUrl={airflowUrl} {...row} value={getValue()}/>
    },
    {
        header: 'Dag Name',
        accessorKey: 'process',
        enableColumnFilter: false,
        className: 'non-numeric-field'
    },
    {
        header: 'Triggered By',
        accessorKey: 'triggeredby',
        enableColumnFilter: false,
        className: 'non-numeric-field'
    }
]);

export const getColumnsWithRunConfiguration = (airflowUrl) => {
    return [
        ...getColumnsWithoutRunConfiguration(airflowUrl),
        {
            header: 'Run Configuration',
            accessorKey: 'runconfiguration',
            enableColumnFilter: false,
            className: 'non-numeric-field'
        }
    ]
}