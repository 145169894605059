import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useServiceBase } from "../../Components/ReactTable/useServiceBase";
import { STORE_ID_AND_NAME_COLUMN_GETTER } from "../../Components/StoreIdAndName";
import RetailerIdAndName from "../../Components/RetailerIdAndName";
import { getRetailerName } from "../../../utils/retailerMapCache";
import { SELECT_COLUMN } from "../../Components/ReactTable/CheckboxColumnComponent";
import ReactTable from "../../Components/ReactTable";
import ConfirmationModal from "../../Components/ConfirmationModal";
import _ from "lodash";
import { getUserEmail } from "../../../auth/accessTokenValidator";
import { makeApiCallDeleteWithData } from "../../../Api/makeApiCall";
import { toastError, toastSuccess } from "../../../utils/toast";
import { toast } from "react-toastify";
import { LoadingWrapper } from "../../Components/LoadingWrapper";

function retailerFilter(row, value, data) {
    if (!value) {
        return true;
    } else {
        const retailerId = data.stores[row.original.storeId]?.retailerId;
        return Boolean(`${retailerId} ${getRetailerName(retailerId)?.toLowerCase()}`.includes(value.toLowerCase()));
    }
}

function postBodyItem(batch, userEmail) {
    const retailerId = batch[0].retailerId;
    const salesEndDate = batch[0].salesEndDate;
    return {
        retailerId,
        salesEndDate,
        storeIds: batch.map(s => s.storeId),
        user: userEmail
    };
}

async function makeApiCall(stores, startOrStop) {
    const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeSalesEndDateConfig/store${startOrStop}`;
    const userEmail = getUserEmail();
    const apiCallBatches = _.groupBy(stores, store => `${store.retailerId}_${store.salesEndDate}`);

    const postBody = Object.values(apiCallBatches).map((batch) => postBodyItem(batch, userEmail));

    return makeApiCallDeleteWithData(url, postBody);
}

const RemoveMeasuresTabContent = ({ startOrStop }) => {
    const schema = {
        "fields": [
            { "name": "storeId", "type": "integer" },
            { "name": "salesEndDate", "type": "date" }
        ]
    };
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/measures/store${startOrStop}`;
    const { loading, setLoading, data, refreshData } = useServiceBase({ endpointUrl });

    const storesWithRetailerId = useMemo(() => {
        return (data?.serviceData?.dates || []).map(date => ({
            ...date,
            retailerId: data.stores[date.storeId]?.retailerId
        }))
    }, [data]);

    const columns = [
        SELECT_COLUMN,
        {
            header: "Retailer",
            id: "retailerIdName",
            size: 200,
            accessorFn: (row) => row.retailerId,
            cell: ({ getValue }) => <RetailerIdAndName retailerId={getValue()} />,
            filterFn: (row, columnId, value) => {
                return retailerFilter(row, value, data);
            }
        },
        STORE_ID_AND_NAME_COLUMN_GETTER(data.stores),
        { header: `Measures ${startOrStop}`, accessorKey: "salesEndDate" }
    ];

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [storesPendingDelete, setStoresPendingDelete] = useState([]);

    async function onDelete() {
        setLoading(true);
        setIsConfirmationModalOpen(false);
        setStoresPendingDelete([]);

        const result = await makeApiCall(storesPendingDelete, startOrStop);
        if (result.apiError) {
            toastError(toast, JSON.stringify(result.apiError));
        } else {
            toastSuccess(toast, `Successfully removed Measures ${startOrStop} for ${storesPendingDelete.length} store(s).`);
        }
        refreshData();
    }


    return (
        <div className={`measures-${startOrStop.toLowerCase()}-service-base`}>
            {
                isConfirmationModalOpen && (
                    <ConfirmationModal
                        confirmButtonClass={"danger-button"}
                        confirmButtonText={"Remove"}
                        headerText={"Remove measure"}
                        cancellationCallback={() => setIsConfirmationModalOpen(false)}
                        confirmationCallback={onDelete}>
                        {`Are you sure you want to remove ${storesPendingDelete.length} measures entry(s)?  This action cannot be reversed.`}
                    </ConfirmationModal>
                )
            }
            <LoadingWrapper loading={loading}>
                <ReactTable
                    data={storesWithRetailerId}
                    filterable
                    schema={schema}
                    columns={columns}
                    initialState={{ sorting: [{ id: "storeId", desc: false }] }}
                >
                    {
                        ({ table }) => {
                            const mappedStores = table.getSelectedRowModel().rows.map(x => x.original);
                            return (
                                <button
                                    className={"button primary"}
                                    disabled={table.getSelectedRowModel().rows.length === 0}
                                    onClick={() => {
                                        setStoresPendingDelete(mappedStores);
                                        setIsConfirmationModalOpen(true);
                                    }}
                                >
                                    Remove measure
                                </button>
                            );
                        }
                    }
                </ReactTable>
            </LoadingWrapper>
        </div>
    );
};

RemoveMeasuresTabContent.propTypes = {
    startOrStop: PropTypes.string.isRequired
};

export default RemoveMeasuresTabContent;