import {toInteger} from "lodash";
import {getRetailerMapUseGetRetailerLookupInstead} from "./retailerMapCache";

export function retailerDropdownDisplay(retailer) {
    return {value: retailer.retailerId, label: retailerDisplay(retailer.retailerId, retailer.retailChain)};
}

export function retailerDisplay(retailerId, retailChain) {
    return `${retailerId} - ${retailChain}`;
}

export const serviceRetailerDropdown = (retailerMap, serviceKey) => {
    const data = Object.entries(retailerMap || getRetailerMapUseGetRetailerLookupInstead())
        .map(([retailerId, retailChain]) => retailerDropdownDisplay({retailerId: toInteger(retailerId), retailChain}))
        .sort();
    return {serviceKey: serviceKey, data};
}