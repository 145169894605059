import React from 'react';
import {makeApiCallPost} from "../../Api/makeApiCall";
import ButtonLoader from "../../utils/components/ButtonLoader";
import {forceLogout} from "../../auth/url";
import {createDagRunConf} from "../../Api/dagRunConf";
import {
    copyAccessTokenToClipboard,
    getUserTypeFromLocalToken,
    navigateToApolloStudioExplorer,
    userType
} from "../../auth/accessTokenValidator";
import {toTitleCase} from "./ReactTable/helpers";

const TitleDropdown = () => {
    const userTypeFromToken = getUserTypeFromLocalToken()
    return <>
        <button aria-label={"hamburger_menu"}
                className={"title-dropdown"}
                data-position="bottom"
                data-alignment="right"
                type="button"
                data-toggle="title-dropdown">
            <i className="fas fa-bars"/>
        </button>
        <div className="dropdown-pane title-dropdown-content left"
             id="title-dropdown"
             data-dropdown={true}
             data-position="bottom"
             data-alignment="right"
             data-allow-overlap="true"
             data-hover-pane="true"
             data-hover="true">
            <div className={"user-type-div"}>{toTitleCase(userTypeFromToken)}</div>
            {(process.env.REACT_APP_ENV === 'uat' && userTypeFromToken !== userType.locatorsUser ) &&
                <>
                <ButtonLoader buttonTitle={<i className="fas fa-sync-alt"/>}
                              axiosFunction={makeApiCallPost}
                              buttonClassName={"header-button"}
                              buttonProps={{
                                  "aria-label": "sync_button",
                                  "data-tooltip": true,
                                  title: "Sync Services and mappings from PROD",
                                  "data-position": "left",
                                  "data-alignment": "center"
                              }}
                              url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger/`}
                              postBody={{
                                  dagName: 'refresh_all_configs_and_services_from_prod',
                                  data: createDagRunConf( {"exclude_snapshot": false})
                              }}
                              buttonHasModal
                              modalHeader={`refresh_all_configs_and_services_from_prod`}
                              modalText={`Are you sure you want to Refresh All Configs And Services From PROD`}
                >
                </ButtonLoader>
                <ButtonLoader buttonTitle={<i className="fas fa-arrow-alt-circle-left"/>}
                              axiosFunction={makeApiCallPost}
                              buttonClassName={"header-button"}
                              buttonProps={{
                                  "aria-label": "sync_service_button",
                                  "data-tooltip": true,
                                  title: "Sync Services from PROD",
                                  "data-position": "left",
                                  "data-alignment": "center"
                              }}
                              url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger/`}
                              postBody={{
                                  dagName: 'refresh_all_configs_and_services_from_prod',
                                  data: createDagRunConf( {"exclude_service_prefixes": "mappings", "exclude_snapshot": true})
                              }}
                              buttonHasModal
                              modalHeader={`refresh_all_configs_and_services_from_prod`}
                              modalText={`Are you sure you want to Refresh All Configs And Services From PROD`}
                >
                </ButtonLoader>
                </>
            }
            <button id={"logout-button"} className={"header-button"} onClick={forceLogout} data-tooltip={true} title="Logout"
                    data-position="left" data-alignment="center">
                <i className="fas fa-sign-out-alt"/>
            </button>
            <button id={"copy-token-button"} className={"header-button"} onClick={copyAccessTokenToClipboard} data-tooltip={true} title="Copy Token"
                    data-position="left" data-alignment="center">
                <i className="fas fa-clone" />
            </button>
            <button id={"apollo-studio-button"} className={"header-button"} onClick={navigateToApolloStudioExplorer} data-tooltip={true} title="Apollo Studio"
                    data-position="left" data-alignment="center">
                <i className="fas fa-link" />
            </button>
        </div>
    </>
};

TitleDropdown.defaultProps = {};

TitleDropdown.propTypes = {};

export default TitleDropdown;