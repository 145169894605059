import React, {Component} from 'react';
import {makeApiCall} from "../../../Api/makeApiCall";
import {getStringParamOrDefault} from "../../../utils/urlBuilder";
import NameValueLabels from "../../Components/NameValueLabels";
import ReportLink from "./ReportLink";
import Page from "../../Components/Page";
import {REPORT_TYPES} from "../../Configurations/ReportTypes";
import {ManifestDropdown} from "./manifestDropdown";
import {SourceFilesReportActions} from "./SourceFilesReportActions";
import {getEdpAirflowUri} from "../../../utils/airflow";
import {CONFIG_TYPES} from "../../Configurations/FileTypes";
import ReactTable from "../../Components/ReactTable";
import LoadingSpinner from "../../Components/LoadingSpinner";
import {BulkLoadArchiveButton, bulkLoadOptionsEnum} from "../../Retailer/BulkLoadArchiveButton";

export const getMultiSourceReportColumns = (archiveLocation, ftp) => {
    return [
        {
            header: 'Partition Path',
            accessorKey: 'partitionPath',
            className: 'non-numeric-field',
            minSize: 60
        },
        {
            header: 'Normalized Record Count',
            accessorKey: 'normalizedRecordCount',
            className: 'numeric-field',
            maxSize: 50
        },
        {
            header: 'Source Files',
            accessorKey: 'sourceFiles',
            cell: ({getValue, row}) => <SourceFilesReportActions
                archiveLocation={archiveLocation}
                sourceFiles={Object.entries(getValue()).map(([key, v]) => ({[key]: v})).sort(
                    (a, b) => {
                        if (Object.keys(a)[0] === row.original.usedFile) {
                            return -1;
                        }
                        if (Object.keys(b)[0] === row.original.usedFile) {
                            return 1;
                        }
                        return Object.keys(a)[0].localeCompare(Object.keys(b)[0]);
                    }
                )}
                displayFileName
                usedFile={row.original.usedFile}
                ftp={ftp}
            />,
            className: 'non-numeric-field'
        }
    ]
};

export const getNormalizedReportsColumns = (archiveLocation, ftp, runId, data) => {

    function constructNormalizedRecordReportUrl(fileName) {
        return `https://storage.cloud.google.com/${process.env.REACT_APP_GCS_TMP_BASE_URL}${runId}/report/${encodeURIComponent('p_data=normalized')}/normalized_record_report/${encodeURIComponent(fileName)}.json`;
    }
    
    return [
        {
            header: 'Actions',
            id: 'actions',
            accessorKey: 'fileName',
            cell: ({getValue}) => <SourceFilesReportActions
                archiveLocation={archiveLocation}
                sourceFiles={[{[getValue()]: ''}]}
                ftp={ftp}
            />,
            className: 'non-numeric-field normalized-report-actions',
            size: 100
        },
        {
            header: 'File Name',
            accessorKey: 'fileName',
            className: 'non-numeric-field'
        },
        {
            header: 'Failed Record Count',
            accessorKey: 'failedRecordCount',
            cell: ({getValue, row}) => (
                <div className={'normalized-failed-record-count-value'}>
                    {(getValue() ?
                        <a href={constructNormalizedRecordReportUrl(row.original.fileName)}>
                            {getValue()}</a> : getValue())
                    }
                </div>
            ),
            className: 'numeric-field',
            size: 40
        },
        {
            header: 'Total Record Count',
            accessorKey: 'totalRecordCount',
            className: 'numeric-field',
            size: 40
        },
        {
            header: 'Percent Records Failed',
            accessorKey: 'percentRecordsFailed',
            className: 'numeric-field',
            size: 40
        },
        {
            header: 'Ingest Mappings',
            accessorKey: 'ingestMappings',
            className: 'non-numeric-field'
        },
        {
            header: 'Manifest',
            cell: ({row}) => <ManifestDropdown ftp={ftp} configName={row.original.manifest}
                                               manifestFileName={row.original.manifestFileName}
                                               ingestFileName={row.original.fileName}
                                               data={data}/>,
            className: 'non-numeric-field table-dropdown'
        }
    ]
}

class NormalizedReport extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
                data: undefined,
                path: getStringParamOrDefault('path'),
                runId: getStringParamOrDefault('runId'),
                runDate: getStringParamOrDefault('runDate'),
                dagName: getStringParamOrDefault('dagName'),
                reportType: getStringParamOrDefault('reportType'),
                archiveLocation: getStringParamOrDefault('archiveLocation'),
                ftp: getStringParamOrDefault('ftp'),
                retailerId: getStringParamOrDefault('retailerId'),
                airflowUrl: undefined,
                ftpConfigData: {},
            }
    }

    async componentDidMount() {
        const ftp = getStringParamOrDefault('ftp');
        this.setState({
            data: await this.getReportData(ftp),
            path: getStringParamOrDefault('path'),
            runId: getStringParamOrDefault('runId'),
            runDate: getStringParamOrDefault('runDate'),
            dagName: getStringParamOrDefault('dagName'),
            reportType: getStringParamOrDefault('reportType'),
            archiveLocation: getStringParamOrDefault('archiveLocation'),
            ftp,
            retailerId: getStringParamOrDefault('retailerId'),
            airflowUrl: `${await getEdpAirflowUri()}/graph`,
            ftpConfigData: await this.getFtpConfigData(ftp)
        });
    }

    getFtpConfigData(ftp) {
        const params = {
            ftp: ftp,
            configType: "file_type"
        };
        return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/`, params);
    }

    async getReportData(ftp) {
        const path = encodeURIComponent(this.state.path);
        const reportData = [];

        const results = await makeApiCall(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath/?path=${path}&file_type=text`
        )
        let manifestData = await makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/manifest/`,
            {manifestFileName: ftp});

        results.trim().split("\n").forEach(x => {
                const record = JSON.parse(x);
                if (record.ingestMappings) {
                    record.ingestMappings = this.prettyFormatObject(record.ingestMappings)
                }

                record.manifest = Object.keys(manifestData.configFiles).includes(record.fileName) ?
                    manifestData.configFiles[record.fileName] : undefined;

                reportData.push(record);
            }
        )
        return reportData;
    }

    prettyFormatObject(record) {
        let formattedObject = "";
        Object.keys(record).forEach(key => {
            formattedObject += key + " (" + record[key] + ")\n"
        })
        return formattedObject;
    }

    getAirFlowUrl() {
        const AIRFLOW_ADMIN_URL = this.state.airflowUrl;
        const dagName = this.state.dagName;
        const airFlowUrl = `${AIRFLOW_ADMIN_URL}?dag_id=${dagName}&root=&execution_date=${encodeURIComponent(this.state.runDate)}`;
        return <a target='_blank' id={'run-id-tag'} rel="noopener noreferrer" href={airFlowUrl}>{this.state.runId}</a>;
    }

    render() {
        if (!this.state.data) {
            return <LoadingSpinner/>
        }

        const labelValuePairs = [
            {label: "Dag Run:", value: this.getAirFlowUrl()},
            {
                label: "Report:", value: <ReportLink
                    displayValue={this.state.reportType}
                    runId={encodeURIComponent(this.state.runId)}
                    fileType={CONFIG_TYPES.SALES}
                    gcsSuffix={`/${this.state.reportType}`}
                />
            },
            {label: "FTP:", value: this.state.ftp},
            {label: "", value: <BulkLoadArchiveButton retailerId={this.state.retailerId} fileType={bulkLoadOptionsEnum.sales}/>}
        ];


        const isNormalizedFileReport = this.state.reportType === REPORT_TYPES.NORMALIZED_FILE;
        const pageName = isNormalizedFileReport ? "File Report" : "Multiple Source Report";
        const columns = isNormalizedFileReport ? getNormalizedReportsColumns(this.state.archiveLocation, this.state.ftp, this.state.runId, this.state.ftpConfigData)
            : getMultiSourceReportColumns(this.state.archiveLocation, this.state.ftp);
        return (
            <Page name={pageName}>
                <div className={"page-header"}>
                    <NameValueLabels labelValuePairs={labelValuePairs}/>
                </div>
                <ReactTable
                    data={this.state.data}
                    columns={columns}
                />
            </Page>
        )
    }
}

export default NormalizedReport;
