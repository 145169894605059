import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral'
import csvDownloadWithNulls from '../../../utils/CsvDownloadWithNulls';
import ReactTable from "../../Components/ReactTable";

const ExtractSpotlightContent = ({data, columns}) => {
    const sums = data.reduce((accumulator, currentValue) => ({
        weeks: accumulator.weeks + currentValue.weeks,
        problemWeeks: accumulator.problemWeeks + currentValue.problemWeeks
    }), {weeks: 0, problemWeeks: 0})

    const downloadCsv = (rows) => {
        const data = rows.map(x => x.original);
        csvDownloadWithNulls(data, 'extract-spotlight.csv', null)
    }

    return (
        <>
            <div className="spotlight-header-wrapper">
                <div className="all-retailers-sum">
                    <h6 className={'spotlight-header'}>ALL RETAILERS SUM</h6>

                    <span className="sum-label">Weeks:</span><span data-testid="sum-weeks"
                    className="sum-data">{numeral(sums.weeks).format('0,0')}</span>
                    <span className="sum-label">Problem Weeks:</span><span data-testid="sum-problem-weeks"
                    className="sum-data">{numeral(sums.problemWeeks).format('0,0')}</span>
                    <span className="sum-label">% Good:</span><span data-testid="sum-percent-good"
                    className="sum-data">{numeral(1 - (sums.problemWeeks / sums.weeks)).format('0%')}</span>
                </div>
            </div>
            <div className="spotlight-table">
                <ReactTable
                    filterable
                    data={data}
                    columns={columns}
                    initialState={{
                        pagination: { pageSize: 10 },
                        columnFilters: [{id: 'problemWeeks', value: 'problemOnly'}],
                        sorting: [{id: 'percentGood', desc: false}]
                    }}
                >
                    {({rows}) => {
                        return (rows.length > 0 &&
                            <div className={"tab-download-csv"}>
                                <button className={'download-spotlight-csv hollow button'}
                                        onClick={() => downloadCsv(rows)}>{'Download CSV'}</button>
                            </div>
                        )
                    }}
                </ReactTable>
            </div>
        </>
    )
}

ExtractSpotlightContent.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array
}

export default ExtractSpotlightContent;