import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {getStringParamOrDefault, updateQueryStringForState} from "../../utils/urlBuilder";
import {toTitleCase} from "../Components/ReactTable/helpers";

const ReactTableFilterDropdown = ({id, onChange, options, selectedValue, queryStringParamId, isClearable}) => {
    const paramValue = queryStringParamId && getStringParamOrDefault(queryStringParamId, undefined);
    const optionValue = paramValue ? paramValue : selectedValue

    return <div className={"react-table-dropdown-filter-container"} data-testid={`${id}-filter-dropdown-component-container`}>
        <Select
            id={`${id}_filter_dropdown`}
            placeholder={`${toTitleCase(id)}...`}
            menuPosition={"fixed"}
            menuPlacement={"bottom"}
            menuShouldBlockScroll={true}
            maxMenuHeight={200}
            className={`${id}-filter-dropdown`}
            classNamePrefix={`${id}-filter-dropdown`}
            value={options.find(option => option.value === optionValue)}
            options={options}
            onChange={(option) => {
                onChange(option && option.value);
                queryStringParamId && updateQueryStringForState({[queryStringParamId]: option ? option.value : ''});
            }}
            isClearable={isClearable}
        />
    </div>
};

ReactTableFilterDropdown.defaultProps = {
    isClearable: false
};

ReactTableFilterDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    isClearable: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    queryStringParamId: PropTypes.string,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ReactTableFilterDropdown;