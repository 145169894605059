import React, {useState} from 'react';
import NameValueLabels from "../Components/NameValueLabels";
import PropTypes from 'prop-types';

const InactiveRowKey = ({inactiveColorClassName}) => {
    const [inHover, setHover] = useState(false);
    return <div className={"fas fa-key tooltip-icon general-page-key"}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
        <h6 className={"general-page-key-label"}>Key</h6>
        {inHover && <div className={'tooltip-contents'}>
            <NameValueLabels
                containerClassName={'general-page-key-list'}
                labelValuePairs={[
                    {label: "", value: <div className={'general-key-status-color'}><div className={`${inactiveColorClassName} general-key-border`}>Inactive</div></div>}
                ]}/>
        </div>}
    </div>
        ;
};


InactiveRowKey.propTypes = {
    inactiveColorClassName: PropTypes.string
};

export default InactiveRowKey;