import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from "../../Components/ReactTable";

const DynamicColumns = ({data, columns, dynamicColumnsFunc}) => {
    return <div id={'totals-data'}>
        <ReactTable
            filterable
            columns={columns.concat(dynamicColumnsFunc(data))}
            data={data.data}
            initialState={{sorting: [{id: 1,desc: false},{id: 2,desc: false}]}}
        />
    </div>;
};

DynamicColumns.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    dynamicColumnsFunc: PropTypes.func,
    columns: PropTypes.array
};

export default DynamicColumns;