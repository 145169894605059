import React from 'react';
import PropTypes from 'prop-types';

const NumberedTextAreaList = ({values}) => {
    const csv = values.join(',');
    const text = values.join('\n');
    return <div>
        <textarea disabled={true} rows={3} defaultValue={text}></textarea>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} onClick={() => navigator.clipboard.writeText(csv)}>Select all and copy</a>
    </div>
};

NumberedTextAreaList.defaultProps = {};

NumberedTextAreaList.propTypes = {
    values: PropTypes.array.isRequired
};

export default NumberedTextAreaList;