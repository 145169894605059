import React, {Component} from 'react';
import TotalsSearchResults from "../Components/TotalsSearchResults"
import Page from "../../Components/Page";
import {getDynamicColumnsRetailers, getSeriesRetailers} from "../StoreTotals/Components/MapSalesIngestionSummary";
import _ from 'lodash'
import {getRetailerDataAsCsv} from "../StoreTotals/Components/StoreTotalsTotalDollarsCsv";
import {getStringParamOrDefault, updateQueryStringForState} from "../../../utils/urlBuilder";
import ReadinessIssues from "../../PeriodRelease/ReadinessIssues";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import ApiGetContainer from "../../../Api/ApiGetContainer";
import {createReadinessIssuesListItem} from "../../../utils/util";
import {
    retailerTotalsReadinessCreateListItems,
    retailerTotalsReadinessFormatData
} from "../../PeriodRelease/readinessFunctions";
import {currentPeriodQuery} from "../../../queries/currentPeriodQuery";
import {TotalsSearchOptions} from "../Components/TotalsSearchOptions";
import {periodAbbreviationQuery} from "../../../queries/periodAbbreviationQuery";
import {STORE_TOTALS_DEFAULT_SELECTIONS} from "../Components/TotalsSearchOptionsModal";
import {periodDetailsQuery} from "../Components/periodDetailsQuery";
import {getStartAndEndDatesFromPeriodDetails} from "../Components/getStartAndEndDatesFromPeriodDetails";

class RetailerTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchTotalsModal: getStringParamOrDefault('directSubmit', false),
            endPeriod: undefined,
            retailerId: getStringParamOrDefault('retailerId', undefined),
            endPeriodAbbreviation: getStringParamOrDefault('periodAbbreviation', undefined),
            endDate: undefined,
            startDate: undefined,
            graphqlQuery: undefined,
            accounts: undefined,
            dataSelection: 'Dollars',
            ...STORE_TOTALS_DEFAULT_SELECTIONS
        };
        this.handleRetailerChange = this.handleRetailerChange.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.shouldRenderResults = this.shouldRenderResults.bind(this);
        this.confirmationCallback = this.confirmationCallback.bind(this);
    }

    componentDidMount() {
        if (this.state.endPeriodAbbreviation){
            return makeApolloClientCall(periodAbbreviationQuery, false,
                {periodAbbreviation: this.state.endPeriodAbbreviation}).then(async (response) => {
                const endPeriod = response.records[0];
                const periodAbbreviation = endPeriod.periodAbbreviation;
                const periodDetails = await makeApolloClientCall(periodDetailsQuery, true, {periodAbbreviation});
                const {startDate, endDate} = getStartAndEndDatesFromPeriodDetails(periodDetails.records[0].weeks);
                this.setState({endPeriod, startDate, endDate});
            });
        } else {
            return makeApolloClientCall(currentPeriodQuery)
                .then(async (response) => {
                    const endPeriod = response.records[0];
                    const periodAbbreviation = endPeriod.periodAbbreviation;
                    const periodDetails = await makeApolloClientCall(periodDetailsQuery, true, {periodAbbreviation});
                    const {startDate, endDate} = getStartAndEndDatesFromPeriodDetails(periodDetails.records[0].weeks);
                    this.setState({endPeriod, startDate, endDate});
            });
        }
    }

    async handleRetailerChange(retailerId) {
        await this.setState({retailerId});
    }

    canCreateGraphqlQuery() {
        return (this.state.retailerId && this.state.startDate && this.state.endDate);
    }

    shouldRenderResults() {
        return (this.canCreateGraphqlQuery() && this.state.graphqlQuery);
    }

    async confirmationCallback(selections) {
        await this.setState({...selections});
        updateQueryStringForState({
            directSubmit: undefined,
            periodAbbreviation: this.state.endPeriod.periodAbbreviation,
            retailerId: this.state.retailerId
        });
        return await this.handleSubmitButton();
    }

    async handleSubmitButton() {
        function generateGraphqlQuery(currentState) {
            function getSingleRetailerGraphqlQuery(retailerId) {
                let includeOptions = `includePlu: true, productSnapshotOnly: false`;
                if (!currentState.includeAllData) {
                    includeOptions = `includePlu: ${currentState.includePlu}, productSnapshotOnly: ${currentState.productSnapshotOnly}, startDate: "${currentState.startDate}", endDate: "${currentState.endDate}"`;
                }
                return `retailerId_${retailerId.trim()}:salesIngestionSummary(
                            retailerId: ${retailerId.trim()},
                            sources: cleansed,
                            ${includeOptions}
                        )
                        {
                            weekEndDates
                            rollUpDates
                            stores { weekEndData { date, ${currentState.dataSelection.toLowerCase()} } }
                            errors {
                                retailerId
                                storeId
                                weekEndDate
                                dollars
                                units
                            }
                        }`;
            }

            const first = currentState.retailerId.split(',')
                .map(retailer => getSingleRetailerGraphqlQuery(retailer))
                .flatMap(retailer => retailer);
            return `{${first}}`;
        }

        if (!this.canCreateGraphqlQuery()) {
            return;
        }
        const graphqlQueryForAccountIssues = `
            query {
              periodReleaseReadiness(retailerIds: [${this.state.retailerId.split(',').map(x => Number(x))}], startDate: "${this.state.startDate}", endDate: "${this.state.endDate}") {
                periodAbbreviation
                accounts {
                  retailer {retailerId retailChain}
                  isKeyAccount
                  issues {issueType week {endDate}}
                }
              }
            }
        `;

        makeApolloClientCall(graphqlQueryForAccountIssues)
            .then(result => this.setState({
                accounts: result.periodReleaseReadiness.accounts
            }));

        const graphqlQueryForRetailerTotals = generateGraphqlQuery(this.state);
        this.setState({
            graphqlQuery: graphqlQueryForRetailerTotals
        });
    }


    render() {
        return (
            <Page name={`Retailer Totals`}>
                <TotalsSearchOptions
                    cancellationCallback={() => this.setState({showSearchTotalsModal: !this.state.showSearchTotalsModal})}
                    showSearchTotalsModal={this.state.showSearchTotalsModal}
                    endPeriod={this.state.endPeriod}
                    confirmationCallback={this.confirmationCallback}
                    retailerId={this.state.retailerId}
                    onRetailerChange={this.handleRetailerChange}
                    displayThreshold={false}
                    percentThreshold={this.state.percentThreshold}
                    dataSelection={this.state.dataSelection}
                    includeAllData={this.state.includeAllData}
                    includePlu={this.state.includePlu}
                    productSnapshotOnly={this.state.productSnapshotOnly}
                    shouldRenderChart={this.state.shouldRenderChart}
                    stateFilterOptions={[]}
                />
                {
                    this.shouldRenderResults() &&
                    <>
                        {this.getIssues()}
                        <ApiGetContainer
                            graphqlQuery={this.state.graphqlQuery}
                            useApolloCache={true}
                            componentToRender={TotalsSearchResults}
                            componentToRenderProps={
                                {
                                    dataSelection: this.state.dataSelection,
                                    getSeriesFunc: getSeriesRetailers,
                                    dynamicColumnsFunc: getDynamicColumnsRetailers,
                                    downloadAsCSVFunc: getRetailerDataAsCsv,
                                    retailerIds: _.replace(this.state.retailerId, ",", "_"),
                                    readinessIssuesCreatListItemsFunc: retailerTotalsReadinessCreateListItems,
                                    readinessFormatDataFunc: retailerTotalsReadinessFormatData,
                                    defaultSortedId: "Retailer",
                                    shouldRenderChart: this.state.shouldRenderChart,
                                    trClassName: 'retailer-totals-row'
                                }
                            }
                        />
                    </>
                }
            </Page>
        )
            ;
    }

    getIssues() {
        if (this.state.accounts && !_.isEmpty(this.state.accounts.filter(account => account.issues !== null))) {

            return <>
                <h2>Retailer Issues</h2>
                {this.state.accounts.map((account, key) => (<ReadinessIssues key={key}
                                                                             issues={account.issues}
                                                                             retailChain={account.retailer.retailChain}
                                                                             collapsedContent={`${account.retailer.retailChain} ${account.issues.length} issues`}
                                                                             createListItem={createReadinessIssuesListItem}
                />))}
            </>
        }
    }
}

export default RetailerTotals;