import React from 'react';
import PropTypes from 'prop-types';
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {
    INDEX_FIELD,
    RETAILER_ID_FIELD,
    RETAILER_ID_NAME_COLUMN
} from "../../Components/ReactTable/common_custom_columns";
import {serviceRetailerDropdown} from "../../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import {LoadingWrapper} from "../../Components/LoadingWrapper";

const RetailerListConfiguration = ({endpoint, configDescription, configFootnote, alertDescriptions}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/${endpoint}/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    return <LoadingWrapper loading={loading}>
        <div>
            <span>{configDescription}</span>
            <ul>{alertDescriptions.map(alertDescription => <li key={alertDescription}><b>{alertDescription}</b></li>)}</ul>
            {configFootnote && <div>
                <div>{configFootnote}</div>
                <div>&nbsp;</div>
            </div>}
        </div>
        <EditableReactTableV2
            filterable
            data={data.data}
            columns={[RETAILER_ID_NAME_COLUMN]}
            initialState={{pagination: {pageSize: 25}, sorting: [{id: "retailerIdName", desc: false}]}}
            editTable={[INDEX_FIELD, RETAILER_ID_FIELD(serviceRetailerDropdown().data)]}
            onRowEditConfirmed={onRowEditConfirmed}
            onRowDeleteConfirmed={onRowDeleteConfirmed}
        />
    </LoadingWrapper>
};

RetailerListConfiguration.defaultProps = {};

RetailerListConfiguration.propTypes = {
    endpoint: PropTypes.string,
    configDescription: PropTypes.string,
    configFootnote: PropTypes.string,
    alertDescriptions: PropTypes.arrayOf(PropTypes.string)
};

export default RetailerListConfiguration;