import {getSingleRowActionQueryParams} from "../Components/ReactTable/helpers";
import {makeApiCallPost} from "../../Api/makeApiCall";

export function isValidMatchingEndDate(periodParam, endDate, suffix) {
    return Boolean(
        (periodParam && endDate && periodParam.toString().length === 10)
        && (suffix === '04' || (endDate.toString().replace(/-/g, "") + suffix === periodParam.toString()))
        && (suffix === '01' || periodParam.toString().endsWith('04'))
    );

}

export function isValidSpinsWeek(spinsWeek){
    const spinsWeekRegex = new RegExp(/^\d{8}$/g);
    return spinsWeekRegex.test(spinsWeek)
}

export const COLUMNS_TO_CHECK_FOR_UNIQUE = ['insightsStart', 'isCurrentPeriodWeek', 'isCurrentPeriodQuad', 'isCurrentStoreInsightsWeek', 'isCurrentStoreInsightsPeriod', 'isCurrentMarketInsightsPeriod']

export async function setAllOtherRowsToNotCurrent(endpointUrl, newData, records) {
    let fieldsUpdated = [];
    const newIndex = newData['index'];
    Object.keys(newData).forEach(key => {
        if (newData[key] === 1 && COLUMNS_TO_CHECK_FOR_UNIQUE.includes(key)) {
            fieldsUpdated.push(key)
        }
    });
    if (fieldsUpdated.length > 0) {

        const rawRecords = records.map(row => row.original)

        const activeRecords = rawRecords.filter(record => {
            let activeForFieldsUpdated = []
            fieldsUpdated.forEach(field => {
                if (record[field] === 1) {
                    activeForFieldsUpdated.push(true)
                }
            })
            return record['index'] !== newIndex && activeForFieldsUpdated.length > 0;
        })

        for (const row of activeRecords) {
            fieldsUpdated.forEach(field => row[field] = 0)
            await makeApiCallPost(endpointUrl + getSingleRowActionQueryParams("update", false), row);
        }
    }
}
