import React, {useState} from 'react'
import Page from "../Components/Page"
import {getEdpAirflowUri} from "../../utils/airflow"
import {makeApolloClientCall} from "../../Api/makeApolloApiCall"
import {makeApiCall, makeApiCallPost} from "../../Api/makeApiCall"
import _ from "lodash"
import MarketPeriodAssignableMeasuresDagLogs from "./MarketPeriodAssignableMeasuresDagLogs";
import {SPINS_OUTLETS_QUERY} from "../../queries/spinsOutlets";
import {BETA_GENERATE_MEASURES_DAG_ID} from "./beta";

export const marketPeriodQuery = `query { marketPeriodList }`

const MeasuresRelease = (   ) => {
    const [airflowUrl, updateAirflowUrl] = useState(undefined)
    const [marketPeriodList, updateMarketPeriodList] = useState([])
    const [outlets, updateOutlets] = useState([])
    const [logsByOutlet, updateLogsByOutlet] = useState({})
    const [logsByOutletLoaded, setLogsByOutletLoaded] = useState(false)
    const [marketPeriodRunIdentifiersByOutlet, updateMarketPeriodRunIdentifiersByOutlet] = useState(undefined)
    const marketPeriodRunIdentifierByOutletURL = `${process.env.REACT_APP_EDP_API_BASE_URL}/marketPeriodRunIdentifiersByOutlet/`;

    React.useEffect(() => {
        const processParams = ['measures_generate_measures_bq', BETA_GENERATE_MEASURES_DAG_ID].map(dag_id => `processName=${dag_id}`).join('&')
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/airflow/daglogs?${processParams}`)
            .then(result => result.metadata || [])
            .then(logs => logs.filter(log => log.status === 'success'))
            .then(logs => _.groupBy(logs, log => {
                try {
                    return log.conf?.outlet
                } catch {
                    return ''
                }
            }))
            .then(mappedLogs => {
                updateLogsByOutlet(mappedLogs)
                setLogsByOutletLoaded(true)
            })
        makeApiCall(marketPeriodRunIdentifierByOutletURL).then(result => updateMarketPeriodRunIdentifiersByOutlet(result))
        makeApolloClientCall(SPINS_OUTLETS_QUERY).then(result => updateOutlets(result.outlets.map(outlet => outlet.name)))
        makeApolloClientCall(marketPeriodQuery).then(result => updateMarketPeriodList(result.marketPeriodList.sort().reverse()))
        getEdpAirflowUri().then(edpAirflowUrl => updateAirflowUrl(edpAirflowUrl))
    }, [marketPeriodRunIdentifierByOutletURL])


    const onMarketPeriodChange = (postBody) => {
        makeApiCallPost(marketPeriodRunIdentifierByOutletURL, postBody)
            .then(data => updateMarketPeriodRunIdentifiersByOutlet(data))
    }

    return (<Page name={"Release"}>
        <h1>Market Insight Runs By Outlet</h1>
        {
            outlets.sort().map(outlet => {
                const logsPerOutlet = logsByOutlet[outlet]
                const logs = (logsByOutletLoaded && logsPerOutlet === undefined) ? [] : logsPerOutlet
                return <div key={`${outlet}-logs`} data-testid={`${outlet}-logs`}>
                    {airflowUrl && marketPeriodList && marketPeriodRunIdentifiersByOutlet &&
                        <MarketPeriodAssignableMeasuresDagLogs
                            airflowUrl={airflowUrl}
                            outlet={outlet}
                            logs={logs}
                            marketPeriodList={marketPeriodList}
                            runIdentifierMarketPeriods={marketPeriodRunIdentifiersByOutlet[outlet]}
                            onMarketPeriodChange={onMarketPeriodChange}
                        />
                    }
                    <br/>
                </div>
            })
        }
    </Page>)
}

MeasuresRelease.defaultProps = {}

MeasuresRelease.propTypes = {}

export default MeasuresRelease