import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCall} from "../../Api/makeApiCall";
import ReactTable from "../Components/ReactTable";
import ReIngest from "../IngestionLogs/Columns/ReIngest";
import FileLink from "../IngestionLogs/Columns/FileLink";
import _ from "lodash";
import { LoadingWrapper } from "../Components/LoadingWrapper";

export const RETAILER_FILES_COLUMNS = [
    {
        header: 'Archived Time',
        accessorKey: 'timeCreated',
        size: 28
    },
    {
        header: 'FTP',
        accessorKey: 'ftp',
        size: 48
    },
    {
        header: 'File',
        accessorKey: 'uniqueArchiveFileName',
        filterFn: (row, columnIds, filterValue) => {
            return row.original?.uniqueArchiveFileName.toUpperCase().includes(filterValue.toUpperCase());
        },
        cell: ({row, getValue}) =>
            <FileLink
                displayText={getValue()}
                gcsFileLocation={row.original.fullFilePath}
            />
    },
    {
        header: 'Re-Ingest',
        accessorKey: 'fullFilePath',
        enableColumnFilter: false,
        cell: ({row, getValue}) => {
            return <div>
                <ReIngest className='original' original={row.original} value={getValue()}/>
            </div>
        },
        size: 30
    }
]

const RetailerFiles = ({fileType, retailerId}) => {
    const gscDirectoryPrefix = `gs://spins-edp-${process.env.REACT_APP_ENV}-archive/p_data=${fileType}/p_retailer_id=${retailerId}/`
    const [retailerFilesList, updateRetailerFilesList] = useState([]);
    const [loading, updateLoading] = useState(false);

    React.useEffect(() => {
        const listBlobsUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/listGcsFiles?url=`
        const fileRegex = new RegExp("p_ftp=([^\\/]*)\\/(.*)");
        updateLoading(true)
        makeApiCall(`${listBlobsUrl}${gscDirectoryPrefix}`)
            .then(results => {
                const files = results.map(file => ({file, ftpMatch: fileRegex.exec(file.url)}))
                    .filter(({ftpMatch}) => ftpMatch)
                    .map(({file, ftpMatch}) => {
                        const retailerFtp = ftpMatch[1]
                        return {
                            fullFilePath: file.url,
                            ftp: retailerFtp,
                            uniqueArchiveFileName: ftpMatch[2],
                            retailerId,
                            isBulk: false,
                            timeCreated: file.timeCreatedChicago?.substring(0, 19)
                        }
                    })
                updateRetailerFilesList(files)
                return files;
            }).finally(() => updateLoading(false))
    }, [fileType, retailerId, gscDirectoryPrefix])

    return <>
        <LoadingWrapper loading={loading}>
            {!_.isEmpty(retailerFilesList) ? <ReactTable
                    data={retailerFilesList}
                    columns={RETAILER_FILES_COLUMNS}
                    filterable
                /> :
                <div className={"retailer-files-no-data-found"}>
                    <FileLink
                        displayText={"No Data Found"}
                        gcsFileLocation={gscDirectoryPrefix}
                    />
                </div>}
        </LoadingWrapper>
    </>;
};

RetailerFiles.defaultProps = {};

RetailerFiles.propTypes = {
    fileType: PropTypes.string.isRequired,
    retailerId: PropTypes.number.isRequired
};

export default RetailerFiles;