import React, {useEffect, useState} from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {getRetailerName} from "../../utils/retailerMapCache";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {
    EditFormDateInputField,
    EditFormNumberInputField,
    EditFormTextInputField
} from "../Components/ReactTable/EditRecordForm";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import InactiveRowKey from "../Components/InactiveRowKey";
import DownloadCsvFromBackendServiceButton from "../../utils/components/DownloadCsvFromBackendServiceButton";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";
import gql from "graphql-tag";
import IconImage, {Icons} from "../Components/IconImage";
import {AddressMapInputField} from "./StoreService/AddressMapInputField";
import {AddressStateInputField} from "./StoreService/AddressStateInputField";
import {RetailerInputField} from "./StoreService/RetailerInputField";
import {BulkUploadStoresButton} from "./StoreService/BulkUploadStoresButton";

export const SALESFORCE_STORES_QUERY = gql`
    query {
        salesforceStores{
            notActiveStore
            storeId
        }
    }`;

const StoreService = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/stores/`;
    const downloadEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/stores`;
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed, onDeleteAllConfirmed, onBulkRowUploadConfirmed } = useServiceBase({ endpointUrl });
    const [salesforceStoreIdMap, setSalesforceStoreIdMap] = useState([]);
    const [decodeTablesLoading, setDecodeTablesLoading] = useState(true);

    const customColumns = [
        {
            header: "Retailer",
            id: "retailerIdName",
            filterable: true,
            accessorKey: "retailerId",
            sticky: "left",
            size: 250,
            cell: ({ row }) => <RetailerIdAndName retailerId={row.original.retailerId} />,
            filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.retailerId} - ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
        },
        {header: "Store Name", sticky: "left", size: 250, accessorKey: "storeName"},
        {header: "Store Id", size: 100, accessorKey: "storeId"},
        {header: "Retail Tag", accessorKey: "retailTag"},
        {header: "Store Tag", size: 100, accessorKey: "storeTag"},
        {header: "Store Code", accessorKey: "storeCode"},
        {header: "External Store Id", accessorKey: "externalStoreId"},
        {header: "Account", accessorKey: "account"},
        {header: "Banner", accessorKey: "banner"},
        {header: "Consumer Name", accessorKey: "consumerName"},
        {
            header: "Geographical Address?",
            id: "geographicalAddress",
            filterable: false,
            accessorFn: (row) => !!(row.latitude && row.longitude) ? 'yes' : 'no',
            size: 250,
            cell: ({ row }) => (row.original.latitude && row.original.longitude) ? <><IconImage iconType={Icons.VALIDATED}/><span>({row.original.latitude}/{row.original.longitude})</span></> : <></>,
            filter: () => <></>
        },
        {header: "Addr 1", accessorKey: "addr1"},
        {header: "Addr 2", accessorKey: "addr2"},
        {header: "City", accessorKey: "city"},
        {header: "State", accessorKey: "state"},
        {header: "County", accessorKey: "county"},
        {header: "Country", accessorKey: "country"},
        {header: "Zip 1", accessorKey: "zip1"},
        {header: "Zip 2", accessorKey: "zip2"},
        {header: "Closed", accessorKey: "closed"},
        {header: "Closed Date", accessorKey: "closedDate"},
        {header: "Acv", accessorKey: "acv"},
    ];

    useEffect(() => {

        setDecodeTablesLoading(true)

        Promise.all([
            makeApolloClientCall(SALESFORCE_STORES_QUERY),
        ])
            .then(([salesForceStoresQueryResults]) => {
                let storeIdMap = {};
                salesForceStoresQueryResults.salesforceStores.forEach((store) => {
                    const storeIdNumber = Number(store.storeId);
                    storeIdMap[storeIdNumber] = {
                        storeId: storeIdNumber,
                        notActiveStore: store.notActiveStore === true
                    }
                });
                setSalesforceStoreIdMap(storeIdMap);
                setDecodeTablesLoading(false)
            })
    }, []);

    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        return onRowEditConfirmed(sanitizedData);
    }

    return (
        <Page beta name={"Store Service"}>
            <LoadingWrapper loading={loading || decodeTablesLoading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={customColumns}
                    initialState={{ pagination: { pageSize: 25 }, sorting: [{id: "retailerIdName", desc: false}, {id: "storeId", desc: false}] }}
                    actionButtonsProps={{sticky: true}}
                    editModalAdditionalClass={"store-service-edit-modal"}
                    editTable={[
                        {id: "retailer", Component: args => <RetailerInputField {...args} label={"Retailer"}/>},
                        {id: "storeName", Component: args => <EditFormTextInputField{...args}  label={"Store Name"} isRequired/>},
                        {id: "storeId", Component: args => <EditFormNumberInputField {...args} disabled={true} label={"Store Id"}/>},
                        {id: "storeTag", Component: args => <EditFormNumberInputField {...args} label={"Store Tag"}/>},
                        {id: "addr1", Component: args => <EditFormTextInputField{...args}  label={"Address 1"}/>},
                        {id: "addr2", Component: args => <EditFormTextInputField{...args}  label={"Address 2"}/>},
                        {id: "city", Component: args => <EditFormTextInputField{...args}  label={"City"}/>},
                        {id: "state", Component: args => <AddressStateInputField{...args}/>},
                        {id: "county", Component: args => <EditFormTextInputField{...args}  label={"County"}/>},
                        {id: "country", Component: args => <EditFormTextInputField{...args}  label={"Country"}/>},
                        {id: "zip1", Component: args => <EditFormTextInputField{...args}  label={"Zipcode 1"}/>},
                        {id: "zip2", Component: args => <EditFormTextInputField{...args}  label={"Zipcode 2"}/>},
                        {id: "storeAddress", Component: args => <AddressMapInputField {...args} label={"Store Address"}/>},
                        {id: "latitude", defaultValue: 0, Component: args => <EditFormNumberInputField {...args} disabled label={"Latitude"}/>},
                        {id: "longitude", defaultValue: 0, Component: args => <EditFormNumberInputField {...args} disabled label={"Longitude"}/>},
                        {id: "storeCode", Component: args => <EditFormTextInputField{...args}  label={"Store Code"} isRequired/>},
                        {id: "externalStoreId", Component: args => <EditFormTextInputField{...args}  label={"External Store Id"}/>},
                        {id: "account", Component: args => <EditFormTextInputField{...args}  label={"Account"}/>},
                        {id: "banner", Component: args => <EditFormTextInputField{...args}  label={"Banner"}/>},
                        {id: "consumerName", Component: args => <EditFormTextInputField{...args}  label={"Consumer Name"} isRequired/>},
                        {id: "closed", Component: args => <EditFormNumberInputField {...args} label={"Closed"}/>},
                        {id: "closedDate", Component: args => <EditFormDateInputField{...args}  label={"Closed Date"}/>},
                        {id: "acv", Component: args => <EditFormNumberInputField {...args} label={"ACV"}/>},
                        {id: "areaCode", Component: args => <EditFormTextInputField{...args}  label={"Area Code"}/>},
                        {id: "dairyDoorNum", Component: args => <EditFormNumberInputField {...args} label={"Dairy Door Number"}/>},
                        {id: "dbTag", Component: args => <EditFormTextInputField{...args}  label={"DB Tag"} isRequired/>},
                        {id: "notes", Component: args => <EditFormTextInputField{...args}  label={"Notes"}/>},
                        {id: "phone", Component: args => <EditFormTextInputField{...args}  label={"Phone"}/>},
                        {id: "primarySvc", Component: args => <EditFormTextInputField{...args}  label={"Primary Svc"} isRequired/>},
                        {id: "refDoorNum", Component: args => <EditFormNumberInputField {...args} label={"Ref Door Number"}/>},
                        {id: "storeLevelInd", Component: args => <EditFormNumberInputField {...args} label={"Store Level Ind"}/>},
                        {id: "version", Component: args => <EditFormNumberInputField {...args} label={"Version"}/>},
                        {id: "xsMkt", Component: args => <EditFormNumberInputField {...args} label={"XS Mkt"} isRequired/>},
                        {id: "retailTag", Component: args => <EditFormNumberInputField {...args} disabled label={"Retail Tag"}/>}
                    ]}
                    getTrProps={(rowInfo) => {
                        let className = ''
                        const mapEntry = salesforceStoreIdMap[rowInfo.original.storeId];
                        if (mapEntry && mapEntry.notActiveStore) {
                            className = 'inactive-store-row'
                        }
                        return {className}
                    }}
                    onRowEditConfirmed={onRowEditConfirmedWrapper}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                >
                    {
                        () => {
                            return (
                                <div>
                                    <div className={'market-service-content-controls'}>
                                        <InactiveRowKey inactiveColorClassName={'inactive-store-row'}/>
                                        <DownloadCsvFromBackendServiceButton endpoint={downloadEndpointUrl}
                                                                             fileName={'all-stores.csv'}/>
                                        <BulkUploadStoresButton onSubmit={onBulkRowUploadConfirmed}/>
                                    </div>

                                </div>
                            )
                        }
                    }
                </EditableReactTableV2>
            </LoadingWrapper>
        </Page>
    );
};

export default StoreService;
