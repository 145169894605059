import React, {useEffect, useState} from 'react';
import {getBooleanParamOrDefault, getStringParamOrDefault} from "../../../utils/urlBuilder";
import Page from "../../Components/Page";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import ReactTable from "../../Components/ReactTable";
import NameValueLabels from "../../Components/NameValueLabels";
import LoadingSpinner from "../../Components/LoadingSpinner";

const MissingWeeksSpotlightDrillDown = () => {
    const retailerId = getStringParamOrDefault("retailerId", undefined);
    const retailerName = getStringParamOrDefault("retailerName", undefined);
    const fromSchemaSelection = getStringParamOrDefault("fromSchemaSelection", undefined);
    const toSchemaSelection = getStringParamOrDefault("toSchemaSelection", undefined);
    const runIdentifier = getStringParamOrDefault("runIdentifier", undefined);
    const ignorePlu = getBooleanParamOrDefault("ignorePlu", false);
    const [missingWeeksData, setMissingWeeksData] = useState(undefined);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setLoading(true)
        makeApolloClientCall(
            `query { getMissingDataExtractSpotlightDrillDown(
                                              runIdentifier:"${runIdentifier}",
                                              retailerId: ${retailerId}
                          ){
                            weekId
                            storeId
                          }
                        }`
        )
            .then((missingWeeksQueryResults) => {
                setMissingWeeksData(missingWeeksQueryResults?.getMissingDataExtractSpotlightDrillDown)
            })
            .finally(() => setLoading(false))
    }, []);

    return (<Page name={`Missing Weeks Spotlight: (${retailerId}) ${retailerName}`}>
            <div>
                <div className={'spotlight-selection-group'}>
                    <div className={'column'}>
                        <h6 className={'spotlight-header'}>COMPARE</h6>
                        <div className={'schema-selection'}>
                            <div className={'schema-field'}>
                                <NameValueLabels containerClassName={"from-schema-selection-name-value-label"}
                                                 labelValuePairs={[{
                                                     label: "From Table:",
                                                     value: fromSchemaSelection
                                                 }]}/>
                            </div>
                            <div className={'schema-field'}>
                                <NameValueLabels containerClassName={"to-schema-selection-name-value-label"}
                                                 labelValuePairs={[{label: "To Table:", value: toSchemaSelection}]}/>
                            </div>
                            <div className={'schema-field'}>
                                <NameValueLabels containerClassName={"ignore-plu-selection-name-value-label"}
                                                 labelValuePairs={[{
                                                     label: "Ignore PLU:",
                                                     value: ignorePlu.toString()
                                                 }]}/>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && <LoadingSpinner/>}
                {missingWeeksData && <ReactTable
                    filterable
                    data={missingWeeksData}
                    initialState={{sorting: [{id: 'storeId', desc: true}, {id: 'weekId', desc: true}]}}
                    columns={[{header: 'Store ID', accessorKey: 'storeId'}, {header: 'Week', accessorKey: 'weekId'}]}/>}
            </div>
        </Page>
    )
}
export default MissingWeeksSpotlightDrillDown;