import moment from "moment";
import {getDrillDownLink, getMissingWeeksDrillDownLink} from "./ExtractSpotlight/extractSpotlightFunctions";
import _ from "lodash";
import React from "react";
import ReactTableFilterDropdown from "../ReactTable/ReactTableFilterDropdown";

export function getMissingWeeksSpotlightColumns(fromSchemaSelection, toSchemaSelection, ignorePlu, runIdentifier){
    return [
        {
            header: 'Retailer ID',
            accessorKey: 'retailerId',
            cell: ({row}) => <div className='retailer-id-cell-element'>
                <a target='_blank' rel="noopener noreferrer"
                   href={getMissingWeeksDrillDownLink({
                       ...row.original,
                       fromSchemaSelection, toSchemaSelection, ignorePlu, runIdentifier
                   })}
                   title={"Missing Data Spotlight Drill Down"}>
                    {row.original.retailerId}</a>
            </div>
        },
        {
            header: 'Retailer Name',
            accessorKey: 'retailerName',
            cell: props => <div className='retailer-name-cell-element'>{props.getValue()}</div>
        },
        {
            header: 'Problem Weeks',
            accessorKey: 'problemWeeks',
            enableColumnFilter: false,
            cell: props => <div className='problem-weeks-cell-element'>{props.getValue()}</div>
        }
    ]
}

export function getSpotlightColumns(fromSchemaSelection, toSchemaSelection, ignorePlu, ignoreIfps, thresholdDollars, thresholdUnits) {
    return [
        {
            header: 'Retailer ID',
            accessorKey: 'retailerId',
            cell: ({row}) => <div className='retailer-id-cell-element'>
                <a target='_blank' rel="noopener noreferrer"
                   href={getDrillDownLink({
                       ...row.original,
                       fromSchemaSelection, toSchemaSelection, ignorePlu, ignoreIfps, thresholdDollars, thresholdUnits
                   })}
                   title={"Spotlight Drill Down"}>
                    {row.original.retailerId}</a>
            </div>
        },
        ...getBaseSpotlightColumns()
    ];
}


export function getCheckpointSpotlightColumns(){
    return [
        {
            header: 'Retailer ID',
            accessorKey: 'retailerId',
            cell: props => <div>{props.getValue()}</div>
        },
        ...getBaseSpotlightColumns()
    ];
}

export function getBaseSpotlightColumns(){
    const eightyFourDaysAgo = moment(Date.now()).add(-84, 'days').format('yyyy-MM-DD');

    return [
        {
            header: 'Retailer Name',
            accessorKey: 'retailerName',
            cell: props => <div className='retailer-name-cell-element'>{props.getValue()}</div>
        },
        {
            header: 'Weeks',
            accessorKey: 'weeks',
            enableColumnFilter: false,
            cell: props => <div className='weeks-cell-element'>{props.getValue()}</div>
        },
        {
            id: 'problemWeeks',
            header: 'Problem Weeks',
            accessorKey: 'problemWeeks',
            cell: props => <div className='problem-weeks-cell-element'>{props.getValue()}</div>,
            filterFn: (row, columnIds, filterValue) => {
                return filterValue === 'all' || row.original?.problemWeeks > 0;
            },
            filter: (theseProps) => {
                const options = [
                    {value: "problemOnly", label: "Problem Retailers"},
                    {value: "all", label: "All Retailers"}
                ];
                return <ReactTableFilterDropdown
                    id={"problem_weeks"}
                    options={options}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                />
            }
        },
        {
            header: `Problem Weeks (last 12, since ${eightyFourDaysAgo})`,
            accessorKey: 'problemWeeksLast12',
            enableColumnFilter: false,
            cell: props => <div className='problem-weeks-cell-element'>{props.getValue()}</div>,
        },
        {
            header: '% Good',
            id: 'percentGood',
            accessorKey: 'percentGood',
            enableColumnFilter: false,
            sortingFn: (rowA, rowB) => {
                const percentGoodFromA = rowA?.original?.percentGood;
                const percentGoodFromB = rowB?.original?.percentGood;
                return Math.round(percentGoodFromA * 100) - Math.round(percentGoodFromB * 100)
            },
            cell: props => <div className='percent-good-cell-element'>{_.round(props.getValue() * 100, 2)}</div>
        }
    ]
}