import {getBaseFileName} from "../../utils/GetBaseFileName";

export const nameDisplay = (payload) => {
    if (payload && !payload.is_bulk_load) {
        const lastIndex = payload.archived_file_url.lastIndexOf('/')
        const fileName = payload.archived_file_url.substring(lastIndex + 1)
        return fileName.length > 0 ? getBaseFileName(fileName) : '(filename unknown)'
    } else if (!payload) {
        return undefined
    }

    const lastIndex = payload.archived_file_url.lastIndexOf('p_ftp=')
    return lastIndex > 0 ? payload.archived_file_url.substring(lastIndex + 'p_ftp='.length):'(ftp unknown)' ;
}