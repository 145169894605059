import React from 'react';
import MarketServiceGenericService from "./MarketServiceGenericService";

function GeographyTypeService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/geographyType/`;
    return (<MarketServiceGenericService
        endpointUrl={endpointUrl}
        pageName={"Geography Type Service"}
    />);
}

export default GeographyTypeService;