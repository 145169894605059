import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCall} from '../../Api/makeApiCall';
import Page from '../Components/Page';
import {getManifestTableColumns} from "./ManifestTableColumns";
import ReactTable from "../Components/ReactTable";

export const ManifestTable = ({ftp}) => {
    const [data, setData] = useState([]);
    const [ftpConfigData, setFtpConfigData] = useState({});
    const [timestamp, setTimestamp] = useState(Date.now())


    React.useEffect(() => {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/manifest/?manifestFileName=${ftp}`)
            .then(newData => setData(
                    Object.entries(newData.configFiles)
                        .map(([key, value]) => ({
                                ingestFileName: key,
                                configFileName: value
                            })
                        )
                )
            );

        const params = {
            ftp: ftp,
            configType: "file_type"
        };

        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/`, params)
            .then(result => {
                setFtpConfigData(result)
            });

    }, [timestamp, ftp]);

    const onSubmitCallback = () => {
        setTimestamp(Date.now())
    }

    return (<Page name={`${ftp} Manifest`} className={'manifest-information-service-base'} displayHelmetHeader={false}>
        <ReactTable
            data={data}
            columns={getManifestTableColumns(ftp, onSubmitCallback, ftpConfigData)}
        />
    </Page>);
};

ManifestTable.propTypes = {
    ftp: PropTypes.string
};
