import {makeApolloClientCall} from "../../Api/makeApolloApiCall";

export function labelCurrentPeriodWeek(response) {
    return response.periods.map(period => {
        const labelWeekId = period.isCurrentPeriodQuad ? `${period.weekId} (Current Period)` : period.weekId.toString();
        return {
            label: labelWeekId,
            value: period.weekId.toString(),
            isCurrentPeriodQuad: period.isCurrentPeriodQuad,
            endDate: period.endDate};
    });
}

export function labelCurrentPeriodQuad(response) {
    return response.periods.map(period => {
        const labelPeriodAbbreviation = period.isCurrentPeriodQuad ? `${period.periodAbbreviation} (Current Period Quad)` : period.periodAbbreviation.toString();
        return {
            label: labelPeriodAbbreviation,
            value: period.weekId.toString(),
            isCurrentPeriodQuad: period.isCurrentPeriodQuad,
            endDate: period.endDate};
    });
}

export function makeApiCallForPeriods(periodEndWeeksOnly){
    return makeApolloClientCall(`{ periods${periodEndWeeksOnly ? "(periodEndWeeksOnly: true)" : ""}{ weekId, quadId, endDate, periodAbbreviation, spinsWeek, isCurrentPeriod, isCurrentPeriodQuad } }`);
}
