import React from 'react';
import PropTypes from 'prop-types';
import Page from "../Components/Page";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import RetailerFiles from "./RetailerFiles";


export const RetailerFilesTabs = ({retailerId}) => {
    return <Page name={"Retailer Archive Files"}>
        <Tabs>
            <TabList>
                <Tab>Sales</Tab>
                <Tab>Items</Tab>
                <Tab>Stores</Tab>
                <Tab>Tlog</Tab>
            </TabList>
            <TabPanel id={"sales"}>
                <RetailerFiles retailerId={retailerId} fileType={"sales"}/>
            </TabPanel>
            <TabPanel id={"items"}>
                <RetailerFiles retailerId={retailerId} fileType={"items"}/>
            </TabPanel>
            <TabPanel id={"stores"}>
                <RetailerFiles retailerId={retailerId} fileType={"stores"}/>
            </TabPanel>
            <TabPanel id={"tlog"}>
                <RetailerFiles retailerId={retailerId} fileType={"tlog"}/>
            </TabPanel>
        </Tabs>
    </Page>;
};

RetailerFilesTabs.propTypes = {
    retailerId: PropTypes.number.isRequired,

}