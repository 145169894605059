import Page from '../Components/Page';
import React, {useEffect, useState} from 'react';
import {makeApiCallPost} from '../../Api/makeApiCall';
import {toast} from 'react-toastify';
import Files from 'react-files';
import {Label} from 'react-foundation';
import {labelCurrentPeriodWeek} from '../Components/periodDropdownMethods';
import ExtractsTriggerConfirmationModal from './ExtractsTriggerConfirmationModal';
import {toastError, toastSuccess} from '../../utils/toast';
import {RadioButtonGroup} from '../../utils/components/RadioButtonGroup';
import OptionalTextBox from './OptionalTextBox';
import {prefixFilterRules} from '../../utils/prefixFilterRules';
import {invokeDAGviaApiPost} from '../../Api/invokeDag';
import CheckpointDropdown from "../Components/CheckpointDropdown";
import AirflowDagLogs from "../Components/airflow/dagLogs/AirflowDagLogs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import OptionalDropDown from "./OptionalDropDown";
import {INDEX_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import Checkbox from "../Components/Checkbox";
import {getValueFromOptions} from "../../utils/maybeAddLabelToOptions";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";

const CLEANSE_FILL_EXTRACT_DAG_NAME = 'cleanse_fill_extract';
const PROMO_EXTRACT_DAG_NAME = 'promo_extract';

const DAG_TO_CONFIG = {
    [CLEANSE_FILL_EXTRACT_DAG_NAME]: 'cleanseFillExtractConfig',
    [PROMO_EXTRACT_DAG_NAME]: 'promoExtractConfig'
}

export const NucleusExtractsTriggerDag = () => {
    const pageName = 'Nucleus Extract Processes';

    const [startWeekId, updateStartWeekId] = useState(undefined);
    const [useStartWeekId, updateUseStartWeekId] = useState(false);
    const [endWeekId, updateEndWeekId] = useState(undefined);
    const [useEndWeekId, updateUseEndWeekId] = useState(false);

    const [runMissingPromoAndPromoCfeMismatch, updateRunMissingPromoAndPromoCfeMismatch] = useState(true)
    const [runPromoExtract, updateRunPromoExtract] = useState(false)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [nucleusDag, updateNucleusDag] = useState(CLEANSE_FILL_EXTRACT_DAG_NAME)
    const [showConfirmationModal, updateShowConfirmationModal] = useState(false);
    const [usePrefix, updateUsePrefix] = useState(false);
    const [extractsRunPrefix, updateExtractsRunPrefix] = useState(undefined);
    const [checkpointPath, updateCheckpointPath] = useState(undefined);
    const [periodDropdownOptions, updatePeriodDropdownOptions] = useState([]);


    const isCleanseFillExtract = nucleusDag === CLEANSE_FILL_EXTRACT_DAG_NAME;
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/${DAG_TO_CONFIG[nucleusDag]}/`;
    const {
        loading,
        data,
        onRowEditConfirmed,
        onDeleteAllConfirmed,
        onRowDeleteConfirmed,
        refreshData
    } = useServiceBase({endpointUrl});

    useEffect(() => {
        makeApolloClientCall(`{ periods { weekId, quadId, endDate, periodAbbreviation, spinsWeek, isCurrentPeriod, isCurrentPeriodQuad } }`)
            .then((results) => {
                return labelCurrentPeriodWeek(results)
            }).then((options) => {
            updatePeriodDropdownOptions(options)
        })
    }, []);

    function modalConfirmed(retailerIds) {
        let data = {
            start_week_id: startWeekId,
            end_week_id: endWeekId,
            retailer_ids: retailerIds,
            extracts_run_prefix: extractsRunPrefix,
            checkpoint_path: checkpointPath,
            run_missing_promo_and_promo_cfe_mismatch: isCleanseFillExtract ? runMissingPromoAndPromoCfeMismatch : undefined,
            run_promo_extract: isCleanseFillExtract ? runPromoExtract : undefined
        };

        invokeDAGviaApiPost(nucleusDag, data)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `${nucleusDag} DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `${nucleusDag} DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `${nucleusDag} DAG trigger failed with error ${JSON.stringify(e)}`)
            })
            .finally(() => updateShowConfirmationModal(false));

    }

    function uploadCsvPostCall(fileContents) {
        const data = {data: fileContents};
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/extractsUpdate/${DAG_TO_CONFIG[nucleusDag]}/`;
        return makeApiCallPost(url, JSON.stringify(data));
    }

    function onFilesChange(files) {
        const fileReader = new FileReader();
        const file = files[0];
        fileReader.onload = (loadedFiles) => {
            return uploadCsvPostCall(loadedFiles.target.result)
                .then(() => {
                    setLoadingCSV(false);
                    refreshData()
                    return Promise.resolve();
                }).catch(error => {
                    console.log("=============> error: ", error)
                });
        };
        setLoadingCSV(true);
        fileReader.readAsText(file);
    }

    return (
        <Page name={pageName}>
            <RadioButtonGroup
                callback={(dagToTrigger) => {
                    updateCheckpointPath(undefined);
                    updateNucleusDag(dagToTrigger);
                }}
                selectedValue={nucleusDag}
                radioButtonOptions={Object.keys(DAG_TO_CONFIG)}
            />
            <div className={'trigger-dag-page-controls'}>
                <div className={'left-things'}>
                    {<>
                        <OptionalDropDown checkboxText={'Use an optional Start Week ID for the dataset you create'}
                                          labelText={'Start Week ID'}
                                          selectedDropDownValue={getValueFromOptions(startWeekId, periodDropdownOptions)}
                                          checkBoxIsChecked={useStartWeekId}
                                          onCheckBoxChange={updateUseStartWeekId}
                                          onDropDownChange={updateStartWeekId}
                                          dropDownValues={periodDropdownOptions}
                                          placeholder={"Select Start Week Id..."}

                        />
                        <OptionalDropDown checkboxText={'Use an optional End Week ID for the dataset you create'}
                                          labelText={'End Week ID'}
                                          selectedDropDownValue={getValueFromOptions(endWeekId, periodDropdownOptions)}
                                          checkBoxIsChecked={useEndWeekId}
                                          onCheckBoxChange={updateUseEndWeekId}
                                          onDropDownChange={updateEndWeekId}
                                          dropDownValues={periodDropdownOptions}
                                          placeholder={"Select End Week Id..."}
                        />
                        <OptionalTextBox
                            checkboxText={`Use an optional prefix for the dataset you create`}
                            labelText={'Extract Prefix'}
                            helpText={'Optional prefix for extracts dataset (cannot be more than 20 characters)'}
                            onCheckBoxChange={updateUsePrefix}
                            maxTextLength={20}
                            onTextBoxChange={(value) => updateExtractsRunPrefix(prefixFilterRules(value))}
                            checkBoxIsChecked={usePrefix}
                            textInput={extractsRunPrefix}
                        />
                        {!isCleanseFillExtract && (
                            <CheckpointDropdown
                                updateCheckpointPath={({checkpointPath}) => updateCheckpointPath(checkpointPath)}
                                datasets={['cleanse_fill_extract', 'filtered_promo', 'promo_extract', 'store_insights']}
                                tableData={['promo_parquet']}
                            />
                        )}
                    </>}
                    {isCleanseFillExtract && (
                        <div className="checkbox-container missing-nucleus-extract-promo-checkbox">
                            <Checkbox onChange={() => updateRunMissingPromoAndPromoCfeMismatch(!runMissingPromoAndPromoCfeMismatch)}
                                      labelText={"Run Promo & Cleanse Filled Data Compare Check"}
                                      checked={runMissingPromoAndPromoCfeMismatch}
                            />
                            <Checkbox onChange={() => updateRunPromoExtract(!runPromoExtract)}
                                      labelText={"Run Promo Extract"}
                                      checked={runPromoExtract}
                            />
                        </div>
                    )}
                    <br/>
                    <div className={'dag-trigger-group'}>
                        <button type="button"
                                className="button primary submit"
                                onClick={() => updateShowConfirmationModal(true)}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            {!loadingCSV &&
                <Page
                    name={"Included Retailers"}
                    secondaryLabel={"All available retailers will be used if there are no included retailers"}
                >
                    <div className={'right-things'}>
                        <Label className={"csv-label"} title={"Only Retailer Ids"}>
                            <Files
                                className='hollow button secondary files-dropzone'
                                onChange={onFilesChange}
                                onError={(error) => console.error('error code ' + error.code + ': ' + error.message)}
                                accepts={['.csv']}
                                multiple
                                maxFiles={1}
                                maxFileSize={10000000}
                                clickable
                            >
                                Upload CSV
                            </Files>
                        </Label>
                    </div>
                    <LoadingWrapper loading={loading}>
                        <EditableReactTableV2
                            filterable
                            hasStickyHeader={false}
                            actionButtonsProps={{showDeleteAllButton: true}}
                            data={data.data}
                            columns={[RETAILER_ID_NAME_COLUMN]}
                            editTable={[
                                INDEX_FIELD,
                                {id: 'retailerId', Component: args => <EditFormTextInputField {...args} isRequired label={"Retailer Id"}/>},
                            ]}
                            onRowEditConfirmed={onRowEditConfirmed}
                            onRowDeleteConfirmed={onRowDeleteConfirmed}
                            onDeleteAllConfirmed={onDeleteAllConfirmed}
                        />
                    </LoadingWrapper>
                </Page>}
            <br/>
            <AirflowDagLogs processName={nucleusDag} includeRetailer={true} includeDagName={true}/>
            {
                showConfirmationModal &&
                <ExtractsTriggerConfirmationModal confirmationCallback={modalConfirmed}
                                                  cancellationCallback={() => updateShowConfirmationModal(false)}
                                                  dagToTrigger={nucleusDag}
                                                  endWeekId={endWeekId}
                                                  startWeekId={startWeekId}
                                                  runMissingPromoAndPromoCfeMismatch={isCleanseFillExtract ? runMissingPromoAndPromoCfeMismatch : undefined}
                                                  extractsRunPrefix={extractsRunPrefix}
                                                  checkpointPath={checkpointPath}
                                                  endpointUrl={`${process.env.REACT_APP_EDP_API_BASE_URL}/${DAG_TO_CONFIG[nucleusDag]}/`}
                                                  processesToRun={['nucleus']}
                                                  runIdToScaffold={undefined}
                                                  runPromoExtract={isCleanseFillExtract ? runPromoExtract : undefined}
                />
            }
        </Page>
    );
}

export default NucleusExtractsTriggerDag;