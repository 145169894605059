import React from "react";
import Page from "../Components/Page";
import PropTypes from "prop-types";
import AirflowDagLogs from "../Components/airflow/dagLogs/AirflowDagLogs";

export const ProcessingLogsPage = ({retailerId, displayHelmetHeader}) => {
    return (
        <Page name={'Processing Logs'} displayHelmetHeader={displayHelmetHeader}>
            <AirflowDagLogs
                retailerId={retailerId}
                includeDagName={true}
                includeRetailer={true}
                customColumns={[
                    {header: 'Until Date', accessorKey: 'fillenddate', className: 'non-numeric-field'}
                ]}
            />
        </Page>
    )
}

ProcessingLogsPage.defaultProps = {
    retailerId: undefined,
    displayHelmetHeader: true
}

ProcessingLogsPage.propTypes = {
    retailerId: PropTypes.number,
    displayHelmetHeader: PropTypes.bool
};