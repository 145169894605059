import React from "react";
import PropTypes from "prop-types";
import Modal from 'react-modal';

function StoreInfoModal(props) {
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.closeModal}
            className="Modal"
            overlayClassName="Overlay"
            ariaHideApp={false}
            contentLabel="Example Modal">
            {props.marketKey && <h2>{`Store Ids for Market Key: ${props.marketKey}`}</h2>}
            {props.designation && <h2>{`Store Ids for Designation: ${props.designation}`}</h2>}
            {props.onChange ?
                <textarea
                    className='modal-text-area'
                    value={props.storeInfo}
                    onChange={props.onChange}
                />
                :
                <textarea
                    className='modal-text-area'
                    defaultValue={props.storeInfo}
                />
            }
            {props.updateRow &&
                <button className="hollow button secondary modal-button" type="button" name="update" id="update"
                        onClick={() => props.updateRow()}>
                    Update
                </button>
            }
            <button className="hollow button secondary modal-button" type="button" name="close"
                    onClick={() => props.closeModal()}>
                Close
            </button>
        </Modal>
    );
}

StoreInfoModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    designation: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    marketKey: PropTypes.number,
    onChange: PropTypes.func,
    storeInfo: PropTypes.array,
    updateRow: PropTypes.func
};

export default StoreInfoModal