import React from "react";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";

function StoreBanner() {

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/storeBanner/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    return (<Page name={"Store Banner"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "Stores"}, {label: "Banner"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[{header: "Store Account", accessorKey: "storeAccount"}]}
                editTable={[
                    INDEX_FIELD,
                    {id: 'storeAccount', Component: args => <EditFormTextInputField {...args} isRequired/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            >
                {
                    ({}) => {
                        return (
                            <div>
                                <div>When Store Service data is retrieved from the Ops Portal, stores with an Account
                                    listed below will have their Banner field populated with either a market
                                    description, a consumer name, or a store name.
                                </div>
                                <div>Stores with other Account values will have their Banner field populated with the
                                    retailer Retail Chain.
                                </div>
                            </div>
                        )
                    }
                }
            </EditableReactTableV2>
        </LoadingWrapper>
    </Page>);
}



export default StoreBanner;