import React from 'react';
import PropTypes from 'prop-types';
import {convertLabelTextToDataTestId} from "../../utils/testHelpers/assertUtils";

const BaseOutlierInputBlur = ({defaultValue, labelText, onBlur}) => {
    return <div className={'id-input'}>
        <span className='input-group-label'>{labelText}</span>
        <input
            data-testid={`${convertLabelTextToDataTestId(labelText, "base-outlier-input")}`}
            className={'input-group-field'}
            type='text'
            defaultValue={defaultValue}
            onBlur={onBlur}
        />
    </div>;
};

BaseOutlierInputBlur.propTypes = {
    defaultValue: PropTypes.string,
    labelText: PropTypes.string,
    onBlur: PropTypes.func
};

export default BaseOutlierInputBlur;