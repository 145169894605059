import PropTypes from "prop-types";
import React from "react";

const StoreClosedIcon = ({date, storeClosures}) => {
    const isStoreClosedOnDate = storeClosures?.some(storeClosure =>
        date >= storeClosure.startDate && (!storeClosure.endDate || date <= storeClosure.endDate));

    return <>{isStoreClosedOnDate && <i id={'store-closed'} className={'fas fa-store-slash store-closure-color'}/>}</>
}


StoreClosedIcon.propTypes = {
    date: PropTypes.string.isRequired,
    storeClosures: PropTypes.arrayOf(PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string
    }))
}

export default StoreClosedIcon