import React, {useState} from 'react';
import Checkbox from "../Components/Checkbox";
import PropTypes from "prop-types";
import _ from "lodash";

const STORE_INSIGHTS_TABLE_NAMES = [
    {label: 'Store Dimensions', tableName: 'dim_stores'},
    {label: 'Retailer Dimensions', tableName: 'dim_retailers'},
    {label: 'Product Dimensions', tableName: 'dim_products'},
    {label: 'Aggregate Period', tableName: 'dim_agg_periods'},
    {label: 'Weekly Facts Aggregate', tableName: 'weekly_facts_agg'},
]

const StoreInsightsTableCheckboxList = ({onCheckboxChange}) => {
    const [checkedTables, setCheckedTables] = useState(STORE_INSIGHTS_TABLE_NAMES.map(table => table.tableName));

    React.useEffect(() => {
        onCheckboxChange(checkedTables)
    }, [])

    return <div className={"datasets-checkboxes-container input-group"}>
        <span className='input-group-label'>Tables to Create:</span>
        <div className={'datasets-checkboxes'}>
            {
                STORE_INSIGHTS_TABLE_NAMES.map(({label, tableName}) =>
                    <Checkbox
                        key={`checkbox-${tableName}`}
                        labelText={label}
                        checked={checkedTables.includes(tableName)}
                        onChange={() => {
                            const allCheckedTables = checkedTables.includes(tableName) ? _.without(checkedTables, tableName) : [...checkedTables, tableName];
                            setCheckedTables(allCheckedTables);
                            onCheckboxChange(allCheckedTables)
                        }}
                    />)
            }
        </div>
    </div>;
};

StoreInsightsTableCheckboxList.defaultProps = {};

StoreInsightsTableCheckboxList.propTypes = {
    onCheckboxChange: PropTypes.func.isRequired
};

export default StoreInsightsTableCheckboxList;