import React from "react";
import PropTypes from "prop-types";
import Modal from 'react-modal';
import ArchiveFilenameModalContent from "./ArchiveFilenameModalContent";

function ArchiveFilenameModal({archiveFilenames, closeModal, date, retailerId, storeName, storeId, source}) {
    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="Modal"
        >
            <h2>{`${storeId} - ${storeName} - ${date} - ${source}`}</h2>
            <ArchiveFilenameModalContent archiveFilenames={archiveFilenames} retailerId={Number(retailerId)} storeId={storeId} weekEndDate={date}/>
            <div>
                <button className="hollow button secondary modal-button archive-filename-close" type="button" name="close"
                        onClick={() => closeModal()}>
                    Close
                </button>
            </div>
        </Modal>
    );
}

ArchiveFilenameModal.propTypes = {
    archiveFilenames: PropTypes.arrayOf(PropTypes.string),
    closeModal: PropTypes.func.isRequired,
    date: PropTypes.string,
    retailerId: PropTypes.number,
    source: PropTypes.string,
    storeId: PropTypes.number,
    storeName: PropTypes.string
};

export default ArchiveFilenameModal