import React, {useState} from 'react';
import PropTypes from 'prop-types';
import chevron from "../../assets/right-thin-chevron-svgrepo-com.svg";

const CollapsableContent = ({collapsedContent, expandedContent, className}) => {
    const [expanded, setExpanded] = useState(false)

    const toggleCollapse = () => {
        setExpanded(!expanded)
    }

    return (
        <div className={`collapsable-content-container ${className}`} data-testid={'collapsable-content'}>
            <img className={`${expanded ? 'task-chevron-open' : 'task-chevron'}`} onClick={toggleCollapse}
                 src={chevron} alt={'chevron'}/>
            <div className={'collapsable-content'}>
                {expanded ? expandedContent : collapsedContent}
            </div>
        </div>
    );
};

CollapsableContent.defaultProps = {
    className: ''
}

CollapsableContent.propTypes = {
    collapsedContent: PropTypes.node,
    expandedContent: PropTypes.node,
    className: PropTypes.string
}

export default CollapsableContent;