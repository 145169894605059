import React from 'react';
import PropTypes from "prop-types";
import {CONFIG_TYPES} from "../../Configurations/FileTypes";
import {REPORT_TYPES} from "../../Configurations/ReportTypes";
import ReportLink from "./ReportLink";
import NormalizedFileReportLink from "./NormalizedFileReportLink";

const IssueCount = ({
                        original,
                        issueType,
                        reportType,
                        gcsSuffix,
                        fileCount,
                        retailerId,
                    }) => {
    const shouldDisplayReportIcon = (reportType === REPORT_TYPES.NORMALIZED_FILE || fileCount > 0);
    const fileType = Object.values(CONFIG_TYPES).find(config => config.getName() === original.fileType);

    return (
        <div className={'feedback'}>
            {
                shouldDisplayReportIcon &&
                <div className={'width-80'}><b>{issueType}</b></div>
            }
            {
                (fileCount || fileCount === 0) &&
                (reportType === REPORT_TYPES.NORMALIZED_MULTIPLE_SOURCE ? fileCount !== 0 : true) &&
                <ReportLink
                    displayValue={fileCount}
                    runId={original.normalizationRunId}
                    fileType={fileType}
                    gcsSuffix={gcsSuffix}
                />
            }
            {fileType && <NormalizedFileReportLink original={original}
                                                   reportType={reportType}
                                                   shouldDisplayReportIcon={shouldDisplayReportIcon}
                                                   retailerId={retailerId}
                                                   fileType={fileType}/>}
        </div>
    )
};

IssueCount.propTypes = {
    original: PropTypes.shape({
        normalizationRunId: PropTypes.string,
        fileType: PropTypes.string
    }),
    issueType: PropTypes.string,
    reportType: PropTypes.string.isRequired,
    gcsSuffix: PropTypes.string,
    fileCount: PropTypes.number,
    retailerId: PropTypes.number,
};

export default IssueCount;