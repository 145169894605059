import React from 'react';
import Page from "../../../Components/Page";
import {Breadcrumbs} from "../../../Components/Breadcrumbs";
import SelectDropdown from "../../../Components/Dropdowns/SelectDropdown";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import MissingDataListTab from "../pages/MissingDataListTab";
import MissingDataExportTab from "../pages/MissingDataExportTab";

const MissingDataPage = () => {
    const breadcrumbs = <Breadcrumbs items={[
        {label: 'Alerts'},
        {label: 'Missing Data'}
    ]}/>;

    const exclusionsDropdown =
        <div className={'title-object'}>
            <SelectDropdown
                options={[
                    {label: 'Retailers', value: 'Retailers', url: '/missingDataRetailerExclusions'},
                    {label: 'Stores', value: 'Stores', url: '/configurations/store/missingDataExclusions'}
                ]}
                id={'exclusion_dropdown'}
                selectValue={'Review Exclusions ...'}
                onChange={o => window.open(o.url, '_blank')}
            />
        </div>

    return (
        <div className={'missing-data'}>
            <Page name="Missing Data" breadCrumbs={breadcrumbs} headerSubComponent={exclusionsDropdown}>
                <Tabs>
                    <TabList>
                        <Tab>List</Tab>
                        <Tab data-testid={'exports-tab'}>{'Exports'}</Tab>
                    </TabList>
                    <TabPanel>
                        <MissingDataListTab/>
                    </TabPanel>
                    <TabPanel>
                        <MissingDataExportTab/>
                    </TabPanel>
                </Tabs>
            </Page>
        </div>
    );
};


export default MissingDataPage;