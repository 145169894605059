import _ from "lodash";
import React from "react";
import {faIcons} from "../../../utils/iconConstants";
import DeleteButton from "../../../utils/components/ButtonLoader";
import EditableReactTableV2 from "./EditableReactTableV2";

export const RT_MODES = Object.freeze({
    EDIT: "edit",
    ADD: "add",
    VIEW: "view",
    DELETE: "delete"
});

export function toTitleCase(str) {
    return _.startCase(str);
}

export function getSingleRowActionQueryParams(singleRowAction, urlContainsParams = false) {
    const prefix = urlContainsParams ? "&" : "?"
    return `${prefix}single_row_action=${singleRowAction}`;
}

export function deleteModalText(row) {
    return <div className={"react-table-delete-modal-text"}
                data-testid={"react-table-delete-modal-text"}>{JSON.stringify(row.original, null, 2)}</div>;
}

function getActionButtonPropOrDefault(buttonType, actionButtonsProps, defaultActionButtonsProps) {
    return actionButtonsProps?.[buttonType] !== undefined ? actionButtonsProps[buttonType] : defaultActionButtonsProps[buttonType];
}

export function getActionsColumn(editable, setMode, submitDeleteData, actionButtonsProps,
                                 submitDeleteAllData) {
    if (!editable)
        return [];

    const defaultActionButtonsProps = EditableReactTableV2.defaultProps.actionButtonsProps;
    const showDeleteAll = getActionButtonPropOrDefault("showDeleteAllButton", actionButtonsProps, defaultActionButtonsProps);
    const showDeleteButton = getActionButtonPropOrDefault("showDeleteButton", actionButtonsProps, defaultActionButtonsProps);
    const showAddButton = getActionButtonPropOrDefault("showAddButton", actionButtonsProps, defaultActionButtonsProps);
    const showEditButton = getActionButtonPropOrDefault("showEditButton", actionButtonsProps, defaultActionButtonsProps);
    const sticky = getActionButtonPropOrDefault("sticky", actionButtonsProps, defaultActionButtonsProps);
    const stickyHeader = sticky ? {sticky: "left"} : {}
    return [
        {
            header: () => {
                return <div>
                    {"Actions "}
                    {showAddButton && <i
                        data-testid={"react-table-add-record-button"}
                        onClick={() => {
                            setMode(RT_MODES.ADD, {index: null});
                        }}
                        className={`${faIcons.add} add-button-icon`}
                        title={"Add Record"}
                    />}
                    {showDeleteAll &&
                        <DeleteButton buttonHasModal
                                      clickAction={() => submitDeleteAllData()}
                                      modalHeader={"Delete All Rows?"}
                                      modalText={"All Data will be deleted"}
                                      buttonClassName={"minus-button-icon"}
                                      buttonTitle={<i data-testid={"react-table-delete-all-button"}
                                                      className={faIcons.deleteAll}/>}
                                      confirmationsModalClass={"react-table-delete-confirmation-modal"}
                                      title={"Delete All Records"}
                        />
                    }
                </div>;
            },
            id: "actions",
            filterable: false,
            size: 100,
            cell: ({row}) => {
                return <div>
                    {showEditButton && <button>
                        <i data-testid={"react-table-edit-row-button"}
                           title={'Edit Row'}
                           onClick={() => {
                               setMode(RT_MODES.EDIT, row.original);
                           }} className={faIcons.edit}/>
                    </button>}
                    {showDeleteButton && <DeleteButton buttonHasModal
                                                       clickAction={() => submitDeleteData(row.original)}
                                                       modalHeader={"Delete Row?"}
                                                       modalText={deleteModalText(row)}
                                                       buttonClassName={"delete-button"}
                                                       buttonTitle={<i data-testid={"react-table-delete-row-button"}
                                                                       className="fas fa-trash-alt"/>}
                                                       confirmationsModalClass={"react-table-delete-confirmation-modal"}
                                                       title={"Delete"}
                    />}
                </div>;
            },
            ...stickyHeader
        },
    ];
}

export function DefaultColumnFilter(props) {
    return (
        <input
            data-testid={`${props.column.id}-filter-input`}
            value={props.column.getFilterValue() || ""}
            onChange={e => {
                props.column.setFilterValue(e.target.value || undefined);
            }}
        />
    );
}

export function setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow) {
    const sanitizedData = newOrUpdatedRow;
    Object.keys(sanitizedData).forEach(function (key) {
        if (sanitizedData[key] === undefined || sanitizedData[key] === "") {
            sanitizedData[key] = null;
        }
        if (isNaN(sanitizedData[key]) && !sanitizedData[key]) {
            sanitizedData[key] = null;
        }
    });
    return sanitizedData;
}

