import React from 'react';
import PropTypes from 'prop-types';

function ThresholdSelection(props) {
    return <div className={"threshold-selection"}>
        <div className={"threshold-field"}>
            <span>Dollars</span>
            <input
                id={"threshold-dollars"}
                type={"number"}
                value={props.dollarValue}
                onChange={props.dollarOnChange}
            />
        </div>
        <div className={"threshold-field"}>
            <span>Units</span>
            <input
                id={"threshold-units"}
                type={"number"}
                value={props.unitValue}
                onChange={props.unitOnChange}
            />
        </div>
    </div>;
}

ThresholdSelection.propTypes = {
    dollarOnChange: PropTypes.func,
    dollarValue: PropTypes.number,
    unitOnChange: PropTypes.func,
    unitValue: PropTypes.number
};

export default ThresholdSelection;