import React from 'react';
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import ReactTable from "../Components/ReactTable";
import {makeApiCallPost} from "../../Api/makeApiCall";
import FtpConfigButtons from "../Configurations/FtpConfigurations/Columns/FtpConfigButtons";
import InputAndButton from "./InputAndButton";
import {IgnoreButton} from "./IgnoreButton";
import {toastError, toastSuccess} from "../../utils/toast";
import {toast} from "react-toastify";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import {Breadcrumbs} from "../Components/Breadcrumbs";

const UnmappedRetailersPage = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/unmappedRetailers/`;
    const {loading, data, refreshData} = useServiceBase({endpointUrl});

    const columns = [
        {header: "Ftp", accessorKey: "ftp"},
        {
            header: "Archive",
            id: "archive",
            cell: ({row}) => {
                const ftp = row.original.ftp;
                const GcsUrl = `${process.env.REACT_APP_ARCHIVE_BASE_URL}` + encodeURIComponent(process.env.REACT_APP_ARCHIVE_PATH) + ftp;
                return <a target='_blank' rel="noopener noreferrer" href={GcsUrl} className={'ftp-archive-link'}>Archive
                    Link</a>
            }
        },
        {
            header: "Ingest Mapping",
            id: "ingestMapping",
            cell: ({row}) => <FtpConfigButtons ftp={row.original.ftp}/>
        },
        {
            header: "Add Mapping",
            id: "addMapping",
            cell: ({row}) => <div className={'unmapped-retailer-add-mapping'}>
                <InputAndButton
                    inputType={'number'}
                    sendInputValueToAncestor={(newRetailerId) => addRetailerMapping(row.original.ftp, newRetailerId)}
                />
            </div>
        },
        {
            header: "Ignore",
            id: "ignore",
            cell: ({row}) => <IgnoreButton onSubmitCallback={() => refreshData()}
                                           url={`${process.env.REACT_APP_EDP_API_BASE_URL}/ignoreUnmappedRetailer`}
                                           postBody={{ftp: row.original.ftp}}
                                           unmappedItem={row.original.ftp}/>
        }
    ];

    function addRetailerMapping(ftp, newRetailerId) {
        const newId = parseInt(newRetailerId);
        if (newId !== undefined && Number.isInteger(newId)) {
            return makeApiCallPost(
                `${process.env.REACT_APP_EDP_API_BASE_URL}/mapUnmappedRetailer`,
                {ftp, newId}
            ).then((response) => {
                response?.apiError ? toastError(toast, `there was an error mapping ${ftp} to ${newId}`) :
                    toastSuccess(toast, `${ftp} was successfully mapped to ${newId}`)

            }).finally(() => refreshData())
        }
        toastError(toast, "Error: You must enter a valid integer.")
    }

    return (
        <Page name={'Unmapped Retailers'}
              breadCrumbs={<Breadcrumbs items={[{label: "Unmapped"}, {label: "Retailers"}]}/>}
        >
            <LoadingWrapper loading={loading}>
                <ReactTable
                    data={data.data}
                    columns={columns}
                    filterable={false}
                    initialState={{pagination: {pageSize: 10}, sorting: [{id: "ftp", desc: false}]}}
                    hasStickyHeader={true}
                    className={"unmapped-retailers-table"}
                />
            </LoadingWrapper>
        </Page>
    );
};

export default UnmappedRetailersPage;
