import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import OptionalDropDownApi from './OptionalDropDownApi';
import {makeApiCall} from '../../Api/makeApiCall';
import _ from 'lodash';

const ScaffoldingOptionalDatasetDropDown = (props) => {
    const onParseApiResponse = useCallback((result) => {
        const mappedResult = result.map(item => ({label: item, value: item}));
        return _.sortBy(mappedResult, ['label'])
    }, []);

    const makeApiCallForDatasets = useCallback(() => {
        return makeApiCall(props.endpoint);
    }, [props.endpoint]);

    return (
        <OptionalDropDownApi {...props}
                             apiRequest={makeApiCallForDatasets}
                             onParseApiResponse={onParseApiResponse} />
    )
}

ScaffoldingOptionalDatasetDropDown.propTypes = {
    endpoint: PropTypes.string.isRequired,
    onCheckBoxChange: PropTypes.func.isRequired,
    onDropDownChange: PropTypes.func.isRequired,
    checkBoxIsChecked: PropTypes.bool.isRequired,
    selectedDropDownValue: PropTypes.string,
    checkboxText: PropTypes.string,
    labelText: PropTypes.string,
    helpText: PropTypes.string,
}

export default ScaffoldingOptionalDatasetDropDown