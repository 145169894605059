import React, {useEffect, useState} from 'react';
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormDropdownInputField} from "../Components/ReactTable/EditRecordForm";
import {makeApiCall} from "../../Api/makeApiCall";

function IngestionPausedRetailers() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ingestionPausedRetailers/`
    const {
        loading,
        data,
        onRowEditConfirmed,
        onRowDeleteConfirmed,
        onDeleteAllConfirmed
    } = useServiceBase({endpointUrl});

    const [retailerFtpList, setRetailerFtpList] = useState([]);
    const [decodeLoading, setDecodeLoading] = useState(true);

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {
            header: "FTP", accessorKey: "ftp", cell: ({getValue}) => {
                const value = getValue();
                return <div>{value || "All"}</div>
            }
        }
    ];

    useEffect(() => {
        setDecodeLoading(true)
        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/ftpToRetailerIdDecode/`)
        ])
            .then(([
                       ftpToRetailerIdDecodeService,
                   ]) => {
                const ftpOptions = ftpToRetailerIdDecodeService.data.map(x => {
                    return {value: x.name, label: x.name, retailerId: x.retailerId}
                })
                setRetailerFtpList(ftpOptions);
                setDecodeLoading(false)
            })
    }, []);

    function FtpEditField({id, form}) {
        const retailerId = form.watch("retailerId")
        const options = retailerFtpList.filter(ftp => ftp.retailerId === retailerId)
        return (
            <EditFormDropdownInputField
                label={"FTP"}
                id={id}
                options={[{label: "All", value: null}, ...options]}
                form={form}/>
        );
    }

    function validatePreSave({rows, form}) {
        const formFtpValue = form.getValues('ftp');
        const indexValue = form.getValues('index');
        const retailerIdValue = form.getValues('retailerId');
        const rawRecordsFiltered = rows.map(row => row.original).filter(row => row.ftp === formFtpValue && row.retailerId === retailerIdValue);
        const hasDuplicate = rawRecordsFiltered.length > 0 && indexValue !== rawRecordsFiltered[0].index;

        if (hasDuplicate) {
            const decodedFtp = formFtpValue == null ? `This retailer ${retailerIdValue}`: `This FTP ${formFtpValue}`
            form.setError('ftp', {
                type: 'custom',
                message: [`${decodedFtp} already exists in this config`]
            });
        }

        return !hasDuplicate;
    }

    return (<Page name={"Ingestion Paused Retailers"} breadCrumbs={<Breadcrumbs
        items={[{label: "Configurations"}, {label: "Retailer"}, {label: "Ingestion Paused"}]}/>}>
        <LoadingWrapper loading={loading || decodeLoading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                actionButtonsProps={{showDeleteAllButton: true}}
                initialState={{pagination: {pageSize: 25}, sorting: [{id: "retailerIdName", desc: false}]}}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'ftp', defaultValue: null, Component: args => <FtpEditField {...args} />}
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
                onDeleteAllConfirmed={onDeleteAllConfirmed}
                validatePreSave={validatePreSave}
            />
        </LoadingWrapper>
    </Page>);
}


export default IngestionPausedRetailers;