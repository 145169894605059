import React, {useEffect, useState} from 'react';
import ReactTable from "../Components/ReactTable";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import * as PropTypes from "prop-types";
import {makeApiCall} from "../../Api/makeApiCall";
import Page from "../Components/Page";

const RetailerStores = ({retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/stores/`
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        setLoading(true)
        Promise.all([
            makeApiCall(endpointUrl)
        ])
            .then(([storesResults]) => {
                const filteredStores = storesResults.data
                    .filter(store => store.retailerId === retailerId)
                    .map(store => {
                        return {
                            storeId: store.storeId,
                            storeName: store.storeName,
                            storeTag: store.storeTag,
                            account: store.account,
                            acv: store.acv,
                            addr1: store.addr1,
                            addr2: store.addr2,
                            banner: store.banner,
                            closed: store.closed,
                            externalStoreId: store.externalStoreId,
                            retailTag: store.retailTag,
                            retailerId: store.retailerId,
                            storeCode: store.storeCode,
                        }
                    }
                    )
                setTableData(filteredStores)
                setLoading(false);
            })
    }, []);

    const STORE_COLUMNS = [
        {header: "Store Id", accessorKey: "storeId", sticky: "left"},
        {header: "Store Tag", accessorKey: "storeTag", sticky: "left"},
        {header: "Store Name", accessorKey: "storeName", sticky: "left", size: 300},
        {header: "External Store Id", accessorKey: "externalStoreId"},
        {header: "Account", accessorKey: "account"},
        {header: "Acv", accessorKey: "acv"},
        {header: "Addr 1", accessorKey: "addr1", size: 200},
        {header: "Addr 2", accessorKey: "addr2", size: 200},
        {header: "Banner", accessorKey: "banner"},
        {header: "Closed", accessorKey: "closed"},
        {header: "Retail Tag", accessorKey: "retailTag"},
        {header: "Retail Id", accessorKey: "retailerId"},
        {header: "Store Code", accessorKey: "storeCode"}
    ]

    return (<Page name={"Store Information"}>
        <LoadingWrapper loading={loading}>
            <ReactTable
                filterable={true}
                data={tableData}
                columns={STORE_COLUMNS}
                hasStickyHeader={false}
            />
        </LoadingWrapper>
    </Page>);
};

RetailerStores.propTypes = {
    retailerId: PropTypes.number,
};

export default RetailerStores;