import * as PropTypes from 'prop-types';
import React from "react";
import LoadingSpinner from "../../Components/LoadingSpinner";

function AlertsCell({data, isLoading}) {
    const hasIssue = data.alertData.length > 0;
    const issueClassName = hasIssue ? 'issue-link-color-red' : ''
    const issueText = hasIssue ? `${data.alertData.length}` : '';


    return (
        <div style={{textAlign: 'center'}}>
            {isLoading ? <LoadingSpinner spinnerClassName={'spinner-container-small'}/> : (hasIssue ? <a target="_blank" rel="noopener noreferrer"
                           href={`/alerts/log?retailerId=${data.retailer.retailerId}`}
                           className={`store-totals-link ${issueClassName}`}
                           title={`Alerts`}
                >
                    {issueText}
                </a>
                :
                "")}
        </div>
    )
}

AlertsCell.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool
}

export default AlertsCell;