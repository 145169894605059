import {formatRunIdentifierForThisPeriod} from './ScaffoldingPublishUtils';

export const getMarketInsightsRunsForPeriodSelection = (outletNames, marketInsightRuns, periodSelection, edpAirflowUrl, dagRuns) => {
    const marketInsightRunsTemp = [];

    for (const outletName of outletNames) {
        if (marketInsightRuns[outletName]) {
            let runInfoOfRunForThisPeriod = [];

            for (let [associatedPeriod, runInfo] of Object.entries(marketInsightRuns[outletName])) {
                if (associatedPeriod === periodSelection) {
                    runInfoOfRunForThisPeriod.push({runIdentifier: runInfo.runIdentifier, runId: runInfo.runId})
                }
            }
            const isInCurrentEnvironment = Boolean(dagRuns.find(dagRun => dagRun['dag_run_id'] === runInfoOfRunForThisPeriod[0]?.runId))

            marketInsightRunsTemp.push({
                outlet: outletName,
                runIdElement: formatRunIdentifierForThisPeriod(runInfoOfRunForThisPeriod, edpAirflowUrl, isInCurrentEnvironment),
                runIdentifiers: runInfoOfRunForThisPeriod.map(({runIdentifier}) => runIdentifier)
            })
        } else {
            marketInsightRunsTemp.push({
                outlet: outletName,
                runIdElement: formatRunIdentifierForThisPeriod(undefined, edpAirflowUrl),
                runIdentifiers: []
            })
        }
    }

    return marketInsightRunsTemp;
};