import React from 'react';
import PropTypes from "prop-types";
import {encodeURI} from "@google-cloud/storage/build/src/util";
import CollapsableContent from "../Components/CollapsableContent";


const UnmappedStoresFileDetails = (props) => {
    const prefix = 'https://console.cloud.google.com/storage/browser/_details/';

    const expandedContent = <ul style={{listStyleType: "none"}} data-testid={'unmapped-store-list'}>
        {props.getValue().map((item, key) => {
            return <li key={key} data-testid={`unmapped-store-list-${key}`}>
                <a target='_blank' rel="noopener noreferrer" href={encodeURI(item.uri, false).replace('gs%3A//', prefix)}>{item.date}</a>
            </li>

        })}
    </ul>;

    return <CollapsableContent collapsedContent={props.getValue().length} expandedContent={expandedContent} />
};

UnmappedStoresFileDetails.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
                date: PropTypes.string,
                uri: PropTypes.string
            }
        )
    ).isRequired
};

export default UnmappedStoresFileDetails;