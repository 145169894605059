import React from 'react';
import {Helmet} from 'react-helmet'
import * as PropTypes from "prop-types";
import BetaTag from "../../utils/components/BetaTag";

class Page extends React.Component {

    render() {
        const {name, secondaryLabel, children, displayHelmetHeader, beta, breadCrumbs, headerSubComponent} = this.props;

        const environment = process.env.REACT_APP_ENV !== 'prod' ? process.env.REACT_APP_ENV.toString().toUpperCase() + ' | ' : '';

        return (
            <main>
                {displayHelmetHeader && <Helmet>
                    <title>{`${environment}${name} | Nucleus Dashboard`}</title>
                </Helmet>}
                {breadCrumbs}
                <header className="page-header">
                    <div>
                        <span className={'page-header-label'} data-testid={'page-header-label'}>{name}{beta ?
                            <BetaTag/> : ""}</span>
                        {secondaryLabel && <span className={'page-header-secondary-label'}
                                                 data-testid={'page-header-secondary-label'}>{secondaryLabel}</span>}
                    </div>
                    {headerSubComponent}
                </header>
                <section>
                    {children}
                </section>
            </main>
        );
    }
}

export default Page;

Page.defaultProps = {
    displayHelmetHeader: true,
    beta: false,
    breadCrumbs: undefined,
    secondaryLabel: undefined,
    headerSubComponent: undefined
}

Page.propTypes = {
    beta: PropTypes.bool,
    displayHelmetHeader: PropTypes.bool,
    name: PropTypes.any,
    secondaryLabel: PropTypes.string,
    children: PropTypes.any,
    breadCrumbs: PropTypes.object,
    headerSubComponent: PropTypes.any
};