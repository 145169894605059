import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";

function FileExclusion() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/fileExclusion/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    return (<Page name={"File Exclusion"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "File Exclusion"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[{header: "File Exclusion", accessorKey: "fileExclusion"}]}
                editTable={[
                    INDEX_FIELD,
                    {id: 'fileExclusion', Component: args => <EditFormTextInputField {...args} isRequired/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default FileExclusion;