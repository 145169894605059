import React, {Component} from "react";
import Page from "../Components/Page";
import {getRetailerMapUseGetRetailerLookupInstead} from "../../utils/retailerMapCache";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {salesforceRetailersQuery} from "../../queries/salesforceRetailersQuery";
import { LoadingWrapper } from "../Components/LoadingWrapper";
import ReactTable from "../Components/ReactTable";


export const handleChange = (event, designation, setStoreInfo) => {
    setStoreInfo(event.target["retailerId"]);
}

export class MissingRetailers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            missingRetailers: [],
        };
        this.getMissingRetailerIds = this.getMissingRetailerIds.bind(this);
    }

    getMissingRetailerIds(retailerIds, retailerIdsToFilterOut, status) {
        return retailerIds
            .filter(retailerId => !retailerIdsToFilterOut.includes(retailerId))
            .map(retailerId => {return {"retailerId": retailerId, "status": status}});
    }

    async componentDidMount(){
        const nucleusRetailerIds = Object.keys(getRetailerMapUseGetRetailerLookupInstead()).map(retailerId => parseInt(retailerId));

        await makeApolloClientCall(salesforceRetailersQuery, false)
            .then((response) => {
                const salesforceRetailerIds = response['salesforceRetailers'].map(retailerIdObject => retailerIdObject['retailerId']);
                const salesforceOnlyRetailers = this.getMissingRetailerIds(salesforceRetailerIds, nucleusRetailerIds, "Salesforce Only");
                const nucleusOnlyRetailers = this.getMissingRetailerIds(nucleusRetailerIds, salesforceRetailerIds, "Nucleus Only");
                const missingRetailers = salesforceOnlyRetailers.concat(nucleusOnlyRetailers)
                    .sort((a, b) => {
                        if (a["retailerId"] > b["retailerId"]) {
                            return 1;
                        }
                        if (a["retailerId"] < b["retailerId"]) {
                            return -1;
                        }
                        return 0;
                    });
                this.setState({loading: false, missingRetailers: missingRetailers})
            })
            .catch((error) => {
                console.error(error)
                this.setState({loading: false})
            });
    };

    render() {
        const customColumns = [
            {
                header: "Retailer Id",
                accessorKey: "retailerId",
                enableGlobalFilter: true
            },
            {
                header: "Status",
                accessorKey: "status",
            }
        ];
        return (
            <Page name={"Missing Retailers"}>
                <LoadingWrapper loading={this.state.loading}>
                    <ReactTable
                        data={this.state.missingRetailers}
                        filterable
                        schema={{"fields": [{"name": "retailerId", "type": "integer"}]}}
                        columns={customColumns}
                        initialState={{pagination: {pageSize: 20}}}
                        />
                </LoadingWrapper>
            </Page>
        )
    }
}

export default MissingRetailers