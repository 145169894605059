import ReadinessIssues from "../PeriodRelease/ReadinessIssues";
import {createReadinessIssuesListItem} from "../../utils/util";
import {sortArraysWithNullsLast, sortAscendingWithNullsFirst} from "../../utils/sorting";
import RetailerTotalCell from "../PeriodRelease/Readiness/RetailerTotalCell";
import StoreTotalCell from "../PeriodRelease/Readiness/StoreTotalCell";
import OutliersCell from "../PeriodRelease/Readiness/OutliersCell";
import AlertsCell from "../PeriodRelease/Readiness/AlertsCell";
import UnmappedStoresCell from "../PeriodRelease/Readiness/UnmappedStoresCell";
import FtpConfigurationCell from "../PeriodRelease/Readiness/FtpConfigurationCell";
import React from "react";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {getRetailerName} from "../../utils/retailerMapCache";

export function getReadinessColumns(alertIsLoading) {
    return [
        {
            header: 'Retailer',
            accessorKey: 'retailer.retailerId',
            cell: ({row})=> <RetailerIdAndName retailerId={row.original.retailer.retailerId}/>,
            size: 100,
            className: 'non-numeric-field',
            filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.retailer.retailerId} ${getRetailerName(row.original.retailer.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))
        },
        {
            header: 'Issues',
            id: 'issues',
            accessorKey: 'issues',
            enableColumnFilter: false,
            className: 'non-numeric-field',
            cell: ({getValue}) => <ReadinessIssues issues={getValue()}
                                                collapsedContent={getValue() && `${getValue().length} issues`}
                                                createListItem={createReadinessIssuesListItem}/>,
            sortingFn: (rowA, rowB) => {
                const filterRow = row => row.original.issues;
                return sortArraysWithNullsLast(filterRow(rowA), filterRow(rowB));
            }
        },
        {
            header: 'Retailer Total',
            id: 'retailer-total',
            accessorKey: 'issues',
            enableColumnFilter: false,
            className: 'non-numeric-field',
            maxSize: 80,
            cell: ({row}) => <RetailerTotalCell data={row.original}/>,
            sortingFn: (rowA, rowB) => {
                const filterRow = row => row.original.issues?.filter(x => x.issueType === 'missingData');
                return sortArraysWithNullsLast(filterRow(rowA), filterRow(rowB));
            }
        },
        {
            header: 'Store Total',
            id: 'store-total',
            enableColumnFilter: false,
            accessorKey: 'weeksWithSales',
            className: 'non-numeric-field',
            maxSize: 80,
            cell: ({row}) => <StoreTotalCell data={row.original}/>,
            sortingFn: (rowA, rowB) => {
                const x = rowA.original.weeksWithSales.weeksWithData / rowA.original.weeksWithSales.totalExpectedWeeks
                const y = rowB.original.weeksWithSales.weeksWithData / rowB.original.weeksWithSales.totalExpectedWeeks
                return sortAscendingWithNullsFirst(x, y)
            }
        },
        {
            header: 'Outliers',
            id: 'outliers',
            enableColumnFilter: false,
            minWidth: 200,
            className: 'non-numeric-field',
            cell: ({row}) => <OutliersCell data={row.original}/>,
        },
        {
            header: 'Alerts',
            id: 'alerts',
            enableColumnFilter: false,
            accessorKey: 'alertData',
            className: 'non-numeric-field',
            size: 100,
            cell: ({row}) => <AlertsCell data={row.original} isLoading={alertIsLoading}/>,
            sortingFn: (rowA, rowB) => {
                const filterRow = row => row.original.alertData;
                return sortArraysWithNullsLast(filterRow(rowA), filterRow(rowB));
            }
        },
        {
            header: 'Unmapped Stores',
            id: 'unmapped-stores',
            accessorFn: (original) => (original.issues ? original.issues.find(x => x.issueType === 'unmappedStores') : {count: null}) || {count: null},
            enableColumnFilter: false,
            className: 'non-numeric-field',
            maxSize: 80,
            cell: ({row}) => <UnmappedStoresCell data={row.original}/>,
            sortingFn: (rowA, rowB) => {
                const filterRow = row => row.original.issues?.filter(x => x.issueType === 'unmappedStores').length;
                return sortAscendingWithNullsFirst(filterRow(rowA), filterRow(rowB));
            }
        },
        {
            header: 'FTP Config',
            id: 'ftp-configuration',
            accessorFn: original => original.issues ? original.issues.filter(x => x.issueType === 'missingFtpConfiguration') : null,
            enableColumnFilter: false,
            className: 'non-numeric-field',
            maxSize: 80,
            cell: ({row}) => <FtpConfigurationCell data={row.original}/>,
            sortingFn: (rowA, rowB) => {
                const filterRow = row => row.original.issues?.filter(x => x.issueType === 'missingFtpConfiguration');
                return sortArraysWithNullsLast(filterRow(rowA), filterRow(rowB));
            }
        }
    ];
}