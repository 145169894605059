import React, {useMemo, useState} from 'react';
import Page from '../../Components/Page';
import {makeApolloClientCall} from '../../../Api/makeApolloApiCall';
import ExtractSpotlightContent from './ExtractSpotlightContent';
import LoadingSpinner from '../../Components/LoadingSpinner';
import ButtonLoader from "../../../utils/components/ButtonLoader";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import {getMissingWeeksSpotlightColumns, getSpotlightColumns} from "../GetSpotlightColumns";
import SelectDropdown from "../../Components/Dropdowns/SelectDropdown";
import {updateQueryStringForStateHistory, useQueryParams} from "../../../utils/urlBuilder";
import Checkbox from "../../Components/Checkbox";
import _ from "lodash";
import ReactTable from "../../Components/ReactTable";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";
import ThresholdSelection from "../ThresholdSelection";
import {createDagRunConf} from "../../../Api/dagRunConf";
import {useLocation, useNavigate} from "react-router-dom";
import MissingWeeksSpotlightContent from "./MissingDatesSpotlightContent";

export const cleanseFillExtractTablesQuery = `query {
                tablesInSchema(schemaName: "cleanse_fill_extract", ){
                      tableName
                }
            }`

export function getComparisonTableColumns(history, handleSubmitViewComparison) {
    return [
        {
            header: 'Previous Extract',
            accessorKey: 'from_this_table'
        },
        {
            header: 'Current Extract',
            accessorKey: 'to_this_table'
        },
        {
            header: 'Date Run',
            id: 'createdDate',
            accessorKey: 'created_date'
        },
        {
            header: 'Ignore PLU',
            accessorKey: 'ignore_plu',
            cell: ({getValue}) => {
                return getValue() ? <i className="fas fa-check" aria-hidden="true"/> : <></>;
            },
            size: 100,
            sortingFn: (rowA, rowB) => {
                const ignorePluA = rowA?.original?.ignorePlu;
                const ignorePluB = rowB?.original?.ignorePlu;
                return sortAscendingWithNullsLast(ignorePluA, ignorePluB);
            }
        },
        {
            header: 'Ignore IFPS',
            accessorKey: 'ignore_ifps',
            cell: ({getValue}) => {
                return getValue() ? <i className="fas fa-check" aria-hidden="true"/> : <></>;
            },
            size: 100,
            sortingFn: (rowA, rowB) => {
                const ignoreIfpsA = rowA?.original?.ignoreIfps;
                const ignoreIfpsB = rowB?.original?.ignoreIfps;
                return sortAscendingWithNullsLast(ignoreIfpsA, ignoreIfpsB);
            }
        },
        {
            header: 'View Comparison',
            cell: ({row}) => <button className={"button secondary"}
                                     onClick={() => {
                                         updateQueryStringForStateHistory({
                                             'toSchema': row.original.to_this_table,
                                             'fromSchema': row.original.from_this_table,
                                             'ignorePlu': row.original.ignore_plu,
                                             'ignoreIfps': row.original.ignore_ifps
                                         }, history)
                                         handleSubmitViewComparison(row.original.run_identifier)
                                     }}
            >View</button>
        }
    ];
}

const ExtractSpotlightPage = () => {
    const query = useQueryParams(useLocation())
    const fromSchema = query.get('fromSchema') || undefined
    const toSchema = query.get('toSchema') || undefined
    const view = query.get('view') || undefined
    const ignorePlu = (query.get('ignorePlu') !== 'false')
    const ignoreIfps = (query.get('ignoreIfps') !== 'false')
    const [data, setData] = useState(undefined);
    const [missingWeeksData, setMissingWeeksData] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [schemaOptions, setSchemaOptions] = useState(undefined);
    const [thresholdDollars, setThresholdDollars] = useState(20);
    const [thresholdUnits, setThresholdUnits] = useState(20);
    const [activeRetailersOnly, setActiveRetailersOnly] = useState(true);
    const [loading, setLoading] = useState(false)
    const [comparisonSchemas, setComparisonSchemas] = useState([]);
    const [runIdentifier, setRunIdentifier] = useState(undefined);
    const history = useNavigate();

    const SPOTLIGHT_COLUMNS = getSpotlightColumns(fromSchema, toSchema, ignorePlu, ignoreIfps, thresholdDollars, thresholdUnits)
    const MISSING_SPOTLIGHT_COLUMNS = getMissingWeeksSpotlightColumns(fromSchema, toSchema, ignorePlu, runIdentifier)

    const getQuery = useMemo(() => (runIdentifier) => {
        return `query {
                              getExtractSpotlight(runIdentifier:"${runIdentifier}",
                                                  dollarsPercentThreshold:${thresholdDollars / 100},
                                                  unitsPercentThreshold:${thresholdUnits / 100},
                                                  activeRetailersOnly:${activeRetailersOnly}
                              ){
                                retailerId
                                retailerName
                                weeks
                                problemWeeks
                                percentGood
                                problemWeeksLast12
                              }
                            }`
    }, [thresholdDollars, thresholdUnits, activeRetailersOnly])

    const getMissingWeeksQuery = useMemo(() => (runIdentifier) => {
        return `query {
                              getMissingDataExtractSpotlight(runIdentifier:"${runIdentifier}", 
                                                  activeRetailersOnly:${activeRetailersOnly}
                              ){
                                retailerId
                                retailerName
                                problemWeeks
                              }
                            }`
    }, [activeRetailersOnly])

    const handleSubmitViewComparison = useMemo(() => async (runIdentifier) => {
        setError(undefined)
        const canSubmit = runIdentifier && thresholdDollars && thresholdUnits;
        if (canSubmit) {
            setData(undefined)
            setMissingWeeksData(undefined)
            setLoading(true)
            setRunIdentifier(undefined)


            makeApolloClientCall(getQuery(runIdentifier))
                .then((result) => {
                    setData(result.getExtractSpotlight)
                })
                .catch(() => {
                    setError('Spotlight Query Failed, Please Try Again')
                })
                .finally(() => {
                    setLoading(false)
                    setRunIdentifier(runIdentifier)
                })

            makeApolloClientCall(getMissingWeeksQuery(runIdentifier))
                .then((missingWeeksQueryResults) => {
                    setMissingWeeksData(missingWeeksQueryResults?.getMissingDataExtractSpotlight)
                })
                .catch(() => {
                    setError('Spotlight Query Failed, Please Try Again')
                })
                .finally(() => {
                    setLoading(false)
                    setRunIdentifier(runIdentifier)
                })
        }
    }, [thresholdDollars, thresholdUnits, getQuery, getMissingWeeksQuery])

    React.useEffect(() => {
        Promise.all([
            makeApolloClientCall(cleanseFillExtractTablesQuery),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/extractSpotlightMetadata/`)
        ])
            .then(([schemasForTableQueryResults, schemasForComparisonsTableQueryResults]) => {
                schemasForTableQueryResults && schemasForTableQueryResults.tablesInSchema ?
                    setSchemaOptions(schemasForTableQueryResults.tablesInSchema.map(
                        (s) => s.tableName
                    ).filter(
                        tableName => tableName.includes('__')
                    ).filter(
                        tableName => tableName.endsWith('_cleanse_fill_extract')
                    ).sort((runIdentifier1, runIdentifier2) => {
                        const aTimestamp = runIdentifier1.split('__')[1];
                        const bTimestamp = runIdentifier2.split('__')[1];
                        return bTimestamp.localeCompare(aTimestamp)
                    }).map(
                        runIdentifier => ({
                            value: runIdentifier,
                            label: runIdentifier.replaceAll('_cleanse_fill_extract', '')
                        })
                    )) :
                    setSchemaOptions([])

                setComparisonSchemas(schemasForComparisonsTableQueryResults || [])
            });
        if (view) {
            handleSubmitViewComparison(runIdentifier)
        }
    }, [view, fromSchema, toSchema, ignorePlu, ignoreIfps, handleSubmitViewComparison, runIdentifier])

    const comparisonSchemaColumns = useMemo(() => getComparisonTableColumns(history, handleSubmitViewComparison), [history, handleSubmitViewComparison])
    const isDisabled = !(toSchema && fromSchema && schemaOptions);
    const matchedComparisonFromTable = (fromSchema && toSchema && comparisonSchemas && comparisonSchemas.find((metadata) =>
        metadata.from_this_table === fromSchema && metadata.to_this_table === toSchema && metadata.ignore_plu === ignorePlu && metadata.ignore_ifps === ignoreIfps
    ))

    return (
        <Page name={"Spotlight: Cleanse Fill"}>
            <div className={'spotlight-selection-group'}>
                <div className={'column'}>
                    <h6 className={'spotlight-header'}>THRESHOLD %</h6>
                    <ThresholdSelection dollarValue={thresholdDollars}
                                        dollarOnChange={(event) => setThresholdDollars(event.target.value)}
                                        unitValue={thresholdUnits}
                                        unitOnChange={(event) => setThresholdUnits(event.target.value)}/>
                    <div id={'check-boxes'}>
                        <Checkbox
                            labelText={'Active Retailers Only'}
                            onChange={setActiveRetailersOnly}
                            checked={activeRetailersOnly}/>
                    </div>
                </div>
                <div className={'spotlight-divider'}/>
                <div className={'column'}>
                    <h6 className={'spotlight-header'}>Compare cleanse_fill_extract data sets</h6>
                    <div className={'schema-selection'}>
                        <div className={'schema-selection-row'}>
                            <div className={'schema-field'}>
                                <span>Previous Extract</span>
                                {<SelectDropdown
                                    id={"from-schema-dropdown"}
                                    onChange={(option) => {
                                        const value = option ? option.value : undefined;
                                        updateQueryStringForStateHistory({'fromSchema': value}, history)
                                    }}
                                    options={schemaOptions}
                                    selectValue={fromSchema}
                                    isClearable={false}
                                    placeholder={"Select schema..."}
                                />}
                            </div>
                            <div className={'schema-field'}>
                                <span>Current Extract</span>
                                {<SelectDropdown
                                    id={"to-schema-dropdown"}
                                    onChange={(option) => {
                                        const value = option ? option.value : undefined;
                                        updateQueryStringForStateHistory({'toSchema': value}, history)
                                    }}
                                    options={schemaOptions}
                                    selectValue={toSchema}
                                    isClearable={false}
                                    placeholder={"Select schema..."}
                                />}
                            </div>
                        </div>

                        <div className={'schema-selection-row'}>
                            <div className={'schema-field'}>
                                <div id={'check-boxes'}>
                                    <Checkbox
                                        labelText={'Ignore PLU'}
                                        onChange={(checked) => {
                                            updateQueryStringForStateHistory({'ignorePlu': checked}, history)
                                        }}
                                        checked={ignorePlu}/>
                                    <Checkbox
                                        labelText={'Ignore IFPS'}
                                        onChange={(checked) => {
                                            updateQueryStringForStateHistory({'ignoreIfps': checked}, history)
                                        }}
                                        checked={ignoreIfps}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'spotlight-divider'}/>
                <div className={'submit-spotlight'}>
                    <ButtonLoader buttonTitle={"Generate Comparison"}
                                  buttonClassName={"button submit left-margin"}
                                  axiosFunction={makeApiCallPost}
                                  postBody={{
                                      dagName: "extract_spotlight_pre_process",
                                      data: createDagRunConf({
                                          to_this_table: toSchema,
                                          from_this_table: fromSchema,
                                          ignore_plu: ignorePlu,
                                          ignore_ifps: ignoreIfps
                                      })
                                  }}
                                  disabled={isDisabled}
                                  url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                                  toastTextFailure={`Generate Comparison has failed to kicked off.`}
                                  toastTextSuccess={`Generate Comparison has successfully kicked off.`}
                    />
                    <button disabled={!matchedComparisonFromTable}
                            className={'extract-spotlight-view button primary left-margin'}
                            onClick={() => {
                                return handleSubmitViewComparison(matchedComparisonFromTable.run_identifier)
                            }
                    }
                    >View Comparison
                    </button>
                </div>
            </div>
            {loading && <LoadingSpinner/>}
            {!loading && !data && !_.isEmpty(comparisonSchemas) && <ReactTable
                columns={comparisonSchemaColumns}
                data={comparisonSchemas}
                initialState={{sorting: [{id: 'createdDate', desc: true}]}}
                minRows={0}
            />}
            {data &&
                <ExtractSpotlightContent columns={SPOTLIGHT_COLUMNS} data={data}/>}
            {missingWeeksData && runIdentifier &&
                <MissingWeeksSpotlightContent columns={MISSING_SPOTLIGHT_COLUMNS} data={missingWeeksData}/>}
            {error && <div className={'error-div'}>
                {error}
                <i
                    title={"Refresh"}
                    className={"fas fa-sync-alt"}
                    onClick={() => handleSubmitViewComparison(fromSchema, toSchema, ignorePlu, ignoreIfps)}
                />
            </div>}
        </Page>
    );
}

export default ExtractSpotlightPage;