import React, {useCallback, useEffect, useRef, useState} from 'react';
import Checkbox from "../../Components/Checkbox";
import {StoreAddressMap} from "./StoreAddressMap";
import {useMap, useMapsLibrary} from "@vis.gl/react-google-maps";
import {toast} from "react-toastify";
import {toastError, toastSuccess} from "../../../utils/toast";
import {findBestAddressAndLocationForAddress, findBestAddressForLocation} from "../../../Api/googleMaps/search";
import PropTypes from "prop-types";

export const addressLocationNotSyncedMessage = 'Address location is not validated.'

export function AddressMapInputField({form}) {

    // const [isGeographicalAddress, setIsGeographicalAddress] = useState(true)
    const [isAddressLocationSynced, setIsAddressLocationSynced] = useState(true)

    const geocodingLib = useMapsLibrary('geocoding');
    const placesMap = useMap();
    const placesLib = useMapsLibrary('places');

    const validateAddressButtonName = 'Validate Address'

    const addr1 = form.watch('addr1')
    const addr2 = form.watch('addr2')
    const city = form.watch('city')
    const state = form.watch('state')
    const county = form.watch('county')
    const country = form.watch('country')
    const zip1 = form.watch('zip1')
    const zip2 = form.watch('zip2')

    const addr1Ref = useRef(addr1)
    const addr2Ref = useRef(addr2)
    const cityRef = useRef(city)
    const stateRef = useRef(state)
    const countyRef = useRef(county)
    const countryRef = useRef(country)
    const zip1Ref = useRef(zip1)
    const zip2Ref = useRef(zip2)

    const latitude = form.watch('latitude');
    const longitude = form.watch('longitude');

    const retailerId = form.watch('retailerId')

    const validateAddress = useCallback(() => {
        findBestAddressAndLocationForAddress(
            {addr1, addr2, city, state, county, country, zip1, zip2},
            retailerId,
            geocodingLib, placesLib, placesMap
        ).then((address) => {
            if (address) {
                form.setValue('latitude', address.location.lat)
                form.setValue('longitude', address.location.lng)
                form.setValue('addr1', address.addr1)
                form.setValue('addr2', address.addr2)
                form.setValue('city', address.city)
                form.setValue('state', address.state)
                form.setValue('county', address.county)
                form.setValue('country', address.country)
                form.setValue('zip1', address.zip1)
                form.setValue('zip2', address.zip2)
                toastSuccess(toast, `Address validated/updated and synced with map location.`)

                addr1Ref.current = address.addr1
                addr2Ref.current = address.addr2
                cityRef.current = address.city
                stateRef.current = address.state
                countyRef.current = address.county
                countryRef.current = address.country
                zip1Ref.current = address.zip1
                zip2Ref.current = address.zip2

                setIsAddressLocationSynced(true)
            } else {
                toastError(toast, `Unable to validate address.  Enter a valid address and try again.`)
                setIsAddressLocationSynced(false)
            }
        }).catch((error) => {
            toastError(toast, `Unable to validate address (google mapping api unexpected error "${error.message}").  Please try again.`)
            setIsAddressLocationSynced(false)
        })
    }, [placesLib, placesMap, addr1, addr2, city, country, county, state, zip1, zip2, retailerId, form, geocodingLib])

    const isGeographicalAddressChanged = (isChecked) => {
        if (isChecked) {
            validateAddress()
        } else {
            form.setValue('latitude', 0)
            form.setValue('longitude', 0)
        }
    }

    useEffect(() => {
        setIsAddressLocationSynced((
            addr1Ref.current === addr1 &&
            addr2Ref.current === addr2 &&
            cityRef.current === city &&
            stateRef.current === state &&
            countyRef.current === county &&
            countryRef.current === country &&
            zip1Ref.current === zip1 &&
            zip2Ref.current === zip2
        ))
    }, [addr1, addr2, city, state, county, country, zip1, zip2])

    const isGeographicalAddress = !!(latitude && longitude)

    return (
        <>
            <div className={`edit-form-row-address-controls`} data-testid={'address-controls'}>
                <div>
                    <Checkbox
                        id={'active'}
                        labelText={'Geographical Address'}
                        onChange={isGeographicalAddressChanged}
                        checked={isGeographicalAddress}
                    />
                    <div className={'geographical-address-block'}>
                        {isGeographicalAddress && <button
                            type="button"
                            className={'button primary'}
                            aria-label={'validate-address'}
                            onClick={validateAddress}
                            title={validateAddressButtonName}
                        >
                            {validateAddressButtonName}
                        </button>}
                        {isGeographicalAddress && !isAddressLocationSynced && <div className={'address-location-out-of-sync'}>
                            <p>{addressLocationNotSyncedMessage}</p>
                            <p>Click the "{validateAddressButtonName}" button to validate.</p>
                            <p>Or click the "Save" button to save without validation.</p>
                        </div>}
                    </div>
                </div>


            </div>
            {!isGeographicalAddress && <div className={'hidden-map-for-google-places-api'} data-testid={'hidden-map'}><StoreAddressMap /></div>}
            <div className={`edit-form-row-address-map`} data-testid={'active-map'}>
                {isGeographicalAddress && geocodingLib &&
                    <StoreAddressMap
                        data-testid={'store-address-map-id'}
                        latitude={latitude}
                        longitude={longitude}
                        locationChanged={async (location) => {
                            form.setValue('latitude', location.lat)
                            form.setValue('longitude', location.lng)

                            return await findBestAddressForLocation(location, geocodingLib).then((newAddress) => {
                                if (newAddress) {
                                    form.setValue('addr1', newAddress.addr1)
                                    form.setValue('addr2', newAddress.addr2)
                                    form.setValue('city', newAddress.city)
                                    form.setValue('state', newAddress.state)
                                    form.setValue('county', newAddress.county)
                                    form.setValue('country', newAddress.country)
                                    form.setValue('zip1', newAddress.zip1)
                                    form.setValue('zip2', newAddress.zip2)

                                    toastSuccess(toast, `Address fields have been updated to match new location.`)

                                    addr1Ref.current = newAddress.addr1
                                    addr2Ref.current = newAddress.addr2
                                    cityRef.current = newAddress.city
                                    stateRef.current = newAddress.state
                                    countyRef.current = newAddress.county
                                    countryRef.current = newAddress.country
                                    zip1Ref.current = newAddress.zip1
                                    zip2Ref.current = newAddress.zip2

                                    setIsAddressLocationSynced(true)
                                } else {
                                    toastError(toast, `Unable to update address fields for new location (google api was unable to find an address for this location).`)
                                    setIsAddressLocationSynced(false)
                                }
                            }).catch((error) => {
                                console.log("=============> error: ", error)
                                toastError(toast, `Unable to update address fields for new location (google mapping api unexpected error "${error.message}").  Please try again later.`)
                                setIsAddressLocationSynced(false)
                            })
                        }}
                    />}
            </div>
        </>
    );
}

AddressMapInputField.propTypes = {
    form: PropTypes.object.isRequired
}