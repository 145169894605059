import React from 'react';
import PropTypes from 'prop-types';
import {GcsLinkIfExists} from "../../Components/GcsLinkIfExists";

const GcsDataTab = ({ftps, retailerId}) => {
    const gcsStorageBaseUrl = `https://console.cloud.google.com/storage/browser/spins-edp-${process.env.REACT_APP_ENV}`
    return (<>
        <div>
            <a id={'salesArchiveLink'} target={'_blank'} rel={'noopener noreferrer'}
               href={`${gcsStorageBaseUrl}-archive/p_data%3Dsales/p_retailer_id%3D${retailerId}`}>
                Sales Archive
            </a>
        </div>
        <div>
            <GcsLinkIfExists
                url={`spins-edp-${process.env.REACT_APP_ENV}-archive/p_data%3Ditems/p_retailer_id%3D${retailerId}`}>
                Items Archive
            </GcsLinkIfExists>
        </div>
        <div>
            <GcsLinkIfExists
                url={`spins-edp-${process.env.REACT_APP_ENV}-archive/p_data%3Dstores/p_retailer_id%3D${retailerId}`}>
                Stores Archive
            </GcsLinkIfExists>
        </div>
        <div>
            <GcsLinkIfExists
                url={`spins-edp-${process.env.REACT_APP_ENV}-data/p_data%3Dnormalized/p_duration%3Ddaily/p_retailer_id%3D${retailerId}`}>
                GCS Normalize Data Daily
            </GcsLinkIfExists>
        </div>
        <div>
            <a id={'gcsNormalizeDataWeeklyLink'} target={'_blank'} rel={'noopener noreferrer'}
               href={`${gcsStorageBaseUrl}-data/p_data%3Dnormalized/p_duration%3Dweekly/p_retailer_id%3D${retailerId}`}>
                GCS Normalize Data Weekly
            </a>
        </div>
        <div>
            <a id={'gcsCleanseDataLink'} target={'_blank'} rel={'noopener noreferrer'}
               href={`${gcsStorageBaseUrl}-data/p_data%3Dcleansed/p_duration%3Dweekly/p_retailer_id%3D${retailerId}`}>
                GCS Cleanse Data
            </a>
        </div>
        <div>
            <a id={'gcsFillDataLink'} target={'_blank'} rel={'noopener noreferrer'}
               href={`${gcsStorageBaseUrl}-data/p_data%3Dfill/p_duration%3Dweekly/p_retailer_id%3D${retailerId}`}>
                GCS Fill Data
            </a>
        </div>
        {ftps && ftps.map((ftp, index) =>
            <div key={index}><a id={`gcsBulkStageLink_${ftp}`}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                                href={`${gcsStorageBaseUrl}-bulk-stage/${ftp}`}>{`GCS Bulk Stage (${ftp})`}</a>
            </div>)}
    </>);
};

GcsDataTab.propTypes = {
    ftps: PropTypes.arrayOf(PropTypes.string).isRequired,
    retailerId: PropTypes.number.isRequired
};

export default GcsDataTab;