import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import classnames from "classnames";

const ConfirmationModal = ({
                               children,
                               cancellationCallback,
                               confirmationCallback,
                               modalClassName,
                               modalHeaderClassName,
                               headerText,
                               submitButtonDisabled,
                               confirmButtonText,
                               confirmButtonClass
                           }) => {
    return (
        <Modal
            clickable
            isOpen={true}
            className={modalClassName}
            overlayClassName="Overlay"
            ariaHideApp={false}
            onRequestClose={cancellationCallback}
            testId={"confirmation-modal"}
        >
            <div className={modalHeaderClassName}>
                <div><h5 className={"modal-header-text"}>{headerText}</h5></div>
                <div>
                    <button className={"right-exit-button"} onClick={cancellationCallback}>
                        <i className="fas fa-times" /></button>
                </div>
            </div>
            <div>{children}</div>
            <div className={"right-things"}>
                <button className="button modal-button cancel" type="button" name="close"
                        onClick={cancellationCallback}>
                    Cancel
                </button>
                <button type="button"
                        className={classnames("button", "modal-button", "confirm", confirmButtonClass)}
                        onClick={confirmationCallback}
                        disabled={submitButtonDisabled}
                >
                    {confirmButtonText || "Confirm"}
                </button>
            </div>
        </Modal>
    );
};

ConfirmationModal.defaultProps = {
    modalClassName: "Modal",
    submitButtonDisabled: false,
    modalHeaderClassName: "modal-header"
};
ConfirmationModal.propTypes = {
    cancellationCallback: PropTypes.func.isRequired,
    confirmationCallback: PropTypes.func.isRequired,
    modalHeaderClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    headerText: PropTypes.string,
    submitButtonDisabled: PropTypes.bool
};

export default ConfirmationModal;