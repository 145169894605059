import {CircanaDataLoadStatuses} from './CircanaDataLoad';
import React, {useState} from 'react';
import {makeApiCallPost} from '../../Api/makeApiCall';
import ConfirmationModal from '../Components/ConfirmationModal';
import {toast} from "react-toastify";
import {toastSuccess} from '../../utils/toast';

export const ConventionalUnloadTriggerButton = ({row, onApiCallComplete, onApiCallStart}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const rowData = row.original;
    const rerunButtonTextStatuses = new Set([CircanaDataLoadStatuses.FAILED, CircanaDataLoadStatuses.COMPLETED]);
    const status = rowData.status;
    const isAvailableForUnload = rowData.is_available_for_unload;
    const isLoadInProgress = status === CircanaDataLoadStatuses.IN_PROGRESS;
    const buttonText = rerunButtonTextStatuses.has(status) ? 'Re-Run' : 'Run';
    const table = rowData.table_name;
    const dag = table.startsWith('prod_meta_upc') ? 'conventional_unload_prod_meta_upc' : 'conventional_unload_sales';

    async function makeTriggerUnloadCall() {
        setIsModalOpen(false);
        onApiCallStart();
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/conventional/unload/trigger`;
        const postBody = {load_identifier: rowData.load_identifier}
        await makeApiCallPost(url, postBody);
        toastSuccess(toast, `${dag} has started`);
        onApiCallComplete();
    }

    return (
        <>
            <button
                className={'button primary'} onClick={() => setIsModalOpen(true)}
                disabled={!isAvailableForUnload || isLoadInProgress}
                title={isLoadInProgress ? 'An unload was already triggered, refresh the page to see if it has completed' : !isAvailableForUnload ? 'This load is superseded by a newer load to this table and so is no longer available for unload' : 'Trigger an unload of this data from Snowflake'}
            >
                {buttonText} DAG
            </button>
            {
                isModalOpen && <ConfirmationModal confirmationCallback={makeTriggerUnloadCall}
                                                  cancellationCallback={() => setIsModalOpen(false)}
                                                  headerText={'Trigger Conventional Unload'}

                >
                    <div>{`Are you sure you want to ${buttonText.toLowerCase()} the Conventional Unload DAG?`}</div>
                </ConfirmationModal>
            }
        </>
    )
}