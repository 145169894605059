import React from 'react';
import PropTypes from 'prop-types';
import Page from "../../Components/Page";
import ButtonLoader from "../../../utils/components/ButtonLoader";
import {makeApiCallPost} from "../../../Api/makeApiCall";
import {createDagRunConf} from "../../../Api/dagRunConf";
import {UnmappedStoresTable} from "../../Unmapped/UnmappedStoresTable";
import IgnoredStoreService from "../../Services/IgnoredStoreService";
import {SupplementalStoresTable} from "../../Configurations/SupplementalStoresTable";
import {StoreClosuresForRetailer} from "../../Configurations/StoreClosuresForRetailer";
import RetailerStores from "../RetailerStores";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export const StoresTab = ({retailChain, retailerId}) => {
    return (
        <Tabs>
            <TabList>
                <Tab data-testid={"retailerStores-tab-name"}>Store Info</Tab>
                <Tab data-testid={"ignoredStoreService-tab-name"}>Ignored</Tab>
                <Tab data-testid={"supplementalStoresTable-tab-name"}>Supplemental</Tab>
                <Tab data-testid={"storeClosuresForRetailer-tab-name"}>Closures</Tab>
            </TabList>
            <TabPanel data-testid={"retailerStores-tab-panel"}>
                <RetailerStores retailerId={retailerId}/>
                <div>
                    <Page displayHelmetHeader={false} name={'Unmapped Stores'}>
                        <ButtonLoader
                            buttonClassName={'button alert'}
                            buttonTitle={'Reload Unmapped Stores'}
                            buttonHasModal
                            modalHeader={'Reload Unmapped Stores'}
                            userAcknowledgedCheck
                            modalText={`this cannot be undone`}
                            axiosFunction={makeApiCallPost}
                            url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                            postBody={{
                                dagName: 'reingest_unmapped_stores',
                                data: createDagRunConf({retailer_id: retailerId})
                            }}
                            toastTextFailure={`Reloading of unmapped stores for ${retailChain} has failed to kicked off.`}
                            toastTextSuccess={`Reloading of unmapped stores for ${retailChain} has successfully kicked off.`}
                        />
                        <UnmappedStoresTable retailerId={retailerId}/>
                    </Page>
                </div>
            </TabPanel>
            <TabPanel data-testid={"ignoredStoreService-tab-panel"}><IgnoredStoreService retailerId={retailerId} displayHelmetHeader={false}/></TabPanel>
            <TabPanel data-testid={"supplementalStoresTable-tab-panel"}><SupplementalStoresTable retailerId={retailerId} displayHelmetHeader={false}/></TabPanel>
            <TabPanel data-testid={"storeClosuresForRetailer-tab-panel"}><StoreClosuresForRetailer retailerId={retailerId}/></TabPanel>
        </Tabs>
    )
};


StoresTab.propTypes = {
    retailChain: PropTypes.string.isRequired,
    retailerId: PropTypes.number.isRequired
};