import _ from 'lodash';

export function getBooleanParamOrDefault(key, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    const stringBooleanValue = searchParams.get(key);
    return stringBooleanValue ? stringBooleanValue === 'true' : defaultValue;
}

export function getJsonParamOrDefault(key, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    const stringValue = searchParams.get(key);
    try {
        return stringValue ? JSON.parse(stringValue) : defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getStringParamOrDefault(key, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key) || defaultValue;
}
export function getStringParamsOrDefault(key, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = searchParams.getAll(key);
    return queryParams.length > 0 ? queryParams : [defaultValue];
}

export function getIntParamOrDefault(key, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    return Number(searchParams.get(key)) || defaultValue;
}

export function getStringArrayParamOrDefault(key, defaultValue) {
    const searchParams = new URLSearchParams(window.location.search);
    const response = searchParams.getAll(key);
    return _.isEmpty(response) ? defaultValue : response;
}

export function convertToQueryParams(objectToConvert) {
    const params = new URLSearchParams();
    Object.entries(objectToConvert)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => Array.isArray(value) ? [key, value] : [key, [value]])
        .flatMap(([key, array]) => array.map(arrayValue => [key, arrayValue]))
        .forEach(([key, value]) => params.append(key, value))
    return params.toString();
}

export function setQueryStringForState(state) {
    const queryString = convertToQueryParams(state);
    const url = window.location.origin + window.location.pathname + '?' + queryString;
    window.history.pushState('', '', url);
}

export function updateQueryStringForState(updates) {
    const currentParams = {};
    new URLSearchParams(window.location.search)
        .forEach((value, key) => {
            currentParams[key] = value;
        })
    setQueryStringForState({...currentParams, ...updates});
}

export function setQueryStringForStateHistory(state, history) {
    const queryString = convertToQueryParams(state);
    const url = window.location.pathname + '?' + queryString;
    history(url);
}

export function updateQueryStringForStateHistory(updates, history) {
    const currentParams = {};
    new URLSearchParams(window.location.search)
        .forEach((value, key) => {
            currentParams[key] = value;
        })
    setQueryStringForStateHistory({...currentParams, ...updates}, history);
}


export function useQueryParams(location) {
    return new URLSearchParams(location.search)
}