import React, {useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import Page from '../../Components/Page';
import PeriodsDropdown from '../../Components/Dropdowns/PeriodsDropdown';
import {makeApolloClientCall} from '../../../Api/makeApolloApiCall';
import ScaffoldingPublishTable from './ScaffoldingPublishTable';
import {makeApiCall} from '../../../Api/makeApiCall';
import {SPINS_OUTLETS_QUERY} from '../../../queries/spinsOutlets';
import {getEdpAirflowUri} from '../../../utils/airflow';
import ScaffoldingPublishConfirmationModal from './ScaffoldingPublishConfirmationModal';
import {invokeDAGviaApiPost} from '../../../Api/invokeDag';
import {toastError, toastSuccess} from '../../../utils/toast';
import {getMarketInsightsRunsForPeriodSelection} from './getMarketInsightsRunsForPeriodSelection';
import {getOutletsWithOneAssociatedDagRun} from './getOutletsWithOneAssociatedDagRun';
import _ from 'lodash';
import {getUserEmail} from "../../../auth/accessTokenValidator";
import AirflowDagLogs from "../../Components/airflow/dagLogs/AirflowDagLogs";
import {DAG_NAMES} from "../../Configurations/DagNames";

export const PUBLISH_MARKET_INSIGHTS_DAG_NAME = 'publish_market_insights';

const ScaffoldingPublish = () => {
    const [periodSelection, updatePeriodSelection] = useState(undefined);
    const [marketInsightRuns, updateMarketInsightRuns] = useState(undefined);
    const [outletNames, updateOutletNames] = useState(undefined);
    const [edpAirflowUrl, updateEdpAirflowUrl] = useState(undefined);
    const [isModalVisible, updateIsModalVisible] = useState(false);
    const [dagRuns, updateDagRuns] = useState([])

    React.useEffect(() => {
        const graphqlQuery = `query {
                periods(isCurrentPeriod: true) {
                    periodAbbreviation
                 }
            }`
        makeApolloClientCall(graphqlQuery).then((results) => updatePeriodSelection(results.periods[0].periodAbbreviation));

        const marketPeriodRunIdentifierByOutletURL = `${process.env.REACT_APP_EDP_API_BASE_URL}/marketPeriodRunIdentifiersByOutlet/`;

        Promise.all([
                makeApiCall(marketPeriodRunIdentifierByOutletURL),
                makeApolloClientCall(SPINS_OUTLETS_QUERY).then(result => result.outlets.map(outlet => outlet.name)),
                getEdpAirflowUri(),
                makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/airflow/api/dags/${DAG_NAMES.GENERATE_MEASURES}/dagRuns`),
            ]
        ).then(([marketInsightRuns, outletNames, airflowUrl, dagRuns]) => {
                updateMarketInsightRuns(marketInsightRuns)
                updateDagRuns(dagRuns["dag_runs"])
                updateOutletNames(outletNames)
                updateEdpAirflowUrl(airflowUrl)
            }
        )
    }, [])

    const [marketInsightRunsForThisPeriod, outletsWithOneAssociatedDagRun] = useMemo(() => {
        if (!outletNames) {
            return [undefined, undefined];
        }

        const marketInsightsRunsForPeriodSelection = getMarketInsightsRunsForPeriodSelection(outletNames, marketInsightRuns, periodSelection, edpAirflowUrl, dagRuns);
        const outletsWithOneAssociatedDagRunTemp = getOutletsWithOneAssociatedDagRun(marketInsightsRunsForPeriodSelection)

        return [marketInsightsRunsForPeriodSelection, outletsWithOneAssociatedDagRunTemp];
    }, [periodSelection, outletNames, marketInsightRuns, edpAirflowUrl, dagRuns]);

    const dagConf = {
        period: periodSelection,
        triggered_by: getUserEmail(),
        measures_sources: outletsWithOneAssociatedDagRun &&
            outletsWithOneAssociatedDagRun.map(({runIdentifier}) => runIdentifier)
    };

    const confirmationCallback = () => {
        return invokeDAGviaApiPost(
            PUBLISH_MARKET_INSIGHTS_DAG_NAME,
            dagConf
        )
            .then(() => {
                toastSuccess(toast, `${PUBLISH_MARKET_INSIGHTS_DAG_NAME} was successfully triggered`)
            })
            .catch((exception) => {
                console.error('Encountered an error when triggering the nucleus_market_facts DAG:')
                console.error(exception)
                toastError(toast, `Failed to trigger ${PUBLISH_MARKET_INSIGHTS_DAG_NAME}`)
            })
            .finally(() => updateIsModalVisible(false));
    }

    return (<Page name={'Publish'}>
        <PeriodsDropdown
            updatePeriod={updatePeriodSelection}
            selectedPeriod={periodSelection}
            graphqlQuery={'{ marketPeriodList }'}
        />
        <button disabled={!periodSelection || _.isEmpty(outletsWithOneAssociatedDagRun)}
                className={'button primary scaffolding-publish-button'}
                onClick={() => updateIsModalVisible(true)}
                data-testid={'publishButton'}>
            {'Publish'}
        </button>
        {
            periodSelection &&
            marketInsightRunsForThisPeriod &&
            <ScaffoldingPublishTable marketInsightRunsForThisPeriod={marketInsightRunsForThisPeriod}/>
        }
        {
            isModalVisible && <ScaffoldingPublishConfirmationModal confirmationCallback={() => confirmationCallback()}
                                                                   cancellationCallback={() => updateIsModalVisible(false)}
                                                                   dagConf={dagConf}
            />
        }

        <AirflowDagLogs
            processName={PUBLISH_MARKET_INSIGHTS_DAG_NAME}
            customColumns={[{header: 'Period', accessorKey: 'conf.period', className: 'non-numeric-field'}]}
        />

    </Page>);
};

export default ScaffoldingPublish;