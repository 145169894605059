import React, {useEffect, useState} from 'react';
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import _ from 'lodash'
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormDropdownInputField} from "../Components/ReactTable/EditRecordForm";

function PrivateLabelShown() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/privateLabelShown/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const [decodeLoading, setDecodeLoading] = useState(true)
    const [departmentData, setDepartmentData] = useState([]);

    useEffect(() => {
        makeApolloClientCall(`{ productSnapshotCategory {department departmentTag category categoryTag} }`)
            .then((result) => {
                    const dropdownData = result.productSnapshotCategory.map(x => ({
                            value: `{"departmentTag": "${x.departmentTag}", "categoryTag": "${x.categoryTag}"}`,
                            label: `${x.department}: ${x.category}`
                        })
                    );

                    setDepartmentData(_.sortBy(dropdownData, ['label']))
                    setDecodeLoading(false)
                }
            )
    }, []);

    const columns = [
        {
            header: "Department Category",
            accessorKey: "departmentCategory",
            cell: ({getValue}) => {
                const displayValue = _.find(departmentData, {value: getValue()})
                return <div>{displayValue?.label || getValue()} </div>
            },
        }
    ];

    return (<Page name={"Private Label Shown"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "Private Label Shown"}]}/>}>
        <LoadingWrapper loading={loading || decodeLoading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                initialState={{pagination: {pageSize: 25}}}
                editTable={[
                    INDEX_FIELD,
                    {id: 'departmentCategory', Component: args => <EditFormDropdownInputField {...args} options={departmentData} isRequired/>}
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default PrivateLabelShown