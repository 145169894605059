import React from 'react';
import PropTypes from 'prop-types';
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import Page from "../../Components/Page";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, NAME_COLUMN, NAME_FIELD} from "../../Components/ReactTable/common_custom_columns";
import {getStringParamOrDefault} from '../../../utils/urlBuilder';
import {Breadcrumbs} from '../../Components/Breadcrumbs';

const MarketServiceGenericService = ({pageName, endpointUrl}) => {
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    const period = getStringParamOrDefault('period', '');

    return (<Page name={pageName} breadCrumbs={
        <Breadcrumbs items={[{label: 'Services'}, {label: 'Market', to: `/marketService?selectedMarketPeriod=${period}`}, {label: pageName}]}/>
    }>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[NAME_COLUMN]}
                editTable={[INDEX_FIELD, NAME_FIELD]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
};

MarketServiceGenericService.propTypes = {
    endpointUrl: PropTypes.string,
    pageName: PropTypes.string
};

export default MarketServiceGenericService;