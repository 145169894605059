import React from 'react';
import PropTypes from 'prop-types';
import ButtonLoader from "../../utils/components/ButtonLoader";
import {makeApiCallPost} from "../../Api/makeApiCall";
import {createDagRunConf} from "../../Api/dagRunConf";

const RetailerInfoHeaderSection = ({ftps, retailerInformation, salesforceRetailers}) => {
    function getFirstRow() {
        return (
            <div className={'retailer-info-section'}>
                <div className={'retailer-name-value'} data-testid={"ftp-section"}>
                    <div className={'label-name'}>{"FTP"}</div>
                    <div className={'value-name'}>{ftps.join(', ')}</div>
                </div>
                <div className={'retailer-name-value'} data-testid={"roll-up-day-section"}>
                    <div className={'label-name'}>{"Roll-up Day"}</div>
                    <div className={'value-name'}>{retailerInformation.rollUpDayOfWeek}</div>
                </div>
                <div className={'retailer-name-value'} data-testid={"association-section"}>
                    <div className={'label-name'}>{"Association"}</div>
                    <div className={'value-name'}>{salesforceRetailers.association || 'N/A'}</div>
                </div>
                <div className={'retailer-name-value'}>
                    <div/>
                    <div/>
                </div>
            </div>
        )
    }

    function getSecondRow() {
        return (
            <div className={'retailer-info-section second-row'}>
                <div className={'retailer-name-value'} data-testid={"active-section"}>
                    <div className={'label-name'}>{"Is Active"}</div>
                    <div className={'value-name'}>{salesforceRetailers.active ? 'True' : 'False'}</div>
                </div>
                <div className={'retailer-name-value'} data-testid={"company-type-section"}>
                    <div className={'label-name'}>{"Company Type"}</div>
                    <div className={'value-name'}>{salesforceRetailers.companyType || 'N/A'}</div>
                </div>
                <div className={'retailer-name-value'} data-testid={"market-designation-section"}>
                    <div className={'label-name'}>{"Market Designation"}</div>
                    <div className={'value-name'}>{salesforceRetailers.marketDesignation || 'N/A'}</div>
                </div>
                <div className={'retailer-name-value'} data-testid={"is-fresh-section"}>
                    <div className={'label-name'}>{"Fresh Retailer"}</div>
                    <div className={'value-name'}>{retailerInformation.isFresh ? 'True' : 'False' || false}</div>
                </div>
            </div>
        )
    }

    function getSyncButtons() {
        return <div className={'retail-management-buttons'} data-testid={'retail-management-buttons'}>
            {
                (process.env.REACT_APP_ENV === 'uat') &&
                <ButtonLoader
                    buttonClassName={'button alert fas fa-clone'}
                    title={'Sync Archive Files From Prod To Uat'}
                    buttonHasModal
                    modalHeader={'Sync Archive Files From Prod To Uat'}
                    userAcknowledgedCheck
                    modalText={`Are you sure you want to sync all PROD archive data to UAT?`}
                    axiosFunction={makeApiCallPost}
                    url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                    postBody={{
                        dagName: 'copy_prod_archive_to_uat',
                        data: createDagRunConf({retailer_id: retailerInformation.retailerId})
                    }}
                    toastTextFailure={`Sync all archive data for ${retailerInformation.retailChain} has failed to kicked off.`}
                    toastTextSuccess={`Sync all archive data for ${retailerInformation.retailChain} has successfully kicked off.`}
                />}
            {(process.env.REACT_APP_ENV === 'dev') &&
                <ButtonLoader
                    buttonClassName={'button alert fas fa-clone'}
                    title={'Sync Services and Retailer Data Files From Prod To Dev'}
                    buttonHasModal
                    modalHeader={'Sync Services and Retailer Data Files From Prod To Dev'}
                    userAcknowledgedCheck
                    modalText={`Are you sure you want to sync services and data files from PROD to DEV?`}
                    axiosFunction={makeApiCallPost}
                    url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                    postBody={{
                        dagName: 'refresh_dev_data_from_prod_by_retailer',
                        data: createDagRunConf({retailer_id: retailerInformation.retailerId})
                    }}
                    toastTextFailure={`Sync services and data files for ${retailerInformation.retailChain} has failed to kicked off.`}
                    toastTextSuccess={`Sync services and data files for ${retailerInformation.retailChain} has successfully kicked off.`}
                />
            }
        </div>;
    }

    return (
        <div className={'retailer-info-bar'}>
            <div className={'retailer-info-header'}>
                {getSyncButtons()}
                {getFirstRow()}
                {getSecondRow()}
            </div>
        </div>
    )
};

RetailerInfoHeaderSection.propTypes = {
    ftps: PropTypes.array.isRequired,
    retailerInformation: PropTypes.object.isRequired,
    salesforceRetailers: PropTypes.object.isRequired
};

export default RetailerInfoHeaderSection;