import React, {Component} from 'react';
import StoreTotalsTotalDollars from "./Components/StoreTotalsTotalDollars";
import Page from "../../Components/Page";
import {getStringParamOrDefault, updateQueryStringForState} from "../../../utils/urlBuilder";
import {STORE_TOTALS_DEFAULT_SELECTIONS} from "../Components/TotalsSearchOptionsModal";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import {currentPeriodQuery} from "../../../queries/currentPeriodQuery";
import {TotalsSearchOptions} from "../Components/TotalsSearchOptions";
import {periodAbbreviationQuery} from "../../../queries/periodAbbreviationQuery";
import {getGraphqlQuery} from "./Components/StoreTotalsTotalDollarsQueries";
import {periodDetailsQuery} from "../Components/periodDetailsQuery";
import {getStartAndEndDatesFromPeriodDetails} from "../Components/getStartAndEndDatesFromPeriodDetails";
import {makeApiCall} from "../../../Api/makeApiCall";


export function mapDataSelection(dataSelection) {
    const map = {Dollars: 'dollar', Units: 'unit'}
    return map[dataSelection];
}

export const STORES_QUERY = '{stores{state}}'

class StoreTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchTotalsModal: getStringParamOrDefault('directSubmit', false),
            retailerId: getStringParamOrDefault('retailerId', undefined),
            endPeriodAbbreviation: getStringParamOrDefault('periodAbbreviation', undefined),
            includeFillOnly: false,
            data: undefined,
            retailersData: undefined,
            endPeriod: undefined,
            dataFormat: 'value',
            graphqlQuery: undefined,
            fillWatermark: undefined,
            promoWatermark: undefined,
            stateFilterOptions: [],
            stateFilter: getStringParamOrDefault('stateFilter', undefined),
            openStoresOnlyFilter: false,
            ...STORE_TOTALS_DEFAULT_SELECTIONS
        };

        this.handleRetailerChange = this.handleRetailerChange.bind(this);
        this.shouldRenderResults = this.shouldRenderResults.bind(this);
        this.confirmationCallback = this.confirmationCallback.bind(this);
        this.handleDataFormatChange = this.handleDataFormatChange.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
    }

    componentDidMount() {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/fill_watermark/`).then(result =>
            this.setState({fillWatermark: result})
        )
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/promo_watermark/`).then(result =>
            this.setState({promoWatermark: result})
        )
        makeApolloClientCall(STORES_QUERY).then((result) => {
            const stateFilterOptions = [...new Set(
                result.stores.map(store => store.state).filter(state => state && state !== '').sort()
            )];
            this.setState({stateFilterOptions})
        })
        if (this.state.endPeriodAbbreviation){
            return makeApolloClientCall(periodAbbreviationQuery, false,
                {periodAbbreviation: this.state.endPeriodAbbreviation}).then(async (response) => {
                const endPeriod = response.records[0];
                const periodAbbreviation = endPeriod.periodAbbreviation;
                const periodDetails = await makeApolloClientCall(periodDetailsQuery, true, {periodAbbreviation});
                const {startDate, endDate} = getStartAndEndDatesFromPeriodDetails(periodDetails.records[0].weeks);
                this.setState({endPeriod, startDate, endDate});
            });
        } else {
            return makeApolloClientCall(currentPeriodQuery)
                .then(async (response) => {
                    const endPeriod = response.records[0];
                    const periodAbbreviation = endPeriod.periodAbbreviation;
                    const periodDetails = await makeApolloClientCall(periodDetailsQuery, true, {periodAbbreviation});
                    const {startDate, endDate} = getStartAndEndDatesFromPeriodDetails(periodDetails.records[0].weeks);
                    this.setState({endPeriod, startDate, endDate});
                });
        }
    }

    handleDataFormatChange(dataFormat) {
        this.setState({dataFormat});
    }

    async handleRetailerChange(retailerId) {
        this.setState({retailerId, graphqlQuery: undefined});
    }

    canCreateGraphqlQuery() {
        return (this.state.retailerId !== undefined && this.state.startDate !== undefined && this.state.endDate !== undefined);
    }

    shouldRenderResults() {
        return (this.canCreateGraphqlQuery() && this.state.graphqlQuery);
    }

    async confirmationCallback(selections) {
        await this.setState({...selections});

        updateQueryStringForState({
            directSubmit: undefined,
            periodAbbreviation: this.state.endPeriod.periodAbbreviation,
            retailerId: this.state.retailerId,
            stateFilter: this.state.stateFilter
        });
        return await this.handleSubmitButton();
    }

    async handleSubmitButton() {
        if (!this.canCreateGraphqlQuery()) {
            return;
        }
        let endDateClause = '';
        let startDateClause = '';

        if (!this.state.includeAllData) {
            endDateClause = this.state.endDate ? `, endDate: "${this.state.endDate}"` : '';
            startDateClause = this.state.startDate ? `, startDate: "${this.state.startDate}"` : '';
        }

        const stateFilterClause = this.state.stateFilter ? `, state: "${this.state.stateFilter}"` : '';
        const openStoresOnlyClause = this.state.openStoresOnlyFilter ? `, openStoresOnly: ${this.state.openStoresOnlyFilter}` : ''

        const graphqlQueryForStoreTotals = getGraphqlQuery(
            mapDataSelection(this.state.dataSelection),
            this.state.dataFormat,
            this.state.retailerId,
            this.state.includePlu,
            this.state.includeIfps,
            this.state.includeYago,
            this.state.productSnapshotOnly,
            startDateClause,
            endDateClause,
            stateFilterClause,
            this.state.includeFillOnly,
            openStoresOnlyClause,
            true);
        this.setState({
            graphqlQuery: graphqlQueryForStoreTotals
        });
    }

    render() {
        return (
            <Page name={`Store Totals`}>
                <TotalsSearchOptions
                    cancellationCallback={() => this.setState({showSearchTotalsModal: !this.state.showSearchTotalsModal})}
                    showSearchTotalsModal={this.state.showSearchTotalsModal}
                    endPeriod={this.state.endPeriod}
                    confirmationCallback={this.confirmationCallback}
                    retailerId={this.state.retailerId}
                    onRetailerChange={this.handleRetailerChange}
                    percentThreshold={this.state.percentThreshold}
                    dataSelection={this.state.dataSelection}
                    includeAllData={this.state.includeAllData}
                    includePlu={this.state.includePlu}
                    includeIfps={this.state.includeIfps}
                    includeYago={this.state.includeYago}
                    includeFillOnly={this.state.includeFillOnly}
                    productSnapshotOnly={this.state.productSnapshotOnly}
                    shouldRenderChart={this.state.shouldRenderChart}
                    stateFilterOptions={this.state.stateFilterOptions}
                    stateFilter={this.state.stateFilter}
                    openStoresOnlyFilter={this.state.openStoresOnlyFilter}
                />
                {this.shouldRenderResults() && <StoreTotalsTotalDollars
                    selectedData={this.state.dataSelection}
                    retailerId={this.state.retailerId}
                    percentThreshold={this.state.percentThreshold}
                    dataFormat={this.state.dataFormat}
                    onDataFormatChange={this.handleDataFormatChange}
                    graphqlQuery={this.state.graphqlQuery}
                    shouldRenderChart={this.state.shouldRenderChart}
                    includeFillOnly={this.state.includeFillOnly}
                    fillWatermark={this.state.fillWatermark}
                    promoWatermark={this.state.promoWatermark}
                    includeYago={this.state.includeYago}
                />
                }
            </Page>
        )
    }
}


export default StoreTotals;
