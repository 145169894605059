import _ from 'lodash';

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const sortAscendingWithNullsFirst = (a, b) => {
    if(_.isNil(a) &&  _.isNil(b)){
        return 0;
    }
    if(_.isNil(a)){
        return -1;
    }
    if(_.isNil(b)){
        return 1;
    }

    return a - b;
};

export const sortAscendingWithNullsLast = (a, b) => {
    if(a === null && b === null){
        return 0;
    }
    if(a === null){
        return 1;
    }
    if(b === null){
        return -1;
    }
    return a - b;
};

export const sortArraysWithNullsLast = (a, b) => {
    if(_.isNil(a)  || _.isNil(b)  ) {
        return _.isNil(b) - _.isNil(a);
    }
    return a.length - b.length;
}

export const sortArrayOfObjectsByField = (data, fieldToSortBy, sortOrder, optionalAccessor = undefined) => {
    const arrayToSort = optionalAccessor ? data[optionalAccessor] : data;
    const sortedArray = _.orderBy(arrayToSort, fieldToSortBy, sortOrder);
    if (optionalAccessor){
        data[optionalAccessor] = sortedArray;
        return data
    }

    return sortedArray;
}
