import React, {Component} from 'react';

import {makeApiCall} from "../../Api/makeApiCall";
import moment from "moment-timezone";


class LastKeithFilesRun extends Component {

    constructor() {
        super();
        this.state = {
            lastRunDate: undefined
        }
    }

    async componentDidMount() {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/airflow/daglogs?processName=get_metadata_keith`
        const executionDates = await makeApiCall(endpointUrl).then(data =>data.metadata
            .filter(dagRun => dagRun.status === 'success')
            .map(dagRun => dagRun.executionDate)
            .sort()
        )
        const latestExecutionDate = executionDates[executionDates.length -1]
        if (latestExecutionDate) {
            moment.tz.setDefault('America/Chicago')
            const lastRunDate = moment(latestExecutionDate).format('YYYY-MM-DD hh:mm:ss')
            this.setState({lastRunDate})
        }
    }
    render() {
        return <>
            {this.state.lastRunDate && <span data-testid={'get_metadata_keith_label'} className={'last-run-time-stamp'}>{`get_metadata_keith last ran: ${this.state.lastRunDate}`}</span>}
        </>
    }
}

export default LastKeithFilesRun;
