import {makeApolloClientCall} from '../../../../Api/makeApolloApiCall';
import _ from 'lodash';
import csvDownloadWithNulls from '../../../../utils/CsvDownloadWithNulls';

export async function getStoreDataAsCsv(retailerId, graphqlQuery) {
    const graphqlResponse = await makeApolloClientCall(graphqlQuery);
    const sorted_dates = graphqlResponse.sales.weekEndDates.sort().reverse();
    const storeResultsPreFilter = graphqlResponse.sales.stores;
    const storeOutlier = graphqlResponse.outliers.data;
    const storeWithSkipConfiguration = graphqlResponse.stores.data;

    const storeResults = storeResultsPreFilter.filter(s => !(_.includes(storeWithSkipConfiguration, s.storeId)));

    // noinspection CommaExpressionJS
    const baseObject = sorted_dates.reduce((a, b) => (a[b] = '', a), {}); // eslint-disable-line no-sequences

    const output = storeResults.map(store => {
        const objectToUse = _.cloneDeep(baseObject);
        for (const [key] of Object.entries(objectToUse)) {
            const possiblyFoundDate = store.weekEndData.find(data => data.date === key)
            objectToUse[key] = possiblyFoundDate ? possiblyFoundDate.dollars : null;
        }
        objectToUse['storeName'] = store.storeName;
        objectToUse['storeId'] = store.storeId;
        objectToUse['averageDollarCount'] = storeOutlier.find(outlier => (outlier.storeId === store.storeId)).averageDollarCount;
        return objectToUse;
    });
    const filename = `total_dollars_retailerId_${retailerId}.csv`;
    csvDownloadWithNulls(output, filename);
}

export function getRetailerDataAsCsv(retailerId, data) {
    let output =[]
    data.forEach(retailerData =>
        {
            let object={};
            object['retailer'] = retailerData.name;
            retailerData.data.map(week => object[week.x] = week.y)
            output.push(object);
        }
    )
    const filename = `retailer_totals_${retailerId}.csv`;
    csvDownloadWithNulls(output, filename);
    return output
}