import {makeApiCall} from '../../Api/makeApiCall';

export const verifyFileTypeConfigs = async (salesMappings, ftp) => {
    if (!salesMappings)
        return undefined;

    const newMappingPromise =
        salesMappings.map(mapping =>
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/verifyFileTypeConfigs/?ftp=${ftp}&config=${mapping.filename}`)
        );

    return await Promise.all(newMappingPromise);
};