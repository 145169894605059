import React, {useEffect, useState} from "react";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import Page from '../Components/Page';
import {LoadingWrapper} from '../Components/LoadingWrapper';
import {useServiceBase} from '../Components/ReactTable/useServiceBase';
import EditableReactTableV2 from '../Components/ReactTable/EditableReactTableV2';
import {makeApiCall} from '../../Api/makeApiCall';
import _ from 'lodash';
import {INDEX_FIELD, NAME_FIELD} from '../Components/ReactTable/common_custom_columns';
import {
    EditFormBooleanDropdownInputField,
    EditFormDateInputField,
    EditFormDropdownInputField,
    EditFormNumberInputField,
    EditFormTextInputField
} from '../Components/ReactTable/EditRecordForm';
import {getStringParamOrDefault} from '../../utils/urlBuilder';
import {Breadcrumbs} from '../Components/Breadcrumbs';
import {universeValues} from "../universeValues";


function OutletsService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/outlets/`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const [spinsWeeks, setSpinsWeeks] = useState(null);
    const [channelSources, setChannelSources] = useState(null);

    const period = getStringParamOrDefault('period', '');

    useEffect(() => {
        makeApolloClientCall(` { periods { spinsWeek } } `)
            .then((result) => {
                setSpinsWeeks(result.periods
                    .map((week) => ({value: week.spinsWeek, label: week.spinsWeek})));
            });
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/channelSource`).then((data) => {
            setChannelSources(_.mapValues(_.groupBy(data.data, 'index'), x => x[0].name));
        })
    }, []);

    const columns = [
        {header: 'Name', accessorKey: 'name', size: 250},
        {header: 'Active', accessorKey: 'active'},
        {
            header: 'Channel Source',
            accessorKey: 'channel_source_id',
            cell: ({getValue}) => channelSources[getValue()] || ''
        },
        {header: 'Last Restatement Week', accessorKey: 'last_restatement_week'},
        {header: 'Store Count Growth Rate', accessorKey: 'store_count_growth_rate'},
        {header: 'Same Store Scv Growth Rate', accessorKey: 'same_store_scv_growth_rate'},
        {header: 'Sales Force Outlet Name', accessorKey: 'sales_force_outlet_name'},
        {header: 'Edp Dataset', accessorKey: 'edp_dataset'},
        {header: 'Sample Markets Start Date', accessorKey: 'sample_markets_start_date'},
        {header: 'Id', accessorKey: 'index'}
    ]

    const channelSourcesOptions = _.map(channelSources, (channelName, channelValue) => ({
        label: channelName,
        value: parseInt(channelValue, 10)
    }));

    function cleanUpAndSubmitData(currentData) {
        Object.keys(currentData).forEach(attribute => {
                if (currentData[attribute] === "")
                    currentData[attribute] = null
            }
        )
        onRowEditConfirmed(currentData)
    }

    return (
        <Page name={'Outlets Service'}
              breadCrumbs={<Breadcrumbs items={[{label: 'Services'}, {
                  label: 'Market',
                  to: `/marketService?selectedMarketPeriod=${period}`
              }, {label: "Outlets"}]}/>}
        >
            <LoadingWrapper loading={!spinsWeeks || !channelSources || loading}>
                <EditableReactTableV2
                    initialState={{
                        sorting: [{id: 'channel_source_id', desc: false}, {id: 'index', desc: false}],
                        pagination: {pageSize: 50}
                    }}
                    filterable
                    data={data.data}
                    columns={columns}
                    onRowEditConfirmed={cleanUpAndSubmitData}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                    editTable={[
                        INDEX_FIELD,
                        NAME_FIELD,
                        {
                            id: 'active',
                            defaultValue: true,
                            Component: args => <EditFormBooleanDropdownInputField {...args} />
                        },
                        {
                            id: 'channel_source_id',
                            Component: args => <EditFormDropdownInputField {...args} options={channelSourcesOptions}
                                                                           isRequired/>
                        },
                        {
                            id: 'last_restatement_week',
                            Component: args => <EditFormDropdownInputField {...args} options={spinsWeeks}/>
                        },
                        {id: 'store_count_growth_rate', Component: args => <EditFormNumberInputField {...args} />},
                        {id: 'same_store_scv_growth_rate', Component: args => <EditFormNumberInputField {...args} />},
                        {id: 'sales_force_outlet_name', Component: args => <EditFormTextInputField {...args} />},
                        {
                            id: 'edp_dataset',
                            Component: args => <EditFormDropdownInputField {...args} options={universeValues}/>
                        },
                        {id: 'sample_markets_start_date', Component: args => <EditFormDateInputField {...args} />}
                    ]}
                />
            </LoadingWrapper>
        </Page>
    );
}

export default OutletsService;