import PropTypes from 'prop-types';
import _ from 'lodash';

const ErrorComponent = ({error}) => {
  return _.get(error, ['response', 'data', 'detail']) || JSON.stringify(error);
};

ErrorComponent.propTypes = {
  error: PropTypes.object
};

export default ErrorComponent;