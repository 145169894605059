import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCallDownloadFile} from "../../Api/makeApiCall";

const DownloadCsvFromBackendServiceButton = ({endpoint, fileName}) => {
    const [downloadRequested, setDownloadRequested] = useState(false);
    const apiUrl = `${endpoint}/downloadCsv`;

    useEffect(() => {
        if (downloadRequested) {
            makeApiCallDownloadFile(apiUrl, fileName)
                .then(() => setDownloadRequested(false))
                .catch(console.error)
        }
    }, [downloadRequested, apiUrl, fileName])

    return <button className="hollow button secondary"
                onClick={() => setDownloadRequested(true)}
                disabled={downloadRequested}
        >
            {'Download CSV'}
        </button>
};

DownloadCsvFromBackendServiceButton.defaultProps = {
    fileName: 'download.csv'
};

DownloadCsvFromBackendServiceButton.propTypes = {
    endpoint: PropTypes.string,
    fileName: PropTypes.string
};

export default DownloadCsvFromBackendServiceButton;