import PropTypes from "prop-types";
import React from "react";
import FtpConfigurationConfigPage from "../../Configurations/FtpConfigurations/FtpConfigurationsConfigPage";
import {ManifestTable} from "../ManifestTable";

export const FtpTab = ({retailerId, ftps}) => {
    const manifestTables = ftps.map(ftp => {
        return <ManifestTable key={ftp} ftp={ftp}/>
    })
    return (
        <>
            <FtpConfigurationConfigPage retailerId={retailerId} displayHelmetHeader={false}/>
            {manifestTables}
        </>
    )
};

FtpTab.propTypes = {
    ftps: PropTypes.arrayOf(PropTypes.string).isRequired,
    retailerId: PropTypes.number.isRequired,
}