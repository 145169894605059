import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {
    EditFormDateInputField,
    EditFormDropdownInputField,
    EditFormNumberInputField,
    EditFormTextInputField
} from "../Components/ReactTable/EditRecordForm";
import _ from "lodash";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {
    COLUMNS_TO_CHECK_FOR_UNIQUE,
    isValidMatchingEndDate,
    isValidSpinsWeek,
    setAllOtherRowsToNotCurrent
} from "./TimeServiceValidate";
import iconCheck from "../../assets/icon-check.svg";
import iconX from "../../assets/icon-x.svg";


function TimeService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/timeService/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const activeDropdownOptions = [
        {label: "No", value: 0},
        {label: "Yes", value: 1}
    ]

    function getCellForService(value) {
        const displayValue = _.find(activeDropdownOptions, {value: value})
        const icon = value === 0 ? iconX : iconCheck;
        return <div><img className={"time-service-active-icon"} alt={"active"} src={icon}/>{displayValue?.label || value}
        </div>
    }

    function getActiveFilterValue(row, columnIds, filterValue) {
        const recordValue = row.original[columnIds];
        const label = _.find(activeDropdownOptions, {value: recordValue})?.label
        return !filterValue || Boolean(`${recordValue} ${label?.toLowerCase()}`.includes(filterValue.toLowerCase()));
    }

    function getDefaultFilterValue(row,  columnIds, filterValue){
        const recordValue = row.original[columnIds];
        return !filterValue || recordValue.toString().includes(filterValue)
    }

    const customColumns = [
        {
            header: "",
            id: "timeServiceBaseInformationSection",
            columns: [
                {header: "End Date", accessorKey: "endDate"},
                {
                    header: "Period Week",
                    accessorKey: "periodWeek",
                    filterFn: (row, columnIds, filterValue) => getDefaultFilterValue(row, columnIds, filterValue)
                },
                {header: "Period Abbreviation", accessorKey: "periodAbbreviation", size: 180},
                {
                    header: "Period Quad",
                    accessorKey: "periodQuad",
                    filterFn: (row, columnIds, filterValue) => getDefaultFilterValue(row, columnIds, filterValue)
                },
                {header: "Spins Week", accessorKey: "spinsWeek"},
                {
                    header: "Legacy Period",
                    accessorKey: "legacyPeriod",
                    filterFn: (row, columnIds, filterValue) => getDefaultFilterValue(row, columnIds, filterValue)
                },
                {
                    header: "Insights Start",
                    accessorKey: "insightsStart",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                },
            ]
        },
        {
            header: "CURRENT PERIOD",
            id: "timeServiceCurrentPeriodSection",
            columns: [
                {
                    header: "Week",
                    accessorKey: "isCurrentPeriodWeek",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                },
                {
                    header: "Quad",
                    accessorKey: "isCurrentPeriodQuad",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                },
            ]
        },
        {
            header: "RELEASE",
            id: "timeServiceReleaseSection",
            columns: [
                {
                    header: "Store Insights Weekly",
                    accessorKey: "isCurrentStoreInsightsWeek",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                    size: 200
                },
                {
                    header: "Store Insights Period",
                    accessorKey: "isCurrentStoreInsightsPeriod",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                    size: 200
                },
                {
                    header: "Market Insights",
                    accessorKey: "isCurrentMarketInsightsPeriod",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                    size: 200
                }
            ]
        },
        {
            header: "RESTATEMENT PERIOD",
            id: "timeServiceRestatementPeriodSection",
            columns: [
                {
                    header: "SN",
                    accessorKey: "isSnRestatementPeriod",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                },
                {
                    header: "SG",
                    accessorKey: "isSgRestatementPeriod",
                    cell: ({getValue}) => getCellForService(getValue()),
                    filterFn: (row, columnIds, filterValue) => getActiveFilterValue(row, columnIds, filterValue),
                }
            ]
        },
    ];

    function validatePreSave({form, previousData}) {
        const values = form.getValues();

        const errors = [
            {
                field: 'periodWeek',
                valid: isValidMatchingEndDate(values.periodWeek, values.endDate, '01'),
                message: 'Period Week must match end date and end in 01'
            },
            {
                field: 'periodQuad',
                valid: isValidMatchingEndDate(values.periodQuad, values.endDate, '04'),
                message: 'Period Quad must end in 04'
            },
            {
                field: 'spinsWeek',
                valid: isValidSpinsWeek(values.spinsWeek),
                message: 'Spins Week is not in the correct format (yyyyMM##)'
            }
        ].filter(e => !e.valid);

        COLUMNS_TO_CHECK_FOR_UNIQUE.forEach(column => {
            const dataBeforeUpdate = previousData.filter(x => x.index === values.index);
            if (dataBeforeUpdate.length > 0 && dataBeforeUpdate[0][column] === 1 && values[column] === 0) {
                errors.push({
                    field: column,
                    message: `At least one row must have a value of active for field ${column}`
                })
            }
        })

        errors.forEach(e => {
            form.setError(e.field, {type: 'custom', message: [e.message]});
        })

        return errors.length === 0;
    }

    async function getOnRowEditConfirmedWrapper(newData, rows) {
        await setAllOtherRowsToNotCurrent(endpointUrl, newData, rows)
        await onRowEditConfirmed(newData)
    }

    function CurrentPeriodQuadField({id, form, label}) {
        const indexValue = form.getValues('index');
        const periodQuadValue = form.getValues('periodQuad');
        const endDateValue = form.getValues('endDate');
        const filteredRows = data.data.filter(x => x.periodQuad === periodQuadValue);
        const maxEndDate = _.maxBy(filteredRows, "endDate");
        const disabled = !(maxEndDate?.endDate === endDateValue) || indexValue === null
        return (
            <EditFormDropdownInputField
                id={id}
                form={form}
                label={label}
                options={activeDropdownOptions}
                disabled={disabled}
            />
        );
    }
    function ActiveDropdownField({id, form, label}) {
        const indexValue = form.getValues('index');
        const disabled = indexValue === null;
        return (
            <EditFormDropdownInputField
                id={id}
                form={form}
                label={label}
                options={activeDropdownOptions}
                disabled={disabled}
            />
        );
    }

    /*@formatter:off*/
    return (<Page name={"Time Service"} breadCrumbs={<Breadcrumbs items={[{label: "Services"}, {label: "Time"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                initialState={{pagination: {pageSize: 10}, sorting: [{id: "endDate", desc: true}]}}
                columns={customColumns}
                getTrProps={(rowInfo, table) => {
                    const filteredRows = table.getCoreRowModel().rows.map(row => row.original).filter(x => x.periodQuad === rowInfo.original.periodQuad);
                    const maxEndDate = _.maxBy(filteredRows, "endDate");

                    let className = ''
                    if (maxEndDate.endDate === rowInfo.original.endDate) {
                        className = 'timeService-last-period'
                    }
                    return {className}
                }}
                getTdProps={(rowInfo, table, cellId) => {
                    let className = "";
                    if((cellId === 'periodWeek' && !isValidMatchingEndDate(rowInfo.original.periodWeek, rowInfo.original.endDate, '01')) ||
                       (cellId === 'periodQuad' && !isValidMatchingEndDate(rowInfo.original.periodQuad, rowInfo.original.endDate, '04'))){
                        className = "timeService-invalid-week"
                    }
                    return className
                }}
                editTable={[
                    INDEX_FIELD,
                    {id: 'endDate', Component: args => <EditFormDateInputField {...args} isRequired label={"End Date"}/>},
                    {id: 'periodWeek', Component: args => <EditFormNumberInputField {...args} isRequired label={"Period Week"}/>},
                    {id: 'periodAbbreviation', Component: args => <EditFormTextInputField {...args} isRequired label={"Period Abbreviation"}/>},
                    {id: 'periodQuad', Component: args => <EditFormNumberInputField {...args} isRequired label={"Period Quad"}/>},
                    {id: 'spinsWeek', Component: args => <EditFormTextInputField {...args} isRequired label={"Spins Week"}/>},
                    {id: 'legacyPeriod', Component: args => <EditFormNumberInputField {...args} isRequired label={"Legacy Period"}/>},
                    {id: 'insightsStart', defaultValue: 0, Component: args => <ActiveDropdownField {...args} label={"Insights Start"}/>},
                    {id: 'isCurrentPeriodWeek', defaultValue: 0, Component: args => <ActiveDropdownField {...args} label={"Week"}/>},
                    {id: 'isCurrentPeriodQuad', defaultValue: 0, Component: args => <CurrentPeriodQuadField {...args} label={"Quad"}/>},
                    {id: 'isCurrentStoreInsightsWeek', defaultValue: 0, Component: args => <ActiveDropdownField {...args} label={"Store Insights Weekly"}/>},
                    {id: 'isCurrentStoreInsightsPeriod', defaultValue: 0, Component: args => <ActiveDropdownField {...args} label={"Store Insights Period"}/>},
                    {id: 'isCurrentMarketInsightsPeriod', defaultValue: 0, Component: args => <ActiveDropdownField {...args}  label={"Market Insights"}/>},
                    {id: 'isSnRestatementPeriod', defaultValue: 0, Component: args => <CurrentPeriodQuadField {...args} label={"SN"}/>},
                    {id: 'isSgRestatementPeriod', defaultValue: 0, Component: args => <CurrentPeriodQuadField {...args} label={"SG"}/>},
                ]}
                validatePreSave={validatePreSave}
                onRowEditConfirmed={getOnRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
                editModalAdditionalClass={"time-service-edit-modal"}
            />
        </LoadingWrapper>
    </Page>);
    /*@formatter:on*/
}

export default TimeService;
