import 'react-toastify/dist/ReactToastify.css';

export const toastSuccess = (toast, successMessage) => {
    toast.success(successMessage, {
        className: 'toast-message',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const toastError = (toast, errorMessage) => {
    toast.error(errorMessage, {
        className: 'toast-message',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const toastInfo = (toast, successMessage) => {
    toast.info(successMessage, {
        className: 'toast-message',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}