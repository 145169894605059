import React from 'react';
import ApiGetContainer from "../../Api/ApiGetContainer";
import Page from "../Components/Page";
import {encodeURI} from "@google-cloud/storage/build/src/util";
import JsonPayloadModal from "../Components/JsonPayloadModal";
import {invokeDAGviaApiPost} from "../../Api/invokeDag";
import {toastError, toastSuccess} from "../../utils/toast";
import {toast} from "react-toastify";

function removeGsPrefix(row) {
    return row.original.run_id_gcs_location.replace("gs://", "");
}

function onSubmitButtonClicked(row) {
    const data = {
        checkpoint_path: row.original.run_id_gcs_location,
        datasets: row.original.datasets,
        run_identifier: row.original.run_identifier
    }
    const dagId = 'restore_checkpoint_tables';
    invokeDAGviaApiPost(dagId, data)
        .then((response) => {
            if (response?.apiError)
                toastError(toast, `An error occurred trying to trigger dag ${dagId} - ${JSON.stringify(response.apiError)}`)
            else
                toastSuccess(toast, `Successfully triggered dag ${dagId} to restore tables with run identifier "${row.original.run_identifier}"`)
        })
        .catch(e => {
            toastError(toast, `An error occurred trying to trigger dag ${dagId} - ${JSON.stringify(e)}`)
        })
}

export const checkpointColumns = [
    {
        header: 'Run ID',
        accessorKey: 'run_identifier',
        size: 300,
        cell: ({cell, value}) => {
            return <a target='_blank'
                      rel="noopener noreferrer"
                      href={`https://console.cloud.google.com/storage/browser/${encodeURI(removeGsPrefix(cell.row), false)}`}>
                {cell.getValue()}
            </a>;
        }
    },
    {
        header: 'Process',
        accessorKey: 'process',
        size: 200
    },
    {
        header: 'Datasets',
        accessorKey: 'datasets',
        size: 300,
        cell: ({cell}) => list_as_string(cell.getValue())
    },
    {
        header: 'Retailer Ids Specified',
        accessorKey: 'retailer_ids',
        size: 300,
        cell: ({cell}) => {
            const value = cell.getValue();
            return (value == null || (value && value.length <= 5)) ? list_as_string(value) :
                <JsonPayloadModal value={{retailerIds: value}} buttonText={"Show All"}/>;
        }
    },
    {
        header: 'Days Until Deletion',
        accessorKey: 'days_until_deletion',
        size: 100,
        enableColumnFilter: false,
        enableSorting: true
    },
    {
        header: 'Restore Checkpoint Table',
        cell: ({row}) => {
            if (row.original.datasets) {
                return (
                    <div className={'center-button'}>
                        <button type="button"
                                className={'button tertiary'}
                                onClick={() => onSubmitButtonClicked(row)}
                        >restore
                        </button>
                    </div>
                );
            } else return ''
        },
        maxSize: 200,
        enableColumnFilter: false
    }
]

const list_as_string = (value) => (value ? value.join(',') : null)

const CheckpointsPage = () => {
    return <Page name={'Checkpoints'}>
        <ApiGetContainer
            endpoint={`${process.env.REACT_APP_EDP_API_BASE_URL}/checkpoints`}
            columns={checkpointColumns}
            filterableTable={true}
        />
    </Page>;
};

CheckpointsPage.defaultProps = {};

CheckpointsPage.propTypes = {};

export default CheckpointsPage;