import React from 'react';
import {routesPropTypes} from '../../routes';
import SelectDropdown from "./Dropdowns/SelectDropdown";
import {getUserTypeFromLocalToken, userType} from "../../auth/accessTokenValidator";


const SearchBar = ({routes}) => {
    const userTypeFromLocalToken = getUserTypeFromLocalToken()
    let options = routes
        .filter(option => {
            return !(option.path.includes("/:") || option.path.includes("/("))
        })
        .filter(x => {
            return userTypeFromLocalToken === userType.adminUser || x.userAccessList.includes(userTypeFromLocalToken);
        })
        .map(option => {
        return {value: option.path, label: option.path};
    });


    return <div className={'search-dropdown-container'}>
        <SelectDropdown
            id={"search_dropdown"}
            onChange={(option) => {
                window.location.assign(option.value);
            }}
            options={options}
            placeholder={'Search...'}
        />
    </div>
};

SearchBar.propTypes = {
    routes: routesPropTypes
};

export default SearchBar;