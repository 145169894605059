import {Button} from "react-foundation";
import React from "react";
import Select from "react-select";
import {getValueFromOptions} from "../../utils/maybeAddLabelToOptions";
import {SELECT_DROPDOWN_CONTAINER_TEST_ID} from "../Components/Dropdowns/SelectDropdown";
import PropTypes from "prop-types";

export function Pagination(props) {
    const options = [10, 25, 50, 100].map(props.dropdownCallBack);
    const customStyles = {
        input: (provided, state) => ({
            ...provided,
            width: 100,
            height: 32,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            height: 32
        }),
        control: (provided, state) => ({
            ...provided,
            height: 32
        }),
    };

    return <div className="-pagination">
        <div className="-buttons">
            <Button id={"firstPageButton"} onClick={props.firstPageOnClick} disabled={!props.canPreviousPage}
                    data-testid={"firstPageButton"}
                    className="-btn -previous react-table-pagination-button">
                {<i className="fas fa-arrow-left react-table-pagination-icon-first"
                    data-testid={"firstPageButtonIcon"}/>}
            </Button>
            <Button id={"previousButton"} onClick={props.previousPageOnClick} disabled={!props.canPreviousPage}
                    data-testid={"previousButton"}
                    className="-btn react-table-pagination-button">
                {<i className="fas fa-arrow-left react-table-pagination-icon" data-testid={"previousButtonIcon"}/>}

            </Button>
            <span
                data-testid={'react-table-pageInfo'}
                className="-pageInfo">Page{" "}<strong>{props.pageIndex + 1} of {props.pageOptions.length}</strong>{" "}</span>

            {props.showPageSizeDropdown && <div data-testid={SELECT_DROPDOWN_CONTAINER_TEST_ID}>
                <Select id={"numberOfRows_dropdown"}
                        className={"pagination-number-of-rows-select-dropdown"}
                        classNamePrefix={`pagination-number-of-rows-select-dropdown`}
                        value={getValueFromOptions(props.value, options)}
                        inputId={'numberOfRows_dropdown'}
                        onChange={props.onChange}
                        options={options}
                        menuPlacement={"top"}
                        styles={customStyles}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                />
            </div>}
            <Button id={"nextButton"} onClick={props.nextPageOnClick} disabled={!props.canNextPage}
                    data-testid={"nextButton"}
                    className="-btn -next react-table-pagination-button">
                {<i className="fas fa-arrow-right react-table-pagination-icon" data-testid={"nextButtonIcon"}/>}
            </Button>
            <Button id={"lastPageButton"} onClick={props.lastPageOnClick} disabled={!props.canNextPage}
                    data-testid={"lastPageButton"}
                    className="-btn react-table-pagination-button">
                {<i className="fas fa-arrow-right react-table-pagination-icon-last"
                    data-testid={"lastPageButtonIcon"}/>}
            </Button>
        </div>
    </div>;
}

Pagination.defaultProps = {
    showPageSizeDropdown: true
}

Pagination.propTypes = {
    dropdownCallBack: PropTypes.func,
    firstPageOnClick: PropTypes.func,
    previousPageOnClick: PropTypes.func,
    nextPageOnClick: PropTypes.func,
    lastPageOnClick: PropTypes.func,
    canPreviousPage: PropTypes.bool,
    canNextPage: PropTypes.bool,
    value: PropTypes.number,
    pageIndex: PropTypes.number,
    pageOptions: PropTypes.array,
    showPageSizeDropdown: PropTypes.bool
}
