import gql from "graphql-tag";

export const currentPeriodQuery = gql`
            query {
                records:periods(isCurrentPeriod:true){
                    weekId
                    endDate
                    quadId
                    periodAbbreviation
                    legacyPeriod
                }
            }`;