import React from 'react';
import Page from "../Components/Page";
import PeriodsDropdown from "../Components/Dropdowns/PeriodsDropdown";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import UnorderedListItems from "../../utils/UnorderedListItems";
import moment from "moment";
import {PeriodReleaseWeek} from "./PeriodReleaseWeek";


class PeriodReleaseChecklist extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedPeriod: undefined,
            weeksInPeriod: []
        }
    }

    componentDidMount() {
        makeApolloClientCall('{ getNextPeriodIfExists {periodAbbreviation} }')
            .then(data => {
                const currentPeriod = data.getNextPeriodIfExists.periodAbbreviation;
                this.setState({selectedPeriod: currentPeriod})
                this.getPeriodWeeks(currentPeriod)
            })
    }

    updatePeriodCallback = (value) => {
        this.setState({selectedPeriod: value});
        this.getPeriodWeeks(value)
    }

    getPeriodWeeks = (periodAbbreviation) => {
        makeApolloClientCall(`{ periodDetails(periodAbbreviation: "${periodAbbreviation}") {weeks{endDate}} }`)
            .then(data => {
                const weeks = data.periodDetails[0].weeks.map(week => week.endDate);
                const periodEndWeek = weeks[0]
                const weeksComponents =weeks.map(endDate => <PeriodReleaseWeek week={endDate}/>)
                const prepWeek = <PeriodReleaseWeek title={'Prep Week: '} week={this.getImportantDates(periodEndWeek, 7)}/>;
                const releaseWeek = <PeriodReleaseWeek title={'Release Week: '} week={this.getImportantDates(periodEndWeek, 14)}/>;
                weeksComponents.unshift(prepWeek)
                weeksComponents.unshift(releaseWeek)
                this.setState({weeksInPeriod: weeksComponents})
            })
    }

    getImportantDates = (endDate, offset) => {
        return moment(endDate).add(offset, 'days').format('YYYY-MM-DD')
    }

    render() {
        return <Page name={'Period Release Checklist'}>
            <PeriodsDropdown id={"periods"}
                             updatePeriod={this.updatePeriodCallback}
                             selectedPeriod={this.state.selectedPeriod}
            />
        <UnorderedListItems listItems={this.state.weeksInPeriod} title={'Weeks In Period:'}/>
        </Page>
    }
}

export default PeriodReleaseChecklist;