import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import RetailerContactsContent from "./RetailerContactsContent";

const RetailerContacts = ({accountId, retailerId}) => {
    const [isOpen, updateIsOpen] = useState(false);

    return (
        <div>
            <button className="hollow button secondary modal-button contacts" type="button" name="close"
                    onClick={() => updateIsOpen(true)}>
                Contacts
            </button>

            <Modal
                isOpen={isOpen}
                onRequestClose={() => updateIsOpen(!isOpen)}
                ariaHideApp={false}
                className="Modal"
            >
                <RetailerContactsContent retailerId={retailerId}/>
                {accountId && <div>
                    <a id={'viewInSalesforce'} target={'__blank'}
                       href={`https://spins.lightning.force.com/lightning/r/Contact/${accountId}/related/Contacts/view`}>
                        View all contacts in Salesforce
                    </a>
                </div>}
                <div>
                    <button className="hollow button secondary modal-button close" type="button" name="close"
                            onClick={() => updateIsOpen(false)}>
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
};

RetailerContacts.propTypes = {
    accountId: PropTypes.string,
    retailerId: PropTypes.number
};

export default RetailerContacts;