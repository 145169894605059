import FtpConfigs from '../../FtpConfigs';
import React from 'react';
import PropTypes from 'prop-types';
import {CONFIG_TYPES} from '../../FileTypes';
import ReactTablePopoverMenu from "../../../Components/ReactTable/ReactTablePopoverMenu";

const FtpConfigButtons = (props) => {
    return (
        <ReactTablePopoverMenu id={`mapping-po-${props.ftp}`} title={'Mapping'}>
            { Object.values(CONFIG_TYPES).map(function (value) {
                return <FtpConfigs
                    key={value.getName()}
                    className={value.getName()}
                    ftp={props.ftp}
                    buttonTitle={value.getFileTypeName()}
                    configType={value}/>
            })
        }
        </ReactTablePopoverMenu>
    );
};

FtpConfigButtons.propTypes = {
    ftp: PropTypes.string
};
export default FtpConfigButtons;
