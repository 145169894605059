import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {SELECT_COLUMN} from "../../Components/ReactTable/CheckboxColumnComponent";
import ReactTable from "../../Components/ReactTable";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import IconImage, {Icons} from "../../Components/IconImage";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {toastError} from "../../../utils/toast";
import {toast} from "react-toastify";
import {useRedirectMessage} from "../../../utils/redirectMessage";

const CellsToMarkets = () => {
    const params = useParams()
    const cellId = parseInt(params.cellId)
    useRedirectMessage();

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToMarkets?cell_id=${cellId}`;

    const [cellsToMarkets, setCellsToMarkets] = useState([])
    const [loading, setLoading] = useState(false)
    const [unassignMarketModalVisible, setUnassignMarketModalVisible] = useState(false)


    const loadCellsToMarkets = () => {
        setLoading(true);
        makeApiCall(endpointUrl)
            .then(data => {
                const results = data.data.filter(cell => cell.cell_id === cellId)
                setCellsToMarkets(results)
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadCellsToMarkets()
    }, []);


    const saveUnassignMarketsAndRefresh = (selectedRows) => {
        setLoading(true)
        setUnassignMarketModalVisible(false)
        return makeApiCallPost(
            `${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToMarkets/unassignMarkets`,
            {
                cellId: cellId,
                marketIds: selectedRows.map(row => row.original.market_id),
            }
        ).then((response) => {
            if (response?.apiError) {
                console.error(`error =============> ${JSON.stringify(response.apiError)}`);
                toastError(toast, `Issue unassigning markets`)
                setLoading(false);
            }
        }).then(() => loadCellsToMarkets())
    }

    return (<Page beta name={"Cell To Markets"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Services"},
                      {label: "Cells", to: "/cellsService"},
                      {label: `${cellId}`}]}
                  />}>
            <LoadingWrapper loading={loading}>

                <ReactTable
                    filterable
                    data={cellsToMarkets}
                    columns={[
                        SELECT_COLUMN,
                        {header: "Cell Id", accessorKey: "cell_id"},
                        {header: "Cell Description", accessorKey: "cell_description"},
                        {header: "Market Id", accessorKey: "market_id"},
                        {header: "Market Description", accessorKey: "market_description"},
                    ]
                    }
                    initialState={{pagination: {pageSize: 25}}}
                >
                    {
                        ({table}) => {
                            const selectedRows = table.getSelectedRowModel().rows;
                            return (
                                <span className={'designation-action-buttons-container'}
                                      data-testid={"stores-action-button-container"}>
                                 <a type={"button"} className={"button primary"}
                                    href={`/cellsToMarkets/assignMarket/${cellId}`}>Assign Market</a>
                                 <div className="designation-header-component-divider"/>
                                 <button type={'button'} aria-label={'un-assign'}
                                         onClick={() => setUnassignMarketModalVisible(true)}
                                         className={"designation-action-button"}
                                         disabled={!selectedRows?.length}>
                                     <IconImage iconType={Icons.UN_ASSIGN}/>
                                 </button>
                                    {unassignMarketModalVisible && <ConfirmationModal
                                        confirmButtonClass={"danger-button"}
                                        confirmButtonText={"Unassign"}
                                        headerText={`Unassign ${selectedRows.length === 1 ? 'Market' : 'Markets'}`}
                                        cancellationCallback={() => setUnassignMarketModalVisible(false)}
                                        confirmationCallback={() => saveUnassignMarketsAndRefresh(selectedRows)}
                                    >
                                        {`Unassign ${selectedRows.length} ${selectedRows.length === 1 ? 'market' : 'markets'} from`}&nbsp;
                                        <b>{`${cellId}`}</b>
                                    </ConfirmationModal>}
                            </span>
                            )
                        }
                    }
                </ReactTable>
            </LoadingWrapper>
        </Page>
    );
}

export default CellsToMarkets