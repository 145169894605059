import React, {Fragment, useState} from 'react';
import {makeApiCall} from '../../Api/makeApiCall';
import PropTypes from 'prop-types';
import sound from '../../assets/flush.mp3'
import ConfirmationModal from "../../Dashboard/Components/ConfirmationModal";
import {toast} from "react-toastify";
import _ from 'lodash';
import {toastError, toastSuccess} from "../toast";

export const ButtonLoader = (props) => {

    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(!props.userAcknowledgedCheck);

    const getOnClick = async () => {
        if (props.buttonHasModal) {
            setLoading(true);
            await props.buttonOnClickAction()
            setLoading(false);
            return setModalIsOpen(true)
        }
        return fetchData()

    }

    const closeModal = () => {
        setIsChecked(!props.userAcknowledgedCheck)
        setModalIsOpen(false)
        props.onClose()
    }

    const fetchData = async () => {
        setLoading(true);
        setModalIsOpen(false)
        if (props.playSound) {
            let snd = new Audio(sound);
            await snd.play();
        }
        const result = await props.axiosFunction(props.url, props.postBody);
        props.onSubmitCallback(result);
        if (props.toastTextFailure || props.toastTextSuccess) {
            if (result?.apiError) {
                const errorMessage = props.includeApiError ?
                    props.toastTextFailure + " " + JSON.stringify(result.apiError.data.detail) :
                    props.toastTextFailure;
                toastError(toast, errorMessage);
            } else
                toastSuccess(toast, props.toastTextSuccess)
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {modalIsOpen &&
            <ConfirmationModal confirmationCallback={props.clickAction || fetchData}
                               cancellationCallback={closeModal}
                               headerText={props.modalHeader}
                               submitButtonDisabled={!isChecked}
            >
                <div className={props.confirmationsModalClass}>
                    {props.modalChildren}
                    <div className={'confirmation-modal-body'}>{props.modalText}</div>

                    {props.userAcknowledgedCheck && <>
                        <input className={'user-confirmation-checkbox'}
                               data-testid={'user-confirmation-checkbox'}
                               type="checkbox"
                               checked={isChecked}
                               onChange={() => setIsChecked(!isChecked)}
                        />
                        <label className={'checkbox-label'}>{"I understand this can not be undone"}</label>
                    </>
                    }
                </div>
            </ConfirmationModal>
            }
            <button className={props.buttonClassName} onClick={getOnClick}
                    title={props.title}
                    disabled={props.disabled || loading} {...props.buttonProps}>
                {loading && (<><i className="fa fa-spinner fa-spin" data-testid={"spinner"}/><span>Loading</span></>)}
                {!loading && props.buttonTitle}
            </button>
        </Fragment>
    );
}

ButtonLoader.defaultProps = {
    axiosFunction: makeApiCall,
    buttonClassName: 'button hollow secondary',
    buttonHasModal: false,
    buttonProps: {},
    confirmationsModalClass: 'confirmation-modal',
    includeApiError: false,
    postBody: undefined,
    toastTextFailure: undefined,
    toastTextSuccess: undefined,
    userAcknowledgedCheck: false,
    onSubmitCallback: _.identity,
    buttonOnClickAction: _.identity,
    onClose: _.identity,
};

ButtonLoader.propTypes = {
    axiosFunction: PropTypes.func,
    buttonOnClickAction: PropTypes.func,
    buttonClassName: PropTypes.string,
    buttonHasModal: PropTypes.bool,
    buttonProps: PropTypes.object,
    buttonTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    clickAction: PropTypes.func,
    confirmationsModalClass: PropTypes.string,
    includeApiError: PropTypes.bool,
    modalHeader: PropTypes.string,
    modalText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    postBody: PropTypes.object,
    playSound: PropTypes.bool,
    title: PropTypes.string,
    toastTextSuccess: PropTypes.string,
    toastTextFailure: PropTypes.string,
    url: PropTypes.string,
    userAcknowledgedCheck: PropTypes.bool,
    disabled: PropTypes.bool,
    onSubmitCallback: PropTypes.func,
    onClose: PropTypes.func,
    modalChildren: PropTypes.element
};

export default ButtonLoader