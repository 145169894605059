import React from 'react';
import PropTypes from 'prop-types';
import CollapsableContent from "../Components/CollapsableContent";

const ReadinessIssues = ({issues, retailChain, createListItem, collapsedContent}) => {
    if (!issues)
        return null;

    const issueListItems = issues.map((issue, key) => <li key={key}>{createListItem(issue)}</li>);

    return <CollapsableContent
        className={'readiness-issues'}
        collapsedContent={collapsedContent}
        expandedContent={<ul style={{listStyleType: "none"}}>{retailChain}{issueListItems}</ul>}
    />
};

ReadinessIssues.defaultProps = {
    retailChain: ''
}

ReadinessIssues.propTypes = {
    issues: PropTypes.array,
    retailChain: PropTypes.string,
    createListItem: PropTypes.func,
    collapsedContent: PropTypes.string
};

export default ReadinessIssues;