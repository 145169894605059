import {encodeURI} from "@google-cloud/storage/build/src/util";

export const convertGsutilPathToBrowserGcsUrl = gcsFileLocation => {
    let fileNameQueryString, locationWithoutFileName;
    const prefix = 'https://console.cloud.google.com/storage/browser/';
    const filenameIndex = gcsFileLocation && gcsFileLocation.lastIndexOf('/');
    if(gcsFileLocation && gcsFileLocation.includes('.')) {
        fileNameQueryString = `?prefix=${gcsFileLocation.substr(filenameIndex + 1, gcsFileLocation.length)}`
        locationWithoutFileName = gcsFileLocation.substr(0, filenameIndex)
    } else {
        fileNameQueryString = ""
        locationWithoutFileName = gcsFileLocation
    }

    return gcsFileLocation &&
        (prefix
            + encodeURI(locationWithoutFileName.replace('gs://', ''), false)
            + fileNameQueryString
        );
};