import gql from "graphql-tag";

export const compositionReportNoDepartmentQuery = gql`
    query compositionReportNoDepartment($retailerId:Int!, $salesEndDate: String){
        records:compositionReportNoDepartment(retailerId: $retailerId, salesEndDate: $salesEndDate){
            retailerId
            spinsItemCode
            salesAmountTotal
            salesUnitTotal
            itemDescription
        }
    }`;


export const compositionReportCodeAbleItemsQuery = gql`
    query compositionReportCodeAbleItems($retailerId:Int!, $salesEndDate: String){
        records:compositionReportCodeAbleItems(retailerId: $retailerId, salesEndDate: $salesEndDate){
            retailerId
            spinsItemCode
            salesAmountTotal
            salesUnitTotal
            itemDescription
        }
    }`;