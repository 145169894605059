import React from "react";
import Page from "../../Components/Page";
import PropTypes from "prop-types";
import {AuditFtpMetadataItem} from "../AuditFtpMetadataItem";
import {AuditStoreSalesEndDateConfig} from "../AuditStoreSalesEndDateConfig";
import {AuditHistory} from "../AuditHistory";

export const AuditTab = ({retailerId, ftps}) => {
    return (
        <Page displayHelmetHeader={false} name={'Audit'}>
            <h3>FTP Mappings</h3>
            {ftps.map(ftp => <AuditFtpMetadataItem key={ftp} ftp={ftp}/>)}
            <h3>Store Totals Configurations</h3>
            <AuditStoreSalesEndDateConfig retailerId={retailerId} configName={'Measures Start'} configEndpoint={'storeStart'}/>
            <AuditStoreSalesEndDateConfig retailerId={retailerId} configName={'Measures Stop'} configEndpoint={'storeStop'}/>
            <AuditStoreSalesEndDateConfig retailerId={retailerId} configName={'Always Fill'} configEndpoint={'alwaysFill'}/>
            <AuditStoreSalesEndDateConfig retailerId={retailerId} configName={'Do Not Fill'} configEndpoint={'doNotFill'}/>
            <AuditHistory
                configName={'Store Closures'}
                configAuditEndpoint={`storeClosures/audit?retailerId=${retailerId}`}
                customColumns={[
                    {header: 'Start Date', id: 2, accessorKey: 'startDate'},
                    {header: 'End Date', id: 3, accessorKey: 'endDate'},
                    {header: 'Store Id', id: 4, accessorKey: 'storeId'}
                ]}
            />
            <AuditHistory
                configName={'Data Clean Up'}
                configAuditEndpoint={`deleteRetailerJsonByDate/audit?retailerId=${retailerId}`}
                customColumns={[
                    {header: 'Sales End Date', id: 3, accessorKey: 'date_string'}
                ]}
                action={"deleted"}/>
            <AuditHistory
                configName={'File Rename'}
                configAuditEndpoint={`fileRenameAudit/audit?retailerId=${retailerId}`}
                customColumns={[
                    {header: 'Original File Name', accessorKey: 'originalFileDisplayName'},
                    {header: 'New File Name', accessorKey: 'newFileDisplayName'}
                ]}
            />
            <AuditHistory
                configName={'Blocked Item Codes'}
                configAuditEndpoint={`blockedItemCodes/audit/${retailerId}`}
                customColumns={[
                    {header: 'Spins Store Id', accessorKey: 'spinsStoreId'},
                    {header: 'Spins Item Code', accessorKey: 'spinsItemCode'},
                    {header: 'Sales End Date', accessorKey: 'salesEndDate'}
                ]}
            />

        </Page>
    )
};

AuditTab.propTypes = {
    retailerId: PropTypes.number,
    ftps: PropTypes.arrayOf(PropTypes.string)
};