import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import ReactTable from "../Components/ReactTable";
import RunIdLink from "../IngestionLogs/Columns/RunIdLink";
import JsonPayloadModal from "../Components/JsonPayloadModal";
import {addLabelToOptionsIfNotExist} from "../../utils/maybeAddLabelToOptions";
import Select from 'react-select'

const MeasuresExtractsDagLogs = ({
                                     airflowUrl,
                                     data,
                                     tableTitle,
                                     pageSize,
                                     outlet,
                                     marketPeriodList,
                                     runIdentifierMarketPeriods,
                                     onMarketPeriodChange
                                 }) => {
    const dataLoading = !(airflowUrl && data)
    return (
        <Fragment>
            <h2>{tableTitle}</h2>
            <ReactTable
                filterable
                data={data || []}
                columns={[
                    {
                        header: 'Run Id',
                        accessorKey: 'runid',
                        filterable: false,
                        className: 'non-numeric-field',
                        cell: ({row, getValue}) => <RunIdLink
                            airflowUrl={airflowUrl}
                            {...row}
                            value={getValue()}
                            getDisplayText={(row) => row.conf.run_identifier}/>,
                    },
                    {
                        header: 'Run Configuration',
                        accessorKey: 'conf',
                        enableColumnFilter: false,
                        cell: ({getValue}) => <JsonPayloadModal value={getValue()}/>,
                    },
                    {
                        header: 'Run Period',
                        filterable: true,
                        accessorKey: 'conf.markets_period'
                    },
                    {
                        header: 'Period Release',
                        filterable: false,
                        className: 'non-numeric-field table-dropdown',
                        cell: (props) => <div className={'period-release-dropdown-container'}>
                            <Select
                                menuPosition={"fixed"}
                                menuPlacement={"bottom"}
                                menuShouldBlockScroll={true}
                                maxMenuHeight={200}
                                className={'period-release-dropdown'}
                                classNamePrefix={'period-release-dropdown'}
                                value={runIdentifierMarketPeriods && getPeriodForRunIdentifier(runIdentifierMarketPeriods, props.row.original.conf.run_identifier)}
                                options={addLabelToOptionsIfNotExist(marketPeriodList)}
                                onChange={(option) => onMarketPeriodChange({
                                    outlet,
                                    runIdentifier: props.row.original.conf.run_identifier,
                                    runId: props.row.original.runid,
                                    marketPeriod: option.value
                                })}
                            />
                        </div>
                    }
                ]}
                pageSize={pageSize}
                loading={dataLoading}
            />
        </Fragment>);
};

function getPeriodForRunIdentifier(runIdentifierMarketPeriods, originalRunIdentifier) {
    for (const [period, runInfo] of Object.entries(runIdentifierMarketPeriods)) {
        if (runInfo.runIdentifier === originalRunIdentifier) {
            return {value: period, label: period};
        }
    }
    return undefined;
}

MeasuresExtractsDagLogs.propTypes = {
    airflowUrl: PropTypes.string.isRequired,
    data: PropTypes.shape(PropTypes.arrayOf(PropTypes.object)).isRequired,
    tableTitle: PropTypes.string,
    pageSize: PropTypes.number,
    outlet: PropTypes.string,
    marketPeriodList: PropTypes.arrayOf(PropTypes.string).isRequired,
    runIdentifierMarketPeriods: PropTypes.object,
    onMarketPeriodChange: PropTypes.func
};

export default MeasuresExtractsDagLogs;