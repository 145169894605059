import PropTypes from "prop-types";
import React from "react";
import IngestionLogsPage from "../../IngestionLogs/IngestionLogsPage";
import {ProcessingLogsPage} from "../../ProcessingLogs/ProcessingLogsPage";

export const LogsTab = ({retailerId}) => {
    return (
        <>
            <IngestionLogsPage retailerId={retailerId} displayHelmetHeader={false}
                               showAirflowMetrics={false}/>
            <ProcessingLogsPage retailerId={retailerId} displayHelmetHeader={false}/>
        </>
    )
};

LogsTab.propTypes = {
    retailerId: PropTypes.number.isRequired,
}