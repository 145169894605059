import chevronDown from "../../assets/chevron-down.svg";
import React from "react";
import PropTypes from "prop-types";
import { flexRender } from "@tanstack/react-table";
import { getStickyProps } from "../Components/ReactTable/sticky-columns";
import _ from "lodash";

function getChevron(column, sortState) {
    const sortColumn = _.find(sortState, { id: column.id });
    if (sortColumn) {
        if (sortColumn.desc) {
            return <img alt={"chevron"} className={"chevron-down"}
                        src={chevronDown} />;
        } else {
            return <img alt={"chevron"} className={"task-chevron-up"}
                        src={chevronDown} />;
        }
    } else {
        return "";
    }
}

export const ReactTableHeader = ({ headerGroup, filterable, table }) => {
    return <div className="rt-tr" style={{ display: "flex", flex: "1 0 auto", minWidth: "90px" }} role={"row"}>
        {
            headerGroup.headers.map((header, index) => {
                const column = header.column;
                let stickyData = {};
                let style = {
                    position: "relative",
                    width: header.getSize(),
                    flex: `1 0 auto`,
                    boxSizing: "border-box",
                    ...(column.columnDef.headerStyle || {})
                };
                if (column.columnDef.sticky) {
                    const stickyProps = getStickyProps(column, table);
                    const stickyStyle = stickyProps.style;
                    stickyData = stickyProps.data;
                    style = {
                        ...style,
                        ...stickyStyle
                    };
                }
                const columnHeader = column.columnDef.header;
                return (
                    <div
                        key={index}
                        className={`rt-th rt-resizable-header table-cell-${column.id}`}
                        {...stickyData}
                        data-testid={`${column.id}-filter-input-wrapper`}
                        style={style}>

                        <div
                            data-testid={`${column.id}-table-sort-toggle`}
                            style={column.getCanSort() ? { cursor: "pointer" } : {}}
                            onClick={column.getToggleSortingHandler()}
                            className="rt-resizable-header-content">
                            {flexRender(
                                columnHeader,
                                header.getContext()
                            )}
                            <span id={"header-chevron"}>{getChevron(column, table.getState().sorting)}</span>
                        </div>
                        {<div className={"rt-filterable-content"}>{filterable && column.getCanFilter() ? flexRender(
                            column.columnDef.filter,
                            header.getContext()
                        ) : null}</div>}
                        <div
                            role={"separator"}
                            style={{ cursor: "col-resize" }}
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className={`rt-resizer ${
                                column.getIsResizing() ? "isResizing" : ""
                            }`}
                        ></div>
                    </div>

                );
            })}
    </div>;
};

ReactTableHeader.propTypes = {
    filterable: PropTypes.bool,
    headerGroup: PropTypes.object
};