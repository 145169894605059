import React, {Fragment} from 'react';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getOptionalAuthorizationRedirect, writeUsageLogCsv} from '../../auth/accessTokenValidator';
import {Header} from './Navigation/Header';
import {getWindowLocation, getWindowStorage} from '../../utils/util';
import {routesPropTypes} from '../../routes';


export function RouteWithAuthorization(props) {
    const optionalRedirect = getOptionalAuthorizationRedirect(
        getWindowStorage().getItem('access_token'),
        getWindowLocation(),
        props.path
    )
    writeUsageLogCsv(props.path)

    return (
        <Fragment>
            <Header routes={props.routes}/>
            {optionalRedirect ? <Navigate to={`/${optionalRedirect}`}/> : <props.component/>}
        </Fragment>
    )
}

RouteWithAuthorization.propTypes = {
    component: PropTypes.func.isRequired,
    routes: routesPropTypes,
    path: PropTypes.string
}