import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ToggleRadioButtons = ({options, selectedOption, selectedCallback}) => {
    return <div className={'toggle-radio-button-container'}>
        {options.map(option => {
            const id = _.kebabCase(`toggle-radio-button-${option}`);
            return <Fragment key={id}>
                <input className={'toggle-radio-button'}
                       data-testid={id}
                       type="radio"
                       id={id}
                       checked={option === selectedOption}
                       onChange={() => selectedCallback(option)}
                />
                <label
                    className={`toggle-radio-button-label ${selectedOption === option ? 'toggle-radio-button-selected' : ''}`}
                    htmlFor={id}>{option}</label>
            </Fragment>
        })}
    </div>;
};

ToggleRadioButtons.defaultProps = {};

ToggleRadioButtons.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedCallback: PropTypes.func.isRequired
};

export default ToggleRadioButtons;