import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormDateInputField} from "../Components/ReactTable/EditRecordForm";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";

function PromoWatermarkService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/promo_watermark/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "Date Promo", accessorKey: "datePromo"}
    ];
    return (<Page name={"Promo Watermarks"}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data}
                columns={columns}
                actionButtonsProps={{showAddButton: false, showDeleteButton: false}}
                editTable={[
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'datePromo', Component: args => <EditFormDateInputField {...args} isRequired/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default PromoWatermarkService;
