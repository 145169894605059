import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {EditFormDateInputField, EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";

function FillWatermarkService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/fill_watermark/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "Date Filled", accessorKey: "dateFilled"}
    ];
    return (<Page name={"Fill Watermarks"}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data}
                columns={columns}
                actionButtonsProps={{showAddButton: false, showDeleteButton: false}}
                editTable={[
                    {id: 'retailerId', Component: args => <EditFormTextInputField {...args} isRequired label={"Retailer Id"}/>},
                    {id: 'dateFilled', Component: args => <EditFormDateInputField {...args} isRequired/>},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

export default FillWatermarkService;
