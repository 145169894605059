import React, {Fragment, useState} from "react";
import {makeApiCall} from "../../Api/makeApiCall";
import {ConfigType} from "../Configurations/FileTypes";
import PropTypes from 'prop-types';
import _ from "lodash";
import AuditConfigItemDiff from "./AuditConfigItemDiff";

export const AuditFtpConfigUpdateItem = ({fileTypeMappingData, ftp, configType}) => {
    const [configs, updateConfigs] = useState([])


    React.useEffect(() => {
        function getFileMappingNames() {
            let fileNames;
            let fileList;

            fileList = fileTypeMappingData?.[configType.getConfigMappingKey()]
            fileNames = fileList && fileList.map(x => x["filename"].split('.')[0])
            if (!fileList || _.isEmpty(fileList)) {
                fileNames = [ftp]
            }
            return fileNames
        }

        const configFileNames = getFileMappingNames()
        configFileNames
        && Promise.all(
            configFileNames.map((fileName) => {
                    return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/?ftp=${fileName}&configType=${configType.getName()}`)
                        .then(data => {
                            if (data.metadata && data.metadata.updates) {
                                const mostRecentUpdates = _.takeRight(data.metadata.updates, 10);
                                return {updates: _.reverse(mostRecentUpdates), fileName: fileName}
                            }
                        })
                }
            )
        ).then(arrayOfItems => {
            updateConfigs(arrayOfItems);
        })
    }, [fileTypeMappingData, configType, ftp])

    return (
        <div id={`${ftp}_${configType.getName()}`} className={'config-update-item'}>
            <h5>{configType.getFileTypeName()}</h5>
            {_.uniq(configs).length === 1 && configs[0] === undefined ?
                <div className={'no-changes'}>No Changes</div> :
                configs.map((config, index) => {
                    return <Fragment key={index}>
                        <span className={'file-name'}>{config?.fileName} </span>
                        {config && config.updates.map((update, index) => {
                                const previousItem = config?.updates?.length >= index + 1 ? config.updates[index + 1] : null
                                return <AuditConfigItemDiff key={update.timestamp}
                                                            update={update}
                                                            previousItem={previousItem}
                                                            fileName={config?.fileName}
                                                            configName={configType.getName()}
                                />
                            }
                        )}
                    </Fragment>
                })
            }
        </div>
    )
}

AuditFtpConfigUpdateItem.propTypes = {
    fileTypeMappingData: PropTypes.object.isRequired,
    ftp: PropTypes.string.isRequired,
    configType: PropTypes.instanceOf(ConfigType).isRequired
}
