import * as PropTypes from 'prop-types';
import React from "react";

function UnmappedStoresCell({data}) {
    const unmappedStoreIssue = data.issues ? data.issues.find(x => x.issueType === 'unmappedStores') : {};
    const hasIssue = unmappedStoreIssue?.count > 0;
    const issueClassName = hasIssue ? 'issue-link-color-red' : ''
    const issueText = hasIssue ? `${unmappedStoreIssue?.count}` : '';

    return <div>
        {hasIssue ? <a target="_blank" rel="noopener noreferrer"
                       href={`/unmappedStores/?retailerId=${data.retailer.retailerId}`}
                       className={`store-totals-link ${issueClassName}`}
                       title={`Unmapped Stores`}>{issueText}</a> :
            ""}
    </div>
}

UnmappedStoresCell.propTypes = {
    data: PropTypes.object
}

export default UnmappedStoresCell;