import React, {useState} from 'react';
import Modal from "react-modal";
import AceEditor from "react-ace";
import {prettyFormatJsonString} from "../../../utils/formatJson";
import PropTypes from "prop-types";

const PeriodReleaseModal = ({row}) => {
    const [isModalOpen, updateIsModalOpen] = useState(false);

    return (
        <>
            <button className={'button tertiary'} onClick={async () => updateIsModalOpen(true)}>
                View Record
            </button>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => updateIsModalOpen(false)}
                ariaHideApp={false}
                className="Modal audit-modal">
                <>
                    <br/>
                    <h2>{`Details: ${row.action} on ${row.timestamp} by ${row.user}`}</h2>
                    <div className={'editor-and-buttons-side-by-side-container'}>
                        <AceEditor
                            mode="json"
                            theme="github"
                            name="json_payload_ace"
                            editorProps={{$blockScrolling: true}}
                            value={prettyFormatJsonString(JSON.stringify(row.record))}
                            readOnly={true}
                        />
                        {row.previousRecord !== null &&
                            <AceEditor
                                mode="json"
                                theme="github"
                                name="json_payload_ace"
                                editorProps={{$blockScrolling: true}}
                                value={prettyFormatJsonString(JSON.stringify(row.previousRecord))}
                                readOnly={true}
                            />
                        }
                    </div>
                </>
                <div className={"right-things"}>
                    <button className="button cancel modal-button" type="button" name="close"
                            onClick={() => updateIsModalOpen(false)}>
                        Close
                    </button>
                </div>
            </Modal>
        </>
    );
};

PeriodReleaseModal.propTypes = {
    row: PropTypes.object.isRequired,
};

export default PeriodReleaseModal;