import {makeApiCallPost} from './makeApiCall';
import {createDagRunConf} from "./dagRunConf";

export const invokeDAGviaApiPost = async (dagName, data) => {
    const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`;
    const callData = {
        dagName,
        data: createDagRunConf(data),
    }
    return makeApiCallPost(url, callData)
}