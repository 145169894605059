import React from 'react'
import PropTypes from 'prop-types'
import MeasuresExtractsDagLogs from "./MeasuresExtractsDagLogs";


const MarketPeriodAssignableMeasuresDagLogs = ({airflowUrl, outlet, logs, marketPeriodList, runIdentifierMarketPeriods, onMarketPeriodChange}) => {
    const pageSize = logs && logs.length >= 5 ? 5 : logs ? logs.length : 1;
    return <MeasuresExtractsDagLogs
        airflowUrl={airflowUrl}
        data={logs}
        tableTitle={outlet}
        pageSize={pageSize}
        outlet={outlet}
        marketPeriodList={marketPeriodList}
        runIdentifierMarketPeriods={runIdentifierMarketPeriods}
        onMarketPeriodChange={onMarketPeriodChange}
    />
}

MarketPeriodAssignableMeasuresDagLogs.defaultProps = {
    onMarketPeriodChange: () => {
    }
}

MarketPeriodAssignableMeasuresDagLogs.propTypes = {
    airflowUrl: PropTypes.string.isRequired,
    outlet: PropTypes.string.isRequired,
    logs: PropTypes.arrayOf(PropTypes.object),
    marketPeriodList: PropTypes.arrayOf(PropTypes.string).isRequired,
    runIdentifierMarketPeriods: PropTypes.object,
    onMarketPeriodChange: PropTypes.func
}

export default MarketPeriodAssignableMeasuresDagLogs