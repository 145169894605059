import {makeApiCall} from "../Api/makeApiCall";
import _ from 'lodash';

const RETAILER_MAP_KEY = 'retailerMap';
export const loadRetailerMap = () => {
    return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/retailers/`)
        .then(result => {
                const nameLookup = result.data.reduce((accumulator, next) => ({
                    ...accumulator,
                    [next.retailerId]: next.retailChain
                }), {});
                const retailerLookup = result.data.reduce((accumulator, next) => ({
                    ...accumulator,
                    [next.retailerId]: next
                }), {});

                window.localStorage.setItem(RETAILER_MAP_KEY, JSON.stringify({
                    'nameLookup': nameLookup,
                    'retailerLookup': retailerLookup
                }))

                getRetailerMapUseGetRetailerLookupInstead.cache.clear();
                getRetailerLookup.cache.clear();
            }
        );
};

export const isRetailerMapLoaded = () => window.localStorage.hasOwnProperty(RETAILER_MAP_KEY);


export const getRetailerMapUseGetRetailerLookupInstead = _.memoize(() => {
    const parse = JSON.parse(window.localStorage.getItem(RETAILER_MAP_KEY));
    return parse.nameLookup;
});

export const getRetailerLookup = _.memoize(() => JSON.parse(window.localStorage.getItem(RETAILER_MAP_KEY)).retailerLookup);

export const getRetailerName = (retailerId) => {
    return getRetailerLookup()[retailerId]?.retailChain;
}
