import _ from "lodash";


export const validUSStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY',
    'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
    'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];


export const parseGeocodedAddress = (result) => {
    const parsedAddress = {
        location: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng()
        }
    }
    const city = {}
    let streetNumber
    let route
    let buildingName
    result.address_components?.forEach((component) => {
        if (component.types.indexOf('subpremise') >= 0) {
            parsedAddress.addr2 = component.long_name
        }
        if (component.types.indexOf('street_number') >= 0) {
            streetNumber = component.long_name
        }
        if (component.types.indexOf('route') >= 0) {
            route = component.short_name
        }
        if (component.types.indexOf('premise') >= 0) {
            buildingName = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_2') >= 0) {
            parsedAddress.county = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_1') >= 0) {
            parsedAddress.state = component.short_name;
        }
        if (component.types.indexOf('postal_code') >= 0) {
            parsedAddress.zip1 = component.long_name;
        }
        if (component.types.indexOf('postal_code_suffix') >= 0) {
            parsedAddress.zip2 = component.long_name;
        }
        if (component.types.indexOf('country') >= 0) {
            parsedAddress.country = component.short_name;
        }
        ['locality', 'postal_town', 'sublocality', 'neighborhood', 'administrative_area_level_3'].forEach((type) => {
            if (component.types.indexOf(type) >= 0) {
                city[type] = component.long_name;
            }
        })
    })
    const parsedAddr1 = [
        streetNumber,
        route,
        buildingName
    ].filter(x => !_.isEmpty(x))?.join(' ')
    if (parsedAddr1.length > 0) parsedAddress.addr1 = parsedAddr1
    const parsedCity = city.locality || city.postal_town || city.sublocality || city.neighborhood || city.administrative_area_level_3;
    if (parsedCity) parsedAddress.city = parsedCity
    if (parsedAddress.country === 'US' && validUSStates.indexOf(parsedAddress.state) < 0) {
        parsedAddress.state = undefined;
    }
    return parsedAddress
}

export const findUsableAddress = (addresses, requiredElements) => addresses?.find((address) => !requiredElements.find((element) => !address[element]))

export const findBestAddress = (addresses) =>
    findUsableAddress(addresses, ['addr1', 'city', 'state', 'zip1', 'zip2', 'country']) ||
    findUsableAddress(addresses, ['addr1', 'city', 'state', 'zip1', 'country']) ||
    findUsableAddress(addresses, ['city', 'state', 'zip1', 'country']) ||
    findUsableAddress(addresses, ['zip1', 'country']) ||
    findUsableAddress(addresses, ['country'])
