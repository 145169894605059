import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import _ from "lodash";
import {getRetailerName} from "../../../utils/retailerMapCache";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import ReactTable from "../../Components/ReactTable";
import {getNicelyFormattedNumber} from "../../../utils/formatUtil";
import checkCircle from "../../../assets/check-circle.svg";
import {redirectWithMessage} from "../../../utils/redirectMessage";
import {SELECT_COLUMN} from "../../Components/ReactTable/CheckboxColumnComponent";
import ReactTableFilterDropdown from "../../ReactTable/ReactTableFilterDropdown";
import RetailerIdAndName from "../../Components/RetailerIdAndName";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";
import Select from "react-select";

const CellsToStoresAssignStore = () => {

    const params = useParams();
    const cellId = parseInt(params.cellId);

    const storesEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/stores/`;
    const cellsToStoresEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToStores`;

    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        setLoading(true)
        Promise.all([
            makeApiCall(storesEndpointUrl),
            makeApiCall(cellsToStoresEndpointUrl)
        ])
            .then(([storesResults, cellsToStores]) => {
                setLoading(false)
                const filteredStores = cellsToStores.data.filter(cell => cell.cell_id === cellId);
                const storeLookup = _.keyBy(filteredStores, 'store_id');
                const mappedStores = storesResults.data
                    .filter(store => !storeLookup[store.storeId])
                    .map((result) => {
                        return {
                            retailerId: result.retailerId,
                            retailerName: getRetailerName(result.retailerId.toString()),
                            storeTag: result.storeTag,
                            store: `${result.storeId} - ${result.storeName}`,
                            closed: result.closed,
                            consumerName: result.consumerName,
                            city: result.city,
                            country: result.country,
                            state: result.state,
                            zip1: result.zip1,
                            storeId: result.storeId
                        }
                    })
                setTableData(mappedStores)
            })
    }, []);

    function submitSelectedStore(selectedRows) {
        const storesToAdd = selectedRows.map(x => x.original.storeId);
        const apiPost = {storeIds: storesToAdd, cellId};

        makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToStores/addStores`, apiPost).then(() => {
            const message = `Successfully assigned ${storesToAdd.length} store(s) to Cell ${cellId}`;
            redirectWithMessage(message, `/cellsToStores/${cellId}`);
        });
    }

    const columns = [
        SELECT_COLUMN,
        {
            header: "Store",
            id: 'store',
            accessorKey: 'store',
            minSize: 300,
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.original.store.toLowerCase()}`.includes(filterValue.toLowerCase()));
            }
        },
        {
            header: "Store Tag",
            id: "storeTag",
            accessorKey: 'storeTag',
            size: 64,
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.original.storeTag}`.includes(filterValue));
            }
        },
        {
            header: "Is Closed?",
            id: "closed",
            accessorKey: 'closed',
            size: 64,
            filter: (theseProps) => {
                return <ReactTableFilterDropdown
                    id={"closed"}
                    options={[{value: "1", label: "1"}, {value: "0", label: "0"}]}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                    isClearable={true}
                />
            }
        },
        {
            header: "Retailer",
            id: "retailer",
            accessorKey: 'retailer',
            minSize: 100,
            cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
            filterFn: (row, columnId, filterValue) => {
                return !filterValue || Boolean(`${row.original.retailerId} - ${row.original.retailerName.toLowerCase()}`.includes(filterValue.toLowerCase()));
            },
            sortDescFirst: false,
            sortingFn: (rowA, rowB) => {
                const rowBValue = rowB.original.retailerId;
                const rowAValue = rowA.original.retailerId;
                return sortAscendingWithNullsLast(rowAValue, rowBValue);
            }
        },
        {
            header: "Consumer Name",
            id: "consumerName",
            accessorKey: 'consumerName'
        },
        {
            header: "City",
            id: "city",
            accessorKey: 'city'
        },
        {
            header: "Country",
            id: "country",
            accessorKey: 'country',
            size: 64,
        },
        {
            header: "State",
            id: "state",
            accessorKey: 'state',
            size: 164,
            filterFn: (row, columnId, filterValue) => {
                const filtersValues = filterValue.map(filter => filter.value)
                return _.isEmpty(filtersValues) || _.includes(filtersValues, row.original.state)
            },
            filter: (theseProps) => {
                const uniqueStates = _.uniqBy(theseProps.table.getCoreRowModel().rows
                    .map(row => row.original.state)
                    .filter(state => state !== '')
                    .sort())
                const statesOptionsSorted = uniqueStates.map(state => {
                    return {label: state, value: state}
                })
                return <div data-testid={'state-multi-select-dropdown-container'}>
                    <Select id={`${theseProps.column.id}-select-dropdown`}
                            className={'multi-select-dropdown'}
                            classNamePrefix={`${theseProps.column.id}-select-dropdown`}
                            options={statesOptionsSorted}
                            value={theseProps.column.getFilterValue()}
                            isClearable={true}
                            placeholder={''}
                            isMulti
                            isSearchable={false}
                            onChange={event => theseProps.column.setFilterValue(event || undefined)}
                    />
                </div>
            }
        },
        {
            header: "Zip Code",
            id: "zip1",
            accessorKey: 'zip1'
        }
    ]

    return <>
        <LoadingWrapper loading={loading}>
            <Page name={`Assign store to cell ${cellId}`}
                  breadCrumbs={<Breadcrumbs items={[
                      {label: "Services"},
                      {label: "Cells", to: "/cellsService"},
                      {label: "Cells to Stores", to: `/cellsToStores/${cellId}`},
                      {label: `${cellId}`}
                  ]}/>}
            >
                <ReactTable
                    filterable={true}
                    data={tableData}
                    columns={columns}
                    initialState={{pagination: {pageSize: 50}}}
                >
                    {
                        ({table}) => {
                            const totalRows = getNicelyFormattedNumber(table.getFilteredRowModel().rows.length);
                            const selectedRows = table.getSelectedRowModel().rows;
                            const isAllRowsSelected = table.getIsAllRowsSelected();
                            let selectAllButtonText = `Select all ${totalRows} stores`;
                            const allRowsSelectedText = `${totalRows} stores selected`
                            return (
                                <div className={'designation-action-buttons-container'}
                                     data-testid={"action-button-container"}>
                                    {isAllRowsSelected ?
                                        <>
                                            <img alt={'status'} className={'success-image'} src={checkCircle}/>
                                            <div data-testid={'all-stores-selected'}>{allRowsSelectedText}</div>
                                        </>
                                        :
                                        <button data-testid={'select-all-stores'} type={"button"}
                                                className={"button tertiary"}
                                                onClick={() => table.toggleAllRowsSelected()}
                                        >{selectAllButtonText}</button>}
                                    <button disabled={selectedRows.length === 0} type={"button"}
                                            className={"button primary"}
                                            onClick={() => submitSelectedStore(selectedRows)}>
                                        Assign store
                                    </button>
                                </div>
                            );
                        }
                    }
                </ReactTable>
            </Page>
        </LoadingWrapper>
    </>
};

export default CellsToStoresAssignStore;