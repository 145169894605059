import React from 'react';
import PropTypes from 'prop-types';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {
    EditFormDateInputField,
    EditFormNumberInputField,
    EditFormTextInputField
} from "../Components/ReactTable/EditRecordForm";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";

const BlockedItemCodes = ({displayHelmetHeader, retailerId}) => {

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/blockedItemCodes/${retailerId}`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    const columns = [
        {header: "Store ID", accessorKey: "spinsStoreId"},
        {header: "Item Code", accessorKey: "spinsItemCode"},
        {header: "Sales End Date", accessorKey: "salesEndDate"}
    ];

    function validatePreSave({form}) {
        const spinsItemCodeValue = form.getValues('spinsItemCode');
        const errors = [
            {regex: new RegExp(/^[0-9]{2}-[0-9]{5}-[0-9]{5}$/), message: 'Please enter a SPINS Formatted item code, ex: ##-#####-#####'}
        ].filter(e => !e.regex.test(spinsItemCodeValue))
            .map(e => e.message)

        if (errors.length > 0) {
            form.setError('spinsItemCode', {type: 'custom', message: errors});
        }

        return errors.length === 0;
    }

    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Blocked Item Codes"} displayHelmetHeader={displayHelmetHeader}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    {id: 'spinsStoreId', Component: args => <EditFormNumberInputField {...args} />},
                    {id: 'spinsItemCode', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    {id: 'salesEndDate', Component: args => <EditFormDateInputField {...args} />},
                ]}
                validatePreSave={validatePreSave}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
};

BlockedItemCodes.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
};


BlockedItemCodes.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
};


export default BlockedItemCodes;