import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {EditFormDropdownInputField} from "../../Components/ReactTable/EditRecordForm";
import {useForm} from "react-hook-form";
import {getRetailersFromCache} from "./retailerOptions";
import papa from "papaparse";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {fieldProcessors, maybeVerifyAddresses, processParsedResults} from "./bulkUpload";
import PropTypes from "prop-types";
import {useMap, useMapsLibrary} from "@vis.gl/react-google-maps";
import {StoreAddressMap} from "./StoreAddressMap";
import ReactTable from "../../Components/ReactTable";
import {toTitleCase} from "../../Components/ReactTable/helpers";

const defaultValues = {
    retailerIdentification: 'inFile',
    verifyAddresses: true
};

const stepNameUploadFile = 'Upload File';
const stepNameReviewUploadResults = 'Review records to upload';
const steps = [
    {
        title: 'Retailer Identification',
        content: ({form}) => <>
            <label>
                <div>
                    <input type="radio" value="inFile" id={'retailerIdentification-inFile'} {...form.register("retailerIdentification")}/>
                    Retailer(s) will be specified in the uploaded file
                </div>
            </label>
            <label>
                <div>
                    <input type="radio" value="selectOne" id={'retailerIdentification-selectOne'} {...form.register("retailerIdentification")}/>
                    Upload stores for a specified retailer
                </div>
            </label>
            {form.watch('retailerIdentification') === 'selectOne' && <EditFormDropdownInputField
                id={'bulkUploadRetailer'}
                form={form}
                label={'Select Retailer'}
                options={getRetailersFromCache().retailerOptions}
            />}
        </>,
        validate: ({form}) => {
            if (form.getValues('retailerIdentification') === 'selectOne' && !form.getValues('bulkUploadRetailer')) {
                return 'Select a retailer to continue'
            }

            return undefined
        }
    },
    {
        title: stepNameUploadFile,
        content: ({form}) => <>
            <input type="file" id={'storesFile'} {...form.register("storesFile")} />
            <label>
                <input type="checkbox" id={'verifyAddresses'} {...form.register("verifyAddresses")} />
                Verify addresses on upload
            </label>
        </>,
        validate: ({form}) => {
            if (form.getValues('storesFile')?.length === 0) {
                return 'Select a file to continue'
            }
            return undefined
        }
    },
    {
        title: stepNameReviewUploadResults,
        content: ({uploadResult}) => {
            const hasErrors = uploadResult?.errors?.length > 0;
            const hasData = uploadResult?.data?.length > 0;
            const fieldsToDisplay = [
                ...Object.keys(fieldProcessors).filter(fieldName => !['index', 'version'].includes(fieldName)),
                'retailTag'
            ]
            const displayColumns =fieldsToDisplay.map(fieldName => ({header: toTitleCase(fieldName), accessorKey: fieldName}))
            return <LoadingWrapper loading={!uploadResult}>
                {hasErrors && <div>{uploadResult?.errors?.length} parse errors</div>}
                {hasErrors && <textarea title={'Error Details'} defaultValue={uploadResult.errors.map(error => JSON.stringify(error)).join('\n')} disabled={true} />}
                {<div>{uploadResult?.data?.length || 0} valid records</div>}
                {hasData && <ReactTable
                    data={uploadResult.data}
                    initialState={{pagination: {pageSize: 10}}}
                    showPageSizeDropdown={false}
                    columns={displayColumns}
                />}
            </LoadingWrapper>
        },
        validate: ({uploadResult}) => {
            if (!uploadResult?.data?.length > 0) {
                return 'No valid records to upload'
            }
        }
    },
    {
        title: 'Confirm And Import',
        content: ({uploadResult}) => <>Are you sure you want to add {uploadResult.data.length} store(s) to the Store Service?  This action can not be undone.</>
    },
]

export function BulkUploadStoresButton({onSubmit})  {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [stepNumber, setStepNumber] = useState(1)
    const [validationErrorMessage, setValidationErrorMessage] = useState(undefined)
    const [uploadResult, setUploadResult] = useState(undefined)
    const form = useForm({defaultValues})
    const geocodingLib = useMapsLibrary('geocoding');
    const placesMap = useMap();
    const placesLib = useMapsLibrary('places');

    const currentStep = steps[stepNumber - 1]
    const storeFile = form.watch('storesFile')


    useEffect(() => {
        if (currentStep.title === stepNameUploadFile) {
            return
        }
        if (currentStep.title === stepNameReviewUploadResults) {
            if (storeFile?.length > 0) {
                setUploadResult(undefined)
                papa.parse(storeFile[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (parsedResults) =>
                        processParsedResults(
                            parsedResults,
                            form.getValues('retailerIdentification') === 'selectOne' ? form.getValues('bulkUploadRetailer') : undefined
                        ).then(processedResults => maybeVerifyAddresses(
                            processedResults,
                            form.getValues('verifyAddresses'),
                            geocodingLib, placesLib, placesMap
                        )).then(setUploadResult)
                })
            }
        }
        form.setValue('storesFile', undefined)
    }, [form, currentStep, storeFile, geocodingLib, placesLib, placesMap])

    function submitAllRows() {
        setIsModalOpen(false)
        onSubmit(uploadResult.data)
    }

    return <>
        <button
            className="hollow button secondary"
            onClick={() => {
                form.reset(defaultValues)
                setStepNumber(1)
                setIsModalOpen(true)
            }}
        >
            {'Upload CSV'}
        </button>
        <Modal
            clickable
            isOpen={isModalOpen}
            className={`react-table-edit-modal bulk-upload-stores-modal`}
            overlayClassName="Overlay"
            ariaHideApp={false}
            onRequestClose={() => setIsModalOpen(false)}
            shouldCloseOnOverlayClick={false}
        >
            <h5 className={"modal-header-text"}>Bulk Upload Stores</h5>
            <h6>Step {stepNumber} of {steps.length} - {currentStep.title}</h6>
            <div>{currentStep.content({form, uploadResult})}</div>
            {validationErrorMessage && <div className={"validation-error-message"} data-testid={'validation-error-message'}>{validationErrorMessage}</div>}
            {/* Invisible map is included on the page because for some reason this is required for the places api search to work during address verification.*/}
            <StoreAddressMap />
            {/* -------- */}
            <form>
                <div className={'modal-buttons'}>
                    <button type={"button"} className={"button cancel"}
                            onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </button>
                    {stepNumber > 1 && <button type={"button"} className={"button primary"}
                            onClick={() => (stepNumber > 1) && setStepNumber(stepNumber - 1)}
                    >
                        Back
                    </button>}
                    {stepNumber < steps.length && <button type={"button"} className={"button primary"}
                            onClick={() => {
                                if (stepNumber < steps.length) {
                                    if (currentStep.validate) {
                                        const maybeErrorMessage = currentStep.validate({form, uploadResult, setUploadResult})
                                        setValidationErrorMessage(maybeErrorMessage)
                                        if (maybeErrorMessage) return
                                    }
                                }
                                setStepNumber(stepNumber + 1)
                            }}
                    >
                        Continue
                    </button>}
                    {stepNumber === steps.length && <button type={"button"} className={"button primary"}
                            onClick={() => submitAllRows()}
                    >
                        Submit and Import
                    </button>}
                </div>
            </form>
        </Modal>
    </>
}

BulkUploadStoresButton.propTypes = {
    onSubmit: PropTypes.func.isRequired
};