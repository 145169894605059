import {getIntParamOrDefault} from "../../../utils/urlBuilder";
import _ from 'lodash';

export function getValidInitialTabIndexOrDefault() {
    const validTabIndexes = [0,1]
    const tabIndex = getIntParamOrDefault('initialTabIndex', 0)
    return validTabIndexes.includes(tabIndex) ? tabIndex : 0
}

export const qsBuilderNoNils = (params) => {
    return new URLSearchParams(_.omitBy(params, _.isNil)).toString();
}