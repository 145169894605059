import React from 'react'
import Page from '../Components/Page';
import {
    labelCurrentPeriodQuad,
    labelCurrentPeriodWeek,
    makeApiCallForPeriods
} from '../Components/periodDropdownMethods';
import {toastError, toastSuccess} from '../../utils/toast';
import {toast} from 'react-toastify';
import {getUserEmail} from '../../auth/accessTokenValidator';
import {makeApolloClientCall} from '../../Api/makeApolloApiCall';
import {gql} from '@apollo/client';
import {invokeDAGviaApiPost} from '../../Api/invokeDag';
import {sldSchemaDropdownOptions} from "./sldDropdownOptions";
import SelectDropdown from "../Components/Dropdowns/SelectDropdown";
import {NumberSelectionDropdown} from "../Components/Dropdowns/NumberSelectionDropdown";
import CheckpointDropdown from "../Components/CheckpointDropdown";
import {makeApiCall} from "../../Api/makeApiCall";
import {RadioButtonGroup} from "../../utils/components/RadioButtonGroup";
import PeriodsDropdown from "../Components/Dropdowns/PeriodsDropdown";
import StoreInsightsTableCheckboxList from "./StoreInsightsTableCheckboxList";
import ReactSelectDropdownFromApi from "../Components/ReactSelectDropdownFromApi";
import Checkbox from "../Components/Checkbox";
import moment from "moment";
import AirflowDagLogs from "../Components/airflow/dagLogs/AirflowDagLogs";
import {getGcsLink} from "./loggingDagContentColumns";
import {addLabelToOptionsIfNotExist} from "../../utils/maybeAddLabelToOptions";

// noinspection GraphQLUnresolvedReference
export const currentWeekIdQuery = gql`query {
  currentPeriodWeek: periods (isCurrentPeriodWeek: true) {
    weekId
    quadId
    endDate
    periodAbbreviation
    spinsWeek
    isCurrentPeriod
  },
  currentPeriodQuad: periods (isCurrentPeriodQuad: true) {
    weekId
    quadId
    endDate
    periodAbbreviation
    spinsWeek
    isCurrentPeriod
  }
}`;

export class SldReleasePage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedWeekId: undefined,
            selectedNumberOfWeeks: 110,
            weeklyFactsOptions: undefined,
            checkpointPath: undefined,
            snapshotOptions: undefined,
            selectedSnapshot: undefined,
            selectedStoresVersion: undefined,
            typeSelection: 'Weekly',
            selectedInsightStart: undefined,
            selectedMarketPeriod: undefined,
            storeLevelReleaseOptOutChecked: false,
            currentPeriodWeek: undefined,
            currentPeriodQuad: undefined,
            selectedMarketProjectionVersion: 'current',
            includeDiscontinuedMarketStores: true
        }
    }

     getCurrentTimestamp() {
        const currentDate = new Date()
        const format = "YYYYMMDDHHmmss"
        return moment(currentDate).utc().tz('America/Chicago').format(format)
    }

    componentDidMount() {
        const schemasForTableQuery = `query {schemasForTable(tableName: "sld_weekly_facts"){tableSchema createdDate}}`
        Promise.all([
            makeApolloClientCall(currentWeekIdQuery, true)
                .then(response => {
                    const currentPeriodWeek = response.currentPeriodWeek?.[0]?.weekId;
                    const currentPeriodQuad = response.currentPeriodQuad?.[0]?.weekId;
                    const currentPeriodAbbreviation = response.currentPeriodQuad?.[0]?.periodAbbreviation;
                    this.setState({
                        selectedWeekId: currentPeriodWeek,
                        currentPeriodWeek,
                        currentPeriodQuad,
                        selectedMarketPeriod: currentPeriodAbbreviation
                    });
                }),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/productSnapshotVersions`)
                .then(result => {
                        const snapshotOptions = result.sort().reverse().map(option => ({label: option, value: option}));
                        return this.setState({snapshotOptions, selectedSnapshot: snapshotOptions[0]});
                    }
                ),
            sldSchemaDropdownOptions(schemasForTableQuery)
                .then((options) => this.setState({weeklyFactsOptions: options})),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/timeService`, {insightsPeriod: true})
                .then(result => this.setState({
                    selectedInsightStart: result.periodAbbreviation,
                })),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/storesVersions`)
                .then(result => {
                    const storesVersionOptions = result.sort().reverse().map(sv => ({label: sv, value: sv}));
                    this.setState({
                        storesVersionOptions,
                        selectedStoresVersion: storesVersionOptions[0]
                    });
                })
        ])
    }

    render() {
        const {
            selectedWeekId,
            selectedSnapshot,
            snapshotOptions,
            selectedNumberOfWeeks,
            checkpointPath,
            datasetsToGenerate,
            storeLevelReleaseOptOutChecked,
            typeSelection,
            selectedInsightStart,
            selectedMarketPeriod,
            currentPeriodWeek,
            currentPeriodQuad,
            selectedMarketProjectionVersion,
            includeDiscontinuedMarketStores,
            storesVersionOptions,
            selectedStoresVersion
        } = this.state

        const triggerDag = () => {
            const data = {
                weekId: selectedWeekId,
                triggered_by: getUserEmail(),
                checkpoint_path: checkpointPath,
                snapshotVersion: selectedSnapshot?.value,
                storesVersion: selectedStoresVersion?.value,
                datasets_to_generate: datasetsToGenerate,
                is_store_level_release_opt_out: storeLevelReleaseOptOutChecked,
                ...(typeSelection === 'Weekly' && {selectedNumberOfWeeks: selectedNumberOfWeeks}),
                ...(typeSelection === 'Period' && {
                    selected_insight_start: selectedInsightStart,
                    ...(!includeDiscontinuedMarketStores && {stores_in_markets_lookup_period: selectedMarketPeriod}),
                    stores_in_markets_lookup_projection_version: selectedMarketProjectionVersion,
                    include_discontinued_market_stores: includeDiscontinuedMarketStores
                }),
                selected_type: typeSelection,
                timestamp: this.getCurrentTimestamp()
            }
            const dagName = 'publish_store_insights'

            invokeDAGviaApiPost(dagName, data)
                .then((response) => {
                    if (response?.apiError)
                        toastError(toast, `${dagName} DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                    else
                        toastSuccess(toast, `${dagName} DAG triggered successfully with response ${JSON.stringify(response)}`)
                })
                .catch(e => {
                    toastError(toast, `${dagName} DAG trigger failed with error ${JSON.stringify(e)}`)
                })
        };

        return (
            <Page name="Store Insights Release">
                <RadioButtonGroup
                    callback={(value) => {
                        this.setState({
                            typeSelection: value === 'Weekly' ? 'Weekly' : 'Period',
                            selectedWeekId: value === 'Weekly' ? currentPeriodWeek : currentPeriodQuad
                        })
                    }}
                    selectedValue={this.state.typeSelection === 'Weekly' ? 'Weekly' : 'Period (Beta)'}
                    radioButtonOptions={['Weekly', 'Period (Beta)']}
                />
                {this.state.typeSelection !== 'Period' &&
                    <ReactSelectDropdownFromApi
                        onDropDownChange={(option) => {
                            this.setState({selectedWeekId: option.value})
                        }}
                        selectedDropDownValue={selectedWeekId && selectedWeekId.toString()}
                        labelText={"Period Week"}
                        getData={() => makeApiCallForPeriods().then(response => labelCurrentPeriodWeek(response))}
                    />}
                {this.state.typeSelection === 'Period' &&
                    <ReactSelectDropdownFromApi
                        onDropDownChange={(option) => this.setState({selectedWeekId: option.value})}
                        selectedDropDownValue={selectedWeekId && selectedWeekId.toString()}
                        labelText={"Insight End"}
                        getData={() => makeApiCallForPeriods(true).then(response => labelCurrentPeriodQuad(response))}
                    />}
                {this.state.typeSelection !== 'Period' &&
                    <NumberSelectionDropdown
                        labelText="Number of Weeks" minNumber={1} maxNumber={150}
                        selectedValue={selectedNumberOfWeeks}
                        onChange={(v) => this.setState({selectedNumberOfWeeks: v.value})}
                    />}
                {this.state.typeSelection === 'Period' &&
                    <PeriodsDropdown
                        updatePeriod={(v) => this.setState({selectedInsightStart: v})}
                        selectedPeriod={this.state.selectedInsightStart}
                        showLabel={true} periodLabel={'Insight Start'}
                    />}
                <br/>
                <br/>
                <SelectDropdown
                    id={"sld-dropdown-product-snapshot"}
                    inputLabel={"Product snapshot"}
                    selectValue={selectedSnapshot?.value}
                    selectLabel={selectedSnapshot?.label}
                    placeholder={"Select Product Snapshot Version..."}
                    options={snapshotOptions}
                    onChange={(option) => {
                        this.setState({selectedSnapshot: option ? option : undefined})
                    }}
                />
                {this.state.typeSelection === 'Period' &&
                    <>
                        <br/>
                        <br/>
                        <Checkbox labelText={'Include Discontinued Market Stores'}
                                  onChange={(value) => {
                                      this.setState({includeDiscontinuedMarketStores: value});
                                  }}
                                  checked={this.state.includeDiscontinuedMarketStores}
                        />
                        <PeriodsDropdown
                            className={'data-row-dropdown'}
                            updatePeriod={(marketPeriod) => this.setState({selectedMarketPeriod: marketPeriod})}
                            periodLabel={'Market Period'}
                            selectedPeriod={this.state.selectedMarketPeriod}
                            graphqlQuery={'{ marketPeriodList }'}
                            isDisabled={this.state.includeDiscontinuedMarketStores}
                        />
                        <br/>
                        <br/>
                        <ReactSelectDropdownFromApi
                            onDropDownChange={(option) =>
                            {
                                this.setState({selectedMarketProjectionVersion: option.value})}
                            }
                            selectedDropDownValue={this.state.selectedMarketProjectionVersion}
                            labelText={"Market Store (Projections) Version"}
                            getData={() => makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/projectionsVersions`)
                                .then(response => addLabelToOptionsIfNotExist(response))}
                        />
                        <SelectDropdown
                            id={"sld-dropdown-stores-version"}
                            inputLabel={"Stores Version"}
                            selectValue={selectedStoresVersion?.value}
                            selectLabel={selectedStoresVersion?.label}
                            placeholder={"Select Stores Version..."}
                            options={storesVersionOptions}
                            onChange={(option) => {
                                this.setState({selectedStoresVersion: option ? option : undefined})
                            }}
                        />
                    </>
                }

                <StoreInsightsTableCheckboxList
                    onCheckboxChange={(datasetsToGenerate) => this.setState({datasetsToGenerate})}
                />
                <div>Do not include stores using:</div>

                <RadioButtonGroup
                    callback={
                        (value) => {
                            this.setState({storeLevelReleaseOptOutChecked: value !== SLD_RELEASE_OPTION.SKIP_STORE_CONFIG})
                        }
                    }
                    selectedValue={!storeLevelReleaseOptOutChecked ? SLD_RELEASE_OPTION.SKIP_STORE_CONFIG : SLD_RELEASE_OPTION.SALESFORCE_OPT_OUT}
                    radioButtonOptions={Object.values(SLD_RELEASE_OPTION)}
                />
                <CheckpointDropdown
                    updateCheckpointPath={({checkpointPath}) => {
                        this.setState({checkpointPath});
                    }}
                    datasets={['cleanse_fill_extract', 'filtered_promo', 'promo_extract', 'store_insights']}
                    tableData={['promo_parquet']}
                />
                <div id="weeklyFactsActionMessage" style={{marginTop: '15px'}}>
                    This will generate
                    the <strong>{selectedWeekId || '[week-id]'}_[timestamp]_weekly_facts</strong> table.
                </div>
                <br/>
                <button type="button"
                        className="sld-trigger-button button primary submit"
                        onClick={triggerDag}>
                    Generate Selected Store Insight Tables
                </button>
                <h5>Store Insights Run Log</h5>
                <AirflowDagLogs
                    processName={'publish_store_insights'}
                    customColumns={[
                        {
                            header: 'Type',
                            accessorKey: 'conf.selected_type',
                            className: 'non-numeric-field'
                        },
                        {
                            header: 'Week Id',
                            accessorKey: 'weekid',
                            className: 'non-numeric-field'
                        },
                        {
                            header: 'Checkpoint Used',
                            accessorKey: 'checkpointused',
                            className: 'non-numeric-field',
                            enableColumnFilter: false,
                            cell: ({row, getValue}) => {
                                if (getValue()) {
                                    return getGcsLink(getValue());
                                }
                                return <></>;
                            }
                        }
                    ]}
                />
            </Page>
        )
    }
}

// noinspection HtmlUnknownTarget
export const SLD_RELEASE_OPTION = {
    SKIP_STORE_CONFIG: (<span>Skip Store Configuration <a href="/configurations/store/skip" target="_blank"
                                                          rel="noopener noreferrer">(link)</a></span>),
    SALESFORCE_OPT_OUT: 'SalesForce Store Level Release Opt Out'
}
