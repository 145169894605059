export const styleForConfigs = (possiblyFoundDate) => {
    const configStyles = []
    if (possiblyFoundDate?.storeStart) {
        configStyles.push('store-date-start');
    }
    else if (possiblyFoundDate?.storeStop) {
        configStyles.push('store-date-stop');
    }

    if (possiblyFoundDate?.doNotFill) {
        configStyles.push('do-not-fill');
    }
    else if (possiblyFoundDate?.alwaysFill) {
        configStyles.push('always-fill');
    }
    return configStyles.join(' ');
}