import React from 'react';
import PropTypes from "prop-types";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {EditFormBooleanDropdownInputField, EditFormDateInputField, EditFormNumberInputField} from "../Components/ReactTable/EditRecordForm";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";
import {sortAscendingWithNullsLast} from "../../utils/sorting";

function CheckDigitRetailers({displayHelmetHeader, retailerId}) {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/checkDigitRetailers/${retailerId ? `?retailerId=${retailerId}` : ''}`
    const endpointUrlForPost = `${process.env.REACT_APP_EDP_API_BASE_URL}/checkDigitRetailers/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl, endpointUrlForPost});

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "Spins Store Id", accessorKey: "spinsStoreId"},
        {header: "Sales Has Check Digit For UPCs", accessorKey: "salesHasCheckDigitForUPCs", size: 250},
        {header: "Sales Has Check Digit For PLUs", accessorKey: "salesHasCheckDigitForPLUs", size: 250},
        {header: "Items Has Check Digit For UPCs", accessorKey: "itemsHasCheckDigitForUPCs", size: 250},
        {header: "Items Has Check Digit For PLUs", accessorKey: "itemsHasCheckDigitForPLUs", size: 250},
        {header: "Use Until Date", accessorKey: "useUntilDate",
            sortingFn: (rowA, rowB) => {
                const datesA = rowA.original?.useUntilDate;
                const datesB = rowB.original?.useUntilDate;
                const numDatesA = datesA ? new Date(datesA) : null;
                const numDatesB = datesB ? new Date(datesB) : null;
               return sortAscendingWithNullsLast(numDatesA, numDatesB);
            }
        }
    ];

    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Check Digit Retailers Configuration"} displayHelmetHeader={displayHelmetHeader}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                initialState={{sorting: [{id: "retailerIdName", desc: false}]}}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'spinsStoreId', Component: args => <EditFormNumberInputField {...args} />},
                    {id: 'salesHasCheckDigitForUPCs', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'salesHasCheckDigitForPLUs', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'itemsHasCheckDigitForUPCs', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'itemsHasCheckDigitForPLUs', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'useUntilDate', Component: args => <EditFormDateInputField {...args} />},
                ]}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

CheckDigitRetailers.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
}

CheckDigitRetailers.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
}

export default CheckDigitRetailers;