import React, {Component} from 'react';
import {makeApiCall} from '../../../Api/makeApiCall';
import * as _ from "lodash";
import PropTypes from "prop-types";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import SelectDropdown from "./SelectDropdown";
import {toastError} from "../../../utils/toast";
import {toast} from "react-toastify";

const getPeriodsList = () => {
    return makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/timeService`)
        .then(result => result.data);
};

const getMarketPeriodsList = (graphqlQuery, periodsFunction, toastErrorMessage) => {
    return makeApolloClientCall(graphqlQuery)
        .then(periodsFunction)
        .catch(() => {
            if(toastErrorMessage) {
                toastError(toast, toastErrorMessage);
            }
        });
};

class PeriodsDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            periodOptions: undefined,
        }
    }

    componentDidMount() {
        const periods = this.props.graphqlQuery ? getMarketPeriodsList(this.props.graphqlQuery, this.props.periodsFunction, this.props.toastErrorMessage) : getPeriodsList()
        periods
            .then((periodList) => {
                let periodOptions = _.uniq(periodList?.map(this.props.periodOptionsMapFunction).sort().reverse());
                periodOptions = this.props.periodOptionsFilterFunction?.(periodOptions, periodList) || periodOptions
                periodOptions = periodOptions.map(option => ({value: option, label: option}));
                this.setState({
                    periodOptions,
                    periodList
                });
            });
    }

    render() {
        const periodOptions = this.state.periodOptions;
        const {id, selectedPeriod, periodLabel, periodOptionsMapFunction, updatePeriod, isDisabled, showLabel} = this.props;
        return (
            <SelectDropdown
                id={`${id}_dropdown`}
                inputLabel={showLabel ? periodLabel: undefined}
                onChange={
                    (option) => {
                        const period = this.state.periodList.find(period => periodOptionsMapFunction(period) === option.value);
                        updatePeriod(option.value, period);
                    }
                }
                options={periodOptions}
                selectValue={selectedPeriod}
                isDisabled={isDisabled}
            />
        );
    }
}


PeriodsDropdown.defaultProps = {
    id: 'period',
    periodLabel: 'Period',
    periodOptionsMapFunction: period => period.periodAbbreviation,
    periodsFunction: result => result.marketPeriodList.map(period => ({periodAbbreviation: period})),
    showLabel: true,
    periodOptionsFilterFunction: undefined,
    isDisabled: false,
    toastErrorMessage: undefined
}

PeriodsDropdown.propTypes = {
    periodOptionsMapFunction: PropTypes.func,
    periodOptionsFilterFunction: PropTypes.func,
    updatePeriod: PropTypes.func.isRequired,
    periodLabel: PropTypes.string,
    graphqlQuery: PropTypes.string,
    periodsFunction: PropTypes.func,
    className: PropTypes.string,
    selectedPeriod: PropTypes.string,
    showLabel: PropTypes.bool,
    isDisabled: PropTypes.bool,
    id: PropTypes.string,
    toastErrorMessage: PropTypes.string
};

export default PeriodsDropdown;
