export const getLoginRedirectUrl = () => {
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH_AUDIENCE;
  const auth0tenant = process.env.REACT_APP_AUTH_URL;
  const redirectStorage = window.localStorage.getItem('redirect');
  const redirect = redirectStorage && JSON.parse(redirectStorage);
  let state = '';
  if(redirect){
    state = `&state=${encodeURIComponent(redirect.state)}`;
  }
  return `${auth0tenant}/authorize?response_type=token&client_id=${clientId}&redirect_uri=${window.location.origin}/loginCallback&audience=${audience}${state}`
};

export const getLogoutRedirectUrl = () => {
  return `${process.env.REACT_APP_AUTH_URL}/v2/logout?returnTo=${window.location.origin}`
};

export const forceLogout = () => {
  window.location.assign('/logout');
}
