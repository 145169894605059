export const checkApiResults = (results, status) => {

    if (![window.google.maps.GeocoderStatus.OK, window.google.maps.GeocoderStatus.ZERO_RESULTS].includes(status)) {
        console.error(`=============> google api returned status "${status}"`, results)
        throw new Error(`google api returned status "${status}"`)
    }
    if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS || results?.length === 0) {
        console.log("=============> api call returned no results")
        return undefined
    }
    return results
}
