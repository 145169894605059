import React from 'react';
import PropTypes from 'prop-types';
import {filterShouldShowNavigationLink} from "../../../utils/navigationUtils";
import {getUserTypeFromLocalToken} from "../../../auth/accessTokenValidator";

const HeadDropdownSubMenu = ({id, menuItems}) => {
    const userType = getUserTypeFromLocalToken()

    const filteredMenuItems = menuItems
        .filter(item => filterShouldShowNavigationLink(item, userType));

    return (
        <ul className="vertical menu nested" data-testid={`${id}-nested`}>
            {filteredMenuItems
                .map((menuItem) =>
                    <li key={menuItem.name} className={"columns small-1"}>
                        <a href={menuItem.url}
                           id={`${menuItem.name}-dropdown`}
                           data-testid={`${id}-${menuItem.name}-dropdown`}
                           className={"dropdown-menu-link-large"}>
                            <div className={'dropdown-menu-link-container'}>
                                {menuItem.name}
                            </div>
                        </a>
                    </li>
                )}
        </ul>
    );
};

HeadDropdownSubMenu.propTypes = {
    id: PropTypes.string,
    menuItems: PropTypes.arrayOf(
        PropTypes.oneOfType(
            [
                PropTypes.string,
                PropTypes.shape({name: PropTypes.string, url: PropTypes.string})
            ])
    )
};


export default HeadDropdownSubMenu;