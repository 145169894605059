import React from 'react';
import PropTypes from "prop-types";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {
    EditFormBooleanDropdownInputField,
    EditFormDateInputField,
} from "../Components/ReactTable/EditRecordForm";

function ImpliedDecimalRetailers({displayHelmetHeader, retailerId}) {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/impliedDecimalRetailers/${retailerId ? `?retailerId=${retailerId}` : ''}`
    const endpointUrlForPost =`${process.env.REACT_APP_EDP_API_BASE_URL}/impliedDecimalRetailers/`
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl, endpointUrlForPost });
    const customColumns = [
        RETAILER_ID_NAME_COLUMN,
        {
            header: "Sales Amount Is Divided By 100",
            accessorKey: "salesAmountIsDividedBy100"
        },
        {
            header: "Sales Units Is Divided By 100",
            accessorKey: "salesUnitsIsDividedBy100"
        },
        {
            header: "Sales Weight Is Divided By 100",
            accessorKey: "salesWeightIsDividedBy100"
        },
        {
            header: "Price Is Divided By 100",
            accessorKey: "priceIsDividedBy100"
        },
        {
            header: "Use Until Date",
            accessorKey: "useUntilDate"
        }
    ];

    return (<Page name={"Implied Decimal Retailers Configuration"} displayHelmetHeader={displayHelmetHeader}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={customColumns}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'salesAmountIsDividedBy100', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'salesUnitsIsDividedBy100', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'salesWeightIsDividedBy100', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'priceIsDividedBy100', defaultValue: true, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'useUntilDate', defaultValue: null, Component: args => <EditFormDateInputField {...args} />},
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

ImpliedDecimalRetailers.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
}

ImpliedDecimalRetailers.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
}

export default ImpliedDecimalRetailers;