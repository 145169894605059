import React from 'react';
import MarketServiceGenericService from "./MarketServiceGenericService";

function GeographyCompositionService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/geographyComposition/`;
    return (<MarketServiceGenericService
        endpointUrl={endpointUrl}
        pageName={"Geography Composition Service"}
    />);
}

export default GeographyCompositionService;