import {getUnitedState} from "./StoresCompositionTabColumns";

const partOfUniverse = (row, storeCount, marketDesignation, type) => {
        const {salesAcv, averageSalesAcv, departmentsWithSales} = row;
        const state = type === 'store' ? getUnitedState(row["store"]) : '';

    if ((state && state !== 'AK' && state !== 'HI') || type === 'retailer') {
        if (marketDesignation && marketDesignation.toUpperCase() === 'PET') {
            if (storeCount >= 5) {
                return 'YES'
            }
        } else {
            if (salesAcv > 2 || averageSalesAcv > 2) {
                return 'YES'
            } else if (departmentsWithSales === 11) {
                return 'YES'
            }
        }
    }
    return 'NO';
};

export default partOfUniverse;