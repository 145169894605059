import React from 'react';
import PropTypes from "prop-types";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";

const PriorityRetailersMarketInsights = ({displayHelmetHeader}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/priority_retailers_market_insights/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    return (<Page name={"Priority Retailers: Market Insights"} displayHelmetHeader={displayHelmetHeader}
                  breadCrumbs={<Breadcrumbs
                      items={[{label: "Configurations"}, {label: "Retailer"}, {label: "Priority: Market Insights"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={[RETAILER_ID_NAME_COLUMN]}
                editTable={[INDEX_FIELD, RETAILER_ID_FIELD(serviceRetailerDropdown().data)]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

PriorityRetailersMarketInsights.defaultProps = {
    displayHelmetHeader: true,
}

PriorityRetailersMarketInsights.propTypes = {
    displayHelmetHeader: PropTypes.bool,
}

export default PriorityRetailersMarketInsights;