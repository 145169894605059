import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCopy, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {toastSuccess} from "../../utils/toast";
import {toast} from "react-toastify";
import airflowIcon from '../../assets/airflow-icon.png';
import React from 'react';

export const greenEmptyCircle = {
    display: "inline-block",
    width: "16px",
    height: "16px",
    border: "2px solid",
    borderColor: "#449711",
    borderRadius: "50%"
}

export const orangeSolidCircle = {
    display: "inline-block",
    width: "16px",
    height: "16px",
    backgroundColor: "#F1A103",
    borderRadius: "50%"
}

async function setClipboardToGcsPath(outputPath) {
    try {
        await navigator.clipboard.writeText(outputPath);
        toastSuccess(toast, `Copied - ${outputPath}`)
    } catch (e) {
        console.error("Clipboard NOT written successfully");
        console.error(e);
    }
}

function AirflowLinkButton({url}) {
    return (
        <a
            className={'circana-action-button flex-horizontal-center flex-vertical-center'}
            href={url}
            target="_blank" rel="noopener noreferrer">
            <img src={airflowIcon} alt={'airflow'} height={24} width={24}/>
        </a>
    )
}

export function CircanaLoadStatus({getValue, row}) {
    const value = getValue();

    switch (value) {
        case "Received":
            return <div className={'flex-vertical-center'}>
                <span style={orangeSolidCircle}/>
                <span className={'unload-status-text'}>
                    Received
                </span>
            </div>;
        case "In progress":
            return (
                <>
                    <div className={'flex-vertical-center'}>
                        <span style={greenEmptyCircle}/>
                        <span className={'unload-status-text'}>
                        In progress
                    </span>
                    </div>
                    <AirflowLinkButton url={row.original?.airflow_dag_link}/>
                </>
            );
        case "Completed":
            return (
                <>
                    <div className={'flex-vertical-center'}>
                        <FontAwesomeIcon icon={faCircleCheck} style={{color: "#449711"}}/>
                        <div className={'unload-status-text'}>
                            {value}
                        </div>
                    </div>
                    <div className={'flex-vertical-center'}>
                        <button data-testid='copy-conventional-path-button'
                                className={'circana-action-button copy-conventional-path-button'}
                                onClick={() => setClipboardToGcsPath(row.original['output_path'])}
                                title={'Copy GCS Path'}>
                            <FontAwesomeIcon icon={faCopy}/>
                        </button>
                        <AirflowLinkButton url={row.original?.airflow_dag_link}/>
                    </div>
                </>
            );
        case "Failed":
            return (
                <>
                    <div className={'flex-vertical-center'}>
                        <FontAwesomeIcon icon={faExclamationCircle} style={{color: "#CF1220"}}/>
                        <span className={'unload-status-text'}>
                        {value}
                    </span>
                    </div>
                    <AirflowLinkButton url={row.original?.airflow_dag_link}/>
                </>
            );
        default:
            console.error(`Unknown status: ${value}`);
            return <>{value} did not match a case statement.</>
    }
}
