import React, {useState} from 'react';
import Chart from "react-apexcharts";
import PropTypes from 'prop-types';
import ReadinessIssues from "../../PeriodRelease/ReadinessIssues";
import download from "../../../assets/download.svg";
import {getNicelyFormattedNumber} from "../../../utils/formatUtil";
import ReactTable from "../../Components/ReactTable";

const TotalsSearchResults = ({
                                 retailerIds,
                                 pageIndex,
                                 updatePageIndex,
                                 pageSize,
                                 updatePageSize,
                                 downloadAsCSVFunc,
                                 columns,
                                 data,
                                 dataSelection,
                                 getSeriesFunc,
                                 dynamicColumnsFunc,
                                 readinessIssuesCreatListItemsFunc,
                                 readinessFormatDataFunc,
                                 defaultSortedId,
                                 shouldRenderChart,
                                 trClassName,
                                 reactTableXCoordinateRef
                             }) => {
    const dataAccessor = dataSelection.toLowerCase();
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    function getDataDescriptors() {
        return 'dollars' === dataAccessor ? {
            dataDescriptor: 'Sales Amount',
            labelFormatter: '$'
        } : {dataDescriptor: 'Sales Units', labelFormatter: ''};
    }

    const {dataDescriptor, labelFormatter} = getDataDescriptors();
    const options = {
        chart: {
            id: "total-dollars-graph-chart",
            animations: {
                enabled: false
            }
        },
        stroke: {
            curve: 'smooth',
        },
        yaxis: {
            title: {
                text: dataDescriptor
            },
            labels: {
                formatter: function (value) {
                    return labelFormatter + getNicelyFormattedNumber(value);
                }
            },
        },
        xaxis: {
            title: {
                text: 'Sales End Date'
            },
            type: 'datetime'
        },
        legend: {
            position: "top",
            horizontalAlign: "center",
            show: true,
            showForSingleSeries: true,
            floating: true,
            offsetY: 200
        },
    };
    const chartSeries = getSeriesFunc(data, dataAccessor, 'Chart');
    const tableSeries = getSeriesFunc(data, dataAccessor, 'Table');
    return (
        <div className={'chart-container'}>
            {
                readinessFormatDataFunc &&
                readinessIssuesCreatListItemsFunc &&
                <div className={'totals-data-errors-container'}>
                    <ReadinessIssues issues={readinessFormatDataFunc(data)}
                                     collapsedContent={'Data errors'}
                                     createListItem={readinessIssuesCreatListItemsFunc}
                    />
                </div>
            }
            <div>
                {!downloadingCsv && <img className={'download-as-csv-totals'}
                     src={download}
                     alt={'download'}
                     onClick={async () => {
                         setDownloadingCsv(true)
                         await downloadAsCSVFunc(retailerIds, tableSeries)
                         setDownloadingCsv(false)
                     }}>
                </img> }
                {downloadingCsv && <i className="fas fa-carrot fa-spin fa-500px spinner download-as-csv-totals"/>}
            </div>
            <div id={'totals-data'}>
                <ReactTable
                    columns={columns.concat(dynamicColumnsFunc(data, dataAccessor))}
                    data={tableSeries}
                    updatePageSize={updatePageSize}
                    updatePageIndex={updatePageIndex}
                    filterable
                    initialState={{sorting: [{id: defaultSortedId, desc: true}], pagination: {pageSize, pageIndex}}}
                    hasStickyHeader={true}
                    trClassName={trClassName}
                    reactTableXCoordinateRef={reactTableXCoordinateRef}
                />
                <br/>
                {shouldRenderChart === true && <Chart
                    options={options}
                    series={chartSeries}
                    type='line'
                    width="100%"
                />}
            </div>
        </div>
    );
};
TotalsSearchResults.defaultProps = {
    getSeriesFunc: (data) => {
        return data;
    },
    columns: [],
    trClassName: '',
    updatePageSize: (pageSize) => {},
    updatePageIndex: (pageIndex) => {},
    pageSize: 10,
    pageIndex: 0
}

TotalsSearchResults.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    pageSize: PropTypes.number,
    updatePageSize: PropTypes.func,
    pageIndex: PropTypes.number,
    updatePageIndex: PropTypes.func,
    dataSelection: PropTypes.string,
    dynamicColumnsFunc: PropTypes.func,
    getSeriesFunc: PropTypes.func,
    downloadAsCSVFunc: PropTypes.func,
    readinessIssuesCreatListItemsFunc: PropTypes.func,
    readinessFormatDataFunc: PropTypes.func,
    defaultSortedId: PropTypes.string,
    shouldRenderChart: PropTypes.bool,
    trClassName: PropTypes.string,
    reactTableXCoordinateRef: PropTypes.any
};

export default TotalsSearchResults;