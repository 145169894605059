import React from 'react'
import PropTypes from "prop-types";

export const Breadcrumbs = ({items}) => {
    return (
        <nav aria-label="You are here:" role="navigation">
            <ul className="breadcrumbs" data-testid={'breadcrumbs'}>
                {items.map(item => item.to ? <li key={item.label}><a href={item.to}>{item.label}</a></li> : <li key={item.label}>
                    {item.label}
                </li>)}
            </ul>
        </nav>
    )
}

Breadcrumbs.props = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string
    })).isRequired
}