import React, {Component} from 'react';

import ApiGetContainer from '../../../Api/ApiGetContainer';
import PropTypes from "prop-types";
import RetailerIdAndName from "../../Components/RetailerIdAndName";
import {getRetailerName} from "../../../utils/retailerMapCache";


export const columns = [
    {
        id: 1,
        header: 'Retailer',
        accessorKey: 'retailerId',
        className: 'non-numeric-field sticky-column',
        sticky: 'left',
        filterable: true,
        cell: ({row}) => <RetailerIdAndName retailerId={row.original.retailerId}/>,
        filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.retailerId} ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()))

    },
    {
        id: 2,
        header: 'Store ID',
        filterable: true,
        accessorKey: 'storeId',
        className: 'sticky-column',
        sticky: 'left',
        filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.storeId}`.startsWith(filterValue))
    },

];

class ContinuityTable extends Component {

    render() {
        const dataColumns = [...columns, ...this.props.dataColumns]
        return (
            <ApiGetContainer
                columns={dataColumns}
                graphqlQuery={this.props.query}
                dataAccessor={'data'}
                filterableTable={true}
            />
        );
    }
}

ContinuityTable.propTypes = {
    query: PropTypes.string.isRequired,
    dataColumns: PropTypes.arrayOf(PropTypes.object)
}

export default ContinuityTable;
