import axios from 'axios';
import {downloadBlobToFile} from "../utils/CsvDownloadWithNulls";

function getConfig(contentType, params) {
    return {
        headers: {
            'Content-Type': contentType
        },
        params
    };
}

export const makeApiCall = (apiEndpointUrl, params) => {
    return makeApiCallWithoutCatch(apiEndpointUrl, params)
        .catch((apiError) => {
            return {apiError}
        });
};

export const makeApiCallWithoutCatch = (apiEndpointUrl, params) => {
    const config = getConfig(null, params);

    return axios.get(apiEndpointUrl, config)
        .then((response) => {
            return response.data
        });
};

export const makeApiCallDelete = async (apiEndpointUrl, params) => {
    const config = getConfig(null, params);
    let endpointApi = await axios.delete(apiEndpointUrl, config);
    return endpointApi.data;
};

export const makeApiCallDeleteWithData = async (apiEndpointUrl, data) => {
    return makeApiCallWithData(axios.delete, apiEndpointUrl, {data}).catch((apiError) => ({apiError}));
};

export const makeApiCallDownloadFile = (apiEndpointUrl, fileName) => {
    const config = {responseType: 'blob'};
    return axios.get(apiEndpointUrl, config).then((response) => {
        const blob = new Blob([response.data]);
        downloadBlobToFile(blob, fileName)
    });
};

const makeApiCallWithData = async (axiosFunction, apiEndpointUrl, data) => {
    const config = getConfig('application/json');
    let endpointApi = await axiosFunction(apiEndpointUrl, data, config);
    return endpointApi.data;
};

export const makeApiCallPost = async (apiEndpointUrl, data) => {
    return makeApiCallWithData(axios.post, apiEndpointUrl, data).catch((apiError) => ({apiError}));
};

export const makeApiCallPut = async (apiEndpointUrl, data) => {
    return makeApiCallWithData(axios.put, apiEndpointUrl, data).catch((apiError) => ({apiError}));
};

