import {useEffect, useState} from "react";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import {getSingleRowActionQueryParams} from "./helpers";
import {toastError} from "../../../utils/toast";
import {toast} from "react-toastify";

export function useServiceBase({ endpointUrl, endpointUrlForPost, urlContainsParams }) {
    if (!endpointUrlForPost) {
        endpointUrlForPost = endpointUrl;
    }
    if (!urlContainsParams){
        urlContainsParams = false
    }
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [triggerRefresh, setTriggerRefresh] = useState(Date.now());

    useEffect(() => {
        setLoading(true);
        makeApiCall(endpointUrl).then((result) => {
            setData(result);
            setLoading(false);
        });
    }, [endpointUrl, triggerRefresh]);

    function handlePostResponse(response) {
        if (response?.apiError) {
            console.error("=============> response: ", response)
            toastError(toast, `Operation failed with api error "${JSON.stringify(response.apiError.message)}"`)
            setLoading(false)
        } else {
            setTriggerRefresh(Date.now())
        }
    }

    function onRowEditConfirmed(currentData) {
        return makeApiCallPost(endpointUrlForPost + getSingleRowActionQueryParams("update", urlContainsParams), currentData)
            .then(handlePostResponse);
    }

    function onBulkRowUploadConfirmed(currentData) {
        setLoading(true)
        return makeApiCallPost(endpointUrlForPost + getSingleRowActionQueryParams("update", urlContainsParams) + '&multiple_submits=true', currentData)
            .then(handlePostResponse);
    }

    function onRowDeleteConfirmed(currentData) {
        return makeApiCallPost(endpointUrlForPost + getSingleRowActionQueryParams("delete", urlContainsParams), currentData)
            .then(handlePostResponse);
    }
    function onDeleteAllConfirmed(currentData) {
        return makeApiCallPost(endpointUrlForPost + "deleteAllData", currentData)
            .then(handlePostResponse);
    }

    return {
        loading,
        setLoading,
        data,
        onRowEditConfirmed,
        onRowDeleteConfirmed,
        onDeleteAllConfirmed,
        onBulkRowUploadConfirmed,
        refreshData: () => setTriggerRefresh(Date.now())
    };
}