import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import flattenLogDataForCsv from '../../utils/flattenLogDataForCsv';
import csvDownloadWithNulls from '../../utils/CsvDownloadWithNulls';
import {getStringParamOrDefault, updateQueryStringForState} from '../../utils/urlBuilder';
import {CONFIG_TYPES} from '../Configurations/FileTypes';
import {ingestionLogsTrProps} from "./ingestionLogsTrProps";
import ReactTable from "../Components/ReactTable";
import {
    fileNameColumnId,
    fileNameQueryStringKey,
    fileTypeColumnId,
    fileTypeQueryStringKey,
    getIngestionLogsColumns,
    retailerColumnId,
    retailerQueryStringKey
} from "./Columns/GetIngestionLogsColumns";
import {getUserTypeFromLocalToken, RETAILER_INGESTION_USERS} from '../../auth/accessTokenValidator';
import {isOnboarding} from './Columns/RetailerIdWithOnboarding';

function getFilters(fileTypeFilter, defaultFileType) {
    return {
        columnFilters: [
            {
                id: fileNameColumnId,
                value: getStringParamOrDefault(fileNameQueryStringKey, '')
            },
            {
                id: retailerColumnId,
                value: getStringParamOrDefault(retailerQueryStringKey, '')
            },
            {
                id: fileTypeColumnId,
                value: fileTypeFilter !== defaultFileType ? fileTypeFilter : ''
            }
        ]
    };
}

const IngestionLogsContent = ({data, salesforceRetailers, hideOnboarding}) => {
    const defaultFileType = 'all';

    const file_types = [defaultFileType].concat(Object.values(CONFIG_TYPES)
        .filter(type => type !== CONFIG_TYPES.FILE_TYPE)
        .filter(type => type.isAuthorized())
        .map(configClass => configClass.getName())
    );

    function notOnboardingData() {
        return data.metadata.filter(row => {
            const maybeSalesforceRetailer = salesforceRetailers.find((salesforceRetailer) => salesforceRetailer.retailerId === row.retailerId);
            return !isOnboarding(maybeSalesforceRetailer);
        })
    }

    const initialData = hideOnboarding ? notOnboardingData() : data.metadata;
    const filteredData = initialData
        .filter(fileLog => {
            const fileType = fileLog.fileType;
            if (!fileType) {
                // If no file type can be found, data operations to see the log but not locators users
                // This is because locators files ALWAYS have a file type
                return RETAILER_INGESTION_USERS.includes(getUserTypeFromLocalToken());
            }

            return CONFIG_TYPES[(fileLog.fileType || '').toUpperCase()]?.isAuthorized();
        });

    function downloadDataAsCsv() {
        const dataToDownload = flattenLogDataForCsv(filteredData);
        const fileNameForDownload = 'ingestLogs.csv';
        csvDownloadWithNulls(dataToDownload, fileNameForDownload, null)
    }

    const [fileTypeFilter, setFileTypeFilter] = useState(getStringParamOrDefault(fileTypeQueryStringKey, defaultFileType));

    const filters = getFilters(fileTypeFilter, defaultFileType);
    return (
        <Fragment>
            <div className="tab-radio-buttons">
                {file_types.map((fileType) => {
                    return <div key={fileType} className="tab-radio-button-container">
                        <input id={`radio-${fileType}`}
                               type="radio"
                               className="tab-radio-button"
                               name={"file-type-radio"}
                               checked={fileType === fileTypeFilter}
                               onChange={() => {
                                   setFileTypeFilter(fileType);
                                   updateQueryStringForState({[fileTypeQueryStringKey]: fileType});
                               }}
                        />
                        <label htmlFor={`radio-${fileType}`} className="radio-inline">{fileType}</label>
                    </div>
                })}
                <div className={"tab-download-csv"}>
                    <button className={'download-csv hollow button'}
                            onClick={downloadDataAsCsv}>{'Download CSV'}</button>
                </div>
            </div>

            <ReactTable
                key={JSON.stringify(filters)}
                filterable
                data={filteredData}
                initialState={{...filters, sorting: [{id: 'runId', desc: true}]}}
                columns={getIngestionLogsColumns(salesforceRetailers)}
                getTrProps={ingestionLogsTrProps()}
            />
        </Fragment>);
};

IngestionLogsContent.propTypes = {
    data: PropTypes.shape({
        metadata: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    salesforceRetailers: PropTypes.arrayOf(
        PropTypes.shape({
            retailerId: PropTypes.number,
            onboardingStatus: PropTypes.string
        })
    ),
    hideOnboarding: PropTypes.bool
};

export default IngestionLogsContent;