import React from 'react';
import PropTypes from 'prop-types';
import icon from '../../assets/trash-alt.svg'

function DeleteButton(props) {
    return (
        <button type="button" onClick={props.onDelete} data-testid={'delete-button'}>
            <img src={icon} alt='garbage' title={'Delete'} data-testid={'trash-alt'}/>
        </button>
    );
}

DeleteButton.propTypes = {
    onDelete: PropTypes.func
};

export default DeleteButton;