import * as PropTypes from 'prop-types';
import React from "react";
import {nullSafePercentage} from "../../../utils/util";

function StoreTotalCell({data}) {
    const weeksWithSalesPercentage = data.weeksWithSales ?
        nullSafePercentage(data.weeksWithSales.weeksWithData, data.weeksWithSales?.totalExpectedWeeks) :
        0;
    const hasIssue = weeksWithSalesPercentage > 0;
    const issueClassName = hasIssue ? 'issue-link-color-red' : ''
    const issueText = hasIssue ? `${weeksWithSalesPercentage}%` : '';
    const storeTotalsUrl = `/retailerReadiness/storeTotals?retailerId=${data.retailer.retailerId}&directSubmit=true&periodAbbreviation=${data.periodAbbreviation}`;

    return <div>
        {hasIssue ? <a target="_blank"
                       rel="noopener noreferrer"
                       href={storeTotalsUrl}
                       className={`store-totals-link ${issueClassName}`}
                       title={`Store Totals ${issueText}`}
        >{issueText}
        </a> : ""}
    </div>

}

StoreTotalCell.propTypes = {
    data: PropTypes.object
}

export default StoreTotalCell;