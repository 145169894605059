import {makeApolloClientCall} from "../Api/makeApolloApiCall";
import _ from "lodash";

export const GRAPHQL_QUERY = 'query getPriorPeriodQuery($periodsPrior: Int!, $startPeriod: Int!){getPriorPeriod(periodsPrior: $periodsPrior, periodType: QUAD, startPeriod: $startPeriod) { quadId weeks:quadPeriodDetails {weekId endDate} periodAbbreviation}}'
export const getPriorPeriodStartWeekId = (value, quadId) => {
    const periodsInPastAsNumber = Number(value);
    const variables = {periodsPrior: periodsInPastAsNumber, startPeriod: quadId}
    return makeApolloClientCall(GRAPHQL_QUERY, false, variables).then((priorPeriod) => {
        return _.orderBy(priorPeriod.getPriorPeriod.weeks, 'weekId', 'asc')[0]
    })
}