import React from 'react';

import {RadioButton} from './RadioButton';
import PropTypes from "prop-types";
import {convertLabelTextToDataTestId} from "../testHelpers/assertUtils";

const RadioButtonGroup = ({
                              className,
                              callback,
                              radioButtonOptions,
                              selectedValue,
                              disabledRadioButtonOptions,
                              displayCheckIcon
                          }) =>
    <div className={className}>
        <form className={'radio-button-group-form'} data-testid={'radio-button-group'}>
            {radioButtonOptions.map((option, index) => {
                    const isDisabled = disabledRadioButtonOptions.includes(option);
                    return <RadioButton
                        dataTestId={convertLabelTextToDataTestId(option, 'radio-button')}
                        disabled={isDisabled}
                        key={index}
                        index={index}
                        value={option}
                        currentValue={selectedValue}
                        onChange={callback}
                        displayCheckIcon={displayCheckIcon}
                    />
                }
            )}
        </form>
    </div>

RadioButtonGroup.defaultProps = {
    className: 'switches-radio',
    disabledRadioButtonOptions: [],
    displayCheckIcon: false
}

RadioButtonGroup.propTypes = {
    className: PropTypes.string,
    callback: PropTypes.func,
    displayCheckIcon: PropTypes.bool,
    radioButtonOptions: PropTypes.array,
    selectedValue: PropTypes.string,
    disabledRadioButtonOptions: PropTypes.array
};

export {RadioButtonGroup};