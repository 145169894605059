import React from "react";
import PropTypes from "prop-types";
import Page from "../Components/Page";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import { LoadingWrapper } from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";

const SkipFillRetailers = ({displayHelmetHeader, retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/skipFillRetailers/`;
    const endpointUrlForGet = `${endpointUrl}${retailerId ? `?retailerId=${retailerId}` : ""}`;

    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({
        endpointUrl: endpointUrlForGet,
        endpointUrlForPost: endpointUrl
    });

    return (
        <Page name={"Skip Fill Retailers"} displayHelmetHeader={displayHelmetHeader}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={[RETAILER_ID_NAME_COLUMN]}
                    initialState={{pagination: { pageSize: 25 }, sorting: [{id: "retailerIdName", desc: false}]}}
                    editTable={[INDEX_FIELD, RETAILER_ID_FIELD(serviceRetailerDropdown().data)]}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                    />
            </LoadingWrapper>
        </Page>
    );
};

SkipFillRetailers.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
};

SkipFillRetailers.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
};

export default SkipFillRetailers;