export function verifyNonNullableFieldsExistInConfig(parsedConfig) {
    try {
        if ((checkForFieldInAllPlaces(parsedConfig, 'item_code'))
            && (checkForFieldInAllPlaces(parsedConfig, 'sales_units'))
            && (checkForFieldInAllPlaces(parsedConfig, 'sales_amount') || checkForFieldInAllPlaces(parsedConfig, 'price'))
            && (checkForFieldInAllPlaces(parsedConfig, 'sales_end_date') || parsedConfig.file_date_format))
            return true
    } catch {
        return false
    }
    return false
}

function checkForFieldInAllPlaces(parsedConfig, fieldName){
    return parsedConfig.fields?.[fieldName] || parsedConfig.header?.split?.fields?.[fieldName] || parsedConfig.header?.fields?.[fieldName];
}