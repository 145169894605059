import React, {useEffect} from "react";
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormDropdownInputField, EditFormNumberInputField} from "../Components/ReactTable/EditRecordForm";
import {makeApiCall} from "../../Api/makeApiCall";
import _ from 'lodash';

export const ProjectionFactorsService = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/projectionFactors/`;
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl });
    const [legacyPeriodOptions, setLegacyPeriodOptions] = React.useState(undefined);
    const [cellOptions, setCellOptions] = React.useState(undefined);
    const [cellsById, setCellsById] = React.useState({});

    const columns = [
        {header: "Projection Factor Id", size: 100, accessorKey: 'projectionFactorId'},
        {header: "Cell", id: 'cellId', size: 200, accessorFn: (row) => (cellsById[row.cellId].description || row.cellId)},
        {header: "Legacy Period", size: 100, accessorKey: 'legacyPeriod'},
        {header: "Store Id", accessorKey: 'storeId'},
        {header: "Factor", accessorKey: 'factor'},
        {header: "Growth", accessorKey: 'growth'}
    ]

    useEffect(() => {
        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/cells/`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/time/`)
        ]).then(([cells, periods]) => {
            setLegacyPeriodOptions(_.orderBy(periods.data, 'legacyPeriod').map(period => ({value: period.legacyPeriod, label: period.legacyPeriod})))
            setCellsById(_.keyBy(cells.data, 'cell_id'))
            setCellOptions(_.orderBy(cells.data, 'description').map(cell => ({value: cell.cell_id, label: cell.description})))
        })
    }, [])

    return (
        <Page beta name={"Projection Factors"}>
            <LoadingWrapper loading={loading || !cellOptions || !legacyPeriodOptions}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={columns}
                    editTable={[
                        {id: 'projectionFactorId', Component: args => <EditFormNumberInputField {...args} disabled />},
                        {id: 'cellId', Component: args => <EditFormDropdownInputField {...args} isRequired options={cellOptions} />},
                        {id: 'legacyPeriod', Component: args => <EditFormNumberInputField {...args} isRequired options={legacyPeriodOptions} />},
                        {id: 'storeId', Component: args => <EditFormNumberInputField {...args} isRequired />},
                        {id: 'factor', Component: args => <EditFormNumberInputField {...args} isRequired />},
                        {id: 'growth', Component: args => <EditFormNumberInputField {...args} isRequired />},
                    ]}
                    initialState={{pagination: {pageSize: 25}}}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
}
