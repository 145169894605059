import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import _ from "lodash";

export function sldSchemaDropdownOptions(schemasForTableQuery) {
    return makeApolloClientCall(schemasForTableQuery, true)
        .then(schemasForTableQueryResults => {
            const unsortedOptions = schemasForTableQueryResults?.schemasForTable ?
                schemasForTableQueryResults.schemasForTable
                    .filter((s) => {
                        const matches = s.tableSchema.match(/(\d+)$/g);
                        return matches?.[0] && matches?.[0].length >= 8
                    })
                    .map((s) => {
                        const matches = s.tableSchema.match(/(\d+)$/g);
                        return {"label": `${s.createdDate}: ${matches[0]}`, value: s.tableSchema}
                    }) :
                [];
            return _.sortBy(unsortedOptions, ['label']).reverse();
        })
}
