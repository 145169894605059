import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from "../../Components/ReactTable";

const MissingWeeksSpotlightContent = ({data, columns}) => {
    return (
        <>
            <div className={"missing-data-table"}>
                <header className="page-header" >
                    <span className={'page-header-label'}>Missing Data</span>
                </header>
                <ReactTable
                    className={'missing-weeks-spotlight-table'}
                    filterable
                    data={data}
                    columns={columns}
                    initialState={{
                        pagination: {pageSize: 10},
                        sorting: [{id: 'problemWeeks', desc: false}]
                    }}
                />
            </div>
        </>
    )
};

MissingWeeksSpotlightContent.propTypes = {
    missingWeeksColumns: PropTypes.array,
    missingWeeksData: PropTypes.array
};

export default MissingWeeksSpotlightContent;