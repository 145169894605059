import * as PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {makeApolloClientCall} from '../../../Api/makeApolloApiCall';
import _ from 'lodash'
import SelectDropdown from "./SelectDropdown";
import {SPINS_OUTLETS_QUERY} from "../../../queries/spinsOutlets";

const OutletsDropdown = ({onChange, value, additionalOptions}) => {
    const [outletOptions, updateOutletOptions] = useState([]);

    useEffect(() => {
        makeApolloClientCall(SPINS_OUTLETS_QUERY)
            .then((results) => {
                const outletNames = results.outlets.map(outlet => {
                    return {value: outlet.index, label: `${outlet.index} - ${outlet.name}`, outletName: outlet.name}
                });
                const outletNamesWithAdditionalOptions = _.concat(outletNames, additionalOptions);
                return updateOutletOptions(outletNamesWithAdditionalOptions.sort((o1, o2) => o1.label.toLowerCase().localeCompare(o2.label.toLowerCase())));
            });
    }, [additionalOptions]);

    const selectedOption = useMemo(() => {
        let option;
        if(!isNaN(value)){
            option = outletOptions.find((o) => o.value === value)
        }
        else {
            option = outletOptions.find((o) => o.outletName === value)
        }
        return option || {value: null, label: ''}
    }, [outletOptions, value])

    return <SelectDropdown
        id={"outlet_dropdown"}
        inputLabel={"Outlet"}
        onChange={onChange}
        options={outletOptions}
        selectValue={selectedOption.value}
        selectLabel={selectedOption.label}
    />

};

OutletsDropdown.defaultProps = {
    additionalOptions: []
}

OutletsDropdown.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    additionalOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            outletName: PropTypes.string,
        })
    )
};

export default OutletsDropdown;