import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const salesforceRetailersQuery = gql`

    query salesforceRetailers ($retailerIds: [Int]) {
        salesforceRetailers(retailerIds:$retailerIds){
            retailerId
            contacts {
                name
                email
                title
                phoneNumber
            }
        }
    }`;

const RetailerContactsContent = ({retailerId}) => {
    const result = useQuery(
        salesforceRetailersQuery,
        {
            variables: {
                retailerIds: [retailerId]
            },
        },
    );

    return !result.data ? 'Loading primary contacts...' :
        result.data.salesforceRetailers.flatMap((retailers) => {
            const foundContacts = retailers.contacts.map((contact) => {
                const displayName = `${contact.name} (${contact.title}) - ${contact.phoneNumber}`;
                return <li key={contact.email}>
                    <a href={`mailto:${contact.email}`} className='contact-email-link' target={'__blank'} rel={'noopener'}>{displayName}</a>
                </li>
            });
            return foundContacts.length === 0 ? 'No primary contact has been assigned in Salesforce.' : <ul className={'all-retailer-contacts'}>{foundContacts}</ul>;
        });
};

RetailerContactsContent.propTypes = {
    retailerId: PropTypes.number
};

export default RetailerContactsContent;