import React, {useEffect, useState} from "react";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import {useLocation, useParams} from "react-router-dom";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import Page from "../../Components/Page";
import DesignationTable from "./DesignationTable";
import {redirectWithMessage} from "../../../utils/redirectMessage";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {qsBuilderNoNils} from './designationUtils';
import {useQueryParams} from '../../../utils/urlBuilder';

const DesignationLinkChild = () => {
    const [loadingUnlinkedChildDesignations, setLoading] = useState(true)
    const [unlinkedChildDesignations, setUnlinkedChildDesignations] = useState([])
    const params = useParams()
    const qsParams = useQueryParams(useLocation());
    const version = qsParams.get('version');
    const designationId = parseInt(params.designationId)
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/designations/index/${designationId}`;
    const {data: designation} = useServiceBase({endpointUrl});
    const loadUnlinkedChildDesignations = async () => {
        setLoading(true)
        const qsBuilder1 = qsBuilderNoNils({linked: false, version});
        const result = await makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/childDesignations/${designationId}?${qsBuilder1}`);
        setUnlinkedChildDesignations(result)
        setLoading(false)
    }

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        Promise.all([
            loadUnlinkedChildDesignations()
        ])
    }, []);

    function submitSelectedChildDesignations(selectedRows) {
        const childDesignationsToAdd = selectedRows.map(x => x.original.index);
        const apiPost = {
            childDesignationIds: childDesignationsToAdd,
            parentDesignationId: designationId,
            version
        };
        makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/childDesignations`, apiPost).then(() => {
            const message = `Successfully assigned ${childDesignationsToAdd.length} child designation/s to ${designation.index} - ${designation.name}.`;
            const qs = qsBuilderNoNils({initialTabIndex: 1, version})
            const redirectUrl = `/designation/${designation.index}?${qs}`;
            redirectWithMessage(message, redirectUrl);
        });
    }

    return <>
        <LoadingWrapper loading={loadingUnlinkedChildDesignations}>
            <Page name={`Link child designations to ${designation.index} - ${designation.name}`}
                  breadCrumbs={<Breadcrumbs items={[
                      {label: "Services"},
                      {label: "Designations", to: "/designations"},
                      {label: `${designation.name}`, to: `/designation/${designation.index}`},
                      {label: "Link Child Designation"}
                  ]}/>}>
                <DesignationTable designations={unlinkedChildDesignations}>
                    {
                        ({table}) => {
                            const selectedRows = table.getSelectedRowModel().rows;
                            return (
                                <span className={'designation-action-buttons-container'} data-testid={"stores-action-button-container"}>
                                            <button type={"button"} className={"button primary"} disabled={selectedRows.length === 0} onClick={()=> {submitSelectedChildDesignations(selectedRows)}}>Link Child</button>
                                </span>
                            )
                        }
                    }
                </DesignationTable>
            </Page>
        </LoadingWrapper>
    </>
};

export default DesignationLinkChild;