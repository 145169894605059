import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Row} from "react-foundation";
import Select from "react-select";
import {convertLabelTextToDataTestId} from "../../utils/testHelpers/assertUtils";
import {SELECT_DROPDOWN_CONTAINER_TEST_ID} from "./Dropdowns/SelectDropdown";

const ReactSelectDropdownFromApi = ({
                                        onDropDownChange,
                                        selectedDropDownValue,
                                        labelText,
                                        getData,
                                        helpText,
                                        optionalClassName,
                                        sortDropdownValues
                                    }) => {
    const [dropDownValues, updateDropDownValues] = useState([])
    React.useEffect(() => {
        getData()
            .then(result => sortDropdownValues ? result.sort() : result)
            .then(apiDropDownValues => {
                updateDropDownValues(apiDropDownValues)
                return apiDropDownValues
            })
    }, [getData])

    function getSelectValue(selectedDropDownValue, dropDownValues) {
        return dropDownValues.find(option => option.value === selectedDropDownValue)
    }

    return (<Row>
        <div className={'id-input'} data-testid={SELECT_DROPDOWN_CONTAINER_TEST_ID}>
            <span className='input-group-label'>{labelText}:</span>
            <Select
                id={convertLabelTextToDataTestId(labelText, undefined, "_dropdown")}
                className={`optional-dropdown ${optionalClassName}`}
                classNamePrefix={'optional-dropdown'}
                options={dropDownValues}
                value={getSelectValue(selectedDropDownValue, dropDownValues)}
                isClearable={false}
                placeholder={''}
                onChange={(value) => onDropDownChange(value)}
            />
        </div>
        <div className="help-text" data-testid={"help-text"}>{helpText}</div>
    </Row>)
}

ReactSelectDropdownFromApi.defaultProps = {
    optionalClassName: "",
    sortDropdownValues: true
}

ReactSelectDropdownFromApi.propTypes = {
    optionalClassName: PropTypes.string,
    getData: PropTypes.func.isRequired,
    onDropDownChange: PropTypes.func.isRequired,
    selectedDropDownValue: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    sortDropdownValues: PropTypes.bool
}

export default ReactSelectDropdownFromApi