import {AdvancedMarker, InfoWindow, Map, useAdvancedMarkerRef} from '@vis.gl/react-google-maps';
import PropTypes from "prop-types";

export function StoreAddressMap({latitude, longitude, markerTitle, locationChanged}) {

    const defaultCenter = {lat: latitude, lng: longitude}
    const markerPosition = {lat: latitude, lng: longitude}
    const defaultZoom = 17
    const [markerRef, marker] = useAdvancedMarkerRef();

    return (<div id={"mapBox"} >
        <Map mapId={process.env.REACT_APP_GOOGLE_MAPS_STORE_ADDRESS_MAP_ID}
             defaultCenter={defaultCenter}
             defaultZoom={defaultZoom}
             // options={{disableDefaultUI: false, streetViewControl: false, fullscreenControl: false, zoomControl: true,}}
        >
            <AdvancedMarker
                ref={markerRef}
                position={markerPosition}
                title={markerTitle}
                draggable={true}
                onDragEnd={(event) => locationChanged({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                })}
            />

            <InfoWindow
                anchor={marker}
                headerDisabled={true}
            >
                drag to change location
            </InfoWindow>
        </Map>
    </div>)
}

StoreAddressMap.defaultProps = {
    markerTitle: 'store address location',
    locationChanged: () => {},
    latitude: 0,
    longitude: 0
}

StoreAddressMap.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    locationChanged: PropTypes.func,
    markerTitle: PropTypes.string
};
