import React, {useState} from 'react';
import Modal from "react-modal";
import {makeApiCall} from "../../../Api/makeApiCall";
import PropTypes from "prop-types";
import FtpConfigs from "../../Configurations/FtpConfigs";
import {ConfigType} from "../../Configurations/FileTypes";

export const ShowFilePartial = ({path, ftp, fileType, maxCharacters}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileData, setFileData] = useState(undefined);

    async function getFilePartial() {
        setIsModalOpen(true)
        const encodePath = encodeURIComponent(path);
        const url = `${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFilePartial/?path=${encodePath}&start=0&end=${maxCharacters}`;
        const results = await makeApiCall(url)
        setFileData(results.apiError ? "Error retrieving file" : results)
    }

    return (<>
        <div className={'clickable-thingy fas fa-eye fa-2x'} data-testid={"eye_button"}
             onClick={async () => await getFilePartial(path)}/>
        <Modal
            isOpen={isModalOpen}
            className="Modal show-file-partial-modal"
            overlayClassName="Overlay"
            ariaHideApp={false}
            testId={"show_file_partial_modal"}
        >
            <div className={'modal-x-close'}>
                <button className={"modal-x-close-button"} type="button" name="close"
                        onClick={() => setIsModalOpen(false)}>
                    X
                </button>
            </div>
            <FtpConfigs ftp={ftp} buttonTitle={ftp} configType={fileType} isModalMode={false}/>
            <hr/>
            <h2>First {maxCharacters} characters of {path}</h2>
            <div className={'file-partial-content'} data-testid={"file_partial_content"}>{fileData}</div>
        </Modal>
    </>)
}

ShowFilePartial.defaultProps = {
    maxCharacters: 2000
}

ShowFilePartial.propTypes = {
    path: PropTypes.string,
    ftp: PropTypes.string,
    fileType: PropTypes.instanceOf(ConfigType).isRequired,
    maxCharacters: PropTypes.number
}
