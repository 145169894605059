import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeApiCallPost} from "../../Api/makeApiCall";
import Modal from "react-modal";
import ButtonLoader from "../../utils/components/ButtonLoader";
import {RadioButtonGroup} from "../../utils/components/RadioButtonGroup";
import {retailerDisplay} from "../../utils/retailerDisplayFormatter";
import {getRetailerName} from "../../utils/retailerMapCache";

function getModalHeader(updateIsModalOpen, title) {
    return (
        <div className={"modal-header"}>
            <div className={'modal-header-title-container'}><span className={'modal-header-title'}>{title}</span></div>
            <div>
                <button className={"right-exit-button"} onClick={() => updateIsModalOpen(false)}>
                    <i className="fas fa-times"/></button>
            </div>
        </div>
    );
}

const EditAlertModal = ({
                            isModalOpen,
                            updateIsModalOpen,
                            retailerId,
                            taskName,
                            buttonTitle,
                            insertIds,
                            initialNotes,
                            toastTextSuccess,
                            toastTextFailure,
                            onSubmitCallback,
                            acknowledged,
                            falsePositive,
                            user
                        }) => {
    const [isDataIssue, updateIsDataIssue] = useState(falsePositive === null ? null : !falsePositive)
    const [localNotes, updateLocalNotes] = useState(initialNotes === null ? '' : initialNotes);

    function getSavedClassification(falsePositive) {
        if (falsePositive === null)
            return {'classification': 'For further Review', 'highlight': 'highlight-yellow'}
        if (falsePositive) return {'classification': 'Not an issue', 'highlight': 'highlight-light'}
        return {'classification': 'Data issue', 'highlight': 'highlight-pink'}
    }

    const modalTitle = 'Edit Alert'
    const savedClassification = getSavedClassification(falsePositive)

    return (<>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => updateIsModalOpen(false)}
                ariaHideApp={false}
                className="Modal acknowledge-modal">
                <>
                    {getModalHeader(updateIsModalOpen, modalTitle)}
                    <div
                        className={'alert-retailer-label'}>{`Retailer ${retailerDisplay(retailerId, getRetailerName(retailerId))}`}</div>
                    <div className={'alert-modal-task-name'}>{taskName}</div>
                    <br/>
                    {acknowledged &&
                        <>
                            <div className={'alert-modal-saved-classification'}>Saved Classification -
                                <div data-testid={"alert-modal-saved-classification-highlight"}
                                     className={`alert-modal-saved-classification-highlight ${savedClassification['highlight']}`}>{savedClassification['classification']}</div>
                            </div>
                            <br/>
                            <div><span className={'section-header'}>Update Classification</span></div>
                            <RadioButtonGroup
                                callback={(dataIssue) => {
                                    updateIsDataIssue(dataIssue === 'Further Review' ? null : dataIssue === 'Data Issue')
                                }}
                                selectedValue={isDataIssue === null ? 'Further Review' : isDataIssue ? 'Data Issue' : 'Not an issue'}
                                radioButtonOptions={['Data Issue', 'Not an issue', 'Further Review']}
                                className={'modal-radio-button-group-container'}
                                displayCheckIcon={true}
                            />
                        </>}
                    <div>
                        <span className={'section-header'}>Notes</span>
                        <textarea
                            placeholder={'What actions were taken to check this alert?'}
                            className={"modal-alerts-text-area-wide"}
                            value={localNotes}
                            onChange={e => updateLocalNotes(e.target.value)}
                        />
                    </div>
                </>
                <div className={"right-things"}>
                    <button className="button cancel modal-button" type="button" name="close"
                            onClick={() => updateIsModalOpen(false)}>
                        Cancel
                    </button>

                    <ButtonLoader buttonTitle={buttonTitle}
                                  buttonClassName={"button primary modal-button"}
                                  axiosFunction={makeApiCallPost}
                                  url={`${process.env.REACT_APP_EDP_API_BASE_URL}/updateAlerts/`}
                                  postBody={{
                                      alertIds: insertIds,
                                      notes: localNotes,
                                      isDataIssue: isDataIssue,
                                      acknowledged: acknowledged ? acknowledged : false,
                                      assignedUser: user
                                  }}
                                  toastTextSuccess={toastTextSuccess}
                                  toastTextFailure={toastTextFailure}
                                  onSubmitCallback={() => {
                                      updateIsModalOpen(false);
                                      onSubmitCallback();
                                  }}
                    />
                </div>
            </Modal>
        </>
    );
}

EditAlertModal.propTypes = {
    buttonTitle: PropTypes.string,
    insertIds: PropTypes.arrayOf(PropTypes.string),
    toastTextSuccess: PropTypes.string,
    toastTextFailure: PropTypes.string,
    onSubmitCallback: PropTypes.func,
    retailerId: PropTypes.string,
    taskName: PropTypes.string,
    falsePositive: PropTypes.any,
    initialNotes: PropTypes.string,
    acknowledged: PropTypes.bool,
};

export default EditAlertModal