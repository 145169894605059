import {getUserEmail} from "../auth/accessTokenValidator";
import {getWindowLocation} from "../utils/util";

export const createDagRunConf = (data) => {
    return {
        triggered_by: getUserEmail(),
        triggered_from: getWindowLocation().href,
        ...(data || {})
    }
}
