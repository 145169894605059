import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import ButtonLoader from "../../../../utils/components/ButtonLoader";
import {makeApiCallPost} from "../../../../Api/makeApiCall";

const ItemCodeOutliers = ({outliers}) => <>{!_.isEmpty(outliers) && <>
    <h2>Item Code Outliers (Greater than 25% of sales amount)</h2>
    {
        outliers.map(outlier => {
            return <div className={"outlier-item"} key={`outlier-${outlier.spinsItemCode}`}>
                <ButtonLoader buttonTitle={'Block'}
                              url={`${process.env.REACT_APP_EDP_API_BASE_URL}/blockedItemCodes/${outlier.spinsRetailerId}`}
                              toastTextSuccess={`${outlier.spinsItemCode} has successfully been blocked for store ${outlier.spinsStoreId} and date ${outlier.weekEndDate}`}
                              toastTextFailure={`${outlier.spinsItemCode} has failed to be blocked. for store ${outlier.spinsStoreId} and date ${outlier.weekEndDate}`}
                              axiosFunction={makeApiCallPost}
                              postBody={{
                                  spinsStoreId: outlier.spinsStoreId,
                                  spinsItemCode: outlier.spinsItemCode,
                                  salesEndDate: outlier.weekEndDate
                              }}
                />
                <span className={'outlier-item-code-text'}>{`${outlier.spinsItemCode} (${outlier.itemDescription}): ${_.round(outlier.percentTotalDollar, 2)}% ($${outlier.salesAmount})`}</span>
            </div>
        })
    }
</>
}</>;


ItemCodeOutliers.propTypes = {
    outliers: PropTypes.arrayOf(PropTypes.object),
};

export default ItemCodeOutliers;