import React, {Fragment, useEffect, useState} from 'react';
import ButtonLoader from "../../../utils/components/ButtonLoader";
import PropTypes from 'prop-types';
import {toast} from "react-toastify";
import {makeApiCall} from "../../../Api/makeApiCall";
import {toastError} from "../../../utils/toast";
import {getUserEmail} from "../../../auth/accessTokenValidator";
import _ from "lodash";
import {NewFileNameTextComponent} from "./NewFileNameTextComponent";
import {getBaseFileName} from "../../../utils/GetBaseFileName";
import {BulkLoadArchiveButton, bulkLoadOptionsEnum} from "../../Retailer/BulkLoadArchiveButton";


export const ReIngest = (props) => {
    const originalBaseFileName = props.original.isBulk || !props.original.uniqueArchiveFileName ? {} : getBaseFileName(props.original.uniqueArchiveFileName);
    const [modalMessage, setModalMessage] = useState(`Are you sure you want to re-ingest this single file?`);
    const filePath = props.value;

    const [newFileObject, updateNewFileObject] = useState({
        newFileName: originalBaseFileName,
        newFullFilePath: props.value
    });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        updateNewFileObject({
            newFileName: originalBaseFileName,
            newFullFilePath: props.value
        })
    }, [props.value])
    /* eslint-enable react-hooks/exhaustive-deps */

    const messages = newFileObject.newFullFilePath !== props.value ? {
        message: `Re-Naming!`,
        modalHeader: `Re-Name ${filePath} to ${newFileObject.newFullFilePath} and Ingest Single File`,
        toastSuccess: `${filePath} renamed to ${newFileObject.newFullFilePath} has successfully kicked off.`,
        toastFailure: `${filePath} renamed to ${newFileObject.newFullFilePath} has failed to kick off.`,
    } : {
        message: `Re-Ingesting!`,
        modalHeader: `Re-Ingest Single File: ${filePath}`,
        toastSuccess: `${filePath} has successfully kicked off.`,
        toastFailure: `${filePath} has failed to kick off.`,
    }

    const triggerIngestIfRetailerNotRunning = async () => {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ingestionLogs/`;
        const parameters = {
            includeSuccess: false,
            includeShortCircuit: true,
            includeBulkOnly: false,
            includeCurrentlyRunning: true,
            includeFailures: false,
            days: 1
        };
        const ingestLogs = await makeApiCall(endpointUrl, parameters);
        if (ingestLogs?.apiError) {
            toastError(toast, `Check if running for this file has failed to complete.`);
        } else {
            ingestLogs.metadata.find(dagRun => dagRun.archiveLocation === props.value) ?
                setModalMessage(`A re-ingest for ${filePath} is already running, are you sure you want to kick off another one?`)
                : setModalMessage('A re-ingest will be kicked off')
        }
    }

    return (
        <Fragment>
            {!props.original.isBulk && props.value &&
            <ButtonLoader
                buttonClassName={'button tertiary'}
                buttonHasModal
                buttonTitle={'Re-Ingest'}
                toastTextSuccess={messages.toastSuccess}
                toastTextFailure={messages.toastFailure}
                modalHeader={messages.modalHeader}
                modalText={modalMessage}
                postBody={{
                    archiveFileLocation: props.value,
                    newFilePath: newFileObject.newFullFilePath !== props.value ? newFileObject.newFullFilePath : undefined,
                    triggeredBy: getUserEmail()
                }}
                onClose={() => updateNewFileObject({
                    newFileName: originalBaseFileName,
                    newFullFilePath: props.value
                })}
                url={`${process.env.REACT_APP_EDP_API_BASE_URL}/reIngestSingleFile/`}
                buttonOnClickAction={triggerIngestIfRetailerNotRunning}
                onSubmitCallback={props.onSubmitCallback}
                modalChildren={<NewFileNameTextComponent message={messages.message}
                                                         originalFileName={originalBaseFileName}
                                                         defaultValue={newFileObject.newFileName} onChange={(event) => {
                    const newFullFilePath = props.value.replace(`/${originalBaseFileName}`, `/${event.target.value}`);
                    updateNewFileObject({
                        newFileName: event.target.value,
                        newFullFilePath: newFullFilePath
                    });
                }}/>}
            />
            }
            {props.original.isBulk &&
            <BulkLoadArchiveButton retailerId={props.original.retailerId} fileType={bulkLoadOptionsEnum.sales}/>
            }
        </Fragment>);
};

ReIngest.defaultProps = {
    onSubmitCallback: _.identity
}

ReIngest.propTypes = {
    onSubmitCallback: PropTypes.func,
    original: PropTypes.shape({
        uniqueArchiveFileName: PropTypes.string,
        isBulk: PropTypes.bool,
        retailerId: PropTypes.number
    }),
    value: PropTypes.string
};


export default ReIngest;