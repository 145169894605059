import React from 'react';
import MarketServiceGenericService from "./MarketServiceGenericService";

function GeographyLevelService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/geographyLevel/`;
    return (<MarketServiceGenericService
        endpointUrl={endpointUrl}
        pageName={"Geography Level Service"}
    />);
}

export default GeographyLevelService;