import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getEdpAirflowUri} from "../../../../utils/airflow";
import {convertToQueryParams} from "../../../../utils/urlBuilder";
import ApiGetContainer from "../../../../Api/ApiGetContainer";
import AirflowDagLogsContent from "./AirflowDagLogsContent";

const AirflowDagLogs = ({retailerId, processName, customColumns, includeDagName, includeRetailer}) => {
    const [airflowUrl, updateAirflowUrl] = useState(undefined)

    const queryStringParams = convertToQueryParams({retailerId, processName})
    const queryString = queryStringParams ? `?${queryStringParams}` : ''
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/airflow/daglogs${queryString}`;

    React.useEffect(() => {
        getEdpAirflowUri().then(edpAirflowUrl => updateAirflowUrl(edpAirflowUrl))
    }, [])

    return <ApiGetContainer
        endpoint={endpointUrl}
        componentToRender={AirflowDagLogsContent}
        componentToRenderProps={{customColumns, includeDagName, includeRetailer, airflowUrl}}
    />

}

AirflowDagLogs.defaultProps = {
    processName: undefined,
    retailerId: undefined
}

AirflowDagLogs.propTypes = {
    retailerId: PropTypes.number,
    processName: PropTypes.string,
    customColumns: PropTypes.arrayOf(PropTypes.object),
    includeDagName: PropTypes.bool,
    includeRetailer: PropTypes.bool
}

export default AirflowDagLogs;