import React, {useState} from 'react';
import ApiGetContainer from "../../Api/ApiGetContainer";
import Page from "../Components/Page";


const ProductService = () => {
    const [itemCode, setItemCode] = useState(undefined);
    const [submittedItemCode, setSubmittedItemCode] = useState(undefined);

    return (
        <Page name={`Products Service`}>
            <div className={'id-input'}>
                <span className='input-group-label'>SPINS Item Code:</span>
                <input
                    data-testid={'product-text-box'}
                    placeholder={'XX-XXXXX-XXXXX'}
                    className={'input-group-field'}
                    type='text'
                    defaultValue={itemCode}
                    onChange={({target}) => setItemCode(target.value)}
                />
                <div className='input-group-button'>
                    <input type='submit' className='button' value='Submit'
                           onClick={() => setSubmittedItemCode(itemCode)}/>
                </div>
            </div>
            {submittedItemCode &&
            <ApiGetContainer
                graphqlQuery={getProductQuery(submittedItemCode)}
                dataAccessor={'product'}
                columns={productQueryColumns}
            />
            }
        </Page>
    );
};

export const productQueryColumns = [
    {header: 'Spins Item Code', accessorKey: 'spinsItemCode'},
    {header: 'Description', accessorKey: 'description'},
    {header: 'Brand', accessorKey: 'brand'},
    {header: 'Department Tag', accessorKey: 'departmentTag'},
    {header: 'Category Tag', accessorKey: 'categoryTag'},
    {header: 'Subcategory Tag', accessorKey: 'subcategoryTag'},
    {header: 'Positioning Group Tag', accessorKey: 'positioningGroupTag'},
    {header: 'Brand Position Tag', accessorKey: 'brandPositionTag'},
    {header: 'Product Type Tag', accessorKey: 'productTypeTag'},
    {header: 'Unit Of Measure Tag', accessorKey: 'unitOfMeasureTag'},
    {header: 'Size', accessorKey: 'size'},
    {header: 'Pack Count', accessorKey: 'packCount'},
    {header: 'Median', accessorKey: 'medianAverageRetailerPrice'},
    {header: '25%', accessorKey: 'twentyFiveAverageRetailerPrice'},
    {header: '75%', accessorKey: 'seventyFiveAverageRetailerPrice'},
    {header: 'Observations', accessorKey: 'observations'}
];

export const getProductQuery = (itemCode) => {
    return `query {
    product(spinsProductRequest: {spinsItemCodes: ["${itemCode}"]}){
        spinsItemCode
        brand
        ... on SpinsProductDataType {
        description
        departmentTag
        categoryTag
        subcategoryTag
        positioningGroupTag
        brandPositionTag
        productTypeTag
        unitOfMeasureTag
        size
        packCount
        medianAverageRetailerPrice
        twentyFiveAverageRetailerPrice
        seventyFiveAverageRetailerPrice
        observations
        }
      }}`;
};

export default ProductService;