import React from 'react';
import {getWindowLocation, getWindowStorage} from "../../utils/util";
import {useLocation} from "react-router-dom";

export const LoginCallback = () => {
    const windowLocation = getWindowLocation()
    const urlSearchParams = new URLSearchParams(useLocation().hash?.substring(1));
    const defaultDestination = `${windowLocation.origin}/`;

    const accessToken = urlSearchParams?.get('access_token') || '';
    const stateValueFromAuth0 = urlSearchParams?.get('state');
    console.log("STATE: " + stateValueFromAuth0)

    let shouldAcceptToken = false;
    let destinationUrl = `${windowLocation.origin}/unauthorized`;

    const localStorage = getWindowStorage()

    const redirectStorageString = localStorage.getItem('redirect');
    console.log("REDIRECT: " + redirectStorageString)
    console.log("SATEFROMAUTH: " + stateValueFromAuth0)
    if (redirectStorageString) {
        let redirectObject = {}
        try {
            redirectObject = JSON.parse(redirectStorageString);
        } catch (ignore) {
        }

        localStorage.removeItem('redirect');
        const expectedState = redirectObject?.state;
        if (expectedState && expectedState === stateValueFromAuth0) {
            shouldAcceptToken = true;
            destinationUrl = redirectObject?.location?.href || defaultDestination;
        }
    } else if (!stateValueFromAuth0) {
        // If we did not store a 'redirect' object in localStorage, only allow the user to continue if Auth0 did not
        // return a 'state' value.
        shouldAcceptToken = true;
        destinationUrl = defaultDestination;
    }

    if (shouldAcceptToken) {
        localStorage.setItem('access_token', accessToken);
    }

    windowLocation.assign(destinationUrl);
    return <></>;
};
