import * as PropTypes from 'prop-types';
import React from "react";

function FtpConfigurationCell({data}) {
    const missingFtpConfigurations = data.issues ? data.issues.filter(x => x.issueType === 'missingFtpConfiguration') : [];
    const hasIssue = missingFtpConfigurations.length  > 0;
    const issueClassName = hasIssue ? 'issue-link-color-red' : ''
    const issueText = hasIssue ? `${missingFtpConfigurations.length }` : '';

    return <div>{hasIssue ? <a target="_blank" rel="noopener noreferrer"
                    href={`/ftpConfigurations/?retailerId=${data.retailer.retailerId}`}
                    className={`store-totals-link ${issueClassName}`}
                    title={`Configuration`}>{issueText}</a>
        : ""
    }
    </div>
}

FtpConfigurationCell.propTypes = {
    data: PropTypes.object
}

export default FtpConfigurationCell;