import ButtonLoader from "../../../../utils/components/ButtonLoader";
import FileLink from "../../../IngestionLogs/Columns/FileLink";
import React from "react";
import PropTypes from "prop-types";

const ArchiveFilenamesList = ({archiveFileList, retailerId}) => <>{
    archiveFileList.map(archiveFile => {
        const {filename, blobName} = archiveFile;
        const fullArchiveFileLocation = `gs://spins-edp-${process.env.REACT_APP_ENV}-archive/p_data=sales/p_retailer_id=${retailerId}/p_ftp=*/${filename}`;
        return <div className={'archive-file-name-modal'} key={blobName}>
            <div>
                <ButtonLoader buttonTitle={'Ignore'}
                              url={`${process.env.REACT_APP_EDP_API_BASE_URL}/ignoreFile/?archiveLocation=${encodeURIComponent(fullArchiveFileLocation)}`}
                              toastTextSuccess={`${filename} has successfully been ignored`}
                              toastTextFailure={`${filename} has failed to be ignored.`}
                />
            </div>
            <div className={'file-name'}>
                <FileLink
                    gcsFileLocation={`gs://spins-edp-${process.env.REACT_APP_ENV}-archive/${blobName}`}
                    displayText={filename}/>
            </div>
        </div>
    })
}</>;

ArchiveFilenamesList.propTypes = {
    archiveFileList: PropTypes.arrayOf(PropTypes.object),
    retailerId: PropTypes.number
};

export default ArchiveFilenamesList;
