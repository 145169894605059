export const setPendoVisitor = (metadata) => {
    // eslint-disable-next-line no-undef
    if (!window.pendo) {
        return
    }
    // eslint-disable-next-line no-undef
    pendo.initialize({
        visitor: {
            id: metadata.visitorId,
            fname: metadata.visitorFName,
            lname: metadata.visitorLName,
            email: metadata.visitorEmail,
            internal: metadata.visitorInternal
        },
        account: {
            id: metadata.companyId,
            name: metadata.companyName,
            type: metadata.companyType,
            compMarket: metadata.companyMarket,
            marketDes: metadata.companyMarketDes
        },
    })
}