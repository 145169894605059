import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import {getDollarsFormat} from "./SummaryDisplayFunctions";

function YagoDollarsModal({closeModal, weekEndData}) {
    const dollarsFormatted = weekEndData.yagoDollars ? getDollarsFormat(weekEndData.yagoDollars) : "NO YAGO FOUND";
    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="Modal"
        >
            <div data-testid={'yago-dollars-modal'}>
                <h2>{`Year Ago Dollars`}</h2>
                <div>{dollarsFormatted}</div>
                <div>
                    <button className="hollow button secondary modal-button archive-filename-close" type="button"
                            name="close"
                            onClick={() => closeModal()}>
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
}

YagoDollarsModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    weekEndData: PropTypes.object,
};

export default YagoDollarsModal;