import React from 'react';
import PropTypes from 'prop-types';
import {convertLabelTextToDataTestId} from "../../utils/testHelpers/assertUtils";

const BaseOutlierInputOnChange = ({id, defaultValue, labelText, onChange}) => {
    return <div className={'id-input'}>
        <span className='input-group-label'>{labelText}</span>
        <input
            id={id}
            data-testid={convertLabelTextToDataTestId(id,"base-outlier-input")}
            className={'input-group-field'}
            type="date"
            onChange={onChange}
            defaultValue={defaultValue}
        />
    </div>;
};


BaseOutlierInputOnChange.propTypes = {
    id: PropTypes.string,
    defaultValue: PropTypes.string,
    labelText: PropTypes.string,
    onChange: PropTypes.func
};

export default BaseOutlierInputOnChange;