import React from 'react';
import PropTypes from 'prop-types';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {checkForDuplicateStoresForm} from "../Services/serviceUtils";
import Page from "../Components/Page";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormBooleanDropdownInputField, EditFormNumberInputField} from "../Components/ReactTable/EditRecordForm";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {getRetailerName} from "../../utils/retailerMapCache";
import {sortAscendingWithNullsFirst} from "../../utils/sorting";

const SkipStores = ({displayHelmetHeader, retailerId}) => {

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/skipStores/?add_retailer_column=true${retailerId ? `&retailerId=${retailerId}` : ''}`
    const endpointUrlForPost = `${process.env.REACT_APP_EDP_API_BASE_URL}/skipStores/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl, endpointUrlForPost});

    const columns = [
        {
            header: 'Retailer',
            id: 'retailerIdName',
            filterable: true,
            accessorKey: 'retailerId',
            size: 200,
            cell: ({row}) => <RetailerIdAndName retailerId={row.original?.Retailer?.retailerId}/>,
            filterFn: (row, columnIds, filterValue) => {
                const retailerId = row.original?.Retailer?.retailerId;
                return !filterValue || Boolean(`${retailerId} ${getRetailerName(retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()));
            },
            sortingFn: (a, b) => {
                const valA = a.original?.Retailer?.retailerId;
                const valB = b.original?.Retailer?.retailerId;
                return sortAscendingWithNullsFirst(valA, valB);
            }
        },
        {header: "Store Id", accessorKey: "storeId"},
        {header: "Skip Fill", accessorKey: "skipFill"},
        {header: "Skip Alerts", accessorKey: "skipAlerts"},
        {header: "Skip Cleanse Fill Spotlight", accessorKey: "skipCleanseFillSpotlight", size: 200,},
        {header: "Skip Store Totals", accessorKey: "skipStoreTotals"},
        {header: "Skip Outlier", accessorKey: "skipOutlier"},
        {header: "Skip Store Insights", accessorKey: "skipStoreInsights"},
    ];

    function validatePreSave({rows, form}) {
        const storeIdValue = form.getValues('storeId');
        const indexValue = form.getValues('index');
        const hasDuplicate =  checkForDuplicateStoresForm(storeIdValue, indexValue, rows)

        if (hasDuplicate) {
            form.setError('storeId', {
                type: 'custom',
                message: [`Store Id ${storeIdValue} already exists in this config`]
            });
        }
        return !hasDuplicate;
    }
    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        delete sanitizedData.Retailer
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Skip Stores"} displayHelmetHeader={displayHelmetHeader}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "Store"}, {label: "Skip"}]}/>}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                initialState={{ pagination: { pageSize: 25 }, sorting: [{ id: "retailerIdName", desc: false }] }}
                editTable={[
                    INDEX_FIELD,
                    {id: 'storeId', Component: args => <EditFormNumberInputField {...args} isRequired />},
                    {id: 'skipFill', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'skipAlerts', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'skipCleanseFillSpotlight', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'skipStoreTotals', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'skipOutlier', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: 'skipStoreInsights', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                ]}
                validatePreSave={validatePreSave}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
                editModalAdditionalClass={"skip-stores-service-edit-modal"}
            />
        </LoadingWrapper>
    </Page>);
};

SkipStores.defaultProps = {
    displayHelmetHeader: true,
    retailerId: undefined
};

SkipStores.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
};

export default SkipStores;