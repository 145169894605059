import React from 'react';
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import Page from "../../Components/Page";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../../Components/ReactTable/common_custom_columns";
import {EditFormNumberInputField, EditFormTextInputField} from "../../Components/ReactTable/EditRecordForm";

const FtpToDataProviderIdDecodePage = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpToDataProviderIdDecode/`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const columns = [
        {header: "Data Provider Id", filterable: true, id: "dataProviderId", accessorKey: "dataProviderId"},
        {header: "FTP Name", filterable: true, id: "ftpName", accessorKey: "ftpName"}
    ];

    function validatePreSave({rows, form}) {
        const dataProviderIdValue = form.getValues('dataProviderId');
        const indexValue = form.getValues('index');
        const rawRecordsFiltered = rows.map(row => row.original).filter(row => row.dataProviderId === dataProviderIdValue);
        const hasDuplicate = rawRecordsFiltered.length > 0 && indexValue !== rawRecordsFiltered[0].index;

        if (hasDuplicate) {
            form.setError('dataProviderId', {
                type: 'custom',
                message: [`Data Provider Id ${dataProviderIdValue} already exists in this config`]
            });
        }

        return !hasDuplicate;
    }

    return (
        <Page name={"FTP To Data Provider Id Decode"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={columns}
                    editTable={[
                        INDEX_FIELD,
                        {id: 'dataProviderId', Component: args => <EditFormNumberInputField {...args} isRequired/>},
                        {id: 'ftpName', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    ]}
                    initialState={{pagination: {pageSize: 10}, sorting: [{id: "dataProviderId", desc: false}]}}
                    validatePreSave={validatePreSave}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
};

export default FtpToDataProviderIdDecodePage;