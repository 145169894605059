import React, {useState} from 'react';
import {makeApiCallPost} from "../../Api/makeApiCall";
import {toast} from "react-toastify";
import {toastError, toastSuccess} from "../../utils/toast";

export const AddSupplementalStore = ({row}) => {
    const [storeId, setStoreId] = useState(undefined);

    return <div className={'id-input'}>
        <input
            className={'table-input-field'}
            type="number"
            placeholder={'Store ID'}
            onChange={({target}) => setStoreId(target.value)}
        />
        <button className="button input-group-button add-unmapped-store-button" type="button"
                disabled={!storeId}
                onClick={async () => {
                    await makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/supplementalStoreUpdate`,
                        {
                            retailerId: row.original.retailerId,
                            storeId: Number(storeId),
                            fileName: row.original.fileName
                        }).then((response) => {
                        response?.apiError ?
                            toastError(toast, `failed to map ${storeId} to ${row.original.fileName}`) :
                            toastSuccess(toast, `${storeId} was successfully mapped to ${row.original.fileName}`)
                    })
                }
                }>
            add
        </button>
    </div>
}
