import React from 'react';
import Page from "../../Components/Page";
import ContinuityTable from "./ContinuityTable";
import MissingDatesCell from "./MissingDatesCell";
import {sortAscendingWithNullsLast} from "../../../utils/sorting";

export const missingDatesForRetailerQuery = `
    query{
        data:storeSalesDateValidation(skipFillChecks: true){
            retailerId
            dates:datesToFill{
                weekEndDate
            }
            storeId
        }
    }
`
export const missingDatesForRetailerDataColumn =
    {
        header: 'Missing Weeks',
        accessorKey: 'dates',
        cell: ({getValue}) => <MissingDatesCell dates={getValue().map(item => item.weekEndDate)}/>,
        minSize: 100,
        enableColumnFilter: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
            const datesA = rowA.original?.dates;
            const datesB = rowB.original?.dates;
            const numDatesA = datesA ? datesA.length : 0;
            const numDatesB = datesB ? datesB.length : 0;
            return sortAscendingWithNullsLast(numDatesA, numDatesB);
        }
    }

const MissingWeeksPage = () => {
    return (
        <Page name="Missing Weeks">
            <ContinuityTable query={missingDatesForRetailerQuery} dataColumns={[missingDatesForRetailerDataColumn]}/>
        </Page>
    );
};
export default MissingWeeksPage;