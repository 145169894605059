import React from "react";
import ReactTable from "../../Components/ReactTable";
import { SELECT_COLUMN } from "../../Components/ReactTable/CheckboxColumnComponent";
import { DESIGNATION_COLUMNS } from "./designationColumns";

const DesignationTable = ({children, designations}) => {
    return (
        <ReactTable
            filterable={true}
            data={designations}
            columns={[SELECT_COLUMN].concat(DESIGNATION_COLUMNS)}
        >
            {children}
        </ReactTable>
    )
}

export default DesignationTable;
