import React from "react";
import * as PropTypes from "prop-types";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import {EditFormNumberInputField, EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {setValuesToNullIfEmptyOrUndefined} from "../Components/ReactTable/helpers";

export function SupplementalStoresTable({displayHelmetHeader, retailerId}) {

    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/supplementalStores/${retailerId ? `?retailerId=${retailerId}` : ''}`
    const endpointUrlForPost =`${process.env.REACT_APP_EDP_API_BASE_URL}/supplementalStores/`
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl, endpointUrlForPost });

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "External Store Id", accessorKey: "externalStoreId"},
        {header: "Store Id", accessorKey: "storeId"},
        {header: "Retailer Id Displayed", accessorKey: "retailerIdDisplayed"},
    ];
    function onRowEditConfirmedWrapper(newOrUpdatedRow) {
        const sanitizedData = setValuesToNullIfEmptyOrUndefined(newOrUpdatedRow);
        return onRowEditConfirmed(sanitizedData);
    }

    return (<Page name={"Supplemental Store Id Config"} displayHelmetHeader={displayHelmetHeader}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: 'externalStoreId', Component: args => <EditFormTextInputField {...args} isRequired />},
                    {id: 'storeId', Component: args => <EditFormNumberInputField {...args} isRequired />},
                    {id: 'retailerIdDisplayed', Component: args => <EditFormNumberInputField {...args} />}
                ]}
                onRowEditConfirmed={onRowEditConfirmedWrapper}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}


SupplementalStoresTable.propTypes = {
    retailerId: PropTypes.number,
    displayHelmetHeader: PropTypes.bool.isRequired
};
