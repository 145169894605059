import React, {useEffect, useState} from 'react';
import {getExpirationFromToken} from "../accessTokenValidator";
import NameValueLabels from "../../Dashboard/Components/NameValueLabels";
import moment from "moment";
import LogoutModal from "./LogoutModal";
import PropTypes from "prop-types";

function getMillisecondsToSeconds(endTime) {
    return endTime * 1000;
}

const TokenExpirationTimer = ({tokenAccessor, labelValue, onTimerExpiration, sessionType}) => {
    const expirationFromTokenInMilliseconds = getExpirationFromToken(window.localStorage.getItem(tokenAccessor));

    const [currentDate, setDate] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setDate(Date.now()), 1000);
        return () => clearInterval(interval);
    }, []);

    const expirationTimeInSeconds = new Date(getMillisecondsToSeconds(expirationFromTokenInMilliseconds));
    const endTime = moment(expirationTimeInSeconds).diff(currentDate, 'seconds');
    const hoursMinutesSeconds = new Date(getMillisecondsToSeconds(endTime)).toISOString().substr(11, 8);
    return <>
        {endTime <= 0 ?
            <LogoutModal onClose={onTimerExpiration} sessionType={sessionType}/> :
            <NameValueLabels
                labelValuePairs={[{label: labelValue, value: hoursMinutesSeconds}]}
            /> }
    </>;
};

TokenExpirationTimer.propTypes = {
    labelValue: PropTypes.string.isRequired,
    tokenAccessor: PropTypes.string.isRequired,
    sessionType: PropTypes.string.isRequired,
    onTimerExpiration: PropTypes.func.isRequired
};

export default TokenExpirationTimer;