import ButtonLoader from "../../utils/components/ButtonLoader";
import {makeApiCallPost} from "../../Api/makeApiCall";
import {getUserEmail} from "../../auth/accessTokenValidator";
import React from "react";
import PropTypes from "prop-types";

export const RerunCleanseButton = ({retailerId}) =>
    <ButtonLoader buttonTitle={"Rerun Cleanse"}
                  buttonHasModal
                  axiosFunction={makeApiCallPost}
                  postBody={{triggered_by: getUserEmail()}}
                  modalHeader={`Rerun Cleanse: Retailer ${retailerId}`}
                  modalText={`Are you sure you want to rerun cleanse for retailer ${retailerId}?`}
                  url={`${process.env.REACT_APP_EDP_API_BASE_URL}/rerunCleanse?retailerId=${retailerId}`}
                  toastTextFailure={`Rerun Cleanse for Retailer [${retailerId}] has failed to kicked off.`}
                  toastTextSuccess={`Rerun Cleanse for Retailer [${retailerId}] has successfully kicked off.`}
    />;

RerunCleanseButton.propTypes = {retailerId: PropTypes.number.isRequired};