import React from "react";
import PropTypes from "prop-types";
import {convertLabelTextToDataTestId} from "../../utils/testHelpers/assertUtils";

function NameValueLabels(props) {
    return (
        <div className={`name-value-labels ${props.containerClassName}`} data-testid={'name-value-labels'}>
            {props
                .labelValuePairs
                .map((pair, index) => {
                        return (
                            <div key={`${index}-${pair.label}`} className={'name-value-label'}>
                                <div className={'name-value-label-label'} data-testid={convertLabelTextToDataTestId(pair.label, "name-value-label")}>
                                    {pair.label}
                                </div>
                                <div className={'name-value-value'}
                                     data-testid={ convertLabelTextToDataTestId(pair.label, "name-value-value")}>
                                    {pair.value || ''}
                                </div>
                            </div>
                        );
                    }
                )}
        </div>
    );
}

NameValueLabels.defaultProps = {
    containerClassName: ''
}

NameValueLabels.propTypes = {
    containerClassName: PropTypes.string,
    labelValuePairs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any
        })
    ).isRequired
};

export default NameValueLabels;