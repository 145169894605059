import React from "react";
import {forceLogout} from "../url";

export const Unauthorized = () => {
    return (
    <div>
    <div>UNAUTHORIZED</div>
        <button className="hollow button secondary update-button logout"
                onClick={forceLogout}>
            Logout
        </button>
    </div>)
}