import _ from "lodash";

const flattenLogDataForCsv = (data) => {
    return data
        .map((dagRun) => {
            return dagRun.details.map((detail) => {
                return {
                  ...(_.omit(dagRun, 'details')),
                  ...detail
                };
            });
        })
        .flat();
};

export default flattenLogDataForCsv;