import React, {useEffect, useState} from 'react';
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import ReactTable from "../Components/ReactTable";
import {convertDateTime, getNicelyFormattedNumber} from "../../utils/formatUtil";
import ReactTableFilterDropdown from "../ReactTable/ReactTableFilterDropdown";
import _ from "lodash";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import Checkbox from "../Components/Checkbox";
import {getBooleanParamOrDefault} from "../../utils/urlBuilder";
import {makeApiCall} from "../../Api/makeApiCall";
import "../../scss/_circanaDataLoad.scss"
import {ConventionalUnloadTriggerButton} from './ConventionalUnloadTriggerButton';
import {CircanaLoadStatus} from "./CircanaLoadStatus";

export const CircanaDataLoadStatuses = {
    RECEIVED: 'Received',
    IN_PROGRESS: 'In progress',
    COMPLETED: 'Completed',
    FAILED: 'Failed'
}

const CircanaDataLoad = () => {

    const dateDisplayFormat = 'YYYY/MM/DD kk:mm:ss';
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/conventional/unload/notifications`;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const defaultState = {
        includeReceived: getBooleanParamOrDefault('includeReceived', true),
        includeInProgress: getBooleanParamOrDefault('includeInProgress', true),
        includeCompleted: getBooleanParamOrDefault('includeCompleted', true),
        includeFailed: getBooleanParamOrDefault('includeFailed', true),
    }

    const [includeReceived, updateIncludeReceived] = useState(defaultState['includeReceived']);
    const [includeInProgress, updateIncludeInProgress] = useState(defaultState['includeInProgress']);
    const [includeCompleted, updateIncludeCompleted] = useState(defaultState['includeCompleted']);
    const [includeFailed, updateIncludeFailed] = useState(defaultState['includeFailed']);


    async function loadData() {
        setLoading(true);
        const result = await makeApiCall(endpointUrl);
        setData(result.data);
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    const filterStatuses = [
        {status: CircanaDataLoadStatuses.RECEIVED, isIncluded: includeReceived},
        {status: CircanaDataLoadStatuses.IN_PROGRESS, isIncluded: includeInProgress},
        {status: CircanaDataLoadStatuses.COMPLETED, isIncluded: includeCompleted},
        {status: CircanaDataLoadStatuses.FAILED, isIncluded: includeFailed}
    ]

    const filteredData = data.filter(x => filterStatuses.some(({
                                                                   status,
                                                                   isIncluded
                                                               }) => isIncluded && status === x.status));

    const customColumns = [
        {
            header: "Load End Time",
            accessorKey: "load_end_timestamp",
            cell: ({getValue}) => convertDateTime(getValue(), dateDisplayFormat)
        },
        {
            header: "Table Name",
            accessorKey: "table_name",
            filter: (theseProps) => {
                const uniqueOptions = _.uniqBy(
                    theseProps.table.getCoreRowModel().rows.map(row => row.original.table_name).sort()
                ).map(option => ({label: option, value: option}));

                return <ReactTableFilterDropdown
                    id={"tableName"}
                    options={uniqueOptions}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                    isClearable={true}
                />
            }
        },
        {
            header: "Deliverable",
            accessorKey: "deliverable",
            filter: (theseProps) => {
                const uniqueOptions = _.uniqBy(
                    theseProps.table.getCoreRowModel().rows.map(row => row.original.deliverable).sort()
                ).map(option => ({label: option, value: option}));

                return <ReactTableFilterDropdown
                    id={"deliverable"}
                    options={uniqueOptions}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                    isClearable={true}
                />
            }
        },
        {
            header: "Schedule",
            accessorKey: "schedule",
            filter: (theseProps) => {
                const uniqueOptions = _.uniqBy(
                    theseProps.table.getCoreRowModel().rows.map(row => row.original.schedule).sort()
                ).map(option => ({label: option, value: option}));

                return <ReactTableFilterDropdown
                    id={"schedule"}
                    options={uniqueOptions}
                    onChange={(selected) => theseProps.column.setFilterValue(selected)}
                    selectedValue={theseProps.column.getFilterValue()}
                    isClearable={true}
                />
            }
        },
        {
            header: "Rows Inserted",
            accessorKey: "number_of_rows_inserted",
            className: 'numeric-field',
            cell: ({getValue}) => getNicelyFormattedNumber(getValue()),
            getCanFilter: false
        },
        {
            header: "Rows Updated",
            accessorKey: "number_of_rows_updated",
            className: 'numeric-field',
            cell: ({getValue}) => getNicelyFormattedNumber(getValue()),
            getCanFilter: false
        },
        {
            header: "Rows Deleted",
            accessorKey: "number_of_rows_deleted",
            className: 'numeric-field',
            cell: ({getValue}) => getNicelyFormattedNumber(getValue()),
            getCanFilter: false
        },
        {
            header: "New Prod Row Count",
            accessorKey: "new_prod_row_count",
            className: 'numeric-field',
            size: 190,
            cell: ({getValue}) => getNicelyFormattedNumber(getValue()),
            getCanFilter: false
        },
        {
            header: "Schedule Record Count",
            accessorKey: "schedule_record_count",
            className: 'numeric-field',
            size: 190,
            cell: ({getValue}) => getNicelyFormattedNumber(getValue()),
            getCanFilter: false
        },
        {
            header: "Unload Status",
            accessorKey: "status",
            cell: CircanaLoadStatus
        },
        {
            header: "Trigger Unload",
            accessorKey: "triggerUnload",
            enableColumnFilter: false,
            enableSorting: false,
            cell: ({row}) => <ConventionalUnloadTriggerButton row={row}
                                                              onApiCallComplete={loadData}
                                                              onApiCallStart={() => setLoading(true)}/>
        }
    ];
    return (
        <div className={'conventional-data-unload'}>
            <Page name={"Conventional Data Unload"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Circana"}, {label: "Conventional Data Unload"}]}/>}
            >
                <LoadingWrapper loading={loading}>
                    <div className={'circana-data-unload-checkbox-container'}>
                        <span className={'unload-status-title'}>Unload status</span>
                        <div id={'check-boxes'}>
                            <div className={'checkbox-group'}>
                                <div className={'checkbox-checkboxes'}>
                                    <Checkbox labelText={'Received'}
                                              onChange={() => {
                                                  updateIncludeReceived(!includeReceived);
                                              }}
                                              checked={includeReceived}/>
                                    <Checkbox labelText={'In Progress'}
                                              onChange={() => {
                                                  updateIncludeInProgress(!includeInProgress);
                                              }}
                                              checked={includeInProgress}/>
                                    <Checkbox labelText={'Completed'}
                                              onChange={() => {
                                                  updateIncludeCompleted(!includeCompleted);
                                              }}
                                              checked={includeCompleted}/>
                                    <Checkbox labelText={'Failed'}
                                              onChange={() => {
                                                  updateIncludeFailed(!includeFailed);
                                              }}
                                              checked={includeFailed}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactTable
                        filterable
                        data={filteredData}
                        columns={customColumns}
                        initialState={{
                            pagination: {pageSize: 10},
                            sorting: [{id: "load_end_timestamp", desc: true}]
                        }}
                    />
                </LoadingWrapper>
            </Page>
        </div>
    );
};

export default CircanaDataLoad;