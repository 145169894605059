import React from 'react';
import FileLink from "./FileLink";

const IngestionLogsFileLink = ({row, getValue}) => <FileLink displayText={nameDisplay(row.original, getValue())}
                                                          gcsFileLocation={row.original.archiveLocation}/>;

const nameDisplay = (logEntry, fileNameValue) => {
    if (!logEntry.isBulk) {
        return logEntry.uniqueArchiveFileNameDisplay || fileNameValue;
    }
    let details = [`files: ${logEntry.fileCount}`];
    if (logEntry.yearIngested) {
        details.push(`year: ${logEntry.yearIngested}`);
    }
    return `Bulk (${details.join(', ')})`;
}

IngestionLogsFileLink.propTypes = {};

export default IngestionLogsFileLink;