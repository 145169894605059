import ApiGetContainer from "../../Api/ApiGetContainer";
import React from "react";
import * as PropTypes from "prop-types";
import UnmappedStoresFileDetails from "./UnmappedStoresFileDetails";
import {AddSupplementalStore} from "./AddSupplementalStore";
import {IgnoreUnmappedStores} from "./IgnoreUnmappedStores";
import RetailerIdAndName from "../Components/RetailerIdAndName";
import {getRetailerName} from "../../utils/retailerMapCache";

export const columns = [
    {
        header: 'Retailer',
        accessorKey: 'retailerId',
        filterable: true,
        cell: ({row})=> <RetailerIdAndName retailerId={row.original.retailerId}/>,
        minSize: 100,
        filterFn: (row, columnIds, filterValue) => {
            return !filterValue || Boolean(`${row.original.retailerId} ${getRetailerName(row.original.retailerId)?.toLowerCase()}`.includes(filterValue.toLowerCase()));
        }
    },
    {
        header: 'File Name',
        accessorKey: 'fileName',
        minSize: 200
    },
    {
        header: 'Dates',
        accessorKey: 'fileDetails',
        cell: UnmappedStoresFileDetails,
        minSize: 100,
        enableColumnFilter: false
    },
    {
        header: 'Add Mapping',
        cell: AddSupplementalStore,
        minSize: 300
    },
    {
        header: 'Ignore',
        cell: IgnoreUnmappedStores,
        minSize: 100
    }
];


export function UnmappedStoresTable({retailerId}) {

    const endpointBase = `${process.env.REACT_APP_EDP_API_BASE_URL}/unmappedStores/`;
    const endpoint = retailerId ?
        `${endpointBase}?retailerId=` + retailerId :
        `${endpointBase}`;

    return <ApiGetContainer
        columns={columns}
        endpoint={endpoint}
        dataAccessor={"metadata"}
        filterableTable={true}
    />;
}


UnmappedStoresTable.propTypes = {
    retailerId: PropTypes.number
};
