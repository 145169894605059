function mergeFillIntoSales(data) {
    if (!data.fill) {
        return;
    }
    data.fill.stores.forEach(fillStore => {
        let salesStore = data.sales.stores.find(salesStore => salesStore.storeId === fillStore.storeId);
        if (!salesStore) {
            salesStore = {
                ...fillStore,
                weekEndData: []
            };
            data.sales.stores.push(salesStore);
        }

        fillStore.weekEndData.forEach(fillWeekData => {
            const salesWeekData = salesStore.weekEndData.find(salesWeekData => salesWeekData.date === fillWeekData.date);
            if (salesWeekData) {
                salesWeekData.fill = fillWeekData
            } else {
                salesStore.weekEndData.push({
                    date: fillWeekData.date,
                    fill: fillWeekData
                })
            }
        })
    });
}

function updateConfigFlag(data, configName, filledWeeksOnly) {
    data[configName].dates.forEach(record => {
        let foundStore = data.sales.stores.find(store => store.storeId === record.storeId);
        if (foundStore) {
            const weekData = foundStore.weekEndData.find(weekData => weekData.date === record.salesEndDate);
            if (weekData) {
                weekData[configName] = true;
            } else {
                foundStore.weekEndData.push({[configName]: true, date: record.salesEndDate})
            }
        }
    })
}

function mergeConfigsIntoSales(data, filledWeeksOnly) {
    updateConfigFlag(data, 'storeStart', filledWeeksOnly);
    updateConfigFlag(data, 'storeStop', filledWeeksOnly);
    updateConfigFlag(data, 'alwaysFill', filledWeeksOnly);
    updateConfigFlag(data, 'doNotFill', filledWeeksOnly);
}

function mergeErrors(data) {
    return ['sales', 'fill', 'outliers']
        .map(key => {
            if (data[key]) {
                return data[key].errors || [];
            }
            return [];
        })
        .flatMap(x => x);
}

function mergeStores(data) {
    data.stores.forEach(store => {
        const possiblyFoundStore = data.sales.stores.find(storeWithSales => storeWithSales.storeId === store.storeId);
        if(possiblyFoundStore) {
            possiblyFoundStore.closedDate = store.closedDate
        } else {
            data.sales.stores.push({...store, weekEndData:[]})
        }
    })
}

function mergeStoreClosures(data) {
    data.storeClosures.forEach(storeClosure => {
        data.sales.stores
            .filter(storeWithSales => storeWithSales.storeId === storeClosure.storeId)
            .forEach(storeWithSales => {
                const storeClosures = (storeWithSales.storeClosures || []);
                storeClosures.push(storeClosure);
                storeWithSales.storeClosures = storeClosures;
            });
    })
}

export function dataPreProcess(data) {
    mergeStores(data);
    mergeFillIntoSales(data);
    mergeConfigsIntoSales(data, false);
    mergeStoreClosures(data);
    return {stores: data.sales.stores, errors: mergeErrors(data)};
}
export function dataPreProcessFilledDatesOnly(data) {
    data.sales.rollUpDates = data.fill.weekEndDates
    data.fill.rollUpDates = data.fill.weekEndDates
    mergeStores(data);
    mergeFillIntoSales(data);
    mergeConfigsIntoSales(data, true);
    mergeStoreClosures(data);
    return {stores: data.sales.stores, errors: mergeErrors(data)};
}