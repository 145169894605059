import React from 'react';
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";

const AsinToUpc = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/asinToSpinsUpc/`
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl });

    const columns = [
        {header: "Asin", accessorKey: "asin"},
        {header: "Spins Upc", accessorKey: "spinsUpc"}
    ];

    return (<Page name={"ASIN to SPINS UPC"}>
        <LoadingWrapper loading={loading}>
            <EditableReactTableV2
                filterable
                data={data.data}
                columns={columns}
                editTable={[
                    INDEX_FIELD,
                    {id: 'asin', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    {id: 'spinsUpc', Component: args => <EditFormTextInputField {...args} isRequired/>}
                ]}
                onRowEditConfirmed={onRowEditConfirmed}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
};


export default AsinToUpc;