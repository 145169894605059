import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./Api/makeApolloApiCall";
import packageJson from "../package.json";
import Dashboard from "./Dashboard/Dashboard";
import {APIProvider} from '@vis.gl/react-google-maps';


const date = new Date(packageJson.buildDate).toLocaleString();
console.log("=============> Build Date: ", JSON.stringify(date));

ReactDOM.render( <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}><ApolloProvider client={apolloClient}><BrowserRouter><Dashboard/></BrowserRouter></ApolloProvider></APIProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
