import recommendedMarketDesignation from "./RecommendedMarketDesignation.js";
import partOfUniverse from "./PartOfUniverse.js";

export const retailerCompositionTabColumns = (companyType, marketDesignation, storeCount) => [
    {
        header: 'Id',
        id: 1,
        accessorKey: 'retailerId'
    },
    {
        header: 'Average Acv',
        id: 2,
        accessorKey: 'averageSalesAcv',
        cell: ({getValue}) => `${getValue().toFixed(2)}`
    },
    {
        header: 'Average Departments With Sales',
        id: 3,
        accessorKey: 'averageDepartmentsWithSales',
        cell: ({getValue}) => `${getValue().toFixed(2)}`
    },
    {
        header: 'Natural And Specialty Percentage',
        id: 4,
        accessorKey: 'naturalAndSpecialtySalesPercentage',
        cell: ({getValue}) => `${getValue().toFixed(2)}%`
    },
    {
        header: 'Natural Percentage',
        id: 5,
        accessorKey: 'naturalSalesPercentage',
        cell: ({getValue}) => `${getValue().toFixed(2)}%`
    },
    {
        header: 'Herbs & Homeopathic + Vitamins & Supplements Sales Percentage',
        id: 6,
        accessorKey: 'vitaminsAndSupplementsDepartmentsSalesPercentage',
        cell: ({getValue}) => `${getValue().toFixed(2)}%`
    },
    {
        header: 'Recommended Market Designation',
        id: 7,
        accessorFn: (row) => recommendedMarketDesignation(row, companyType, marketDesignation)
    },
    {
        header: 'Part Of Universe',
        id: 8,
        accessorFn: (row) => partOfUniverse(row, storeCount, marketDesignation, 'retailer')
    },
]