import {checkApiResults} from "./errors";
import {parseGeocodedAddress} from "./address";

export const geocodeSearch = (request, geocodingLib) => {
    return new Promise((resolve) => {
        const geocoder = new geocodingLib.Geocoder()
        geocoder.geocode(request, (results, status) => {
            resolve(checkApiResults(results, status)?.map(parseGeocodedAddress))
        })
    });
}
