import React, {useState} from 'react';
import NameValueLabels from "../Components/NameValueLabels";

const IngestionLogsPageKey = () => {
    const [inHover, setHover] = useState(false);
    return <div data-testid={"tooltip-icon"}
                className={"fas fa-key tooltip-icon ingestion-logs-page-key"}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
        <h6 className={"ingestion-logs-page-key-label"}>Ingestion Statuses Key</h6>
        {inHover && <div className={'tooltip-contents'}>
            <NameValueLabels
                containerClassName={'ingestion-logs-page-key-list'}
                labelValuePairs={[
                    {label: "", value: <div data-testid={"key_1"} className={'ingestion-status-color left-dotted-border ingestion-logs-key-border'}><div data-testid={"key_cell_1"} role ='cell' className={''}> Has failures; dag completed (data created)</div></div>},
                    {label: "", value: <div data-testid={"key_2"} className={'ingestion-status-color left-gutter-alert ingestion-logs-key-border'}><div data-testid={"key_cell_2"} role ='cell' className={''}> Has failures; dag did not complete (no data created)</div></div>},
                    {label: "", value: <div data-testid={"key_3"} className={'ingestion-status-color left-gutter-success ingestion-logs-key-border'}><div data-testid={"key_cell_3"} role ='cell' className={''}> No failures; dag completed (data created)</div></div>},
                    {label: "", value: <div data-testid={"key_4"} className={'ingestion-status-color ingestion-logs-key-border'}><div data-testid={"key_cell_4"} role ='cell' className={''}> In progress, no errors so far</div></div>},
                    {label: "", value: <div data-testid={"key_5"} className={'ingestion-status-color show-onboarding ingestion-logs-key-border'}><div data-testid={"key_cell_5"} role ='cell' className={''}> Onboarding</div></div>}
                ]}/>
        </div>}
    </div>
        ;
};

IngestionLogsPageKey.defaultProps = {};

IngestionLogsPageKey.propTypes = {};

export default IngestionLogsPageKey;