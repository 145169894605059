import React from 'react';
import PropTypes from 'prop-types';
import {getRetailerName} from "../../utils/retailerMapCache";
import {retailerDisplay} from "../../utils/retailerDisplayFormatter";

const RetailerIdAndName = ({retailerId}) => {
    const href = retailerId ? `/retailers/${retailerId}` : '';
    const retailerName = retailerId && getRetailerName(retailerId)
    const displayText = retailerName ? retailerDisplay(retailerId, retailerName) : retailerId ? retailerId.toString() : ''
    return <a href={href} target="_blank" rel="noopener noreferrer">{displayText}</a>
};

RetailerIdAndName.defaultProps = {};

RetailerIdAndName.propTypes = {
    retailerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default RetailerIdAndName;