import React, {useState} from 'react'
import {makeApiCall} from '../../Api/makeApiCall';
import PropTypes from "prop-types";

export const GcsLinkIfExists = ({url, children}) => {
    const [showLink, updateShowLink] = useState(false)

    React.useEffect(() => {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/subdirectoryCount?url=gs://${url}`)
            .then(result => updateShowLink(result.count > 0));
    }, [url])

    if (!showLink) {
        return <span>{children}</span>
    }

    return <a target={'_blank'}
              href={`https://console.cloud.google.com/storage/browser/${url}`}
              rel={'noopener noreferrer'}>
        {children}
    </a>;
}

GcsLinkIfExists.propTypes = {
    url: PropTypes.string,
    children: PropTypes.any,
}
