import PropTypes from "prop-types";
import React, {useState} from "react";
import {makeApiCall} from "../../Api/makeApiCall";
import moment from "moment";
import UnorderedListItems from "../../utils/UnorderedListItems";
import _ from "lodash";
import RetailerIdAndName from "../Components/RetailerIdAndName";

function extractRunInfo(data) {
    const runsByRetailer = data.metadata.reduce((accum, run) => {
        const retailerId = run.retailerId;
        const triggeredBy = run.triggeredBy;
        if (accum[retailerId]) {
            const triggeredByCount = accum[retailerId][triggeredBy];
            accum[retailerId][triggeredBy] = triggeredByCount ? triggeredByCount + 1 : 1;
        } else {
            accum[retailerId] = {[triggeredBy]: 1}
        }
        return accum;
    }, {});
    return Object.entries(runsByRetailer)
        .sort((a, b) => (Number(a[0]) > Number(b[0])) ? 1 : -1)
        .map(([retailerId, triggeredBy]) => {
            const triggeredRuns = Object.entries(triggeredBy)
                .map(([person, times]) => `${person} (${times})`)
                .join(', ');
            return <div><RetailerIdAndName retailerId={retailerId}/>: {triggeredRuns}</div>
        });
}

export function PeriodReleaseWeek({week, title}) {
    const [bulkLoads, updateBulkLoads] = useState(undefined)
    React.useEffect(() => {
        updateBulkLoads(undefined);
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ingestionLogs/`;
        const parameters = {
            includeSuccess: true,
            includeShortCircuit: true,
            includeBulkOnly: true,
            includeCurrentlyRunning: true,
            days: 7,
            startDate: moment(week)
        };
        makeApiCall(endpointUrl, parameters)
            .then(data => {
                const records = extractRunInfo(data);
                updateBulkLoads(records)
            });
    }, [week])

    return <>
        {bulkLoads === undefined ?<><i className="fas fa-carrot fa-spin fa-500px spinner" aria-hidden="true"></i> Loading...</>:
            <>
                {title}{week}
                {!_.isEmpty(bulkLoads) && <UnorderedListItems listItems={bulkLoads} title={'Bulk Loads: '}/>}
            </>}
    </>;

}

export default PeriodReleaseWeek;

PeriodReleaseWeek.defaultProps = {
    title: ''
}

PeriodReleaseWeek.propTypes = {
    title: PropTypes.string,
    week: PropTypes.string
};