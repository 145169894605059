import React, {useState} from 'react';
import Page from '../Components/Page';
import ButtonLoader from '../../utils/components/ButtonLoader';
import {makeApiCall, makeApiCallPost} from '../../Api/makeApiCall';
import {createDagRunConf} from "../../Api/dagRunConf";

const System = () => {
    const purgeDatePath = encodeURIComponent(`gs://spins-edp-${process.env.REACT_APP_ENV}-services/purgeDate/purgeDate.json`);
    const [deleteDataUpToDate, setDeleteDataUpToDate] = useState('');
    const [safetyDate, setSafetyDate] = useState(undefined);

    const writeOutPurgeDate = () => {
            return makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath?path=${purgeDatePath}`,
                {
                    purgeDate: deleteDataUpToDate
                })
    }


    React.useEffect(() => {
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/safetyDate`)
            .then(result => setSafetyDate(result.safetyDate));
        makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/getGcsFileByPath?path=${purgeDatePath}`)
            .then(result => setDeleteDataUpToDate(result.purgeDate));
    }, [purgeDatePath]);

    return <Page name={"Purge Date"}>
        <div className={'purge-date-page'}>
            <div id={'safety-date'}>Safety Date: {safetyDate}</div>
            <br/>
            <input
                className='purge-date-input'
                type='date'
                value={deleteDataUpToDate}
                onChange={(event => setDeleteDataUpToDate(event.target.value))}
            />
            <ButtonLoader buttonTitle={"Purge All Data On and Before This Date"}
                          axiosFunction={makeApiCallPost}
                          postBody={{
                              dagName: "delete_data_up_to_date",
                              data: createDagRunConf({up_to_date_to_delete: deleteDataUpToDate})
                          }}
                          onSubmitCallback={writeOutPurgeDate}
                          buttonClassName={"button alert submit"}
                          url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
                          buttonHasModal
                          userAcknowledgedCheck
                          modalHeader={"Purge All Data On and Before This Date"}
                          modalText={`Are you sure you want to purge all data on and before ${deleteDataUpToDate}?`}
                          toastTextFailure={`delete_data_up_to_date has failed to kicked off.`}
                          toastTextSuccess={`delete_data_up_to_date has successfully kicked off with purge date ${deleteDataUpToDate}`}
                          disabled={!deleteDataUpToDate || deleteDataUpToDate >= safetyDate}
            />
        </div>
    </Page>;
};

export default System;