import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import _ from "lodash";
import {SELECT_COLUMN} from "../../Components/ReactTable/CheckboxColumnComponent";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import ReactTable from "../../Components/ReactTable";
import {getNicelyFormattedNumber} from "../../../utils/formatUtil";
import checkCircle from "../../../assets/check-circle.svg";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {redirectWithMessage} from "../../../utils/redirectMessage";

const CellsToMarketsAssignMarket = () => {

    const params = useParams();
    const cellId = parseInt(params.cellId);

    const marketsEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/markets`;
    const cellsToMarketEndpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToMarkets?cell_id=${cellId}`;

    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        setLoading(true)
        Promise.all([
            makeApiCall(marketsEndpointUrl),
            makeApiCall(cellsToMarketEndpointUrl)
        ])
            .then(([marketResults, cellsToMarket]) => {
                setLoading(false)
                const filteredCells = cellsToMarket.data.filter(cell => cell.cell_id === cellId);
                const marketLookup = _.keyBy(filteredCells, 'market_id');
                const mappedMarkets = marketResults.data
                    .filter(market => !marketLookup[market.index])
                    .map((result) => {
                        return {
                            marketId: result.index,
                            marketTag: result.market_key,
                            description: result.description
                        }
                    })
                setTableData(mappedMarkets)
            })
    }, []);

    function submitSelectedMarket(selectedRows) {
        const marketsToAdd = selectedRows.map(x => x.original.marketId);
        const apiPost = {marketIds: marketsToAdd, cellId};

        makeApiCallPost(`${process.env.REACT_APP_EDP_API_BASE_URL}/cellsToMarkets/addMarket`, apiPost).then(() => {
            const message = `Successfully assigned ${marketsToAdd.length} market(s) to Cell ${cellId}`;
             redirectWithMessage(message, `/cellsToMarkets/${cellId}`);
        });
    }

    //ToDo: maybe add other market columns that could be useful?
    const columns = [
        SELECT_COLUMN,
        {
            header: "Market ID",
            id: 'marketId',
            accessorKey: 'marketId',
            maxWidth: 200
        },
        {
            header: "Market Tag",
            id: "marketTag",
            accessorKey: 'marketTag',
            maxWidth: 200
        },
        {
            header: "Description",
            id: "description",
            accessorKey: 'description'
        }]

    return <>
        <LoadingWrapper loading={loading}>
        <Page name={`Assign market to cell ${cellId}`}
              breadCrumbs={<Breadcrumbs items={[
                  {label: "Services"},
                  {label: "Cells", to: "/cellsService"},
                  {label: "Cells to Markets", to: `/cellsToMarkets/${cellId}`},
                  {label: `${cellId}`}
              ]}/>}
        >
            <ReactTable
                filterable={true}
                data={tableData}
                columns={columns}
                initialState={{pagination: {pageSize: 50}}}
            >
                {
                    ({table}) => {
                        const totalRows = getNicelyFormattedNumber(table.getFilteredRowModel().rows.length);
                        const selectedRows = table.getSelectedRowModel().rows;
                        const isAllRowsSelected = table.getIsAllRowsSelected();
                        let selectAllButtonText = `Select all ${totalRows} markets`;
                        const allRowsSelectedText = `${totalRows} markets selected`
                        return (
                            <div className={'designation-action-buttons-container'}
                                 data-testid={"action-button-container"}>
                                {isAllRowsSelected ?
                                    <>
                                        <img alt={'status'} className={'success-image'} src={checkCircle}/>
                                        <div data-testid={'all-markets-selected'}>{allRowsSelectedText}</div>
                                    </>
                                    :
                                    <button data-testid={'select-all-markets'} type={"button"}
                                            className={"button tertiary"}
                                            onClick={() => table.toggleAllRowsSelected()}
                                    >{selectAllButtonText}</button>}
                                <button disabled={selectedRows.length === 0} type={"button"}
                                        className={"button primary"}
                                        onClick={() => submitSelectedMarket(selectedRows)}
                                    >
                                    Assign market
                                </button>
                            </div>
                        );
                    }
                }
            </ReactTable>
        </Page>
    </LoadingWrapper></>

}

export default CellsToMarketsAssignMarket