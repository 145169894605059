import React from 'react';
import PropTypes from 'prop-types';
import {convertGsutilPathToBrowserGcsUrl} from "../../../utils/gcsUtils";

const FileLink = ({gcsFileLocation, displayText}) => {
    return <>
        <span className='number'>
            <a target='_blank'
               rel="noopener noreferrer"
               href={convertGsutilPathToBrowserGcsUrl(gcsFileLocation)}
            >
                {displayText}
            </a>
        </span>
        <br/>
    </>
};

FileLink.propTypes = {
    gcsFileLocation: PropTypes.string,
    displayText: PropTypes.string
};

export default FileLink;