import ReactTable from "../Components/ReactTable";
import React from "react";

function parseDollar(val) {
    return parseFloat(val.replace('$', ''));
}

const BucketPrice = ({ header, fileContents, filePath }) => {
    const data = fileContents.subdirs;
    return (
        <>
            <h2 id={filePath}>{header}</h2>
            <ReactTable
                data={data}
                filterable
                columns={[
                    { header: "Path", accessorKey: "path" },
                    { header: "Size", accessorKey: "size", sortingFn: (rowA, rowB, col) => parseInt(rowA.getValue(col)) - parseInt(rowB.getValue(col)) },
                    { header: "Annual Cost", accessorKey: "annualCost", sortingFn: (rowA, rowB, col) => parseDollar(rowA.getValue(col)) - parseDollar(rowB.getValue(col)) }
                ]}
                initialState={{sorting: [{id: 'annualCost', desc: true}], pagination: {pageSize: 10}}}
            />
        </>
    );
};

export default BucketPrice;