import React from 'react';
import PropTypes from 'prop-types';
import chevronDown from '../../../assets/chevron-down.svg';
import chevronRight from '../../../assets/right-thin-chevron-svgrepo-com.svg';
import {getUserTypeFromLocalToken} from '../../../auth/accessTokenValidator';
import {filterShouldShowNavigationLink} from "../../../utils/navigationUtils";
import HeadDropdownSubMenu from "./HeadDropdownSubMenu";

export const SEPARATOR = '-';

const HeaderDropdown = ({title, menuItems}) => {
    const userType = getUserTypeFromLocalToken()

    const filteredMenuItems = menuItems
        .filter(item => filterShouldShowNavigationLink(item, userType))
    ;

    if (filteredMenuItems.filter(item => item !== SEPARATOR).length === 0) {
        return null;
    }

    return <li className={"columns small-1"}>
        <button className={"dropdown-menu-link-small"} data-testid={`${title}-nav-title`}>
            {title}
            <img alt={'chevron'} className={'chevron-down'} src={chevronDown}/>
        </button>
        <ul className="menu vertical ingest-status-list" data-testid={'menu-items'}>
            {filteredMenuItems
                .map((menuItem, i) =>
                    menuItem === SEPARATOR ?
                        <li key={`${menuItem}-${i}`}><div className="separator"/></li> :
                        <li key={menuItem.name} className={"columns small-1"}>
                            <a href={menuItem.url} id={`${menuItem.name}-dropdown`}
                               data-testid={`${title}-${menuItem.name}-dropdown`} className={"dropdown-menu-link-large"}>
                                <div className={'dropdown-menu-link-container'}>
                                    {menuItem.name}
                                    {menuItem.additionalContent && menuItem.additionalContent}
                                    {menuItem?.nestedContent && <img alt={'chevron'} className={'chevron-down'} src={chevronRight}/>}
                                </div>
                            </a>
                            {menuItem?.nestedContent && <HeadDropdownSubMenu
                                menuItems={menuItem.nestedContent.menuItems}
                                id={menuItem.nestedContent.id} />}
                        </li>
                )}
        </ul>
    </li>
};

HeaderDropdown.defaultProps = {
    nestedContent: undefined
}

HeaderDropdown.propTypes = {
    title: PropTypes.string,
    menuItems: PropTypes.arrayOf(
        PropTypes.oneOfType(
            [
                PropTypes.string,
                PropTypes.shape({
                    name: PropTypes.string,
                    url: PropTypes.string,
                    additionalContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
                    nestedContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
                    userAccessList: PropTypes.arrayOf(PropTypes.string)
                })
            ])
    )
};

export default HeaderDropdown;